import { Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import ChatBox from "./components/chat-box/chat-box.component";
import MessageBox from "./components/message-box/message-box.component";
import MessageInput from "./components/message-input/message-input.component";

import { ChatContext } from "src/contexts/chat.context";

import "./chat.style.scss";
import useTranslate from "src/hooks/use-translate";
import axios from "axios";
import { AuthContext } from "src/contexts/auth.context";
import Dropdown from "components/dropdown-menu/dropdown.component";
import { compareDate, getFormattedDateWithoutTime } from "utils/date.utils";

const Chat = () => {
    const { chats, selectedChat, setSelectedChat, setChats, chatBefore, joinGroupChat } = useContext(ChatContext);
    const { user } = useContext(AuthContext);

    const [chatPerson, setChatPerson] = useState(null);
    const [chattablePeopleList, setChattablePeopleList] = useState([]);
    const lastMessageRef = useRef(null);

    const t = useTranslate("Chat");

    const handleChatBoxClick = async (chatId, groupChat = null) => {
        const res = await axios.get(`messages?chatId=${chatId}${groupChat ? "&groupChat=1" : ""}}`);
        if (groupChat) joinGroupChat(chatId);
        if (res.status === 200) {
            res.data.messages.reverse();
            setSelectedChat({ ...res.data, ...chats[chatId] });
        }
    };

    const openChat = useCallback(async () => {
        if (!chatPerson) return;
        const oldChat = chatBefore(chatPerson.userId);

        if (oldChat) {
            const res = await axios.get(`messages?chatId=${oldChat.chatId}`);
            if (res.status === 200) {
                res.data.messages.reverse();
                setSelectedChat({ ...res.data, ...chats[oldChat.chatId] });
            }
        } else {
            await axios
                .post(`getChatId`, { chatUser: chatPerson?.userId ?? chatPerson.firmId, firmId: chatPerson?.userId ? null : chatPerson.firmId })
                .then((res) => {
                    if (res.status) {
                        const newChat = {
                            chatId: res.data.chatId,
                            lastMessage: "",
                            name: chatPerson.name + (chatPerson.surname ? " " + chatPerson.surname : ""),
                            chatUserId: chatPerson.userId,
                            color: chatPerson.color,
                            numberOfUnreads: 0,
                        };
                        setChats((oldChats) => {
                            const newChats = { ...oldChats };
                            newChats[newChat.chatId] = newChat;
                            return newChats;
                        });
                        setSelectedChat([]);
                    }
                });
            setSelectedChat();
        }
        setChatPerson(null);
    }, [chatPerson, chats, chatBefore, setSelectedChat, setChats]);

    const sortedChats = useMemo(() => {
        // order the chats by last message date
        const newChats = { ...chats };
        const orderOfChatsByLastMsg = Object.keys(newChats).sort((a, b) => {
            if (!newChats[a].lastMessage?.date) return 1;
            if (!newChats[b].lastMessage?.date) return -1;
            return new Date(newChats[b].lastMessage.date) - new Date(newChats[a].lastMessage.date);
        });
        const orderedChats = {};
        for (const chatId of orderOfChatsByLastMsg) {
            orderedChats[chatId] = newChats[chatId];
        }
        return orderedChats;
    }, [chats]);

    useEffect(() => {
        // fetch chattable people from the server
        if (!user) return;
        const fetchChattablePeople = async () => {
            const res = await axios.get(`chattablePeople`);
            if (res.status === 200) setChattablePeopleList(res.data);
        };
        fetchChattablePeople();
    }, [user]);

    useEffect(() => {
        // fetch chats from the server
        if (!user) return;
        const fetchChats = async () => {
            await axios.get("chatList").then((res) => {
                if (res.status === 200 && res.data) setChats(res.data);
            });
        };
        fetchChats();
    }, [user, setChats]);

    useEffect(() => {
        // open chat with the selected person
        if (chatPerson) openChat();
    }, [chatPerson, openChat]);

    useEffect(() => {
        if (lastMessageRef.current) {
            lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [selectedChat?.messages]);

    const Messages = () => (
        <>
            {selectedChat?.messages.map((messageInstance, index) => (
                // if the day of the message is different from the previous message, show the date
                <Fragment key={"msg" + messageInstance.messageId}>
                    <MessageBox messageInstance={messageInstance} lastMessageRef={index === 0 ? lastMessageRef : null} groupChat={selectedChat?.groupChat} />
                    {index < selectedChat.messages.length - 1 && !compareDate(messageInstance.date, selectedChat.messages[index + 1].date) && (
                        <div className="date-separator">{getFormattedDateWithoutTime(selectedChat.messages[index + 1].date)}</div>
                    )}
                </Fragment>
            ))}
        </>
    );

    return (
        <div className="chat-container">
            <div className="left-panel">
                <h1 className="bold with-line">{t("Chat Box")}</h1>
                <Dropdown className="margin-m--top" placeholder={t("Message someone")} list={chattablePeopleList} onSelected={setChatPerson} searchIcon />
                {Object.keys(sortedChats)
                    .reverse()
                    .map((chatId, index) => {
                        const chat = chats[chatId];
                        return (
                            <div key={chatId} className="chat-box-button-wrapper">
                                <div onClick={() => handleChatBoxClick(chatId, chat.groupChat)} className="chat-box-button">
                                    <ChatBox chat={chat} isSelected={selectedChat?.chatId?.toString() === chatId.toString()} />
                                </div>
                            </div>
                        );
                    })}
            </div>

            <div className="right-panel">
                {selectedChat && (
                    <Fragment>
                        <div className="header"></div>
                        <div className="messages-wrapper">
                            <Messages />
                        </div>
                        <div className="message-input-wrapper">
                            <MessageInput />
                        </div>
                    </Fragment>
                )}
            </div>
        </div>
    );
};

export default Chat;
