import { useContext } from "react";
import { format } from "date-fns";

import { AuthContext } from "src/contexts/auth.context";

import "./message-box.style.scss";
import { S3Context } from "src/contexts/s3.context";

const downloadIcon = require("src/assets/download.jpg");

const MessageBox = ({ messageInstance, lastMessageRef, groupChat }) => {
    const { downloadMsgFile } = useContext(S3Context);
    const {
        user: { userId },
    } = useContext(AuthContext);

    const { message, sourceId, date, type = 0, chatId, name, surname, color } = messageInstance;

    return (
        <div className={`size400 message-box ${userId === sourceId ? "my-message" : "others-message"} ${type === 1 && "file"}`} ref={lastMessageRef}>
            <div className="flex flex--column">
                {groupChat && (
                    <div className={`message-name ${userId === sourceId ? "my-message" : ""}`} style={{ backgroundColor: color ?? "turquoise" }}>
                        {`${name ?? ""} ${surname ?? ""}`}
                    </div>
                )}
                <div className="flex flex--center-items">
                    {type === 1 && <img className="download-img pointer" src={downloadIcon} alt="" onClick={() => downloadMsgFile(message, chatId)} />}
                    <div className="text">
                        {message}
                        <span className="time">{format(new Date(date), "HH:mm")}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MessageBox;
