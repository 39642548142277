import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";

import { AuthContext } from "./auth.context";

import { getIndexWhere, isObjectEmpty } from "utils/array.utils";
import axios from "axios";
import { S3Context } from "./s3.context";
import { LoadingContext } from "./loading.context";

export const ClientsContext = createContext({
    clients: [],
    openedClient: {},
    year: null,
    setClients: () => {},
    setOpenedClientById: () => {},
    addNewClient: () => {},
    updateCompanyInfo: () => {},
    updateCompanyInfoPartially: () => {},
    updateEmployees: () => {},
    removeEmployee: () => {},
    downloadClient: () => {},
    downloadFolder: () => {},
    downloadFolderWithRef: () => {},
    deleteClient: () => {},
    selectYear: () => {},
    fetchClients: () => {},
    updateClient: () => {},
});

const ClientsProvider = ({ children }) => {
    const { isSignedIn, user } = useContext(AuthContext);
    const { setLoading } = useContext(LoadingContext);
    const { downloadFolderAsZip, downloadFolderAsZipWithRef } = useContext(S3Context);

    const [clients, setClients] = useState([]); // list of clients
    const [year, setYear] = useState("2023"); // year selected among clients archive
    const [openedClient, setOpenedClient] = useState({}); // currently open client on the table

    const addNewClient = useCallback((newClient) => {
        // add newClient param to the clients
        setClients((oldValue) => [...oldValue, newClient]);
    }, []);

    const updateCompanyInfo = useCallback(
        // set the info of a specific company specified by its id to newInfoObject
        async (clientId, newInfoObject, isPersonalAccount) => {
            console.log(clientId, newInfoObject, isPersonalAccount);
            const isCompany = openedClient.firmId !== -1;
            const error = await axios
                .post("/changeClientInfo", { userId: user.userId, clientId, isCompany, newInfoObject })
                .then((res) => {
                    if (res.status !== 200) return true;
                    return null;
                })
                .catch((err) => {
                    console.error(err);
                    return true;
                });
            if (error) return;
            const clientIndex = getIndexWhere(clients, isCompany ? "firmId" : "userId", clientId);
            setClients((oldClients) => {
                const newClients = [...oldClients];
                newClients[clientIndex] = { ...newInfoObject };
                return newClients;
            });
        },
        [clients, openedClient, user]
    );

    const updateCompanyInfoPartially = useCallback(
        // update a specific key of a company info to the value that is sent as parameter
        async (clientId, key, value) => {
            const isCompany = openedClient.firmId !== -1;
            const clientIndex = getIndexWhere(clients, isCompany ? "firmId" : "userId", clientId);
            const error = await axios
                .post("/updateClientInfo", { clientId, key, value, isCompany })
                .then((res) => {
                    if (res.status !== 200) return true;
                    return null;
                })
                .catch((err) => {
                    console.error(err);
                    return true;
                });
            if (error) return;
            setClients((oldClients) => {
                const newClients = [...oldClients];
                newClients[clientIndex][key] = value;
                return newClients;
            });
        },
        [clients, openedClient]
    );

    const updateEmployees = useCallback(
        // set the employee list of a company to newClientList
        async (firmId, newClientList, employeeId, newPosition) => {
            const clientIndex = getIndexWhere(clients, "firmId", firmId);
            const err = await axios
                .post("/updatePositionEmployee", { firmId, employeeId, userId: user.userId, newPosition: newPosition })
                .then((res) => {
                    if (res.status !== 200) return true;
                    return null;
                })
                .catch(console.error);
            if (err) return;
            setClients((oldClients) => {
                const newClients = [...oldClients];
                newClients[clientIndex].employees = newClientList;
                return newClients;
            });
        },
        [clients, user]
    );

    const removeEmployee = useCallback(
        // set the employee list of a company to newClientList
        async (firmId, newClientList, employeeId) => {
            const clientIndex = getIndexWhere(clients, "firmId", firmId);
            const err = await axios
                .post("/removeEmployee", { firmId, employeeId, userId: user.userId })
                .then((res) => {
                    if (res.status !== 200) return true;
                    return null;
                })
                .catch(console.error);
            if (err) return;
            setClients((oldClients) => {
                const newClients = [...oldClients];
                newClients[clientIndex].employees = newClientList;
                return newClients;
            });
        },
        [clients, user]
    );

    // Download all client data/filesystem
    const downloadClient = useCallback(async () => {
        setLoading(true);
        const folderLocation = openedClient.firmId !== -1 ? "1" + openedClient.firmId : "0" + openedClient.userId;
        const name = openedClient?.surname ? openedClient.name + "_" + openedClient.surname : openedClient.name;
        await downloadFolderAsZip(folderLocation, name);
        setLoading(false);
    }, [openedClient, downloadFolderAsZip, setLoading]);

    // Download a folder as zip
    const downloadFolder = useCallback(
        async (folderName, directory, yearSelection) => {
            setLoading(true);
            let folderLocation;
            if (user.accountant === -1) folderLocation = openedClient.firmId !== -1 ? "1" + openedClient.firmId : "0" + openedClient.userId;
            else folderLocation = user.firmId !== -1 ? "1" + user.firmId : "0" + user.userId;
            folderLocation += "/" + yearSelection + "/" + directory.join("/") + "/" + folderName;
            await downloadFolderAsZip(folderLocation, folderName);
            setLoading(false);
        },
        [openedClient, user, downloadFolderAsZip, setLoading]
    );

    const downloadFolderWithRef = useCallback(
        async (folderName, directory, yearSelection) => {
            setLoading(true);
            let folderLocation;
            if (user.accountant === -1) folderLocation = openedClient.firmId !== -1 ? "1" + openedClient.firmId : "0" + openedClient.userId;
            else folderLocation = user.firmId !== -1 ? "1" + user.firmId : "0" + user.userId;
            folderLocation += "/" + yearSelection + "/" + directory.join("/") + "/" + folderName;
            const userId = isObjectEmpty(openedClient) ? user.userId : openedClient.userId;
            const firmId = isObjectEmpty(openedClient) ? user.firmId : openedClient.firmId;
            await downloadFolderAsZipWithRef(folderLocation, folderName, userId, firmId, yearSelection, directory);
            setLoading(false);
        },
        [openedClient, user, downloadFolderAsZipWithRef, setLoading]
    );

    const deleteClient = useCallback(
        async (clientId) => {
            // delete the client with clientId
            const isCompany = openedClient.firmId !== -1;
            const err = await axios.post("/deleteClient", { clientId, userId: user.userId, isCompany }).then((res) => {
                if (res.status !== 200) return true;
                return null;
            });
            if (err) return;
            setClients((oldClients) => {
                const isCompany = openedClient.firmId !== -1;
                const clientIndex = getIndexWhere(clients, isCompany ? "firmId" : "userId", clientId);
                const newClients = [...oldClients];
                newClients.splice(clientIndex, 1);
                return newClients;
            });
            setOpenedClient({});
        },
        [clients, openedClient, user]
    );

    /**
     * Set the opened client on the clients table by its id
     */
    const setOpenedClientById = useCallback(
        (userId, firmId) => {
            const idType = firmId === -1 ? "userId" : "firmId";
            let clientId = firmId;
            if (idType === "userId") clientId = userId;
            const client = clients.find((client) => client[idType] === clientId);
            if (client) setOpenedClient(client);
        },
        [clients]
    );

    /**
     * will be used to set the year when year is selected in client section the archive
     */
    const selectYear = useCallback((year) => {
        setYear(year);
    }, []);

    const fetchClients = useCallback(async () => {
        if (user?.role === 0) {
            await axios
                .get("getClients", { params: { userId: user.userId } })
                .then(({ data: { companies, clients } }) => {
                    setClients([...companies, ...clients]);
                })
                .catch((err) => console.error(err));
        }
    }, [user]);

    const updateClient = useCallback(
        (userId, firmId, key, value) => {
            const idType = firmId === -1 ? "userId" : "firmId";
            const clientId = idType === "userId" ? userId : firmId;
            setClients((oldClients) => {
                const clientIndex = getIndexWhere(clients, idType, clientId);
                const newClients = [...oldClients];
                newClients[clientIndex] = { ...newClients[clientIndex], [key]: value };
                return newClients;
            });
        },
        [clients]
    );

    // Fetch the clients from the server
    useEffect(() => {
        fetchClients();
    }, [fetchClients]);

    useEffect(() => {
        // update opened client if clients change
        if (clients.length > 0 && !isObjectEmpty(openedClient)) setOpenedClientById(openedClient.userId, openedClient.firmId);
    }, [clients, openedClient, setOpenedClientById]);

    const value = useMemo(
        () =>
            !isSignedIn || user?.role !== 0
                ? { downloadFolder, downloadFolderWithRef }
                : {
                      clients,
                      openedClient,
                      year,
                      setClients,
                      setOpenedClientById,
                      addNewClient,
                      updateCompanyInfo,
                      updateCompanyInfoPartially,
                      updateEmployees,
                      removeEmployee,
                      downloadClient,
                      downloadFolder,
                      downloadFolderWithRef,
                      deleteClient,
                      selectYear,
                      fetchClients,
                      updateClient,
                  },
        [
            clients,
            openedClient,
            year,
            setClients,
            setOpenedClientById,
            addNewClient,
            updateCompanyInfo,
            updateCompanyInfoPartially,
            updateEmployees,
            removeEmployee,
            downloadClient,
            downloadFolder,
            downloadFolderWithRef,
            deleteClient,
            selectYear,
            fetchClients,
            updateClient,
            isSignedIn,
            user,
        ]
    );
    if (!isSignedIn || user?.role !== 0) return <ClientsContext.Provider value={value}>{children}</ClientsContext.Provider>;

    return <ClientsContext.Provider value={value}>{children}</ClientsContext.Provider>;
};

export default ClientsProvider;
