import Image from "components/image/image.component";
import InputField from "components/input-field/input-field.component";
import { useContext, useEffect, useState } from "react";

import { COLORS } from "utils/lists.utils";
import { NewClientContext } from "src/contexts/new-client.context";
import useTranslate from "src/hooks/use-translate";
import { AuthContext } from "src/contexts/auth.context";
import axios from "axios";
import WarningBox from "components/warning-box/warning-box.component";

const Page4_1 = ({ canContinue, toggle, index }) => {
    const {
        newCompanyData: { color = 1 },
        employees,
        updateEmployeeData,
    } = useContext(NewClientContext);
    const {
        user: { userId },
    } = useContext(AuthContext);
    const [emailExist, setEmailExist] = useState(false);

    const t = useTranslate("NewClientModal");

    const { name = "", surname = "", username = "", jobTitle = "", email = "", phone = "" } = employees[index];

    useEffect(() => {
        if (canContinue && (name === "" || surname === "" || username === "" || jobTitle === "" || email === "" || phone === "" || emailExist)) toggle();
        if (
            !canContinue &&
            name.length > 0 &&
            surname.length > 0 &&
            username.length > 0 &&
            jobTitle.length > 0 &&
            email.length > 0 &&
            phone.length > 0 &&
            !emailExist
        )
            toggle();
    }, [toggle, canContinue, name, surname, username, jobTitle, email, phone, emailExist]);

    const handleChange = (key, value) => {
        updateEmployeeData(index, key, value);
    };

    const checkEmailExist = async (email) => {
        await axios
            .post("checkEmail", { userId, email })
            .then((res) => {
                if (res.status !== 200) setEmailExist(true);
                else setEmailExist(false);
            })
            .catch(() => setEmailExist(true));
    };

    return (
        <div className="page-container page4-1">
            <p className="margin-xl--top w600">
                {t("Step")} 4.{index + 1} <span className="red">|</span> {t("Member")} #{index + 1}
            </p>
            <div className="grid  gap-l margin-xl--top">
                <div id="left" className="flex flex--column flex--space-between-content">
                    <span className="image-wrapper" style={{ borderColor: COLORS[color] }}>
                        <Image name={name + " " + surname} color={COLORS[color]} />
                    </span>
                    <div className="flex flex--column gap-xl">
                        <InputField
                            fieldType="underline"
                            label={t("Name")}
                            placeholder="Jane"
                            onChange={({ target: { value } }) => handleChange("name", value)}
                            value={name}
                        />
                        <InputField
                            fieldType="underline"
                            label={t("Surname")}
                            placeholder="Doe"
                            onChange={({ target: { value } }) => handleChange("surname", value)}
                            value={surname}
                        />
                    </div>
                </div>
                <div id="right" className="flex flex--column gap-xl">
                    <InputField
                        fieldType="underline"
                        label={t("Username")}
                        placeholder="janeDoe001"
                        onChange={({ target: { value } }) => handleChange("username", value)}
                        value={username}
                    />
                    <InputField
                        fieldType="underline"
                        label={t("Job Title")}
                        placeholder="Chief Financial Officer"
                        onChange={({ target: { value } }) => handleChange("jobTitle", value)}
                        value={jobTitle}
                    />
                    <InputField
                        fieldType="underline"
                        label={t("Email")}
                        type="email"
                        placeholder="jane.doe@my-company.com"
                        onChange={({ target: { value } }) => handleChange("email", value)}
                        value={email}
                        onBlur={({ target: { value } }) => checkEmailExist(value)}
                    />
                    {emailExist && <WarningBox text={t("email-exists")} size="0.3rem" fontSize="0.6rem" lineHeight="0.3rem" />}

                    <InputField
                        fieldType="underline"
                        label={t("Phone")}
                        type="tel"
                        placeholder="+0123456789"
                        onChange={({ target: { value } }) => handleChange("phone", value)}
                        value={phone}
                    />
                </div>
            </div>
        </div>
    );
};

export default Page4_1;
