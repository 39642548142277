import { ReactComponent as QuestionMark } from "assets/question-mark.svg";

import "./YesNoBox.style.scss";
import useTranslate from "src/hooks/use-translate";

const YesNoBox = ({
    text = "",
    leftOption = "Cancel",
    rightOption = "Continue",
    rightOptionRed = false,
    setVisible = () => {},
    setApproved = () => {},
    setDenied = () => {},
}) => {
    const t = useTranslate("YesNoBox");
    const cancel = () => {
        setVisible(false);
        setDenied();
    };

    const approve = () => {
        setApproved(true);
        setVisible(false);
    };

    return (
        <div className="question-container flex flex--column flex--center-items border semi-round-corners gap-l padding-l size400">
            <QuestionMark className="question-mark margin-xs" />
            <div className="text-area padding-xs padding-l--inline margin-xs--bottom">{text}</div>
            <div className="flex flex--center-content width100">
                <div className="btn btn--hover round-corners padding-s margin-s--inline pointer flex1 text-center flex flex--center" onClick={cancel}>
                    {t(leftOption)}
                </div>
                <div
                    className={`btn btn--hover round-corners padding-s margin-s--inline pointer flex1 text-center ${rightOptionRed ? "btn--red-text" : ""}`}
                    onClick={approve}>
                    {rightOption}
                </div>
            </div>
        </div>
    );
};

export default YesNoBox;
