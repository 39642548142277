import InputField from "components/input-field/input-field.component";
import { useContext, useEffect } from "react";
import { COLORS } from "utils/lists.utils";
import { NewClientContext } from "src/contexts/new-client.context";
import useTranslate from "src/hooks/use-translate";

const PagePersonal2_1 = ({ canContinue, toggle }) => {
	const {
		newCompanyData: { name = "W", surname = "W", color, country, city, address, zipCode },
		updateNewCompanyData,
	} = useContext(NewClientContext);
	const t = useTranslate("NewClientModal");

	useEffect(() => {
		if (
			!canContinue &&
			country.length > 0 &&
			city.length > 0 &&
			address.length > 0 &&
			zipCode.length > 0
		)
			toggle();
		else if (canContinue && (country === "" || city === "" || address === "" || zipCode === ""))
			toggle();
	}, [toggle, canContinue, color, country, city, address, zipCode]);

	const handleChange = (key, value) => {
		updateNewCompanyData(key, value);
	};

	return (
		<div className="page-container page2-1-personal padding-xxl--right">
			<p className="margin-xl--top w600">
				Step 2.1 <span className="red">|</span> Register the Client
			</p>
			<div className="grid gap-xxxxl flex--grow margin-xl--top">
				<div id="left" className="flex flex--column gap-xxl">
					{/* <span className="flex gap-m"> */}
					<InputField
						// className="flex--grow"
						fieldType="underline"
						label={t("Country")}
						placeholder={t("country of this person")}
						value={country}
						onChange={({ target: { value } }) => {
							handleChange("country", value);
						}}
					/>
					<InputField
						// className="flex--grow"
						fieldType="underline"
						label={t("City")}
						placeholder={t("city of this person")}
						value={city}
						onChange={({ target: { value } }) => {
							handleChange("city", value);
						}}
					/>
					{/* </span> */}
					<InputField
						fieldType="underline"
						label={t("Address")}
						placeholder={t("address of this person")}
						value={address}
						onChange={({ target: { value } }) => {
							handleChange("address", value);
						}}
					/>
					<InputField
						fieldType="underline"
						label={t("Postal Zip Code")}
						placeholder={t("zip code")}
						value={zipCode}
						onChange={({ target: { value } }) => {
							handleChange("zipCode", value);
						}}
					/>
				</div>
				<div id="right">
					<div className="flex flex--column gap-s">
						<div className="image flex flex--center" style={{ borderColor: COLORS[color] }}>
							{name[0].toUpperCase() + surname[0].toUpperCase()}
						</div>
						<p className="w600 size300 margin-m--top">{t("Representative Color")}</p>
						<p className="size200">{t("Please choose a representative color for yourself")}</p>
						<div className="flex gap-xs" style={{ maxWidth: "60%", flexWrap: "wrap" }}>
							{COLORS.map((currentColor, index) => {
								return (
									<div
										key={index}
										onClick={() => handleChange("color", index)}
										className={`color-picker pointer ${index === color && "selected-color"}`}
										style={{
											backgroundColor: currentColor,
											color: currentColor === "#000000" ? "white" : "black",
										}}
									/>
								);
							})}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PagePersonal2_1;
