import { ControlledMenu, MenuItem } from "@szhsin/react-menu";

import "@szhsin/react-menu/dist/core.css";
import "./context-menu.style.scss";

const ContextMenu = ({ list = [], menuProps = {}, toggleMenu = () => {}, anchorPoint = {} }) => {
	return (
		<ControlledMenu
			onContextMenu={(e) => {
				e.preventDefault();
			}}
			{...menuProps}
			anchorPoint={anchorPoint}
			onClose={() => toggleMenu(false)}
		>
			{list.map(({ onClick = () => {}, label = "" }, index) => {
				return (
					<MenuItem key={index} onClick={onClick}>
						{label}
					</MenuItem>
				);
			})}
		</ControlledMenu>
	);
};

export default ContextMenu;
