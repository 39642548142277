import { format } from "date-fns";

// const months = {
// 	0: "January",
// 	1: "February",
// 	2: "March",
// 	3: "April",
// 	4: "May",
// 	5: "June",
// 	6: "July",
// 	7: "August",
// 	8: "September",
// 	9: "October",
// 	10: "November",
// 	11: "December",
// };

export const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const formattedDate = format(date, "LLLL dd, yyyy HH:mm");

    return formattedDate;
};

export const getFormattedDate = (dateStr) => {
    const date = new Date(dateStr);
    const formattedDate = format(date, "LLLL dd, yyyy HH:mm");

    return new Date(formattedDate);
};

export const getFormattedDateWithoutTime = (dateStr) => {
    const date = new Date(dateStr);
    const formattedDate = format(date, "dd/MM/yyyy");
    return formattedDate;
};

export const getFormattedNow = (dateStr) => {
    const now = new Date();
    const formattedDate = formatDate(now);

    return new Date(formattedDate);
};

export const getNow = () => {
    const now = new Date();
    const formattedDate = formatDate(now);

    return formattedDate;
};

/**
 * Checks if the two dates are the same without time
 * @param {dateStr1} dateStr1
 * @param {dateStr2} dateStr2
 * @returns boolean
 */
export const compareDate = (dateStr1, dateStr2) => {
    const date1 = new Date(dateStr1);
    const date2 = new Date(dateStr2);

    return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
};
