/**
 * turns the string into a float
 * @param {string} formattedString
 * @returns float
 */
export const parseAsFloat = (formattedString) => {
    if (typeof formattedString !== "string") return parseFloat(formattedString);
    let parsedString = formattedString;
    return parseFloat(parsedString.replace(/[^0-9.]/g, ""));
};

/**
 * removes all the characters except for numbers and dots
 * @param {string} formattedString
 * @returns formattedString
 */
export const formatFloat = (formattedString) => {
    let parsedString = formattedString;
    return parsedString.replace(/[^0-9.]/g, "");
};
