import { useState } from "react";

import { BsEyeFill, BsPencilFill } from "react-icons/bs";

import "./toggle.tab.style.scss";

const TabToggle = ({ value = false, onClick = (_) => {} }) => {
	const [isChecked, setIsChecked] = useState(value);

	const handleClick = () => {
		setIsChecked(!isChecked);
		onClick(!isChecked);
	};

	return (
		<div onClick={handleClick} className="toggle-tab-container unselectable">
			<div className={`toggle ${isChecked && "checked"}`} />
			<span className={`toggle-text ${!isChecked && "selected"}`}>
				<BsEyeFill /> View
			</span>
			<span className={`toggle-text ${isChecked && "selected"}`}>
				<BsPencilFill /> Edit
			</span>
		</div>
	);
};

export default TabToggle;
