import { useContext } from "react";
import { getDate, isSameDay, isSameMonth } from "date-fns";

import { CalendarContext } from "src/contexts/calendar.context";
import { InvoiceContext } from "src/contexts/invoice.context";

import "../calendar.style.scss";

const DayView = () => {
    const { calendar, selectedDay, shownMonth, shownYear, changeDay } = useContext(CalendarContext);
    const { isDayFilled, calculatedBalanceChange } = useContext(InvoiceContext);

    const handleClick = (day) => {
        changeDay(day);
    };

    return (
        <div className="day-view-wrapper">
            <div className="day-names unselectable">
                {["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"].map((dayName) => {
                    return (
                        <div key={dayName} className="dayNames">
                            {dayName}
                        </div>
                    );
                })}
            </div>
            {calendar.map((week) => {
                return (
                    <div key={week} className="week">
                        {week.map((day) => {
                            return (
                                <div
                                    key={day}
                                    onClick={() => {
                                        handleClick(day);
                                    }}
                                    className={`day unselectable
										${!isSameMonth(day, new Date(shownYear, shownMonth, 1, 0, 0, 0, 0)) && "other-month"}
										${isDayFilled(day) && "filled"}
										${isSameDay(day, new Date()) && "today"}
										${isSameDay(day, selectedDay) && "selected"}
                                        ${isSameDay(day, selectedDay) && parseInt(calculatedBalanceChange) > 0 && "green"}
                                        ${isSameDay(day, selectedDay) && parseInt(calculatedBalanceChange) < 0 && "red"}
										`}>
                                    {getDate(day)}
                                </div>
                            );
                        })}
                    </div>
                );
            })}
        </div>
    );
};

export default DayView;
