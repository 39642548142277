import { useState } from "react";

import "./filter-modal.style.scss";
import useTranslate from "src/hooks/use-translate";

const FilterModal = ({ categories = [], filters = null, dismiss = () => {}, onClear = () => {}, onFilter = () => {} }) => {
    const t = useTranslate("Clients");
    const [localFilters, setLocalFilters] = useState(
        filters ?? {
            clientType: [], // for Client Type
            subscription: [], // for Subscription
            archivePeriod: [], // for Archive Period
            safeLockingPeriod: [], // for Safe Locking PeriodSafe Locking Period
            liveChat: [], // for Live Chat
            mobile: [], // for Mobile App
        }
    );

    const handleSubmit = () => {
        onFilter(localFilters);
        dismiss();
    };

    const handleClear = () => {
        onClear();
        dismiss();
    };

    const handleChange = ({ category, choice }) => {
        setLocalFilters((oldValue) => {
            const newValue = { ...oldValue };
            const index = newValue[category].indexOf(choice);
            if (index === -1) {
                // newValue[category].push(choice);
                newValue[category] = [...newValue[category], choice];
            } else {
                // choice is already selected so unselect it
                console.log(index, category, newValue[category]);
                newValue[category].splice(index, 1);
            }
            return newValue;
        });
    };
    return (
        <div className="filter-modal-container absolute fill">
            <div onClick={dismiss} className="absolute fill" />
            <div className="modal absolute smooth-corners padding-m--inline padding-m--bottom">
                {categories.slice(0, 4).map(({ key, label, choices }, categoryIndex) => {
                    return (
                        <div key={key} className="border--bottom padding-s--bottom padding-m--top filter-instance-wrapper margin-s--block">
                            <p className="size300 w600 padding-s--bottom">{t(label)}</p>
                            <div className="flex gap-s size300 w400">
                                {choices.map((choice, choiceIndex) => {
                                    const isSelected = localFilters[key].indexOf(choiceIndex) !== -1;
                                    return (
                                        <div
                                            key={choice}
                                            onClick={() =>
                                                handleChange({
                                                    category: key,
                                                    choice: choiceIndex,
                                                })
                                            }
                                            className={`pointer ${isSelected && "selected"}`}>
                                            {t(choice)}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    );
                })}
                <div className="flex gap-xxl">
                    {categories.slice(4).map(({ key, label, choices }, categoryIndex) => {
                        return (
                            <div key={key} className="flex--grow border--bottom padding-s--bottom padding-m--top filter-instance-wrapper">
                                <p className="size300 w600 padding-s--bottom">{t(label)}</p>
                                <div className="flex gap-s size300 w400">
                                    {choices.map((choice, choiceIndex) => {
                                        const isSelected = localFilters[key].indexOf(choiceIndex) !== -1;
                                        return (
                                            <div
                                                key={choice}
                                                onClick={() => {
                                                    handleChange({
                                                        category: key,
                                                        choice: choiceIndex,
                                                    });
                                                }}
                                                className={`pointer ${isSelected && "selected"}`}>
                                                {t(choice)}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className="flex gap-l margin-l--top">
                    <button onClick={handleClear} className="w500 size300 smooth-corners padding-s--block clear">
                        {t("clear all")}
                    </button>
                    <button onClick={handleSubmit} className="w500 size300 smooth-corners padding-s--block filter">
                        {t("filter")}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default FilterModal;
