import Image from "components/image/image.component";
import InputField from "components/input-field/input-field.component";
import { useContext, useState } from "react";

import { ClientsContext } from "src/contexts/clients.context";

import useTranslate from "src/hooks/use-translate";
import { getItemWhere } from "utils/array.utils";

import { COLORS } from "utils/lists.utils";

import "./info-sub-category.style.scss";

const InfoSubTab = ({ id }) => {
    const { updateCompanyInfo, clients } = useContext(ClientsContext);
    const t = useTranslate("Clients");

    const [currentClient, setCurrentClient] = useState(getItemWhere(clients, "userId", id));

    const { name, surname, username, vatNumber, jobTitle, color, email, phone, address } = currentClient;

    const handleChange = (key, value) => {
        setCurrentClient((oldValue) => {
            const newValue = { ...oldValue, [key]: value };
            return newValue;
        });
    };
    const handleSave = () => {
        updateCompanyInfo(id, currentClient, false);
    };
    const handleCancel = () => {
        setCurrentClient(getItemWhere(clients, "userId", id));
    };

    return (
        <div className="info-sub-category-container padding-m--left">
            <p className="with-line w600">{t("Client Information")}</p>
            <div className="grid">
                <div className="left-side">
                    <div className="size800 w600 image" style={{ borderColor: color }}>
                        <Image name={name + " " + surname} color={color} />
                    </div>
                    <div className="flex flex--column gap-s">
                        <div className="flex gap-m margin-l--top">
                            <InputField
                                className="flex--grow size400 padding-xs--bottom"
                                label={t("Name")}
                                fieldType="underline"
                                value={surname.length > 0 ? name + " " + surname : name}
                                onChange={({ target: { value } }) => {
                                    if (value.lastIndexOf(" ") === -1 || value.lastIndexOf(" ") === value.length - 1) {
                                        handleChange("name", value);
                                        handleChange("surname", "");
                                        return;
                                    }

                                    const newName = value.slice(0, value.lastIndexOf(" "));
                                    const newSurname = value.slice(value.lastIndexOf(" ") + 1);
                                    handleChange("name", newName);
                                    handleChange("surname", newSurname);
                                }}
                            />
                            <InputField
                                className="flex--grow size400 padding-xs--bottom"
                                label={t("Username")}
                                fieldType="underline"
                                value={username}
                                onChange={({ target: { value } }) => {
                                    handleChange("username", value);
                                }}
                            />
                        </div>
                        <InputField
                            className="size400 padding-xs--bottom"
                            label={t("VAT Number")}
                            fieldType="underline"
                            value={vatNumber}
                            onChange={({ target: { value } }) => {
                                handleChange("vatNumber", value);
                            }}
                        />
                        <InputField
                            className="size400 padding-xs--bottom"
                            label={t("Job Title")}
                            fieldType="underline"
                            value={jobTitle}
                            onChange={({ target: { value } }) => {
                                handleChange("jobTitle", value);
                            }}
                        />
                    </div>
                </div>
                <div className="right-side">
                    <div className="flex flex--column gap-m">
                        <span className="flex gap-m">
                            <InputField
                                className="flex--grow size400 padding-xs--bottom"
                                label={t("Email")}
                                fieldType="underline"
                                value={email}
                                onChange={({ target: { value } }) => {
                                    handleChange("email", value);
                                }}
                            />
                            <InputField
                                className="flex--grow size400 padding-xs--bottom"
                                label={t("Phone")}
                                fieldType="underline"
                                value={phone}
                                onChange={({ target: { value } }) => {
                                    handleChange("phone", value);
                                }}
                            />
                        </span>
                        <InputField
                            className="flex--grow size400 padding-xs--bottom"
                            label={t("Location")}
                            fieldType="underline"
                            value={address}
                            onChange={({ target: { value } }) => {
                                handleChange("address", value);
                            }}
                        />
                        <p className="flex gap-l w600">
                            {t("Client Type")} <span className="w500 red">PP</span>
                        </p>
                        <span name="color-picker" className="flex flex--column gap-s">
                            <p className="w600 size400 margin-m--top">{t("Representative Color")}</p>
                            <p className="size300">{t("Please choose a representative color for yourself")}</p>
                            <span className="flex gap-xs" style={{ maxWidth: "60%" }}>
                                {COLORS.map((currentColor, index) => {
                                    return (
                                        <div
                                            key={index}
                                            onClick={() => handleChange("color", COLORS[index])}
                                            className={`color-picker pointer ${currentColor === color && "selected-color"}`}
                                            style={{
                                                backgroundColor: currentColor,
                                                color: currentColor === "#000000" ? "white" : "black",
                                            }}
                                        />
                                    );
                                })}
                            </span>
                        </span>
                    </div>
                </div>
            </div>
            <div className="flex gap-s flex--end-content margin-m--top">
                <button onClick={handleSave} className="size400 w500" style={{ width: "auto" }}>
                    {t("Save")}
                </button>
                <button onClick={handleCancel} className="size400 w500 cancel">
                    {t("Cancel")}
                </button>
            </div>
        </div>
    );
};

export default InfoSubTab;
