import { useContext, useEffect, useMemo } from "react";
import { NewClientContext } from "src/contexts/new-client.context";
import useTranslate from "src/hooks/use-translate";

const PagePersonal3 = ({ canContinue, toggle }) => {
    const {
        newCompanyData: { archivePeriod, safeLockingPeriod },
        updateNewCompanyData,
    } = useContext(NewClientContext);

    const t = useTranslate("NewClientModal");

    const FIELDS = useMemo(
        () => [
            {
                title: t("Archive Period"),
                info: t("The period which Client’s accounts of ACHANTS, VENTES, and TVA will be examined by the accountant."),
                choices: ["1 Box", "2 Box", "4 Box", "12 Box"],
                key: "archivePeriod",
                value: archivePeriod,
            },
            {
                title: t("Safe Locking Period"),
                info: t("The period which Client’s safe to be locked. Client will get notifications about their upcoming safe locking deadlines."),
                choices: [`2 ${t("Weeks")}`, `1 ${t("Month")}`, `3 ${t("Months")}`, `6 ${t("Months")}`, t("Inactivated")],
                key: "safeLockingPeriod",
                value: safeLockingPeriod,
            },
        ],
        [archivePeriod, safeLockingPeriod, t]
    );

    useEffect(() => {
        if (!canContinue && archivePeriod != null && safeLockingPeriod != null) toggle();
        if (canContinue && (archivePeriod == null || safeLockingPeriod == null)) toggle();
    }, [toggle, canContinue, archivePeriod, safeLockingPeriod]);

    const handleChange = (key, value) => {
        updateNewCompanyData(key, value);
    };

    return (
        <div className="page-container page5">
            <p className="size400 w600 margin-xl--top">
                Step 3 <span className="red">|</span> Select Accounting Plans
            </p>
            <div className="flex flex--column gap-xxxl margin-m--top">
                {FIELDS.map(({ title, info, choices, key, value }, index) => {
                    return (
                        <div key={index}>
                            <p className="size400 w600 margin-s--bottom">{title}</p>
                            <p className="size300 margin-m--bottom">{info}</p>
                            <div className="flex gap-s">
                                {choices.map((choice, index) => {
                                    return (
                                        <button
                                            key={choice}
                                            className={`size400 btn smooth-corners padding-l--inline padding-s--block ${value === index && "btn--selected"}`}
                                            onClick={() => {
                                                handleChange(key, index);
                                            }}>
                                            {choice}
                                        </button>
                                    );
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default PagePersonal3;
