import { useContext, useEffect } from "react";
import { NewClientContext } from "src/contexts/new-client.context";
import useTranslate from "src/hooks/use-translate";

const Page3 = ({ canContinue, toggle }) => {
    const { admins, updateNumberOfAdmins } = useContext(NewClientContext);
    const t = useTranslate("NewClientModal");

    useEffect(() => {
        if (!canContinue && admins.length > 0) toggle();
        else if (canContinue && admins.length === 0) toggle();
    }, [toggle, canContinue, admins]);

    const handleClick = (numberOfAdmins) => {
        updateNumberOfAdmins(numberOfAdmins);
    };

    return (
        <div className="page-container page3">
            <p className="size400 w600 margin-xl--top">
                {t("step")} 3 <span className="red">|</span> {t("determine-admins")}
            </p>
            <p className="size300 w400 margin-m--top">{t("max3-admin")}</p>
            <div className="flex gap-m margin-xl--top">
                {[1, 2, 3].map((number) => {
                    return (
                        <button
                            key={number}
                            onClick={() => {
                                handleClick(number);
                            }}
                            className={`btn smooth-corners padding-m--block padding-l--inline ${admins.length === number && "btn--selected"}`}>
                            {number}
                        </button>
                    );
                })}
            </div>
            <div className="flex flex--grow flex--center gap-l"></div>
        </div>
    );
};

export default Page3;
