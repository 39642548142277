import { useContext, useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";

import InputField from "components/input-field/input-field.component";

import { TicketsContext } from "src/contexts/tickets.context";
import { AuthContext } from "src/contexts/auth.context";

import useTranslate from "src/hooks/use-translate";

import "./new-ticket-modal.style.scss";
import RichTextEditor from "../richt-text-editor/rich-text-editor.component";
import Dropdown from "components/dropdown-menu/dropdown.component";
import axios from "axios";
import { S3Context } from "src/contexts/s3.context";

const NewTicketModal = ({ dismiss = () => {} }) => {
    const {
        user: { role, userId },
    } = useContext(AuthContext);
    const { createTicket } = useContext(TicketsContext);
    const { uploadTicketFilesToS3 } = useContext(S3Context);

    const [subject, setSubject] = useState("");
    const [assignedTo, setAssignedTo] = useState(null);
    const [client, setClient] = useState(null); // client of related ticket / first field in ticket
    const [clientList, setClientList] = useState([]);
    const [companyPeopleList, setCompanyPeopleList] = useState([]);
    const [onBlurIndicator, setOnBlurIndicator] = useState(false);
    const [disablePeopleSelect, setDisablePeopleSelect] = useState(false);
    const [files, setFiles] = useState([]);

    const t = useTranslate("Tickets");

    const handleSave = async (text) => {
        const ticketId = await createTicket(
            subject,
            text,
            assignedTo,
            files.map((file) => file.name)
        );
        if (files.length > 0) uploadTicketFilesToS3(ticketId, files);
        dismiss();
    };

    useEffect(() => {
        if (role !== 0) return;
        const fetchClients = async () => {
            const res = await axios.post("getClientList", { userId });
            if (res.status !== 200) return;
            setClientList([...res.data.companies, ...res.data.users]);
        };
        fetchClients();
    }, [userId, role]);

    useEffect(() => {
        // console.log(onBlurIndicator);
        if (!onBlurIndicator || role !== 0) {
            return;
        }
        if (client?.firmId === -1) {
            setAssignedTo(client);
            if (!disablePeopleSelect) setDisablePeopleSelect(true);
            return;
        }
        if (disablePeopleSelect) setDisablePeopleSelect(false);

        const fetchCompanyPeople = async () => {
            const res = await axios.post("getCompanyPeopleList", { userId, firmId: client.firmId });
            if (res.status !== 200) return;
            setCompanyPeopleList(res.data);
        };
        fetchCompanyPeople();
        setOnBlurIndicator(false);
    }, [onBlurIndicator, userId, role, client, disablePeopleSelect]);

    return (
        <div className="new-ticket-modal-container absolute fill">
            <div onClick={dismiss} className="absolute fill" />
            <div className="modal flex flex--column smooth-corners padding-xxl--inline padding-xxl--block">
                <div className="flex flex--space-between-content border--bottom border--thick border--red padding-s--bottom margin-l--bottom">
                    <p className="size500 w600">{t("New Ticket")}</p>
                    <IoClose onClick={dismiss} className="pointer" size={"1.6rem"} />
                </div>
                {role === 0 && (
                    <div className="flex margin-xxl--bottom gap-s">
                        <p className="w500">{t("assigned to")}</p>
                        <p className="w600 red margin-xxxl--right">|</p>
                        <p className="w500 margin-m--right">{t("Client")}</p>
                        <Dropdown
                            className="margin-xl--right"
                            placeholder={t("Type the name of the company/client")}
                            list={clientList}
                            onSelected={setClient}
                            onBlurSetState={setOnBlurIndicator}
                        />
                        <p className="w500 margin-m--right">{t("Person")}</p>
                        <Dropdown
                            placeholder={disablePeopleSelect ? t("You already selected a single client") : t("Select the person in the company")}
                            list={companyPeopleList}
                            onSelected={setAssignedTo}
                            disabled={disablePeopleSelect}
                        />
                    </div>
                )}
                <div className="flex margin-xxl--bottom gap-s">
                    <p className="w500">{t("Subject")}</p>
                    <p className="w600 red">|</p>
                    <InputField
                        className="size400 flex--grow"
                        style={{ paddingTop: 0 }}
                        fieldType="underline"
                        placeholder={t("Type the topic you want support from your accountant...")}
                        value={subject}
                        onChange={({ target: { value } }) => setSubject(value)}
                    />
                </div>
                <RichTextEditor dismiss={dismiss} save={handleSave} files={files} setFiles={setFiles} />
            </div>
        </div>
    );
};

export default NewTicketModal;
