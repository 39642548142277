import { useContext } from "react";

import Person from "./person/person.component";

import { ClientsContext } from "src/contexts/clients.context";

import "./people-tab.style.scss";
import { getItemWhere } from "utils/array.utils";

const sortEmployeeList = (list) => {
    const admins = list.filter((person) => person.position === 0);
    const members = list.filter((person) => person.position === 1);
    return [...admins, ...members];
};

const PeopleTab = ({ id }) => {
    const { clients } = useContext(ClientsContext);
    let client = getItemWhere(clients, "firmId", id);
    if (!client) client = getItemWhere(clients, "userId", id);
    const { firmId } = client;

    let data, color;
    if (firmId === -1) {
        data = getItemWhere(clients, "userId", id);
        ({ color } = data);
    } else {
        data = getItemWhere(clients, "firmId", id).employees;
        ({ color } = getItemWhere(clients, "firmId", id));
        data = sortEmployeeList(data);
    }

    if (firmId === -1) {
        return <Person data={data} color={color} />;
    }

    return (
        <div className="people-tab-container">
            {data.map((employee, index) => {
                return <Person key={index} data={employee} color={color} />;
            })}
        </div>
    );
};

export default PeopleTab;
