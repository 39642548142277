import { useContext, useEffect, useState } from "react";

import { ClientsContext } from "src/contexts/clients.context";

import useTranslate from "src/hooks/use-translate";

import "./delete-sub-tab.style.scss";
import YesNoBox from "components/yes-no-box/YesNoBox.component";
import Modal from "components/modal/modal.component";
import { FolderContext } from "src/contexts/folder.context";

const DeleteSubTap = ({ id }) => {
    const { downloadClient, deleteClient } = useContext(ClientsContext);
    const { setClientFiles } = useContext(FolderContext);
    const t = useTranslate("Account");
    const [showYesNo, setShowYesNo] = useState(false);
    const [approvedDelete, setApprovedDelete] = useState(false);

    const handleDownload = () => {
        downloadClient();
    };

    const handleDelete = () => {
        setShowYesNo(true);
    };

    useEffect(() => {
        if (approvedDelete) {
            deleteClient(id);
            // delete the attribute with clientId from the clientFiles
            setClientFiles((oldFiles) => {
                const newFiles = { ...oldFiles };
                delete newFiles[id];
                return newFiles;
            });
            setApprovedDelete(false);
        }
    }, [approvedDelete, deleteClient, id, setClientFiles]);

    return (
        <div className="people-sub-category-container padding-m--left">
            <p className="size400 w600 with-line margin-l--bottom">{t("Delete Client")}</p>
            <div style={{ width: "95%" }}>
                <p className="size300 w400 margin-xxl--bottom">{t("delete-undone")}</p>
                <p className="size300 w600 margin-s--bottom">{t("Client’s Data")}</p>
                <p className="size300 w400 margin-l--bottom">{t("download-all-before-deleting")}</p>
            </div>
            <span className="button-container flex gap-l">
                <button onClick={handleDownload} className="w500 flex--grow border smooth-corners padding-xs--block">
                    {t("download")}
                </button>
                <button onClick={handleDelete} className="w500 flex--grow border smooth-corners padding-xs--block btn-delete">
                    {t("continue")}
                </button>
            </span>
            {showYesNo && (
                <Modal
                    modal={
                        <YesNoBox
                            setVisible={setShowYesNo}
                            setApproved={setApprovedDelete}
                            text={t("delete-user-warning")}
                            rightOption={t("remove-person")}
                            rightOptionRed
                        />
                    }
                    touchable
                    dismiss={[setShowYesNo]}
                />
            )}
        </div>
    );
};

export default DeleteSubTap;
