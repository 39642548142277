import { useEffect, useState } from "react";
import "./modal.style.scss";

const Modal = ({ modal = <div />, dismiss = [], touchable = false, closeIn3 = false, fullscreen = false, blur = false }) => {
    const [invisibility, setInvisibility] = useState(false);

    useEffect(() => {
        if (closeIn3) {
            const timeout = setTimeout(() => {
                dismiss.forEach((func) => {
                    func(false);
                });
                setInvisibility((invisibility) => true);
            }, 3000);
            return () => {
                clearTimeout(timeout);
            };
        }
    }, [dismiss, closeIn3]);

    const closeModal = () => {
        dismiss.forEach((func) => {
            func(false);
        });
        setInvisibility(true);
    };

    return (
        <div
            className={`modal-container flex flex--center ${fullscreen ? "" : "absolute"} fill ${invisibility ? "invisible" : ""}`}
            style={
                fullscreen
                    ? {
                          position: "fixed",
                          inset: "0",
                          zIndex: "100",
                          backdropFilter: blur ? "blur(2px)" : "none",
                      }
                    : {}
            }
            onClick={closeModal}>
            <div onClick={closeModal} className={touchable === false ? "absolute fill" : ""} />
            {modal}
        </div>
    );
};

export default Modal;
