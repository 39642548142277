import Image from "components/image/image.component";
import { useContext } from "react";
import { Link } from "react-router-dom";

import { AuthContext } from "src/contexts/auth.context";

import "./profile-button.style.scss";

const ProfileButton = ({ title }) => {
    const {
        user: { name, surname, color, companyName, firmId },
    } = useContext(AuthContext);

    // const { name, surname, imageUrl } = user ?? {
    // 	name: "Jane Doe",
    // 	imageUrl:
    // 		"https://static.remove.bg/remove-bg-web/5c20d2ecc9ddb1b6c85540a333ec65e2c616dbbd/assets/start_remove-c851bdf8d3127a24e2d137a55b1b427378cd17385b01aec6e59d5d4b5f39d2ec.png",
    // };

    return (
        <Link to={"account"} className="profile-button" title={title}>
            <Image name={name + " " + surname} color={color} round />
            <p className="w500">
                {name + " " + surname}
                {firmId > 0 && (
                    <>
                        {" | "} <span className="w600">{companyName}</span>
                    </>
                )}
            </p>
        </Link>
    );
};

export default ProfileButton;
