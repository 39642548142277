import { useContext, useEffect, useRef, useState } from "react";
import Checkbox from "../../components/checkbox/checkbox.component";
import { Link } from "react-router-dom";

import WarningBox from "components/warning-box/warning-box.component";

import { AuthContext } from "../../contexts/auth.context";
import { ReactComponent as EyeHidden } from "assets/eye-hidden.svg";
import { ReactComponent as Eye } from "assets/eye.svg";

import useTranslate from "../../hooks/use-translate";

import "./login.style.scss";
import axios from "axios";

const ERROR_CODES = {
    401: "email is wrong",
    402: "password is wrong",
};

const Login = () => {
    const { login } = useContext(AuthContext);
    const t = useTranslate("Login");

    const [upperText, setUpperText] = useState("");
    const [lowerText, setLowerText] = useState("");
    const [image, setImage] = useState(null);
    const [error, setError] = useState(null);
    const [passwordHidden, setPasswordHidden] = useState(true);
    const [rememberMe, setRememberMe] = useState(false);
    const inputRef = useRef(null);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const {
            email,
            password,
            // checkbox
        } = event.target;

        if (email.value.length > 0 && password.value.length > 0) {
            const error = await login({ email: email.value, password: password.value, rememberMe });
            setError(error);
            // if (error) alert(ERROR_CODES[error]);
        }
    };

    function togglePasswordVisibility() {
        if (inputRef.current) {
            inputRef.current.type = passwordHidden ? "text" : "password";
        }
        setPasswordHidden(!passwordHidden);
    }

    useEffect(() => {
        const fetchAdImage = async () => {
            const [link, imageName] = await axios
                .get("getAdvertisement")
                .then((res) => {
                    if (res.status !== 200) return;
                    const { upperText, lowerText, imageUrl, imageName } = res.data;
                    setUpperText(upperText);
                    setLowerText(lowerText);
                    return [imageUrl, imageName];
                })
                .catch(console.error);
            if (!link) return;
            const file = await fetch(link)
                .then((response) => {
                    if (response.status !== 200) return null;
                    return response.blob();
                })
                .then((blob) => {
                    if (!blob) throw new Error("Blob is null");
                    const file = new File([blob], imageName, { type: blob.type });
                    return file;
                })
                .catch((error) => {
                    console.error(error);
                    return null;
                });
            if (!file) return;

            file.url = window.URL.createObjectURL(file);
            setImage(file);
        };
        fetchAdImage();
    }, [setUpperText, setLowerText]);

    return (
        <div className="login-container">
            <div className="login-left-side">
                {image && (
                    <div className="ads">
                        <span>{upperText}</span>
                        <div className="ad-img-container">
                            <img className="ad-img" src={image.url} alt="advertisement" />
                        </div>
                        <span>{lowerText}</span>
                    </div>
                )}
            </div>

            <form className="login-right-side" onSubmit={handleSubmit}>
                <div className="login-form-container">
                    <span className="title">{t("welcome to metibox").toUpperCase()}</span>
                    <input className={`text-box ${error === 401 && "error"}`} type="text" placeholder={t("email")} name="email" autoComplete="on" />
                    <div className="password-container">
                        <input
                            className={`text-box ${error === 402 && "error"}`}
                            type={`password`}
                            placeholder={t("password")}
                            name="password"
                            autoComplete="on"
                            ref={inputRef}
                        />
                        <div className="eye-container" onClick={togglePasswordVisibility}>
                            {passwordHidden ? <EyeHidden className="eye" /> : <Eye className="eye" />}
                        </div>
                    </div>

                    <div className="flex flex--space-between-content flex--center-items padding-l--bottom  margin-m--top">
                        <Checkbox label={<p style={{ fontSize: "1.1vw" }}>{t("remember me")}</p>} handleClick={() => setRememberMe((old) => !old)} />
                        <Link className="forgot-password" style={{ fontSize: "1.1vw" }} to={"/forgot-password"}>
                            {t("forgot password?")}
                        </Link>
                    </div>
                    <div className="flex flex--start-items margin-l--top">
                        <button
                            className="btn btn--red w500 padding-s--block padding-xxl--inline margin-auto--left"
                            style={{ width: "11vw", fontSize: "1vw" }}
                            type="submit">
                            {t("login").toUpperCase()}
                        </button>
                    </div>
                    {error && <WarningBox text={t(ERROR_CODES[error])} size="0.5rem" fontSize={"0.8rem"} style={{ marginTop: "2vh", marginBottom: "2vh" }} />}
                </div>
            </form>
        </div>
    );
};

export default Login;
