import { Fragment, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useMenuState } from "@szhsin/react-menu";

import ContextMenu from "components/context-menu/context-menu.component";
import { ReactComponent as FolderAdd } from "assets/folder/folder_add.svg";
import { ReactComponent as FolderEmpty } from "assets/folder/folder_empty.svg";
import { ReactComponent as FolderFilled } from "assets/folder/folder_filled.svg";
import { ReactComponent as FolderLocked } from "assets/folder/locked_folder.svg";
import { ReactComponent as FolderUnlocked } from "assets/folder/unlocked_folder.svg";

import { FolderContext } from "src/contexts/folder.context";

import "./folder.style.scss";
import ReactTextareaAutosize from "react-textarea-autosize";
import useTranslate from "src/hooks/use-translate";
import Modal from "components/modal/modal.component";
import WarningBox from "components/warning-box/warning-box.component";
import { ClientsContext } from "src/contexts/clients.context";

const Folder = ({ fileName, isEmpty = false, addButton = false, size = "medium", folderId = 0, locked = false, isCustomFolder = false }) => {
    const { getInside, addFolder, renameFolder, deleteFolder, yearSelection, currentLocation, currentFolderLocked } = useContext(FolderContext);
    const { downloadFolder, downloadFolderWithRef } = useContext(ClientsContext);
    const [menuProps, toggleMenu] = useMenuState();
    const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });

    const [updatedFolderName, setUpdatedFolderName] = useState(fileName);

    const [isEditable, setIsEditable] = useState(false);
    const [showError, setShowError] = useState(false);
    const [errorDeleteBox, setErrorDeleteBox] = useState(false);
    const [errorRenameBox, setErrorRenameBox] = useState(false);

    const inputRef = useRef();

    const t = useTranslate("Archive");

    useEffect(() => {
        if (isEditable && inputRef) {
            inputRef.current.focus();
        }
    }, [isEditable]);

    const contextMenuList = useMemo(
        () => [
            {
                label: t("download-ref"),
                onClick: () => {
                    downloadFolderWithRef(fileName, currentLocation, yearSelection);
                },
            },
            {
                label: t("download"),
                onClick: () => {
                    downloadFolder(fileName, currentLocation, yearSelection);
                },
            },
            {
                label: t("rename"),
                onClick: () => {
                    if (!isCustomFolder) {
                        setErrorRenameBox(true);
                        return;
                    }
                    if (currentFolderLocked) return;
                    setIsEditable(true);
                },
            },
            {
                label: t("delete"),
                onClick: async () => {
                    if (currentFolderLocked) return;
                    if (!isCustomFolder) {
                        setErrorDeleteBox(true);
                        return;
                    }
                    const res = await deleteFolder(fileName);
                    if (res !== "success") setShowError(true);
                },
            },
        ],
        [fileName, currentFolderLocked, isCustomFolder, currentLocation, yearSelection, downloadFolder, deleteFolder, downloadFolderWithRef, t]
    );

    const handleClick = () => {
        if (addButton) {
            addFolder(t("New Folder"));
            return;
        }
        getInside(fileName);
    };

    const handleChangeName = ({ target: { value } }) => {
        setUpdatedFolderName(value);
    };

    const handleSubmitRename = async () => {
        if (updatedFolderName.length === 0) {
            handleCancelRename();
            return;
        }
        setIsEditable(false);
        const updatedName = await renameFolder(fileName, updatedFolderName, folderId);
        setUpdatedFolderName(updatedName);
    };

    const handleCancelRename = () => {
        setUpdatedFolderName(fileName);
        setIsEditable(false);
    };

    return (
        <Fragment>
            <div className={`folder_container ${size}`}>
                <span
                    onContextMenu={(e) => {
                        e.preventDefault();
                        if (currentFolderLocked) return;
                        setAnchorPoint({ x: e.clientX, y: e.clientY });
                        toggleMenu(true);
                    }}
                    onClick={handleClick}>
                    {addButton ? (
                        <FolderAdd className={`folder`} />
                    ) : locked ? (
                        <FolderLocked className={`folder`} />
                    ) : !isCustomFolder ? (
                        <FolderUnlocked className={`folder`} />
                    ) : isEmpty ? (
                        <FolderEmpty className={`folder`} />
                    ) : (
                        <FolderFilled className={`folder`} />
                    )}
                </span>
                <ReactTextareaAutosize
                    ref={inputRef}
                    className="folder_name unselectable"
                    maxRows={3}
                    value={updatedFolderName ?? fileName}
                    onChange={handleChangeName}
                    onKeyDown={({ code }) => {
                        if (code === "Enter") handleSubmitRename();
                        if (code === "Escape") handleCancelRename();
                    }}
                    onBlur={handleSubmitRename}
                    disabled={!isEditable}
                />
            </div>

            {errorDeleteBox && <Modal modal={<WarningBox text={t("error-deletebox")} />} closeIn3 dismiss={[setErrorDeleteBox]} fullscreen />}
            {errorRenameBox && <Modal modal={<WarningBox text={t("error-renamebox")} />} closeIn3 dismiss={[setErrorDeleteBox]} fullscreen />}
            {showError && <Modal modal={<WarningBox text={t("error-deletefolder")} />} closeIn3 dismiss={[setShowError]} />}
            <ContextMenu list={contextMenuList} menuProps={menuProps} toggleMenu={toggleMenu} anchorPoint={anchorPoint} />
        </Fragment>
    );
};

export default Folder;
