import { useContext, Fragment, useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";

import Sidebar from "components/sidebar/sidebar.component";
import Topbar from "components/topbar/topbar.component";

import Login from "routes/login/login.route";
import ForgotPassword from "routes/forgot-password/forgot-password.route";
//PART CLIENT
import Archive from "routes/client-view/archive/archive.route";
import MySafe from "routes/client-view/invoices/invoices.route";
import Chat from "routes/client-view/chat/chat.route";
import Trash from "routes/client-view/trash/trash.route";
import Account from "routes/client-view/account/account.route";
//PART CLIENT

import { AuthContext } from "./contexts/auth.context";

import "./App.scss";
import MyAccount from "routes/client-view/account/inner-routes/personal/my-account/my-account.route";
import Settings from "routes/client-view/account/inner-routes/personal/settings/settings.route";
import CompanyInformation from "routes/client-view/account/inner-routes/company/company-information/company-information.route";
import People from "routes/client-view/account/inner-routes/company/people/people.route";
import Plans from "routes/client-view/account/inner-routes/company/plans/plans.route";
import Notifications from "routes/accountant-view/notifications/notifications.route";
import Clients from "routes/accountant-view/clients/clients.route";
import Tickets from "routes/accountant-view/tickets/tickets.route";
import Verification from "routes/common-routes/verification/verification.route";
import NewPassword from "routes/common-routes/new-password/new-password.route";
import Modal from "components/modal/modal.component";
import WarningBox from "components/warning-box/warning-box.component";
// import ClientsProvider from "./contexts/clients.context";
import NewClientProvider from "./contexts/new-client.context";
import Advertisement from "routes/client-view/account/inner-routes/company/advertisement/Advertisement.route";

function App() {
    const { isSignedIn, user } = useContext(AuthContext);
    const role = user?.role ?? undefined;

    const [isOnline, setIsOnline] = useState(navigator.onLine);

    useEffect(() => {
        // Update network status
        const handleStatusChange = () => {
            setIsOnline(navigator.onLine);
        };

        // Listen to the online status
        window.addEventListener("online", handleStatusChange);

        // Listen to the offline status
        window.addEventListener("offline", handleStatusChange);

        // Specify how to clean up after this effect for performance improvment
        return () => {
            window.removeEventListener("online", handleStatusChange);
            window.removeEventListener("offline", handleStatusChange);
        };
    }, [isOnline]);

    return (
        <Fragment>
            <Routes>
                <Route path="/" element={<Topbar />}>
                    {!isSignedIn ? (
                        <Fragment>
                            <Route index element={<Login />} />
                            <Route path="/forgot-password" element={<ForgotPassword />} />
                            <Route path="/verification" element={<Verification />} />
                            <Route path="/new-password" element={<NewPassword />} />
                        </Fragment>
                    ) : role === 1 ? ( // role is client
                        <Route path="/" element={<Sidebar />}>
                            <Route index element={<Archive />} />
                            <Route path="/invoices" element={<MySafe />} />
                            <Route path="/chat" element={<Chat />} />
                            <Route path="/tickets" element={<Tickets />} />
                            <Route path="/trash" element={<Trash />} />
                            <Route path="/account" element={<Account />}>
                                <Route index element={<MyAccount />} />
                                <Route path="settings" element={<Settings />} />
                                <Route path="company/information" element={<CompanyInformation />} />
                                <Route path="company/people" element={<People />} />
                                <Route path="company/plans" element={<Plans />} />
                            </Route>
                        </Route>
                    ) : (
                        // role is accountant
                        <Route
                            path="/"
                            element={
                                <NewClientProvider>
                                    <Sidebar />
                                </NewClientProvider>
                            }>
                            <Route index element={<Notifications />} />
                            <Route path="/clients" element={<Clients />} />
                            <Route path="/chat" element={<Chat />} />
                            <Route path="/tickets" element={<Tickets />} />
                            <Route path="/account" element={<Account />}>
                                <Route index element={<MyAccount />} />
                                <Route path="settings" element={<Settings />} />
                                <Route path="company/information" element={<CompanyInformation />} />
                                <Route path="company/advertisement" element={<Advertisement />} />
                                <Route path="company/people" element={<People />} />
                                <Route path="company/plans" element={<Plans />} />
                            </Route>
                        </Route>
                    )}
                </Route>
            </Routes>
            {!isOnline && <Modal modal={<WarningBox text="Internet has disconnected." />} />}
        </Fragment>
    );
}

export default App;
