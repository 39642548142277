import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useContext } from "react";
import { TicketsContext } from "src/contexts/tickets.context";
import NewTicketModal from "./components/new-ticket-modal/new-ticket-modal.component";
import TicketTable from "./components/table/tickets-table.component";
import TicketDetails from "./components/ticket-details/ticket-details.component";

import "./tickets.style.scss";
import useTranslate from "src/hooks/use-translate";

const Tickets = () => {
    const { tickets, shownTickets, COLUMNS, fetchTickets, fetchMessages, changeTab, deleteTickets } = useContext(TicketsContext);
    const [selectedTab, setSelectedTab] = useState(0);
    const [checkedTickets, setCheckedTickets] = useState([]);
    const [detailsShowing, setDetailsShowing] = useState(null);
    const [modalVisible, setModalVisible] = useState(false);

    const t = useTranslate("Tickets");

    const handleTabChange = useCallback(
        (tabIndex) => {
            if (selectedTab === tabIndex) return;
            setSelectedTab(tabIndex);
            changeTab(tabIndex);
        },
        [selectedTab, changeTab]
    );

    const handleChecked = useCallback((_checkedTickets) => {
        setCheckedTickets(_checkedTickets);
    }, []);

    const handleDeleteButton = () => {
        deleteTickets(checkedTickets);
    };

    const handleCreateTicketButton = () => {
        setModalVisible(true);
    };

    const handleClickRow = (id) => {
        fetchMessages(id);
        setDetailsShowing(id);
    };

    const handleGoBack = () => {
        setDetailsShowing(null);
    };

    useEffect(() => {
        fetchTickets();
    }, [fetchTickets]);

    return (
        <div className="tickets-container route-container">
            <div
                name="header"
                className="flex flex--end-items gap-xxxxl border--bottom margin-xl--bottom"
                // style={{  borderBottom: "3px solid blue" }}
            >
                <p className="size600 w600 padding-xs--bottom">{t("Tickets")}</p>
                {detailsShowing == null && (
                    <Fragment>
                        <div name="tab buttons" className="size400 w600 tabs-wrapper">
                            <span
                                onClick={() => handleTabChange(0)}
                                className={`pointer text-center padding-xs--bottom ${selectedTab === 0 && "selected-tab"}`}>
                                {t("OPEN")} {`[${tickets.filter(({ status }) => status === 1).length}]`}
                            </span>
                            <span
                                onClick={() => handleTabChange(1)}
                                className={`pointer text-center padding-xs--bottom ${selectedTab === 1 && "selected-tab"}`}>
                                {t("CLOSED")} {`[${tickets.filter(({ status }) => status === 0).length}]`}
                            </span>
                        </div>
                        <div name="create/delete buttons" className="flex gap-m margin-xl--right margin-xs--bottom">
                            <button onClick={handleCreateTicketButton} className="btn btn--hover size400 w600 padding-l--inline padding-xs--block">
                                {t("Create Ticket")}
                            </button>
                            <button onClick={handleDeleteButton} className="btn btn--hover btn--red-text size400 w600 padding-l--inline padding-xs--block">
                                {t("Delete")}
                            </button>
                        </div>
                    </Fragment>
                )}
            </div>

            {detailsShowing == null && (
                <Fragment>
                    {shownTickets.length === 0 && <p className="empty-message">{t("There are no awaiting tickets")}</p>}
                    {shownTickets.length > 0 && (
                        <TicketTable columns={COLUMNS} rows={shownTickets} checkbox onChecked={handleChecked} clickable onClick={handleClickRow} />
                    )}
                </Fragment>
            )}
            {detailsShowing && <TicketDetails goBack={handleGoBack} ticketId={detailsShowing} />}
            {modalVisible && <NewTicketModal dismiss={() => setModalVisible(false)} />}
        </div>
    );
};

export default Tickets;
