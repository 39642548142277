import { BsPersonFill, BsTelephoneFill, BsEnvelopeFill, BsChatLeftText } from "react-icons/bs";

import Image from "components/image/image.component";

import { POSITIONS } from "utils/lists.utils";

import "./person.style.scss";
import useTranslate from "src/hooks/use-translate";

const Person = ({ data, color }) => {
    const { name, surname, jobTitle, email, phone, position } = data;
    const t = useTranslate("Clients");

    const handleChatClick = () => {
        console.log("Chatting with:", name);
    };

    return (
        <div className="person-container--admin flex flex--center-items gap-m padding-s--block scroll-bar scroll-bar--overflow">
            <div className="flex flex--center gap-s">
                <span className="image-wrapper" style={{ borderColor: color, borderWidth: color ? "0.25rem" : 1 }}>
                    <Image name={name + " " + surname} color={color} />
                </span>
                <span className="size400 w600 unselectable">{name + " " + surname}</span>
            </div>
            {jobTitle.length > 0 && (
                <div className="size300 flex flex--center gap-s">
                    <BsPersonFill className="icon" />
                    <span>{jobTitle}</span>
                </div>
            )}
            {email.length > 0 && (
                <div className="size300 flex flex--center gap-s">
                    <BsEnvelopeFill className="icon" />
                    <span>{email}</span>
                </div>
            )}
            {phone.length > 0 && (
                <div className="size300 flex flex--center gap-s">
                    <BsTelephoneFill className="icon" />
                    <span>{phone}</span>
                </div>
            )}
            <div>
                <span className="size300 unselectable user-type">{position ? t(POSITIONS[position]) : t("Administrator")}</span>
            </div>
            <div style={{ flexShrink: 1 }}>{/* <BsChatLeftText onClick={handleChatClick} size={20} /> */}</div>
        </div>
    );
};

export default Person;
