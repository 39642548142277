import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { AuthContext } from "./auth.context";
import io from "socket.io-client";
import { S3Context } from "./s3.context";

export const ChatContext = createContext({
    chats: null,
    selectedChat: null,
    setSelectedChat: () => {},
    sendMessage: () => {},
    sendFile: () => {},
    setChats: () => {},
    chatBefore: () => {},
    joinGroupChat: () => {},
});

const ChatProvider = ({ children }) => {
    const { uploadMsgFileToS3 } = useContext(S3Context);
    const { user } = useContext(AuthContext);
    const [socket, setSocket] = useState(null); // socket connection to the server
    const [chats, setChats] = useState([]); // set chats to the value needed
    const [selectedChat, setSelectedChat] = useState(null); // this is the chat to be shown to the user

    const sendMessage = useCallback(
        (message, receiverId, chatId, groupChat = null) => {
            // send message
            let route = "sendMessage";
            if (groupChat) route = "sendGroupMessage";
            socket.emit(route, {
                message,
                sourceId: user.userId,
                receiverId,
                chatId: groupChat ? chatId : parseInt(chatId),
                name: user.name,
                surname: user.surname,
                color: user.color,
            });
            const newMessage = { message, sourceId: user.userId, receiverId, date: new Date(), name: user.name, surname: user.surname, color: user.color };
            setSelectedChat((initialChat) => {
                // update chats accordingly
                const tempChat = { ...initialChat };
                tempChat.messages = [newMessage, ...tempChat.messages];
                return tempChat;
            });
            setChats((oldChats) => {
                const newChats = { ...oldChats };
                newChats[chatId].lastMessage = newMessage;
                return newChats;
            });
        },
        [socket, user]
    );

    const sendFile = useCallback(
        (file, receiverId, chatId, groupChat = null) => {
            // send file
            let route = "sendFile";
            if (groupChat) route = "sendGroupFile";
            socket.emit(route, { message: file.name, sourceId: user.userId, receiverId, chatId: groupChat ? chatId : parseInt(chatId) });
            uploadMsgFileToS3(chatId, file);
            const newMessage = {
                message: file.name,
                sourceId: user.userId,
                receiverId,
                date: new Date(),
                type: 1,
                name: user.name,
                surname: user.surname,
                color: user.color,
            };
            setSelectedChat((initialChat) => {
                // update chats accordingly
                const tempChat = { ...initialChat };
                tempChat.messages = [newMessage, ...tempChat.messages];
                return tempChat;
            });
            setChats((oldChats) => {
                const newChats = { ...oldChats };
                newChats[chatId].lastMessage = newMessage;
                return newChats;
            });
        },
        [socket, user, uploadMsgFileToS3]
    );

    const chatBefore = useCallback(
        (userId) => {
            // find the chat if chatted before
            console.log({ chats });
            if (!chats || chats.length === 0) return null;
            for (const chatId of Object.keys(chats)) {
                if (chats[chatId].chatUserId === userId) return chats[chatId];
            }
            return null;
        },
        [chats]
    );

    const joinGroupChat = useCallback(
        (groupId) => {
            if (!socket || !user) return;
            socket.emit("joinRoom", { roomId: groupId, userId: user.userId });
        },
        [socket, user]
    );

    useEffect(() => {
        if (!user) return;
        const url = `${process.env.REACT_APP_MESSAGING_API}`;
        const newSocket = io(url, { query: { userId: user.userId } });
        setSocket(newSocket);
        return () => newSocket.close();
    }, [user, setSocket]);

    useEffect(() => {
        if (!socket || !user) return;
        socket.on("receiveMessage", (data) => {
            // receive message
            const newMessage = { ...data, receiverId: user.userId };
            console.log({ data, a: chats[data.chatId].lastMessage });
            if (selectedChat)
                setSelectedChat((initialChat) => {
                    // update chats accordingly
                    const tempChat = { ...initialChat };
                    tempChat.messages = [newMessage, ...tempChat.messages];
                    return tempChat;
                });
            setChats((oldChats) => {
                const newChats = { ...oldChats };
                newChats[data.chatId].lastMessage = newMessage;
                return newChats;
            });
        });
        socket.on("receiveFile", (data) => {
            // receive file
            const newMessage = { ...data, receiverId: user.userId, type: 1 };
            if (selectedChat)
                setSelectedChat((initialChat) => {
                    // update chats accordingly
                    const tempChat = { ...initialChat };
                    tempChat.messages = [newMessage, ...tempChat.messages];
                    return tempChat;
                });
            setChats((oldChats) => {
                const newChats = { ...oldChats };
                newChats[data.chatId].lastMessage = newMessage;
                return newChats;
            });
        });
        return () => {
            socket.off("receiveMessage");
            socket.off("receiveFile");
        };
    }, [socket, user, selectedChat, chats]);

    const value = useMemo(
        () => ({ chats, selectedChat, setSelectedChat, sendMessage, sendFile, setChats, chatBefore, joinGroupChat }),
        [chats, selectedChat, sendMessage, sendFile, setChats, chatBefore, joinGroupChat]
    );
    return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};

export default ChatProvider;
