import { useContext, useEffect, useMemo, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";

import { AuthContext } from "src/contexts/auth.context";

import useTranslate from "src/hooks/use-translate";

import "./account.style.scss";
import YesNoBox from "components/yes-no-box/YesNoBox.component";
import Modal from "components/modal/modal.component";

const PERSONAL_LIST = [
    { label: "My Profile", href: "/account" },
    { label: "settings", href: "/account/settings" },
];

const Account = () => {
    const {
        logout,
        user: { role, firmId },
    } = useContext(AuthContext);
    const location = useLocation();
    const t = useTranslate("Account");

    const [showYesNo, setShowYesNo] = useState(false);
    const [approvedLogout, setApprovedLogout] = useState(false);

    const COMPANY_LIST = useMemo(
        () =>
            role === 1
                ? firmId === -1
                    ? [
                          { label: "people", href: "/account/company/people" },
                          { label: "plans", href: "/account/company/plans" },
                      ]
                    : [
                          { label: "information", href: "/account/company/information" },
                          { label: "people", href: "/account/company/people" },
                          { label: "plans", href: "/account/company/plans" },
                      ]
                : [
                      { label: "advertisement", href: "/account/company/advertisement" },
                      { label: "people", href: "/account/company/people" },
                  ],
        [role, firmId]
    );

    const handleLogOut = () => {
        setShowYesNo(true);
    };

    useEffect(() => {
        if (approvedLogout) {
            logout();
            setApprovedLogout(false);
        }
    }, [approvedLogout, logout]);

    return (
        <div className="account-container unselectable">
            <div className="left-panel">
                <h1 className="with-line">{t("account")}</h1>
                <div className="list-container">
                    <p className="size500 w500 with-line">{t("personal")}</p>
                    <div className="buttons-container">
                        {PERSONAL_LIST.map(({ label, href }) => {
                            const isSelected = href === location.pathname;
                            return (
                                <Link key={label} className={`button ${isSelected && "selected"}`} to={href}>
                                    {t(label)}
                                </Link>
                            );
                        })}
                        <button onClick={handleLogOut} className="button log-out">
                            {t("log out")}
                        </button>
                    </div>
                </div>
                <div className="list-container">
                    <p className="size500 w500 with-line">{t("company")}</p>
                    <div className="buttons-container">
                        {COMPANY_LIST.map(({ label, href }) => {
                            const isSelected = href === location.pathname;
                            return (
                                <Link key={label} className={`button ${isSelected && "selected"}`} to={href}>
                                    {t(label)}
                                </Link>
                            );
                        })}
                    </div>
                </div>
            </div>
            <div className="right-panel">
                <Outlet />
            </div>
            {showYesNo && (
                <Modal
                    modal={
                        <YesNoBox
                            setVisible={setShowYesNo}
                            setApproved={setApprovedLogout}
                            text={t("logout-warning")}
                            rightOption={t("logout")}
                            rightOptionRed
                        />
                    }
                    touchable
                    dismiss={[setShowYesNo]}
                />
            )}
        </div>
    );
};

export default Account;
