import { useContext } from "react";

import { ReactComponent as FolderEmpty } from "assets/folder/folder_empty.svg";
import { ReactComponent as FolderFilled } from "assets/folder/folder_filled.svg";

import { FolderContext } from "src/contexts/folder.context";

import "./folder.style.scss";

const Folder = ({ fileName = null, isEmpty = false }) => {
	const { getInsideMoveFile } = useContext(FolderContext);

	const handleClick = () => {
		getInsideMoveFile(fileName);
	};

	return (
		<div
			title={fileName}
			onClick={handleClick}
			className={`folder-container-move-file w500 flex flex--center-items gap-m border smooth-corners padding-m--inline padding-s--block`}
			style={{ height: "min-content" }}
		>
			<span>
				{isEmpty ? <FolderEmpty className={`folder`} /> : <FolderFilled className={`folder`} />}
			</span>
			{fileName && <span className="folder-name">{fileName}</span>}
		</div>
	);
};

export default Folder;
