import Loading from "components/loading/Loading.component";
import { createContext, useCallback, useMemo, useState } from "react";

export const LoadingContext = createContext({
    isLoading: null,
    setLoading: () => {},
});

const LoadingProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState(false); // selected language (english is default but can set it through cookies)

    const setLoading = useCallback((boolean) => {
        // set Loading value
        setIsLoading(boolean);
    }, []);

    const value = useMemo(() => ({ isLoading, setLoading }), [isLoading, setLoading]);
    return (
        <LoadingContext.Provider value={value}>
            {isLoading && <Loading />}
            {children}
        </LoadingContext.Provider>
    );
};

export default LoadingProvider;
