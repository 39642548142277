import useTranslate from "src/hooks/use-translate";
import { ARCHIVE_PERIODS, SAFE_LOCKING_PERIODS } from "utils/lists.utils";
import "./plans.style.scss";

const Plans = () => {
	const t = useTranslate("Account");

	return (
		<div className="plans-container">
			<span className="with-line size600 w600">Plans</span>
			<div className="data-wrapper">
				<div className="block with-line">
					<p className="size400 w600 margin-m--top">Archive Period</p>
					<p className="size300 margin-s--top">
						{t(
							"The period which your accounts of PURCHASES, SALES, and VAT will be examined by your accountants."
						)}
					</p>
					<p className="size300 margin-s--top">
						{t("Please contact with your accountant to change your Archive Period.")}
					</p>
					<ul className="size300">
						{ARCHIVE_PERIODS.map((period) => (
							<li key={period}>
								{t(period)} {t("archive")}
							</li>
						))}
					</ul>
					<span className="size400 w500 selected-plan-container">3 Months</span>
				</div>
				<div className="block with-line">
					<p className="size400 w600 margin-m--top">Safe Locking Period</p>
					<p className="size300 margin-s--top">
						{t(
							"The period which your safe to be locked. You will get notifications about your upcoming safe locking deadlines."
						)}
					</p>
					<p className="size300 margin-s--top">
						{t("Please contact with your accountant to change your Safe Locking Period")}.
					</p>
					<ul className="size300">
						{SAFE_LOCKING_PERIODS.map((period) => (
							<li key={period}>
								{t(period)} {t("safe")}
							</li>
						))}
					</ul>
					<span className="size400 w500 selected-plan-container">4 Months</span>
				</div>
			</div>
		</div>
	);
};

export default Plans;
