import { useCallback } from "react";
import { createContext, useEffect, useMemo, useState } from "react";

import takeMonth, { getYearInterval } from "../utils/calendar.utils";

export const CALENDAR_VIEW_TYPES = {
    year: "year",
    month: "month",
    day: "day",
};

export const CalendarContext = createContext({
    calendar: [],
    calendarViewType: "",
    setCalendarViewType: () => {},
    Prev: () => {},
    Next: () => {},
    changeDay: () => {},
    changeMonth: () => {},
    changeYear: () => {},
    goToToday: () => {},
    showSelectedDay: () => {},
    changeView: () => {},
});

const CalendarProvider = ({ children }) => {
    const [calendar, setCalendar] = useState([]); // list of days in the month
    // set selected day as first moment of today
    const [selectedDay, setSelectedDay] = useState(new Date(new Date().setHours(0, 0, 0, 0))); // selected day by the user
    const [lastDay, setLastDay] = useState(new Date(new Date().setHours(23, 59, 59, 999))); // end of the day (if month or year, last day of the period) by the user
    const [shownMonth, setShownMonth] = useState(selectedDay.getMonth()); // which month is shown
    const [shownYear, setShownYear] = useState(selectedDay.getFullYear()); // which year is shown
    const [shownYearsInterval, setShownYearsInterval] = useState(getYearInterval(shownYear)); // (for year view) which interval to be shown
    const [calendarViewType, setCalendarViewType] = useState("day"); // day || month || year

    useEffect(() => {
        // change calendar and shownYearsInterval values according to selected month and year
        const date = new Date(shownYear, shownMonth, 1, 0, 0, 0, 0);
        const tm = takeMonth(date);
        setCalendar(tm());
        setShownYearsInterval(getYearInterval(shownYear));
    }, [shownMonth, shownYear]);

    useEffect(() => {
        // get month and year from selectedDay
        setShownMonth(selectedDay.getMonth());
        setShownYear(selectedDay.getFullYear());
    }, [selectedDay]);

    const Prev = useCallback(() => {
        // going back in time (interval is changing with respect to calendarViewType)
        switch (calendarViewType) {
            case CALENDAR_VIEW_TYPES.day: // if calendarViewType === day, go back a month and year if neeeded
                if (shownMonth === 0) setShownYear((oldYear) => oldYear - 1);
                setShownMonth((oldMonth) => (oldMonth + 11) % 12);
                break;

            case CALENDAR_VIEW_TYPES.month: // if calendarViewType === month, go back a year
                setShownYear((oldYear) => oldYear - 1);
                break;

            case CALENDAR_VIEW_TYPES.year: // if calendarViewType === year, change the shownYearsInterval (subtract 10)
                setShownYearsInterval((oldInterval) => [oldInterval[0] - 10, oldInterval[1] - 10]);
                break;

            default: // else, do nothing
                break;
        }
    }, [calendarViewType, shownMonth]);

    const Next = useCallback(() => {
        // going forward in time (interval is changing with respect to calendarViewType)

        switch (calendarViewType) {
            case CALENDAR_VIEW_TYPES.day: // if calendarViewType === day, go forward a month and year if neeeded
                if (shownMonth === 11) setShownYear((oldYear) => oldYear + 1);
                setShownMonth((oldMonth) => (oldMonth + 1) % 12);

                break;
            case CALENDAR_VIEW_TYPES.month: // if calendarViewType === month, go forward a year
                setShownYear((oldYear) => oldYear + 1);
                break;
            case CALENDAR_VIEW_TYPES.year: // if calendarViewType === year, change the shownYearsInterval (add 10)
                setShownYearsInterval((oldInterval) => [oldInterval[0] + 10, oldInterval[1] + 10]);

                break;
            default:
                break;
        }
    }, [calendarViewType, shownMonth]);

    const changeDay = useCallback((day) => {
        // set selectedDay to the parameter passed
        setSelectedDay(day);
        setLastDay(new Date(day.getFullYear(), day.getMonth(), day.getDate(), 23, 59, 59, 999));
    }, []);

    const changeMonth = useCallback((month, year) => {
        // change month and year values accordingly
        setSelectedDay(new Date(year, month, 1, 0, 0, 0, 0));
        if (month === 11) setLastDay(new Date(year + 1, 0, 1, 0, 0, 0, 0));
        else setLastDay(new Date(year, month + 1, 1, 0, 0, 0, 0));
        setShownMonth(month);
        setShownYear(year);
    }, []);

    const changeYear = useCallback((year) => {
        // change shownYear value accordingly
        setSelectedDay(new Date(year, 0, 1, 0, 0, 0, 0));
        setLastDay(new Date(year, 11, 31, 23, 59, 59, 999));
        setShownMonth(1);
        setShownYear(year);
    }, []);

    const goToToday = useCallback(() => {
        // set the selectedDay to today's date
        setSelectedDay(new Date(new Date().setHours(0, 0, 0, 0)));
    }, []);

    const showSelectedDay = useCallback(() => {
        // change month, year and viewType values to show the last selected day by the user
        setShownMonth(selectedDay.getMonth());
        setShownYear(selectedDay.getFullYear());
        setCalendarViewType(CALENDAR_VIEW_TYPES.day);
    }, [selectedDay]);

    const changeView = useCallback(() => {
        // extend the time interval to be shown
        switch (calendarViewType) {
            case CALENDAR_VIEW_TYPES.day:
                setCalendarViewType(CALENDAR_VIEW_TYPES.month);
                break;
            case CALENDAR_VIEW_TYPES.month:
                setCalendarViewType(CALENDAR_VIEW_TYPES.year);
                break;
            default:
                break;
        }
    }, [calendarViewType]);

    const value = useMemo(
        () => ({
            calendar,
            selectedDay,
            lastDay,
            shownMonth,
            shownYear,
            shownYearsInterval,
            calendarViewType,
            setCalendarViewType,
            Prev,
            Next,
            changeDay,
            changeMonth,
            changeYear,
            goToToday,
            showSelectedDay,
            changeView,
        }),
        [
            calendar,
            selectedDay,
            lastDay,
            shownMonth,
            shownYear,
            shownYearsInterval,
            calendarViewType,
            setCalendarViewType,
            Prev,
            Next,
            changeDay,
            changeMonth,
            changeYear,
            goToToday,
            showSelectedDay,
            changeView,
        ]
    );

    return <CalendarContext.Provider value={value}>{children}</CalendarContext.Provider>;
};

export default CalendarProvider;
