import Image from "components/image/image.component";
import { format } from "date-fns";

import "./chat-box.style.scss";

const ChatBox = ({ chat, isSelected }) => {
    const { numberOfUnreads, name, surname, lastMessage } = chat;
    const { message, date } = lastMessage;

    return (
        <div className={`size400 chat-box ${isSelected && "selected"}`}>
            <div className="left">
                <Image name={name} color={chat.color} />
            </div>
            <div className="middle">
                <div className="name">{name + (surname ? " " + surname : "")}</div>
                <div className="message">{message}</div>
            </div>
            <div className="right">
                {date && <div className="date">{format(new Date(date), "dd/MM/yyyy")}</div>}
                {numberOfUnreads !== null && numberOfUnreads > 0 && <div className="unreads">{numberOfUnreads}</div>}
            </div>
        </div>
    );
};

export default ChatBox;
