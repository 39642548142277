import axios from "axios";
import { createContext, useCallback, useContext, useMemo, useState } from "react";
import { AuthContext } from "./auth.context";
import useTranslate from "src/hooks/use-translate";
import { getNow } from "utils/date.utils";

export const TicketsContext = createContext({
    tickets: [],
    shownTickets: [],
    COLUMNS: [],
    fetchTickets: () => {},
    fetchMessages: () => {},
    changeTab: () => {},
    deleteTickets: () => {},
    getTicketById: () => {},
    respond: () => {},
    createTicket: () => {},
    closeTicket: () => {},
});

const TicketsProvider = ({ children }) => {
    const { user } = useContext(AuthContext);

    const t = useTranslate("Tickets");

    const COLUMNS = useMemo(
        () => [
            {
                key: "requestedBy",
                headerName: t("Requested By"),
                span: 2,
                valueGetter: (value) => {
                    var valueUpdated = value;
                    if (value === user.userId) valueUpdated = user;
                    return (
                        <>
                            {!!valueUpdated?.companyName && <span className="w600">{valueUpdated.companyName} / </span>}
                            {`${valueUpdated?.name ?? ""} ${valueUpdated?.surname ?? ""}`}
                        </>
                    );
                },
            },
            {
                key: "subject",
                headerName: t("Subject"),
                span: 2,
            },
            {
                key: "ticketId",
                headerName: t("Ticket ID"),
                span: 1,
            },
            {
                key: "status",
                headerName: t("Status"),
                span: 1,
                valueGetter: (value) => (value ? t("OPEN") : t("CLOSED")),
            },
            {
                key: "createdAt",
                headerName: t("Created Date"),
                span: 1.5,
            },
            {
                key: "lastActivity",
                headerName: t("Last Activity"),
                span: 1.5,
            },
        ],
        [user, t]
    );

    // const { companyColor, name, surname, companyName } = useMemo(() => {
    //     return user ?? { companyColor: "", name: "", surname: "", companyName: null };
    // }, [user]);

    const [tickets, setTickets] = useState([]); // list of all tickets
    const [selectedTab, setSelectedTab] = useState(0); // selected tab (OPEN or CLOSED)

    const shownTickets = useMemo(() => {
        // tickets to be shown with respect to selected tab
        return tickets.filter(({ status }) => {
            return status === 1 - selectedTab;
        });
        // return Object.values(tickets)[selectedTab] ?? [];
    }, [tickets, selectedTab]);

    const fetchTickets = useCallback(async () => {
        // get the tickets from the server and update 'tickets' accordingly
        const response = await axios.post("/getTickets", { userId: user.userId });
        if (response.status !== 200) return;
        setTickets(response.data);
    }, [user]);

    const fetchMessages = useCallback(
        // fetch messages of a ticket when it's opened
        async (ticketId) => {
            const response = await axios.post("/getTicketMessage", { userId: user.userId, ticketId });
            if (response.status !== 200) return;
            const { data } = response;
            setTickets((oldValue) => {
                const index = oldValue.findIndex(({ ticketId: _ticketId }) => {
                    return _ticketId === ticketId;
                });
                const newValue = [...oldValue];
                newValue[index].messages = data;
                return newValue;
            });
        },
        [user]
    );

    const changeTab = useCallback((tabIndex) => {
        // change 'selectedTab' value accordingly
        setSelectedTab(tabIndex);
    }, []);

    const deleteTickets = useCallback(
        async (toBeDeletedList) => {
            // delete tickets selected
            if (toBeDeletedList.length === 0) return;
            const ticketsToDelete = "(" + toBeDeletedList.join(",") + ")";
            const res = await axios.post("deleteTickets", { userId: user.userId, ticketIds: ticketsToDelete });
            if (res.status !== 200) return;

            setTickets((oldValue) => {
                let newValue = [...oldValue];
                newValue = newValue.filter(({ ticketId }) => {
                    return !toBeDeletedList.includes(ticketId.toString());
                });

                return newValue;
            });
        },
        [user]
    );

    const getTicketById = useCallback(
        (id) => {
            const selectedTicket = tickets.find(({ ticketId }) => {
                return id === ticketId;
            });

            return selectedTicket;
        },
        [tickets]
    );

    const respond = useCallback(
        // send a response to a ticket
        async (ticketId, message, ticketTo, userId, files = null) => {
            const now = getNow();
            const res = await axios.post("ticketMessage", {
                ticketId,
                userId,
                message,
                ticketTo,
                files: files?.length > 0 ? JSON.stringify(files) : null,
            });
            if (res.status !== 200) return;

            setTickets((oldValue) => {
                const indexOfTicket = oldValue.findIndex((ticket) => {
                    return ticket.ticketId === ticketId;
                });
                const newValue = [...oldValue];
                const messageObject = {
                    sourceId: userId,
                    date: now,
                    messages: message,
                    name: user.name,
                    surname: user.surname,
                    color: user.color,
                    companyName: user.companyName,
                    files,
                };
                newValue[indexOfTicket].messages.push(messageObject);
                return newValue;
            });
        },
        [user]
    );

    const createTicket = useCallback(
        // create a ticket and send it to the server
        async (subject, message, assignedTo = null, fileNames = null) => {
            const newTicket = {
                requestedBy: user.userId,
                firmId: user.firmId,
                assignedTo: assignedTo,
                subject,
                status: 1,
                message,
                isSenderAccountant: user.accountant === -1,
                files: fileNames?.length > 0 ? JSON.stringify(fileNames) : null,
            };
            const response = await axios.post("openTicket", newTicket);
            if (response.status !== 200) return;
            const { data } = response;

            data.color = user.color;
            setTickets((oldValue) => [data, ...oldValue]);
            return data.ticketId;
        },
        [user]
    );

    const closeTicket = useCallback(async (ticketId, userId) => {
        // close a ticket (move it to CLOSED)
        const response = await axios.post("closeTicket", { userId, ticketId });
        if (response.status !== 200) return;
        setTickets((oldValue) => {
            const indexOfTicket = oldValue.findIndex((ticket) => {
                return ticket.ticketId === ticketId;
            });
            const newValue = [...oldValue];
            const ticketToBeClosed = newValue.splice(indexOfTicket, 1);
            ticketToBeClosed[0].status = 0;
            newValue.push(ticketToBeClosed[0]);
            return newValue;
        });
    }, []);

    const value = useMemo(
        () => ({
            tickets,
            shownTickets,
            COLUMNS,
            fetchTickets,
            fetchMessages,
            changeTab,
            deleteTickets,
            getTicketById,
            respond,
            createTicket,
            closeTicket,
        }),
        [tickets, shownTickets, COLUMNS, fetchTickets, fetchMessages, changeTab, deleteTickets, getTicketById, respond, createTicket, closeTicket]
    );
    return <TicketsContext.Provider value={value}>{children}</TicketsContext.Provider>;
};

export default TicketsProvider;
