import axios from "axios";
import { createContext, useMemo, useState, useCallback, useContext } from "react";
import { COLORS } from "utils/lists.utils";
import { AuthContext } from "./auth.context";
import { ClientsContext } from "./clients.context";

export const NewClientContext = createContext({
    newCompanyData: {},
    admins: [],
    employees: [],
    updateNewCompanyData: () => {},
    updateNumberOfAdmins: () => {},
    updateAdminData: () => {},
    updateNumberOfEmployees: () => {},
    updateEmployeeData: () => {},
    save: () => {},
    clear: () => {},
});

const initialPersonalAccount = {
    companyType: 0, // holds index of ["Personal", "Company"]
    name: "",
    surname: "",
    username: "",
    vatNumber: "",
    jobTitle: "",
    email: "",
    phone: "",
    country: "",
    city: "",
    address: "",
    zipCode: "",
    id: "",
    subscription: 0,
    color: 0,
    archivePeriod: null, // holds index of ["1 Box", "2 Box", "4 Box", "12 Box"]
    safeLockingPeriod: null, // holds index of ["2 Weeks", "1 Month", "3 Months", "6 Month", "Inactivated"]
    liveChat: null, // holds index of ["Activated", "Inactivated"]
    mobile: null, // holds index of ["Activated", "Inactivated"]
};

const initialCompany = {
    companyType: 1, // holds index of ["Personal", "Company"]
    name: "",
    vatNumber: "",
    clientType: null, // holds index of ["SRL", "ASBL", "SA", "SDE", "SCS", "SNC"]
    email: "",
    website: "",
    country: "",
    city: "",
    address: "",
    zipCode: "",
    id: "",
    specialities: "",
    color: 0,
    subscription: 0,
    archivePeriod: null, // holds index of ["1 Box", "2 Box", "4 Box", "12 Box"]
    safeLockingPeriod: null, // holds index of ["2 Weeks", "1 Month", "3 Months", "6 Month", "Inactivated"]
    liveChat: null, // holds index of ["Activated", "Inactivated"]
    mobile: null, // holds index of ["Activated", "Inactivated"]
};

const initialAdmin = {
    name: "",
    surname: "",
    username: "",
    jobTitle: "",
    email: "",
    phone: "",
    position: 0,
};

const initialEmployee = {
    name: "",
    surname: "",
    username: "",
    jobTitle: "",
    email: "",
    phone: "",
    position: 1,
};

const organizeClient = (newCompanyData, admins, employees) => {
    // shape the client data to expected format
    const now = new Date();
    newCompanyData.createdAt = now.toDateString();
    if (newCompanyData.companyType === 0)
        // if it is a personal account
        return { ...newCompanyData, filler: { done: 100, message: 20, invoices: 13 } };
    // if it is not a personal account (it is a company account)
    const { companyType, id, ...company } = newCompanyData;
    return {
        companyType,
        id,
        company,
        employees: [...admins, ...employees],
        filler: { done: 100, message: 20, invoices: 13 },
    };
};

const NewClientProvider = ({ children }) => {
    const { user } = useContext(AuthContext);
    const { fetchClients } = useContext(ClientsContext);

    const [newCompanyData, setNewCompanyData] = useState({ companyType: null }); // the data of the client to be added
    const [admins, setAdmins] = useState([]); // admin list of the client (no need if the client is a personal account)
    const [employees, setEmployees] = useState([]); // employee list of the client (no need if the client is a personal account)

    const userId = useMemo(() => user?.userId ?? -1, [user.userId]);

    //PART: Funtions
    const updateNewCompanyData = useCallback((key, value) => {
        // add {key: value} to 'newCompanyData'
        if (key === "companyType") {
            setNewCompanyData(value === 0 ? initialPersonalAccount : initialCompany);
            return;
        }

        setNewCompanyData((oldValue) => {
            const newValue = { ...oldValue, [key]: value };
            return newValue;
        });
    }, []);

    const updateNumberOfAdmins = useCallback((length) => {
        // set the number of admins to 'length'
        const adminList = new Array(length).fill(initialAdmin);
        setAdmins(adminList);
    }, []);

    const updateAdminData = useCallback((index, key, value) => {
        // add {key: value} to the 'admins[index]'
        setAdmins((oldValue) => {
            const newValue = [...oldValue];
            newValue[index] = { ...newValue[index], [key]: value };
            return newValue;
        });
    }, []);

    const updateNumberOfEmployees = useCallback((length) => {
        // set the number of employees to 'length'

        const employeeList = new Array(length).fill(initialEmployee);
        setEmployees(employeeList);
    }, []);

    const updateEmployeeData = useCallback((index, key, value) => {
        // add {key: value} to the 'employees[index]'
        setEmployees((oldValue) => {
            const newValue = [...oldValue];
            newValue[index] = { ...newValue[index], [key]: value };
            return newValue;
        });
    }, []);

    const save = useCallback(async () => {
        // save the client and upload it to the server
        newCompanyData.color = COLORS[newCompanyData.color];
        const organizedNewClient = organizeClient(newCompanyData, admins, employees);
        const dataToBeSent = { ...organizedNewClient, userId };

        const id = await axios
            .post("/newClient", dataToBeSent)
            .then((res) => {
                if (res.status === 200) return res.data.id;
                return null;
            })
            .catch((err) => {
                console.error(err);
                return null;
            });
        if (!id) return;

        fetchClients();
    }, [newCompanyData, admins, employees, userId, fetchClients]);

    const clear = useCallback(() => {
        setNewCompanyData({ companyType: null });
        setAdmins([]);
        setEmployees([]);
    }, []);

    //PART: Value

    const value = useMemo(
        () => ({
            newCompanyData,
            admins,
            employees,
            updateNewCompanyData,
            updateNumberOfAdmins,
            updateAdminData,
            updateNumberOfEmployees,
            updateEmployeeData,
            save,
            clear,
        }),
        [
            newCompanyData,
            admins,
            employees,
            updateNewCompanyData,
            updateNumberOfAdmins,
            updateAdminData,
            updateNumberOfEmployees,
            updateEmployeeData,
            save,
            clear,
        ]
    );

    return <NewClientContext.Provider value={value}>{children}</NewClientContext.Provider>;
};

export default NewClientProvider;
