import { createContext, useState, useEffect, useContext } from "react";
import PdfViewer from "components/pdf-viewer/pdf-viewer.component";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";

import axios from "axios";

import { AuthContext } from "./auth.context";

import { getNow } from "utils/date.utils";
import { useCallback } from "react";
import { useMemo } from "react";
import MoveFileModal from "components/move-file-modal/move-file-modal.component";
import { ClientsContext } from "./clients.context";
import { isObjectEmpty } from "utils/array.utils";
import { YEARS } from "utils/lists.utils";
import { LoadingContext } from "./loading.context";
import useTranslate from "src/hooks/use-translate";

export const FolderContext = createContext({
    currentFolderLocked: null,
    files: {},
    clientFiles: {},
    currentLocation: [],
    focusedFiles: {},
    currentLocationMoveFile: [],
    focusedFilesMoveFile: {},
    openedFile: null,
    openedFileInfo: null,
    yearSelection: null,
    setClientFiles: () => {},
    addFile: () => {},
    sortFiles: () => {},
    deleteFile: () => {},
    setFileToBeMoved: () => {},
    moveFile: () => {},
    openFile: () => {},
    closeFile: () => {},
    addFolder: () => {},
    lockFolder: () => {},
    lockFolderAcc: () => {},
    unlockFolderAcc: () => {},
    renameFolder: () => {},
    deleteFolder: () => {},
    getInside: () => {},
    getInsideMoveFile: () => {},
    goBack: () => {},
    goBackMoveFile: () => {},
    updateRef: () => {},
    getUpdatedFile: () => {},
    updateFilesFromServer: () => {},
    setYear: () => {},
    setFocusedFiles: () => {},
});

/**
 * Organizes the current folder structure by uniting
 * foldername with other attributes ** ("isEmpty") and
 * folder are organized as an Array
 * @param {Object} list Raw folder tree - comes from getData
 * @returns Organized information in folder tree structure with Arrayed folders
 */
const organizeCurrentFolders = (list = { folders: [], files: [], isLocked: 0 }) => {
    // organize the files to the format expected
    const { folders, files, isLocked } = list;
    const organizedList = {
        files: [],
        folders: [],
        isLocked: isLocked,
    };

    Object.keys(folders).forEach((fileName) => {
        const newFile = {
            fileName,
            folderId: folders[fileName].folderId,
            isLocked: folders[fileName].isLocked,
            // Add isEmpty attribute to the folder
            isEmpty: Object.keys(folders[fileName]?.files ?? {}).length === 0,
            isCustom: folders[fileName].isCustom,
        };
        organizedList["folders"].push(newFile);
    });
    organizedList.files = files;
    return organizedList;
};

/**
 * Fetches the directory from db
 * @param {Number} userId
 * @returns the root of the directory
 */
const fetchFiles = async (userId, role, firmId, clients = []) => {
    // get files/folders tree from the server
    let errorFlag = false;
    let res;
    if (role === 1) {
        res = await axios
            .get(`/getFiles?userId=${userId}&firmId=${firmId}`)
            .then((res) => res.data)
            .catch((err) => {
                errorFlag = true;
                return err;
            });
    } else if (role === 0) {
        // Accountant fetches files for all clients
        let clientIds = clients.join(",");
        res = await axios
            .get(`/getClientFiles?clients=${clientIds}`)
            .then((res) => res.data)
            .catch((err) => {
                errorFlag = true;
                return err;
            });
    }
    if (errorFlag) return null;
    return res;
};

const renameFunction = () => {
    // returns a function to update the file/folder name if there is a file/folder with the same name
    let index = 1;

    return function (fileName) {
        if (index === 0) return fileName;
        return `${fileName} (${index++})`;
    };
};

const addFolderToServer = async (userId, firmId, folderName, currentLocation, yearSelection) => {
    // send the folder specified to the server
    let path = yearSelection;
    if (currentLocation.length !== 0) path += "/" + currentLocation.join("/");

    const data = await axios
        .post("addFolder", {
            userId,
            firmId,
            location: path,
            folderName,
        })
        .then((res) => {
            if (res.status === 200) return res.data.folderId;
            return null;
        })
        .catch(console.error);
    return data;
};

const uploadFile = async (userId, file, currentLocation, firmId, year) => {
    // function to be used to upload files to the server but it is changed and s3 connection will be used instead
    var errorFlag = false;

    const path = year + "/" + currentLocation.join("/");

    const resp = await axios
        .get(`/getSecurePut?userId=${userId ?? -1}&firmId=${firmId}&location=${path}&fileName=${file.file.name}&year=${year}`)
        .then((res) => {
            if (res.status !== 200) {
                errorFlag = true;
                return null;
            }
            return res.data;
        })
        .catch((err) => {
            errorFlag = true;
            return err;
        });
    if (errorFlag || !resp) return null;

    const error = await fetch(resp, {
        method: "PUT",
        headers: {
            "Content-type": "multipart/form-data",
        },
        body: file.file,
    })
        .then((res) => res.text())
        .then((data) => {
            if (data === "") {
                console.log("File uploaded successfully.");
                return null;
            } else return true;
        })
        .catch((err) => {
            console.error({ errorOnUploadPhotoPut: err });
            return true;
        });

    if (error) return;
    await axios
        .post("/uploadFileInfo", {
            userId,
            firmId,
            location: path,
            fileName: file.file.name,
            createdAt: getNow(),
            ref: "NA",
        })
        .then((res) => {
            if (res === "file uploaded") {
                console.log("file uploaded");
            }
        })
        .catch(console.error);
};

export const getFileLinkFromServer = async (userId, firmId, path) => {
    // get the link from s3 for opening the file
    var errorFlag = false;

    const resp = await axios
        .get(`/getSecureRead?userId=${userId}&firmId=${firmId}&objectPath=${path}`)
        .then((res) => res.data)
        .catch((err) => {
            errorFlag = true;
            return err;
        });

    if (errorFlag) return "";
    return resp;
};

const sendRenameRequest = async (userId, location, oldName, newName, folderId, firmId) => {
    // send the rename request to server
    const err = await axios
        .post("/renameFolder", { userId, location, folderName: oldName, newName, folderId, firmId })
        .then((res) => (res.status !== 200 ? true : null))
        .catch((err) => {
            console.error(err);
            return true;
        });

    return err;
};

function sortFoldersByName(foldersObj) {
    const keys = Object.keys(foldersObj);
    keys.sort((a, b) => {
        if (a.length < b.length) return -1;
        else if (a.length > b.length) return 1;
        else if (a < b) return -1;
        else if (a > b) return 1;
        return 0;
    });
    const sortedObj = {};
    for (let key of keys) {
        sortedObj[key] = foldersObj[key];
    }
    return sortedObj;
}

// Add progress info to the files
const addProgressInfo = (_files) => {
    // For each client
    Object.keys(_files).forEach((clientId) => {
        const rootFolders = _files[clientId]?.[YEARS[0]]?.folders;
        // For each root folder
        Object.keys(rootFolders ?? {}).forEach((folderName) => {
            let progressArr = [];
            let boxCount = 0;
            // for each subfolder to check if it is locked
            const nonCustomFolders = Object.fromEntries(Object.entries(rootFolders[folderName].folders).filter(([subFolderName, folder]) => !folder.isCustom));
            const sortedNonCustomFolders = sortFoldersByName(nonCustomFolders);
            Object.keys(sortedNonCustomFolders).forEach((subFolderName, index) => {
                const currentFolder = rootFolders[folderName].folders[subFolderName];
                boxCount++;
                if (currentFolder.isLocked === 1) progressArr.push(index + 1);
            });
            rootFolders[folderName].progress = [progressArr, boxCount];
        });
    });
};

/**
 *
 *
 * THE FOLDER CONTEXT
 *
 *
 */
const FolderProvider = ({ children }) => {
    const { user } = useContext(AuthContext);
    const { setLoading } = useContext(LoadingContext);
    const { openedClient, clients } = useContext(ClientsContext);
    const t = useTranslate("Archive");
    const [openedFile, setOpenedFile] = useState(null);
    const [clientFiles, setClientFiles] = useState({});
    const [parentFiles, setParentFiles] = useState({});
    const [files, setFiles] = useState({});
    const [focusedFiles, setFocusedFiles] = useState({
        // files and folders to be shown to the user according to user's location
        files: [],
        folders: [],
        isLocked: null,
        isCustom: null,
    });

    const [currentFolderLocked, setCurrentFolderLocked] = useState(false); // Indicates whether user can make changes to current folder
    const [currentLocation, setCurrentLocation] = useState([]); // location of the user (which folder to be shown)
    const [currentLocationMoveFile, setCurrentLocationMoveFile] = useState([]); // location of the folder to be moved
    const [focusedFilesMoveFile, setFocusedFilesMoveFile] = useState({
        // just like focusedFiles but for moveFile popup
        files: [],
        folders: [],
    });
    const [fileToBeMoved, setFileToBeMoved] = useState(null); // the file to be moved to another location
    const [openedFileInfo, setOpenedFileInfo] = useState({}); // {fileName, directory}, file info that is opened
    const [yearSelection, setYearSelection] = useState(YEARS[0]);

    // **********************************************************************************************************************************************************
    // **********************************************************************************************************************************************************
    // ********************************************************* Class Functions ********************************************************************************
    // **********************************************************************************************************************************************************
    // **********************************************************************************************************************************************************

    /**
     * First, reach to the current directory from parent folder in "files".
     * Then, get the data in the specified directory
     * @returns the data {folders, files, isLocked} for the specified directory
     */
    const getData = useCallback(
        (directory = currentLocation, _files = files) => {
            if (directory.length === 0) return _files;

            let data = _files;
            directory.slice(0, -1).forEach((folderName) => {
                data = data[folderName].folders;
            });
            data = data[directory.at(-1)];
            return data; // {isLocked, folders, files, isCustom, folderId}
        },
        [currentLocation, files]
    );

    // get inside of (open) the specified folder
    const getInside = useCallback(
        (folderName, isRootFile = false) => {
            // if folderName exists: get inside of that folder; else: return files in the root
            if (isRootFile) {
                // if it is a root folder
                setCurrentLocation((old) => [folderName]);
                return;
            }
            if (folderName) setCurrentLocation([...currentLocation, folderName]);
        },
        [currentLocation]
    );

    const getInsideMoveFile = useCallback(
        // just like getInside function but for moveFile popup
        (folderName) => {
            const isRootFile = currentLocationMoveFile.length === 0;
            // if folderName exists: get inside of that folder; else: return files in the root
            if (isRootFile) {
                // if it is a root folder
                setCurrentLocationMoveFile([folderName]);
                return;
            }
            if (folderName) setCurrentLocationMoveFile([...currentLocationMoveFile, folderName]);
        },
        [currentLocationMoveFile]
    );

    const goBack = useCallback(() => {
        // go back a step in folders
        if (currentLocation.length === 0) return;

        let newList = [...currentLocation];
        newList.pop();
        setCurrentLocation(newList);
    }, [currentLocation]);

    const goBackMoveFile = useCallback(() => {
        // just like goBack function but for moveFile popup
        if (currentLocationMoveFile.length === 0) return;

        let newList = [...currentLocationMoveFile];
        newList.pop();
        setCurrentLocationMoveFile(newList);
    }, [currentLocationMoveFile]);

    const addFolder = useCallback(
        // add the folder with the name specified after checking if the name is duplicate and updating if needed (e.g. folder => folder 1)
        async (name, isRoot = false) => {
            var updatedName = t("New Folder");
            var filesWithSameName = [];

            const rename = renameFunction();
            var checkDuplicate = ({ fileName }) => {
                return fileName.toUpperCase() === updatedName.toUpperCase();
            };

            if (isRoot) {
                checkDuplicate = (name) => {
                    return name.toUpperCase() === updatedName.toUpperCase();
                };

                filesWithSameName = Object.keys(files).filter(checkDuplicate);
                while (filesWithSameName.length > 0) {
                    updatedName = rename(t("New Folder"));
                    filesWithSameName = Object.keys(files).filter(checkDuplicate);
                }
                let newFolderId;
                if (user?.accountant === -1) newFolderId = await addFolderToServer(openedClient?.userId, openedClient.firmId, updatedName, [], yearSelection);
                else newFolderId = await addFolderToServer(user?.userId, user.firmId, updatedName, [], yearSelection);
                if (!newFolderId) return;

                if (user?.accountant === -1) {
                    setClientFiles((oldValue) => {
                        const newValue = { ...oldValue };
                        let tempCurrentLocation = newValue[openedClient.userId ?? openedClient.firmId][yearSelection].folders;
                        currentLocation.slice(0, -1).forEach((folderName) => {
                            tempCurrentLocation = tempCurrentLocation[folderName].folders;
                        });
                        tempCurrentLocation[currentLocation.at(-1)].folders[updatedName] = {
                            isLocked: 0,
                            folders: {},
                            files: [],
                            isCustom: 1,
                            folderId: newFolderId,
                        };
                        return newValue;
                    });
                    return;
                }
                setFiles((initialFiles) => {
                    const newFiles = { ...initialFiles };
                    newFiles[updatedName] = {
                        isLocked: 0,
                        folders: {},
                        files: [],
                        isCustom: 1,
                        folderId: newFolderId,
                    };
                    return newFiles;
                });
                return;
            }
            // If not in root folder
            filesWithSameName = focusedFiles.folders.filter(checkDuplicate);
            // Get updated name for folder
            while (filesWithSameName.length > 0) {
                updatedName = rename(t("New Folder"));
                filesWithSameName = focusedFiles.folders.filter(checkDuplicate);
            }
            let newFolderId;
            if (user?.accountant === -1)
                newFolderId = await addFolderToServer(openedClient?.userId, openedClient.firmId, updatedName, currentLocation, yearSelection);
            else newFolderId = await addFolderToServer(user?.userId, user.firmId, updatedName, currentLocation, yearSelection);
            if (!newFolderId) return;

            if (user?.accountant === -1) {
                setClientFiles((oldValue) => {
                    const newValue = { ...oldValue };
                    let tempCurrentLocation = newValue[openedClient.userId ?? openedClient.firmId][yearSelection].folders;
                    currentLocation.slice(0, -1).forEach((folderName) => {
                        tempCurrentLocation = tempCurrentLocation[folderName].folders;
                    });
                    tempCurrentLocation[currentLocation.at(-1)].folders[updatedName] = {
                        isLocked: 0,
                        folders: {},
                        files: [],
                        isCustom: 1,
                        folderId: newFolderId,
                    };
                    return newValue;
                });
                return;
            }
            setFiles((oldValue) => {
                const newValue = { ...oldValue };
                var tempCurrentLocation = newValue;
                currentLocation.slice(0, -1).forEach((folderName) => {
                    tempCurrentLocation = tempCurrentLocation[folderName].folders;
                });

                tempCurrentLocation[currentLocation.at(-1)].folders[updatedName] = {
                    isLocked: 0,
                    folders: {},
                    files: [],
                    isCustom: 1,
                    folderId: newFolderId,
                };
                return newValue;
            });
        },
        [user, currentLocation, files, focusedFiles, yearSelection, openedClient, t]
    );

    /**
     * Update the file/folder system from server by fetching
     */
    const updateFilesFromServer = useCallback(async () => {
        if (!user) return;
        if (user.role === 1 && yearSelection) {
            // If user is client
            const _files = await fetchFiles(user.userId, user.role, user.firmId);
            if (_files) {
                const fileSystemByYears = _files[""].folders;
                setFiles(fileSystemByYears[yearSelection].folders);
                setParentFiles(fileSystemByYears);
            }
        } else if (user.role === 0 && clients.length !== 0) {
            // If user is accountant, fetch and set file systems of ALL clients
            const clientIds = [];
            clients.forEach((client) => {
                if (client.userId) clientIds.push(client.userId, client.firmId);
                else clientIds.push(-1, client.firmId);
            });

            const _files = await fetchFiles(user.userId, user.role, user.firmId, clientIds);
            if (_files) {
                addProgressInfo(_files);
                setClientFiles(_files);
            }
        }
    }, [user, clients, yearSelection]);

    /**
     * Change the name of the folder after checking if the name is unique,
     * and if not unique update it (e.g. folder => folder 1)
     */
    const renameFolder = useCallback(
        async (oldFolderName, newFolderName, folderId, directory = currentLocation) => {
            setLoading(true);
            if (oldFolderName.trim() === newFolderName.trim()) return;

            let updatedName = newFolderName;
            let filesWithSameName = [];

            const rename = renameFunction();
            const checkDuplicate = (name) => {
                return name.toUpperCase() === updatedName.toUpperCase();
            };

            let folders;
            if (user.role === 1) folders = getData(directory).folders;
            else folders = getData(directory, clientFiles[openedClient.userId ?? openedClient.firmId][yearSelection].folders).folders;

            // Check the new name if it's duplicate
            filesWithSameName = Object.keys(folders).filter(checkDuplicate);
            while (filesWithSameName.length > 0) {
                updatedName = rename(newFolderName);
                filesWithSameName = Object.keys(folders).filter(checkDuplicate);
            }
            const err = await sendRenameRequest(
                openedClient?.userId ?? user.userId,
                yearSelection + "/" + directory.join("/"),
                oldFolderName,
                updatedName,
                folderId,
                openedClient?.firmId ?? user.firmId
            );
            if (err) {
                setLoading(false);
                return "error";
            }
            await updateFilesFromServer();
            setLoading(false);
            return updatedName;
        },
        [user, currentLocation, yearSelection, clientFiles, openedClient, getData, updateFilesFromServer, setLoading]
    );

    // Delete the folder specified by name and it's location (directory)
    const deleteFolder = useCallback(
        async (folderName, directory = currentLocation) => {
            let path = yearSelection;
            if (currentLocation.length !== 0) path += "/" + directory.join("/");
            let errorFlag = false;
            await axios
                .post("/deleteFolder", {
                    userId: user.accountant === -1 ? (openedClient.firmId === -1 ? openedClient.userId : openedClient.firmId) : user.userId,
                    firmId: user.accountant === -1 ? openedClient.firmId : user.firmId,
                    deletedBy: user.userId,
                    deletedAt: getNow(),
                    location: path, // path of the parent folder
                    folderName: folderName, // folder to be locked
                })
                .then((res) => {})
                .catch((err) => {
                    console.error(err);
                    errorFlag = true;
                });
            if (errorFlag) return null;

            // updateFilesFromServer();
            if (user.accountant === -1) {
                setClientFiles((oldValue) => {
                    const newValue = { ...oldValue };
                    let tempCurrentLocation = newValue[openedClient.userId ?? openedClient.firmId][yearSelection].folders;
                    directory.slice(0, -1).forEach((folderName) => {
                        tempCurrentLocation = tempCurrentLocation[folderName].folders;
                    });
                    tempCurrentLocation = tempCurrentLocation[directory[directory.length - 1]].folders;

                    delete tempCurrentLocation[folderName];
                    return newValue;
                });
                return "success";
            }
            setFiles((oldValue) => {
                const newValue = { ...oldValue };
                let tempCurrentLocation = newValue;
                directory.slice(0, -1).forEach((folderName) => {
                    tempCurrentLocation = tempCurrentLocation[folderName].folders;
                });
                tempCurrentLocation = tempCurrentLocation[directory[directory.length - 1]].folders;

                delete tempCurrentLocation[folderName];
                return newValue;
            });
            return "success";
        },
        [currentLocation, user, yearSelection, openedClient]
    );

    // Lock the folder for clients
    const lockFolder = useCallback(
        async (id) => {
            var path = yearSelection;
            if (currentLocation.length !== 1) path += "/" + currentLocation.slice(0, -1).join("/");
            const error = await axios
                .post("lockFolder", {
                    userId: user.userId,
                    firmId: user.firmId,
                    location: path, // path of the parent folder
                    folderName: currentLocation.at(-1), // folder to be locked
                })
                .then((res) => {
                    console.log(res);
                    if (res.status !== 200) return true;
                    setFiles((oldFiles) => {
                        let newFiles = { ...oldFiles };
                        let tempFiles = newFiles;
                        currentLocation.slice(0, -1).forEach((folderName) => {
                            tempFiles = tempFiles[folderName].folders;
                        });
                        tempFiles[currentLocation.at(-1)].isLocked = 1;
                        return newFiles;
                    });
                    return null;
                })
                .catch((err) => {
                    console.error(err);
                    return true;
                });
            return error;
        },
        [user, currentLocation, yearSelection]
    );

    // Lock folder for accountant
    const lockFolderAcc = useCallback(
        async (id) => {
            var path = yearSelection;
            if (currentLocation.length !== 1) path += "/" + currentLocation.slice(0, -1).join("/");
            const error = await axios
                .post("lockFolderAcc", {
                    userId: user.userId,
                    clientId: id,
                    isCompany: openedClient.firmId !== -1,
                    location: path, // path of the parent folder
                    folderName: currentLocation.at(-1), // folder to be locked
                })
                .then((res) => {
                    console.log(res);
                    if (res.status !== 200) return true;
                    setClientFiles((oldFiles) => {
                        const newFiles = { ...oldFiles };
                        let tempFiles = newFiles[id][yearSelection].folders;
                        currentLocation.slice(0, -1).forEach((folderName) => {
                            tempFiles = tempFiles[folderName].folders;
                        });
                        tempFiles[currentLocation.at(-1)].isLocked = 1;
                        return newFiles;
                    });
                    return null;
                })
                .catch((err) => {
                    console.error(err);
                    return true;
                });
            return error;
        },
        [user, currentLocation, openedClient, yearSelection]
    );

    // Unlock folder for accountant
    const unlockFolderAcc = useCallback(
        async (id) => {
            let path = yearSelection;
            if (currentLocation.length !== 1) path += "/" + currentLocation.slice(0, -1).join("/");
            const error = await axios
                .post("unlockFolder", {
                    userId: user.userId,
                    clientId: id,
                    isCompany: openedClient.firmId !== -1,
                    location: path, // path of the parent folder
                    folderName: currentLocation.at(-1), // folder to be locked
                })
                .then((res) => {
                    console.log(res);
                    if (res.status !== 200) return true;
                    setClientFiles((oldFiles) => {
                        const newFiles = { ...oldFiles };
                        let tempFiles = newFiles[id][yearSelection].folders;
                        currentLocation.slice(0, -1).forEach((folderName) => {
                            tempFiles = tempFiles[folderName].folders;
                        });
                        tempFiles[currentLocation.at(-1)].isLocked = 0;
                        return newFiles;
                    });
                    return null;
                })
                .catch((err) => {
                    console.error(err);
                    return true;
                });
            return error;
        },
        [user, yearSelection, openedClient, currentLocation]
    );

    /**
     * Upload a file to given directory
     */
    const addFile = useCallback(
        async (file, newDirectory = currentLocation, isNewFile = true) => {
            setLoading(true);
            const fileToBeAdded = isNewFile ? { file, createdAt: getNow(), ref: "" } : file;
            let updatedName = fileToBeAdded.file.name ?? fileToBeAdded.fileName;
            let tempCurrentLocation;
            if (user?.accountant === -1) tempCurrentLocation = { ...clientFiles }[openedClient.userId ?? openedClient.firmId][yearSelection].folders;
            else tempCurrentLocation = { ...files };

            // Go to the files of the current folder to check names
            newDirectory.slice(0, -1).forEach((folderName) => {
                tempCurrentLocation = tempCurrentLocation[folderName].folders;
            });
            tempCurrentLocation = tempCurrentLocation[newDirectory[newDirectory.length - 1]].files;

            // Check if the files in current directory has the same name as the file to be added
            const rename = renameFunction();
            const checkDuplicate = ({ fileName }) => {
                return fileName.toUpperCase() === updatedName.toUpperCase();
            };
            let filesWithSameName = tempCurrentLocation.filter(checkDuplicate);
            while (filesWithSameName.length > 0) {
                updatedName = rename(fileToBeAdded.file.name);
                filesWithSameName = tempCurrentLocation.filter(checkDuplicate);
            }

            // Update the file to be added with the updated name
            fileToBeAdded.file = new File([fileToBeAdded.file], updatedName);
            // Upload the file to database
            if (user?.accountant === -1) await uploadFile(openedClient.userId, fileToBeAdded, newDirectory, openedClient.firmId, yearSelection);
            else await uploadFile(user.userId, fileToBeAdded, newDirectory, user.firmId, yearSelection);
            updateFilesFromServer();
            setLoading(false);
        },
        [user, files, currentLocation, yearSelection, openedClient, clientFiles, updateFilesFromServer, setLoading]
    );

    const sortFiles = useCallback(
        // change the order of the files to the param 'newDocs'
        (newDocs, isAccountant = false) => {
            if (!isAccountant)
                setFiles((initialFiles) => {
                    const newFiles = { ...initialFiles };
                    var tempCurrentLocation = newFiles;
                    currentLocation.slice(0, -1).forEach((folderName) => {
                        tempCurrentLocation = tempCurrentLocation[folderName].folders;
                    });

                    tempCurrentLocation[currentLocation.at(-1)].files = newDocs;

                    return newFiles;
                });
            else
                setClientFiles((initialFiles) => {
                    const newFiles = { ...initialFiles };
                    var tempCurrentLocation = newFiles[openedClient.userId ?? openedClient.firmId][yearSelection].folders;
                    currentLocation.slice(0, -1).forEach((folderName) => {
                        tempCurrentLocation = tempCurrentLocation[folderName].folders;
                    });

                    tempCurrentLocation[currentLocation.at(-1)].files = newDocs;

                    return newFiles;
                });
        },
        [currentLocation, openedClient, yearSelection]
    );

    const openFile = useCallback(
        // open a file
        async (fileName, isOriginal = false, directory = currentLocation) => {
            setLoading(true);
            const path = yearSelection + "/" + directory.join("/") + "/" + fileName;
            if (user.role === 0) {
                // if user is accountant, use client's id to see files
                const link = await getFileLinkFromServer(openedClient.userId, openedClient.firmId, path); // Get the url of the object
                const file = await fetch(link)
                    .then((response) => {
                        if (response.status !== 200) throw new Error("File not found");
                        return response.blob();
                    })
                    .then((blob) => {
                        const file = new File([blob], fileName, { type: blob.type });
                        return file;
                    })
                    .catch((error) => {
                        console.error(error);
                        setLoading(false);
                        return null;
                    });
                if (!file) return;

                const { ref, fileId } = focusedFiles.files.find((file) => file.fileName === fileName);
                file.ref = ref;
                setOpenedFile(file);
                setLoading(false);
                setOpenedFileInfo({ fileName, directory, isOriginal, url: link, ref, fileId });
                return;
            }
            // Get the url of the object
            const link = await getFileLinkFromServer(user.userId, user.firmId, path);
            // Download file
            const file = await fetch(link)
                .then((response) => {
                    if (response.status !== 200) throw new Error("File not found");
                    return response.blob();
                })
                .then((blob) => {
                    const file = new File([blob], fileName, { type: blob.type });
                    return file;
                })
                .catch((error) => {
                    setLoading(false);
                    console.error(error);
                    return null;
                });
            if (!file) return;

            const { ref, fileId } = focusedFiles.files.find((file) => file.fileName === fileName);
            file.ref = ref;
            setOpenedFile(file);
            setLoading(false);
            setOpenedFileInfo({ fileName, directory, isOriginal, url: link, ref, fileId });
        },
        [user, currentLocation, yearSelection, focusedFiles, openedClient, setLoading]
    );

    const closeFile = useCallback(() => {
        setOpenedFile(null);
    }, []);

    const deleteFile = useCallback(
        // deletes file and returns the deleted file
        (fileName) => {
            let file = null;
            if (fileName) {
                file = focusedFiles.files.find((file) => file.fileName === fileName);
            }
            const deleteRequest = async () => {
                console.log({
                    userId: user.userId,
                    location: currentLocation.join("/"),
                    fileName: fileName,
                });
                const error = await axios
                    .post("/deleteFile", {
                        userId: user.accountant === -1 ? (openedClient.firmId === -1 ? openedClient.userId : openedClient.firmId) : user.userId,
                        firmId: user.accountant === -1 ? openedClient.firmId : user.firmId,
                        deletedBy: user.userId,
                        location: yearSelection + "/" + currentLocation.join("/"),
                        fileName: fileName,
                        deletedAt: getNow(),
                    })
                    .then((response) => {
                        if (response.status !== 200) return true;
                        return null;
                    })
                    .catch((error) => {
                        console.error(error);
                        return true;
                    });
                if (error) return;
                if (user.accountant === -1) {
                    // if accountant deleted client's folder
                    setClientFiles((oldValue) => {
                        const newValue = { ...oldValue };
                        var tempCurrentLocation = newValue[openedClient.firmId === -1 ? openedClient.userId : openedClient.firmId][yearSelection].folders;
                        currentLocation.slice(0, -1).forEach((folderName) => {
                            tempCurrentLocation = tempCurrentLocation[folderName].folders;
                        });

                        tempCurrentLocation[currentLocation[currentLocation.length - 1]].files = tempCurrentLocation[currentLocation.at(-1)].files.filter(
                            (file) => fileName !== file.fileName
                        );

                        return newValue;
                    });
                } else {
                    setFiles((oldValue) => {
                        const newValue = { ...oldValue };
                        var tempCurrentLocation = newValue;
                        currentLocation.slice(0, -1).forEach((folderName) => {
                            tempCurrentLocation = tempCurrentLocation[folderName].folders;
                        });

                        tempCurrentLocation[currentLocation[currentLocation.length - 1]].files = tempCurrentLocation[
                            currentLocation[currentLocation.length - 1]
                        ].files.filter((file) => fileName !== file.fileName);

                        return newValue;
                    });
                }
            };
            deleteRequest();
            return file;
        },
        [currentLocation, focusedFiles, user, yearSelection, openedClient]
    );

    const moveFile = useCallback(async () => {
        // move the file to the location specified by 'currentLocationMoveFile'
        setLoading(true);
        const err = await axios
            .post("moveFile", {
                oldLocation: yearSelection + "/" + currentLocation.join("/"),
                newLocation: yearSelection + "/" + currentLocationMoveFile.join("/"),
                fileName: fileToBeMoved,
                userId: openedClient?.userId ?? user.userId,
                firmId: openedClient?.firmId ?? user.firmId,
            })
            .then((response) => {
                if (response.status !== 200) return true;
                return null;
            })
            .catch((error) => {
                console.error(error);
                return true;
            });
        if (err) return;

        if (user.role === 1)
            setFiles((oldFiles) => {
                // move file to new location as the client
                const newFiles = { ...oldFiles };
                var tempCurrentLocation = newFiles;
                currentLocationMoveFile.slice(0, -1).forEach((folderName) => {
                    tempCurrentLocation = tempCurrentLocation[folderName].folders;
                });
                const fileToMove = focusedFiles.files.find((file) => file.fileName === fileToBeMoved);
                const filesInNewDirectory = [...tempCurrentLocation[currentLocationMoveFile.at(-1)].files];
                tempCurrentLocation[currentLocationMoveFile.at(-1)].files = [...filesInNewDirectory, fileToMove];

                // delete file from old location
                tempCurrentLocation = newFiles;
                currentLocation.slice(0, -1).forEach((folderName) => {
                    tempCurrentLocation = tempCurrentLocation[folderName].folders;
                });
                tempCurrentLocation[currentLocation.at(-1)].files = tempCurrentLocation[currentLocation.at(-1)].files.filter(
                    (file) => file.fileName !== fileToBeMoved
                );

                return newFiles;
            });
        else
            setClientFiles((oldFiles) => {
                // move file to new location as accountant
                const newFiles = { ...oldFiles };
                var tempCurrentLocation = newFiles[openedClient.userId ?? openedClient.firmId][yearSelection].folders;
                currentLocationMoveFile.slice(0, -1).forEach((folderName) => {
                    tempCurrentLocation = tempCurrentLocation[folderName].folders;
                });
                const fileToMove = focusedFiles.files.find((file) => file.fileName === fileToBeMoved);
                const filesInNewDirectory = [...tempCurrentLocation[currentLocationMoveFile.at(-1)].files];
                tempCurrentLocation[currentLocationMoveFile.at(-1)].files = [...filesInNewDirectory, fileToMove];

                // delete file from old location
                tempCurrentLocation = newFiles[openedClient.userId ?? openedClient.firmId][yearSelection].folders;
                currentLocation.slice(0, -1).forEach((folderName) => {
                    tempCurrentLocation = tempCurrentLocation[folderName].folders;
                });
                tempCurrentLocation[currentLocation.at(-1)].files = tempCurrentLocation[currentLocation.at(-1)].files.filter(
                    (file) => file.fileName !== fileToBeMoved
                );

                return newFiles;
            });

        setCurrentLocationMoveFile([]);
        setFileToBeMoved(null);
        setLoading(false);
    }, [fileToBeMoved, currentLocationMoveFile, user, yearSelection, currentLocation, focusedFiles, openedClient, setLoading]);

    const updateRef = useCallback(
        // update the 'ref' value of a file
        (newRef, fileId, directory = currentLocation, isAccountant = false) => {
            if (!isAccountant)
                setFiles((oldValue) => {
                    const newValue = { ...oldValue };
                    var tempCurrentLocation = newValue;
                    directory.slice(0, -1).forEach((folderName) => {
                        tempCurrentLocation = tempCurrentLocation[folderName].folders;
                    });

                    tempCurrentLocation[directory.at(-1)].files = tempCurrentLocation[directory.at(-1)].files.map((_file) => {
                        if (_file.fileId !== fileId) return _file;
                        return { ..._file, ref: newRef };
                    });

                    return newValue;
                });
            else
                setClientFiles((oldValue) => {
                    const newValue = { ...oldValue };
                    var tempCurrentLocation = newValue[openedClient.userId ?? openedClient.firmId][yearSelection].folders;
                    directory.slice(0, -1).forEach((folderName) => {
                        tempCurrentLocation = tempCurrentLocation[folderName].folders;
                    });

                    tempCurrentLocation[directory.at(-1)].files = tempCurrentLocation[directory.at(-1)].files.map((_file) => {
                        if (_file.fileId !== fileId) return _file;
                        return { ..._file, ref: newRef };
                    });

                    return newValue;
                });
            if (openedFile)
                setOpenedFile((oldFile) => {
                    const newFile = new File([oldFile], oldFile.name, {
                        type: oldFile.type,
                        lastModified: oldFile.lastModified,
                    });
                    newFile.ref = newRef;
                    return newFile;
                });
        },
        [currentLocation, openedFile, openedClient, yearSelection]
    );

    /**
     * Write the 'ref' value to upper right of the file and return it
     */
    const getUpdatedFile = useCallback(async (file, textToBeWritten) => {
        const buffer = await file.arrayBuffer();
        const pdfDoc = await PDFDocument.load(buffer);
        const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

        const pages = pdfDoc.getPages();
        const firstPage = pages[0];
        const { width, height } = firstPage.getSize();

        firstPage.drawText(textToBeWritten, {
            x: width * (0.97 - textToBeWritten.length * 0.028),
            y: height - (height > 300 ? 30 : height * 0.1) - 5,
            maxWidth: width * 0.35,
            wordBreaks: [""],
            size: height > 300 ? 30 : height * 0.1,
            font: helveticaFont,
            color: rgb(0, 0, 0),
        });

        const pdfBytes = await pdfDoc.save();

        return new Blob([pdfBytes.buffer]);
    }, []);

    /**
     * Check parent folders recursively to see if there is any lock
     */
    const updateCurrentFolderLocked = useCallback(() => {
        let _files = files;
        const lockExists = currentLocation.some((location) => {
            if (_files[location].isLocked) return true;
            _files = _files[location].folders;
            return false;
        });

        if (lockExists) setCurrentFolderLocked(true);
        else setCurrentFolderLocked(false);
    }, [currentLocation, files]);

    const setYear = useCallback((value) => {
        setYearSelection(value);
        setCurrentLocation([]);
        setFocusedFiles({ files: [], folders: [], isLocked: null });
    }, []);

    // **********************************************************************************************************************************************************
    // **********************************************************************************************************************************************************
    // ************************************************************** useEffects ********************************************************************************
    // **********************************************************************************************************************************************************
    // **********************************************************************************************************************************************************

    // Get the files from server and update 'files' accordingly on login
    useEffect(() => {
        (async () => {
            await updateFilesFromServer();
        })();
    }, [user, updateFilesFromServer]);

    // when the 'openedClient' changed, empty 'currentLocation'
    useEffect(() => {
        if (!isObjectEmpty(openedClient)) {
            setCurrentLocation([]);
            setOpenedFileInfo({});
        }
    }, [openedClient]);

    // When the 'currentLocation' changed, organize the folders and 'setFocusedFile' accordingly
    useEffect(() => {
        if (!user || currentLocation.length === 0) return;
        if (user.role === 1) {
            let currentFolderData = getData();
            const organizedList = organizeCurrentFolders(currentFolderData); // organize the folders and files like adding "isEmpty" property
            setFocusedFiles(() => {
                let customFolders = organizedList.folders.filter((folder) => folder?.isCustom);
                customFolders.sort((a, b) => {
                    if (a.fileName.length < b.fileName.length) return -1;
                    else if (a.fileName.length > b.fileName.length) return 1;
                    else if (a.fileName < b.fileName) return -1;
                    else if (a.fileName > b.fileName) return 1;
                    return 0;
                });
                let nonCustomFolders = organizedList.folders.filter((folder) => !folder?.isCustom);
                nonCustomFolders.sort((a, b) => {
                    if (a.fileName.length < b.fileName.length) return -1;
                    else if (a.fileName.length > b.fileName.length) return 1;
                    else if (a.fileName < b.fileName) return -1;
                    else if (a.fileName > b.fileName) return 1;
                    return 0;
                });
                organizedList.folders = [...nonCustomFolders, ...customFolders];
                return organizedList;
            });
            updateCurrentFolderLocked();
        } else if (user.role === 0) {
            // If user is accountant
            if (currentLocation.length !== 0 && !isObjectEmpty(openedClient)) {
                // handle directory changes for accountant "in clients table" archive tab
                let currentFolderData = getData(undefined, clientFiles[openedClient.userId ?? openedClient.firmId][yearSelection].folders);
                const organizedList = organizeCurrentFolders(currentFolderData);
                setFocusedFiles(() => {
                    let customFolders = organizedList.folders.filter((folder) => folder?.isCustom);
                    customFolders.sort((a, b) => {
                        if (a.fileName.length < b.fileName.length) return -1;
                        else if (a.fileName.length > b.fileName.length) return 1;
                        else if (a.fileName < b.fileName) return -1;
                        else if (a.fileName > b.fileName) return 1;
                        return 0;
                    });
                    let nonCustomFolders = organizedList.folders.filter((folder) => !folder?.isCustom);
                    nonCustomFolders.sort((a, b) => {
                        if (a.fileName.length < b.fileName.length) return -1;
                        else if (a.fileName.length > b.fileName.length) return 1;
                        else if (a.fileName < b.fileName) return -1;
                        else if (a.fileName > b.fileName) return 1;
                        return 0;
                    });
                    organizedList.folders = [...nonCustomFolders, ...customFolders];
                    return organizedList;
                });
            } else {
                // When in root folder, handle the focusedFiles changes
                setFocusedFiles({ files: [], folders: [], isLocked: null });
            }
        }
    }, [currentLocation, user, clientFiles, openedClient, yearSelection, getData, updateCurrentFolderLocked]);

    // when the 'currentLocationMoveFile' changed, organize the folders and 'setFocusedFilesMoveFile' accordingly
    useEffect(() => {
        if (!user) return;
        if (currentLocationMoveFile.length !== 0) {
            let currentFolderData;
            if (user.role === 1) currentFolderData = getData(currentLocationMoveFile);
            else currentFolderData = getData(currentLocationMoveFile, clientFiles[openedClient.userId ?? openedClient.firmId][yearSelection].folders);

            const organizedList = organizeCurrentFolders(currentFolderData);
            setFocusedFilesMoveFile(organizedList);
        } else {
            let folders;
            if (user.role === 1)
                folders = Object.keys(files).map((folderName, index) => {
                    const childrenFolders = files[folderName]?.files ?? {};
                    const isEmpty = Object.keys(childrenFolders)?.length === 0;
                    return { fileName: folderName, isEmpty };
                });
            else {
                if (isObjectEmpty(openedClient) || isObjectEmpty(clientFiles)) return;
                const _clientFiles = clientFiles[openedClient.userId ?? openedClient.firmId][yearSelection].folders;
                folders = Object.keys(_clientFiles).map((folderName, index) => {
                    const childrenFolders = _clientFiles[folderName]?.files ?? {};
                    const isEmpty = Object.keys(childrenFolders)?.length === 0;
                    return { fileName: folderName, isEmpty };
                });
            }
            setFocusedFilesMoveFile({ files: [], folders });
        }
    }, [currentLocationMoveFile, files, clientFiles, openedClient, yearSelection, user, getData]);

    // Update the file system when year selection changes
    useEffect(() => {
        if (!isObjectEmpty(parentFiles)) {
            setFiles(parentFiles[yearSelection].folders);
        }
    }, [yearSelection, parentFiles]);

    const value = useMemo(
        () => ({
            currentFolderLocked,
            files,
            clientFiles,
            currentLocation,
            focusedFiles,
            focusedFilesMoveFile,
            currentLocationMoveFile,
            openedFile,
            openedFileInfo,
            yearSelection,
            setClientFiles,
            getInside,
            getInsideMoveFile,
            goBack,
            goBackMoveFile,
            addFolder,
            renameFolder,
            deleteFolder,
            addFile,
            openFile,
            closeFile,
            sortFiles,
            deleteFile,
            setFileToBeMoved,
            moveFile,
            updateRef,
            getUpdatedFile,
            lockFolder,
            lockFolderAcc,
            unlockFolderAcc,
            updateFilesFromServer,
            setYear,
            setFocusedFiles,
        }),
        [
            currentFolderLocked,
            files,
            clientFiles,
            currentLocation,
            focusedFiles,
            focusedFilesMoveFile,
            currentLocationMoveFile,
            openedFile,
            openedFileInfo,
            yearSelection,
            setClientFiles,
            getInside,
            getInsideMoveFile,
            goBack,
            goBackMoveFile,
            addFolder,
            renameFolder,
            deleteFolder,
            addFile,
            openFile,
            closeFile,
            sortFiles,
            deleteFile,
            setFileToBeMoved,
            moveFile,
            updateRef,
            getUpdatedFile,
            lockFolder,
            lockFolderAcc,
            unlockFolderAcc,
            updateFilesFromServer,
            setYear,
            setFocusedFiles,
        ]
    );

    if (!user) <>{children}</>;
    return (
        <FolderContext.Provider value={value}>
            {openedFile && <PdfViewer viewOriginal={openedFileInfo?.isOriginal ?? false} />}
            {fileToBeMoved && ( // show the modal if there is a file to be moved
                <MoveFileModal
                    dismiss={() => {
                        setFileToBeMoved(null);
                        setCurrentLocationMoveFile([]);
                    }}
                />
            )}
            <div>{children}</div>
        </FolderContext.Provider>
    );
};

export default FolderProvider;
