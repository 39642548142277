import { createContext, useState } from "react";

const LANGUAGES = {
    English: "en",
    French: "fr",
};

export const LanguageContext = createContext({
    language: null,
    changeLanguage: () => {},
});

const LanguageProvider = ({ children }) => {
    const [language, setLanguage] = useState(sessionStorage.getItem("language") ?? LANGUAGES.French); // selected language (French is default but can set it through cookies)

    const changeLanguage = (lang) => {
        // change the 'language' value accordingly
        setLanguage(LANGUAGES[lang]);
        sessionStorage.setItem("language", LANGUAGES[lang]);
    };

    const value = { language, changeLanguage };
    return <LanguageContext.Provider value={value}>{children}</LanguageContext.Provider>;
};

export default LanguageProvider;
