import { useContext, useMemo } from "react";
import { Outlet, useLocation } from "react-router-dom";

import SidebarButton from "./sidebar-button/sidebar-button.component.js";
import { ReactComponent as HomeIcon } from "assets/sidebar-icons/home.svg";
import { ReactComponent as HomeFilledIcon } from "assets/sidebar-icons/home-filled.svg";
import { ReactComponent as ChatIcon } from "assets/sidebar-icons/chat.svg";
import { ReactComponent as ChatFilledIcon } from "assets/sidebar-icons/chat-filled.svg";
import { ReactComponent as InvoiceIcon } from "assets/sidebar-icons/invoice.svg";
import { ReactComponent as TrashIcon } from "assets/sidebar-icons/trash.svg";
import { ReactComponent as PeopleIcon } from "assets/sidebar-icons/people.svg";
import { ReactComponent as PeopleFilledIcon } from "assets/sidebar-icons/people-filled.svg";
import { ReactComponent as TicketIcon } from "assets/sidebar-icons/ticket.svg";
import { ReactComponent as TicketFilledIcon } from "assets/sidebar-icons/ticket-filled.svg";
import { ReactComponent as AccountIcon } from "assets/sidebar-icons/account.svg";
import { ReactComponent as AccountFilledIcon } from "assets/sidebar-icons/account-filled.svg";

import useTranslate from "../../hooks/use-translate.js";

import { AuthContext } from "src/contexts/auth.context.js";

import "./sidebar.style.scss";

const CLIENT_TABS = [
    { title: "Archive", icon: <HomeIcon />, iconFilled: <HomeFilledIcon />, route: "/" },
    { title: "Chat", icon: <ChatIcon />, iconFilled: <ChatFilledIcon />, route: "/chat" },
    { title: "Invoices", icon: <InvoiceIcon />, iconFilled: <InvoiceIcon />, route: "/invoices" },
    { title: "Tickets", icon: <TicketIcon />, iconFilled: <TicketFilledIcon />, route: "/tickets" },
    {
        title: "Profile",
        icon: <AccountIcon />,
        iconFilled: <AccountFilledIcon />,
        route: "/account",
    },
    { title: "Trash", icon: <TrashIcon />, iconFilled: <TrashIcon />, route: "/trash" },
];

const ADMIN_TABS = [
    { title: "Notifications", icon: <HomeIcon />, iconFilled: <HomeFilledIcon />, route: "/" },
    { title: "Clients", icon: <PeopleIcon />, iconFilled: <PeopleFilledIcon />, route: "/clients" },
    { title: "Tickets", icon: <TicketIcon />, iconFilled: <TicketFilledIcon />, route: "/tickets" },
    { title: "Chat", icon: <ChatIcon />, iconFilled: <ChatFilledIcon />, route: "/chat" },
    {
        title: "Profile",
        icon: <AccountIcon />,
        iconFilled: <AccountFilledIcon />,
        route: "/account",
    },
];

const Sidebar = () => {
    const {
        user: { role },
    } = useContext(AuthContext);
    const tabs = role === 1 ? CLIENT_TABS : ADMIN_TABS;

    let location = useLocation();
    const selectedTabName = useMemo(() => location.pathname.split("/")[1], [location]);

    const t = useTranslate("Sidebar");

    return (
        <div className="app-container">
            <div className="sidebar">
                {tabs.map((tab, index) => {
                    const { title, icon, iconFilled, route } = tab;
                    const isSelected = selectedTabName === route.slice(1);
                    return <SidebarButton key={title} title={t(title)} icon={isSelected ? iconFilled : icon} route={route} isSelected={isSelected} />;
                })}
            </div>
            <Outlet />
        </div>
    );
};

export default Sidebar;
