import InputField from "components/input-field/input-field.component";
import Selector from "components/selector/selector.component";
import Toggle from "components/toggle/toggle.component";
import LanguageSelector from "components/topbar/language-selector/language-selector.component";
import useTranslate from "src/hooks/use-translate";
import "./settings.style.scss";
import axios from "axios";
import { AuthContext } from "src/contexts/auth.context";
import { useContext, useState } from "react";
import Modal from "components/modal/modal.component";
import WarningBox from "components/warning-box/warning-box.component";
import { wait } from "utils/function.utils";

const Settings = () => {
    const {
        user: { userId, phone, email, jobTitle },
        updateUserFromServer,
    } = useContext(AuthContext);
    const t = useTranslate("Account");
    const [pass1, setPass1] = useState("");
    const [pass2, setPass2] = useState("");
    const [newPhone, setNewPhone] = useState(phone ?? "");
    const [newEmail, setNewEmail] = useState(email ?? "");
    const [newJobTitle, setJobTitle] = useState(jobTitle ?? "");
    const [showError, setShowError] = useState(false);
    const [showPasswordError, setPasswordError] = useState(false);
    const [passwordUpdated, setPasswordUpdated] = useState(false);

    const handleSaveProfileInfo = async () => {
        const res = await axios.post("changeInfo", { userId, phone: newPhone, email: newEmail, jobTitle: newJobTitle });
        if (res.status !== 200) setShowError(true);
        console.log(res.data);
        updateUserFromServer();
    };

    const handlePasswordChange = async (pass1, pass2) => {
        if (pass1 !== pass2) {
            setPasswordError(true);
            return;
        }
        const res = await axios.post("changeInfo", { userId, phone: newPhone, email: newEmail, jobTitle: newJobTitle, password: pass1 });
        if (res.status !== 200) setShowError(true);
        else {
            setPass1("");
            setPass2("");
            setPasswordUpdated(true);
            wait(2000).then(() => setPasswordUpdated(false));
        }
    };

    return (
        <div className="settings-container">
            <span className="with-line size600 w600">{t("settings")}</span>
            <div className="data-wrapper scroll-bar scroll-bar--overflow">
                <div name="profile" className="block">
                    <p className="size500 w600 with-line">{t("profile")}</p>
                    <p className="size400  w600 margin-m--top">{t("change job title")}</p>
                    <p className="size300  margin-s--top margin-l--bottom">
                        {t("change your job title based on the updates in your career path in your company.")}
                    </p>
                    <InputField type="text" placeholder={t("current job title")} value={newJobTitle} onChange={(e) => setJobTitle(e.target.value)} />
                    <p className="size400  w600 margin-m--top">{t("change contact information")}</p>
                    <p className="size300  margin-s--top margin-l--bottom">
                        {t("change contact information based on the recent changes in your phone number or email address.")}
                    </p>

                    <div className="inputs gap-s">
                        <span className="grid">
                            <p>{t("phone")}</p>
                            <InputField type="text" placeholder={t("phone")} value={newPhone} onChange={(e) => setNewPhone(e.target.value)} />
                        </span>
                        <span className="grid">
                            <p>{t("email")}</p>
                            <InputField type="text" placeholder={t("email")} value={newEmail} onChange={(e) => setNewEmail(e.target.value)} />
                        </span>
                        <button className="btn" onClick={handleSaveProfileInfo}>
                            {t("save")}
                        </button>
                    </div>
                </div>
                <div name="password" className="block">
                    <p className="size500 w600 with-line">{t("password")}</p>
                    <p className="size400  w600 margin-m--top">{t("change password")}</p>
                    <p className="size300  margin-s--top margin-l--bottom">
                        {t("use a password at least 8 characters long which includes letters, numbers, and symbols.")}
                    </p>
                    <div className="inputs gap-s">
                        {/* <InputField type="password" placeholder={t("current password")} style={{ width: "18vw" }} /> */}
                        <InputField
                            type="password"
                            placeholder={t("new password")}
                            value={pass1}
                            onChange={(e) => setPass1(e.target.value)}
                            style={{ width: "18vw" }}
                        />
                        <InputField
                            type="password"
                            placeholder={t("confirm new password")}
                            value={pass2}
                            onChange={(e) => setPass2(e.target.value)}
                            style={{ width: "18vw" }}
                        />
                        {passwordUpdated && <WarningBox text={t("password updated")} isCheckmark size="0.5rem" fontSize={"1rem"} />}
                        <button className="btn" onClick={() => handlePasswordChange(pass1, pass2)}>
                            {t("save")}
                        </button>
                    </div>
                </div>
                <div name="notifications" className="block">
                    <div className="size500 w600 with-line">{t("notifications")}</div>
                    <div className="flex flex--center-items">
                        <div className="flex--grow">
                            <p className="size400  w600 margin-m--top">{t("mobile push notifications")}</p>
                            <p className="size300  margin-s--top margin-l--bottom">
                                {t("receive push notifications on messages and reminders via your mobile app")}
                            </p>
                        </div>
                        <Toggle />
                    </div>
                    <div className="flex flex--center-items">
                        <div className="flex--grow">
                            <p className="size400  w600 margin-m--top">{t("desktop push notifications")}</p>
                            <p className="size300  margin-s--top margin-l--bottom">
                                {t("receive push notifications on messages and reminders immediately via your desktop app")}
                            </p>
                        </div>
                        <Toggle />
                    </div>
                    <div className="flex flex--center-items ">
                        <div className="flex--grow">
                            <p className="size400  w600 margin-m--top">{t("email push notifications")}</p>
                            <p className="size300  margin-s--top margin-l--bottom">{t("receive email updates on messages and reminders")}</p>
                        </div>
                        <Toggle />
                    </div>
                </div>
                <div name="preferences" className="block">
                    <p className="size500 w600 with-line">{t("preferences")}</p>
                    <div className="flex flex--center-items">
                        <div className="flex--grow">
                            <p className="size400  w600 margin-m--top">{t("language")}</p>
                            <p className="size300  margin-s--top margin-l--bottom">{t("change the language used in the user interface.")}</p>
                        </div>
                        <LanguageSelector />
                    </div>
                    {/* <div className="flex flex--center-items">
                        <div className="flex--grow">
                            <p className="size400 w600 margin-m--top">{t("appearance")}</p>
                            <p className="size300 margin-s--top margin-l--bottom">{t("customize how METI Box looks on your device")}</p>
                        </div>
                        <div style={{ width: 100 }}>
                            <Selector data={["Light", "Dark"]} onSelected={() => {}} />
                        </div>
                    </div> */}
                    {/* <p className="size400  w600 margin-m--top">Cookie Settings</p>
					<p className="size300  margin-s--top margin-l--bottom">Customize cookies.</p> */}
                </div>
            </div>
            {showPasswordError && <Modal modal={<WarningBox text={t("Your passwords do not match.")} />} closeIn3 dismiss={[setPasswordError]} />}
            {showError && <Modal modal={<WarningBox text={t("error")} />} closeIn3 dismiss={[setShowError]} />}
        </div>
    );
};

export default Settings;
