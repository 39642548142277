import React, { useEffect, useState } from "react";
import "./dropdown.style.scss";
import { wait } from "utils/function.utils";
import { ReactComponent as SearchSvg } from "assets/buttons/search.svg";
const s = [{ name: "Option 1" }, { name: "Option 2" }, { name: "Option 3" }, { name: "Option 4" }, { name: "Option 5" }];

const Dropdown = ({
    className = "",
    list = s,
    placeholder = "Select",
    onSelected = () => {},
    afterSelection = () => {},
    onBlurSetState = () => {},
    disabled = false,
    searchIcon = false,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredOptions, setFilteredOptions] = useState([]);

    useEffect(() => {
        if (list.length === 0) return;
        setFilteredOptions(list.filter((option) => option?.name.toLowerCase().includes(searchTerm.toLowerCase())));
    }, [searchTerm, list]);

    const handleOptionClick = (option) => {
        setSearchTerm(option?.name);
        onSelected(option);
        setIsOpen(false);
        onBlurSetState(true);
        afterSelection();
    };

    return (
        <div className={`dropdown ${className}`}>
            <div className="flex flex--center-items gap-xs">
                {searchIcon && <SearchSvg className="search-icon" />}
                <div
                    className=""
                    onClick={() => {
                        if (disabled) return;
                        setIsOpen(!isOpen);
                    }}
                    onBlur={async () => {
                        await wait(150);
                        if (searchTerm !== "") onBlurSetState(true);
                        setIsOpen(false);
                    }}>
                    <input
                        type="text"
                        className="search-input"
                        placeholder={placeholder}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        disabled={disabled}
                    />
                </div>
            </div>
            {isOpen && (
                <div className="smooth-corners dropdown-menu">
                    {filteredOptions.map((option, index) => (
                        <div
                            key={option?.name + option?.surname + index ?? JSON.stringify(option) + index}
                            className="onhover padding-xs size350"
                            onClick={() => handleOptionClick(option)}>
                            {`${index + 1}) ${option?.name} ${option?.surname ?? ""}`}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Dropdown;
