import { compareAsc, isSameDay, isSameMonth, isSameYear } from "date-fns";
import { useMemo } from "react";
import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { CalendarContext, CALENDAR_VIEW_TYPES } from "./calendar.context";
import axios from "axios";
import { AuthContext } from "./auth.context";
import { formatDate } from "utils/date.utils";
import { isObjectEmpty } from "utils/array.utils";
import { ClientsContext } from "./clients.context";
import useTranslate from "src/hooks/use-translate";
import { formatFloat, parseAsFloat } from "utils/invoice.utils";

export const InvoiceContext = createContext({
    invoices: null,
    shownInvoices: null,
    newRows: null,
    removedInvoices: null,
    filteredNewRows: null,
    startingBalance: null,
    finalBalanceTotal: null,
    balanceChangeTotal: null,
    calculatedStartingBalance: null,
    calculatedFinalBalance: null,
    calculatedBalanceChange: null,
    addNewRow: () => {},
    removeNewRow: () => {},
    removeRow: () => {},
    changeOnNewRow: () => {},
    save: () => {},
    isDayFilled: () => {},
    calculateBalanceChange: () => {},
    setStartingBalance: () => {},
});

const InvoiceProvider = ({ children }) => {
    const t = useTranslate("MySafe");
    const { selectedDay, lastDay, shownMonth, shownYear, calendarViewType } = useContext(CalendarContext);
    const { openedClient } = useContext(ClientsContext);
    const { user } = useContext(AuthContext);
    const [invoices, setInvoices] = useState([]); // list of invoices as a whole (ROWS is dummy data)
    const [shownInvoices, setShownInvoices] = useState([]); // list of the invoices to be shown
    const [newRows, setNewRows] = useState([]); // list of the rows that are added but not saved
    const [removedInvoices, setRemovedInvoices] = useState([]); // list of the invoices that are just marked for removing
    const [startingBalance, setStartingBalance] = useState(user?.role !== 0 ? user?.startingBalance ?? 0 : openedClient?.startingBalance ?? 0); // starting balance (set it to a value from the server)

    const filteredNewRows = useMemo(
        // filter out the values in 'newRows' without date or at least one of revenue or expense values
        () => newRows.filter((row) => row.date && (row.revenue || row.expense)),
        [newRows]
    );

    const calculateBalanceChange = useCallback(
        // calculate the balance between 'start' and 'end' dates
        (start, end) => {
            var balance = 0;
            invoices.forEach((row) => {
                const date = new Date(row.date);
                const startDate = new Date(start);
                const endDate = new Date(end);
                if (compareAsc(startDate, date) === -1 && compareAsc(date, endDate) === -1) {
                    balance += row.revenue - row.expense;
                }
            });
            return balance;
        },
        [invoices]
    );

    const calculatedStartingBalance = useMemo(
        // calculate the starting balance by adding up the revenue and subtracting the expenses
        () => new Intl.NumberFormat().format(calculateBalanceChange("10/10/1999", selectedDay) + startingBalance),
        [calculateBalanceChange, selectedDay, startingBalance]
    );

    const calculatedFinalBalance = useMemo(
        // calculate the final balance by adding up the revenue and subtracting the expenses + balance change for the selected date period (daily or montly or yearly)
        () => new Intl.NumberFormat().format(calculateBalanceChange("10/10/1999", lastDay) + startingBalance),
        [calculateBalanceChange, lastDay, startingBalance]
    );

    const calculatedBalanceChange = useMemo(
        // calculate the balance by adding up the revenue and subtracting the expenses
        () => new Intl.NumberFormat().format(parseAsFloat(calculatedFinalBalance) - parseAsFloat(calculatedStartingBalance)),
        [calculatedFinalBalance, calculatedStartingBalance]
    );

    const finalBalanceTotal = useMemo(
        // calculate the final balance by adding up the revenue and subtracting the expenses + balance change for the selected date period (daily or montly or yearly)
        () => new Intl.NumberFormat().format(calculateBalanceChange("10/10/1999", new Date()) + startingBalance),
        [calculateBalanceChange, startingBalance]
    );

    const balanceChangeTotal = useMemo(
        // calculate the balance by adding up the revenue and subtracting the expenses
        () => new Intl.NumberFormat().format(parseAsFloat(finalBalanceTotal) - startingBalance),
        [finalBalanceTotal, startingBalance]
    );

    const fetchInvoices = useCallback(
        async (userId = null, firmId = null) => {
            await axios.get(`getInvoiceTable?userId=${userId ?? user.userId}&firmId=${firmId ?? user.firmId}`).then((res) => {
                if (res.status === 200) setInvoices(res.data.rows);
            });
        },
        [user]
    );

    const addNewRow = useCallback(() => {
        // add a new value to 'newRows'
        setNewRows((initialRows) => {
            const newRows = [{ date: selectedDay.toISOString(), explanation: null, revenue: null, expense: null }, ...initialRows];
            return newRows;
        });
    }, [selectedDay]);

    const removeNewRow = useCallback((index) => {
        // remove a value from 'newRows'
        setNewRows((initialRows) => {
            const newRows = [...initialRows];
            newRows.splice(index, 1);
            return newRows;
        });
    }, []);

    const removeRow = useCallback(
        (index) => {
            // remove a row from 'invoices'
            const rowToBeRemoved = shownInvoices[index];
            const indexOfInvoice = invoices.findIndex((row) => row.date === rowToBeRemoved.date && row.explanation === rowToBeRemoved.explanation);
            setRemovedInvoices((initialRows) => [...initialRows, invoices[indexOfInvoice]]);
            setInvoices((initialRows) => {
                const tempRows = [...initialRows];
                tempRows.splice(indexOfInvoice, 1);
                return tempRows;
            });
        },
        [shownInvoices, invoices]
    );

    const changeOnNewRow = useCallback((event, index) => {
        // change a value in newRows
        if (event?.target == null) {
            setNewRows((initialRows) => {
                const tempRows = [...initialRows];
                tempRows[index].date = formatDate(event);
                return tempRows;
            });
            return;
        }
        const { name, value } = event.target;
        const isFloat = event.target.getAttribute("data-isfloat") === "true";
        setNewRows((initialRows) => {
            const tempRows = [...initialRows];
            if (isFloat) tempRows[index][name] = formatFloat(value);
            else tempRows[index][name] = value;
            return tempRows;
        });
    }, []);

    const save = useCallback(async () => {
        // save the 'newRows' if the balance after those added is nonnegative
        let newBalance;
        // convert the revenue and expense values to float
        const tempNewRows = filteredNewRows.map((row) => {
            return { ...row, revenue: row?.revenue ? parseAsFloat(row.revenue) : 0, expense: row?.expense ? parseAsFloat(row.expense) : 0 };
        });

        if (isObjectEmpty(openedClient)) {
            // for clients
            newBalance = tempNewRows.reduce((prevValue, currentValue) => {
                return prevValue + ((currentValue?.revenue ?? 0) - (currentValue?.expense ?? 0));
            }, parseAsFloat(finalBalanceTotal));
        } else {
            // for accountants
            newBalance = tempNewRows.reduce((prevValue, currentValue) => {
                return prevValue + ((currentValue?.revenue ?? 0) - (currentValue?.expense ?? 0));
            }, parseAsFloat(finalBalanceTotal));
        }

        // check if new balance is negative
        if (newBalance < 0) {
            alert(t("negative-balance"));
            return;
        }

        // update the database
        const err = await axios
            .post("updateInvoiceTable", {
                userId: isObjectEmpty(openedClient) ? user.userId : openedClient.userId,
                firmId: isObjectEmpty(openedClient) ? user.firmId : openedClient.firmId,
                addedRows: tempNewRows,
                removedRows: removedInvoices,
            })
            .then((res) => {
                if (res.status !== 200) return true;
                return null;
            })
            .catch(() => true);
        setNewRows([]);
        setRemovedInvoices([]);
        if (err) {
            if (user.accountant !== -1) fetchInvoices();
            else fetchInvoices(openedClient.userId, openedClient.firmId);
            return;
        }
        if (tempNewRows.length !== 0) setInvoices((initialRows) => [...tempNewRows, ...initialRows]);
    }, [filteredNewRows, user, removedInvoices, openedClient, finalBalanceTotal, fetchInvoices, t]);

    const isDayFilled = useCallback(
        (day) => {
            // check if an invoice saved in given day
            return invoices.some((row) => {
                return isSameDay(new Date(row.date), day);
            });
        },
        [invoices]
    );

    useEffect(() => {
        if (!user || user.accountant === -1) return;
        // fetch invoices for client view
        fetchInvoices();
    }, [user, fetchInvoices]);

    useEffect(() => {
        if (isObjectEmpty(openedClient)) return;
        // fetch invoices for accountant view
        setStartingBalance(openedClient?.startingBalance ?? 0);
        fetchInvoices(openedClient.userId, openedClient.firmId);
    }, [openedClient, fetchInvoices]);

    useEffect(() => {
        // upddate 'shownInvoices' when 'calendarViewType' changed
        if (invoices.length === 0) return;
        var date = new Date();
        let tempInvoices = [];
        switch (calendarViewType) {
            case CALENDAR_VIEW_TYPES.day:
                date = new Date(selectedDay);
                tempInvoices = invoices.filter((row) => isSameDay(new Date(row.date), date));
                const rowsToBeShown = tempInvoices.map(({ date, explanation, revenue, expense }) => ({ date, explanation, revenue, expense }));
                setShownInvoices(rowsToBeShown);
                break;
            case CALENDAR_VIEW_TYPES.month:
                date = new Date(shownYear, shownMonth, 1);
                tempInvoices = invoices.filter((row) => isSameMonth(new Date(row.date), date));
                tempInvoices.forEach((invoice) => {
                    delete invoice.userId;
                    delete invoice.firmId;
                    delete invoice.invoiceId;
                    delete invoice.balance;
                });
                setShownInvoices(tempInvoices);
                break;
            case CALENDAR_VIEW_TYPES.year:
                date = new Date(shownYear, 0, 1);
                tempInvoices = invoices.filter((row) => isSameYear(new Date(row.date), date));
                tempInvoices.forEach((invoice) => {
                    delete invoice.userId;
                    delete invoice.firmId;
                    delete invoice.invoiceId;
                    delete invoice.balance;
                });
                setShownInvoices(tempInvoices);
                break;
            default:
                break;
        }
        // }, [selectedDay, shownMonth, shownYear]);
    }, [selectedDay, shownMonth, shownYear, calendarViewType, invoices]);

    const value = useMemo(
        () => ({
            invoices,
            startingBalance,
            finalBalanceTotal,
            balanceChangeTotal,
            calculatedStartingBalance,
            calculatedFinalBalance,
            calculatedBalanceChange,
            shownInvoices,
            newRows,
            removedInvoices,
            filteredNewRows,
            addNewRow,
            removeNewRow,
            removeRow,
            changeOnNewRow,
            save,
            isDayFilled,
            calculateBalanceChange,
            setStartingBalance,
        }),
        [
            invoices,
            startingBalance,
            finalBalanceTotal,
            balanceChangeTotal,
            calculatedStartingBalance,
            calculatedFinalBalance,
            calculatedBalanceChange,
            shownInvoices,
            newRows,
            removedInvoices,
            filteredNewRows,
            addNewRow,
            removeNewRow,
            removeRow,
            changeOnNewRow,
            save,
            isDayFilled,
            calculateBalanceChange,
            setStartingBalance,
        ]
    );
    return <InvoiceContext.Provider value={value}>{children}</InvoiceContext.Provider>;
};

export default InvoiceProvider;
