import { useContext, useEffect, useState } from "react";

import InputField from "components/input-field/input-field.component";

import { NewClientContext } from "src/contexts/new-client.context";

import useTranslate from "src/hooks/use-translate";

import { SUBSCRIPTIONS } from "utils/lists.utils";
import axios from "axios";
import { AuthContext } from "src/contexts/auth.context";
import WarningBox from "components/warning-box/warning-box.component";

const PagePersonal2 = ({ canContinue, toggle, index }) => {
    const {
        user: { userId },
    } = useContext(AuthContext);
    const {
        newCompanyData: { name = "", surname = "", username = "", vatNumber = "", jobTitle = "", email = "", phone = "", subscription = 0 },
        updateNewCompanyData,
    } = useContext(NewClientContext);
    const [emailExist, setEmailExist] = useState(false);

    const t = useTranslate("NewClientModal");

    useEffect(() => {
        if (canContinue && (name === "" || surname === "" || username === "" || vatNumber === "" || jobTitle === "" || !isValidEmail(email) || emailExist)) {
            // Close continue button
            toggle();
        } else if (
            !canContinue &&
            name.length > 0 &&
            surname.length > 0 &&
            username.length > 0 &&
            vatNumber.length > 0 &&
            jobTitle.length > 0 &&
            isValidEmail(email) &&
            !emailExist
        ) {
            // Open continue button
            toggle();
        }
    }, [toggle, canContinue, name, surname, username, vatNumber, jobTitle, email, phone, emailExist]);

    const handleChange = (key, value) => {
        updateNewCompanyData(key, value);
    };

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    // function isValidPhoneNumber(phoneNumber) {
    //     const phoneRegex = /^\+\d{10}$/;
    //     return phoneRegex.test(phoneNumber);
    // }

    const checkEmailExist = async (email) => {
        await axios
            .post("checkEmail", { userId, email })
            .then((res) => {
                if (res.status !== 200) setEmailExist(true);
                else setEmailExist(false);
            })
            .catch(() => setEmailExist(true));
    };

    return (
        <div className="page-container page-personal-2">
            <p className="margin-xl--top w600">
                {t("step")} 2 <span className="red">|</span> {t("register the client")}
            </p>
            <div className="grid  gap-l margin-xl--top">
                <div id="left" className="flex flex--column flex--space-between-content">
                    <div className="flex flex--column gap-xl">
                        <span className="flex gap-m">
                            <InputField
                                className="flex--grow"
                                fieldType="underline"
                                label={t("name")}
                                placeholder="Jane"
                                onChange={({ target: { value } }) => handleChange("name", value)}
                                value={name}
                            />
                            <InputField
                                className="flex--grow"
                                fieldType="underline"
                                label={t("surname")}
                                placeholder="Doe"
                                onChange={({ target: { value } }) => handleChange("surname", value)}
                                value={surname}
                            />
                        </span>
                        <InputField
                            fieldType="underline"
                            label={t("username")}
                            placeholder="janeDoe001"
                            onChange={({ target: { value } }) => handleChange("username", value)}
                            value={username}
                        />
                        <InputField
                            fieldType="underline"
                            label={t("email")}
                            type="email"
                            placeholder="jane.doe@my-company.com"
                            onChange={({ target: { value } }) => handleChange("email", value)}
                            value={email}
                            onBlur={({ target: { value } }) => checkEmailExist(value)}
                        />
                        {emailExist && <WarningBox text={t("email-exists")} size="0.3rem" fontSize="0.6rem" lineHeight="0.3rem" />}
                        <InputField
                            fieldType="underline"
                            label={t("phone")}
                            type="tel"
                            placeholder={`+32 81 00 00 00`}
                            onChange={({ target: { value } }) => handleChange("phone", value)}
                            value={phone}
                        />
                    </div>
                </div>
                <div id="right" className="flex flex--column gap-xl">
                    <InputField
                        fieldType="underline"
                        label={t("vat number")}
                        placeholder="1234567890"
                        onChange={({ target: { value } }) => handleChange("vatNumber", value)}
                        value={vatNumber}
                    />
                    <InputField
                        fieldType="underline"
                        label={t("job title")}
                        placeholder="Chief Financial Officer"
                        onChange={({ target: { value } }) => handleChange("jobTitle", value)}
                        value={jobTitle}
                    />
                    <div>
                        <p className="w600 size300 margin-s--block">{t("Subscription")}</p>
                        <p className="size300">{t("Subscription will be determined based on the size and revenue of the company")}</p>
                        <div className="flex gap-s margin-xl--top">
                            {SUBSCRIPTIONS.map((subName, index) => {
                                return (
                                    <button
                                        key={index}
                                        onClick={() => {
                                            handleChange("subscription", index);
                                        }}
                                        className={`w500 size400 flex--grow btn smooth-corners padding-m--left padding-s--block ${
                                            subscription === index && "btn--selected"
                                        }`}>
                                        {t(subName)}
                                    </button>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PagePersonal2;
