import { useContext, useEffect } from "react";
import { NewClientContext } from "src/contexts/new-client.context";
import useTranslate from "src/hooks/use-translate";

const Page1 = ({ canContinue, toggle }) => {
    const {
        newCompanyData: { companyType },
        updateNewCompanyData,
    } = useContext(NewClientContext);

    const t = useTranslate("NewClientModal");

    useEffect(() => {
        if (!canContinue && companyType !== null) toggle();
        else if (canContinue && companyType === null) toggle();
    }, [toggle, canContinue, companyType]);

    const handleSelect = (idx) => {
        updateNewCompanyData("companyType", idx);
    };

    return (
        <div className="page-container page1">
            <p className="margin-xl--top w600">
                {t("step")} 1 <span className="red">|</span> {t("Choose an account type")}...
            </p>
            <div className="flex flex--grow flex--center gap-l">
                <button
                    onClick={() => {
                        handleSelect(0);
                    }}
                    className={`size400 w600 smooth-corners padding-m--block btn ${
                        companyType === 0 && "btn--selected"
                        // selectedButton === 0 && "btn--selected"
                    }`}>
                    {t("Personal")}
                </button>
                <button
                    onClick={() => {
                        handleSelect(1);
                    }}
                    className={`size400 w600 smooth-corners padding-m--block btn ${
                        companyType === 1 && "btn--selected"
                        // selectedButton === 1 && "btn--selected"
                    }`}>
                    {t("Company")}
                </button>
            </div>
        </div>
    );
};

export default Page1;
