import { Fragment, useContext } from "react";
import { Outlet } from "react-router-dom";
import "./topbar.style.scss";

// import { ReactComponent as CDLogo } from "assets/cd-logo.svg";
import CDLogo from "assets/cd-logo.png";
import MetiLogoPng from "assets/meti-box.png";

import useTranslate from "src/hooks/use-translate";

import { AuthContext } from "../../contexts/auth.context";

import ProfileButton from "./profile-button/profile-button.component";
import LanguageSelector from "./language-selector/language-selector.component";

const Topbar = () => {
    const { user } = useContext(AuthContext);
    const t = useTranslate("Topbar");

    return (
        <Fragment>
            <div className={`topbar-container`}>
                <div className="left-side-container">
                    <div className="logo-container">
                        {/* <MetiLogo className="meti-logo" /> */}
                        <img src={MetiLogoPng} className="meti-logo" alt="meti-logo" />
                    </div>
                    {/* <CDLogo className="cd-logo" /> */}
                    <div className="cd-logo-container">
                        {/* <CDLogo1 className="cd-logo" /> */}
                        <img src={CDLogo} className="cd-logo" alt="cd-logo" />
                    </div>
                </div>

                <div className="right-side-container">
                    {/* User information is shown here */}
                    {user ? (
                        <Fragment>
                            <ProfileButton title={t("Profile")} />
                        </Fragment>
                    ) : (
                        <LanguageSelector />
                    )}
                </div>
            </div>
            <Outlet />
        </Fragment>
    );
};

export default Topbar;
