export const getItemWhere = (array, key, value) => {
    return array.find((item) => {
        return item[key] ? item[key] === value : false;
    });
};

export const getIndexWhere = (array, key, value) => {
    return array.findIndex((item) => {
        return item[key] ? item[key] === value : false;
    });
};

export const changeWhere = (array, key, value, newValue) => {
    return array.map((item) => {
        if (item[key] !== value) return item;

        return newValue;
    });
};

export const isObjectEmpty = (obj) => {
    return Object.keys(obj ?? {}).length === 0;
};

export const trimTrailingChars = (s, charToTrim) => {
    var regExp = new RegExp(charToTrim + "+$");
    var result = s.replace(regExp, "");

    return result;
};
