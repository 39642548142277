import { Fragment, useContext, useEffect, useState } from "react";
import { NewClientContext } from "src/contexts/new-client.context";

import InputField from "components/input-field/input-field.component";
// import { MdLabelImportant } from "react-icons/md";
import { CLIENT_TYPES } from "utils/lists.utils";
import useTranslate from "src/hooks/use-translate";
import axios from "axios";
import WarningBox from "components/warning-box/warning-box.component";
import { AuthContext } from "src/contexts/auth.context";

const Page2 = ({ canContinue, toggle }) => {
    const {
        newCompanyData: { name, vatNumber, clientType, email, website, country, city, address, zipCode, id },
        updateNewCompanyData,
    } = useContext(NewClientContext);
    const {
        user: { userId },
    } = useContext(AuthContext);

    const [emailExist, setEmailExist] = useState(false);

    const t = useTranslate("NewClientModal");

    const INPUT_FIELDS = {
        left: {
            name: {
                label: t("name"),
                placeholder: "my company",
                inputType: "text",
                key: "name",
                value: name,
            },
            vat: {
                label: t("vat number"),
                placeholder: "1234567890",
                inputType: "text",
                key: "vatNumber",
                value: vatNumber,
            },
            clientType: {
                label: t("client type"),
                placeholder: "",
                inputType: "choice",
                key: "clientType",
                value: clientType,
                choices: CLIENT_TYPES,
            },
            email: {
                label: t("email"),
                placeholder: "placeholder@placeholder.com",
                inputType: "email",
                key: "email",
                value: email,
            },
        },
        right: {
            website: {
                label: t("website"),
                placeholder: "www.placholder.com",
                inputType: "url",
                key: "website",
                value: website,
            },
            country: {
                label: t("Country"),
                placeholder: "Belgium",
                inputType: "text",
                key: "country",
                value: country,
            },
            city: { label: t("City"), placeholder: "Brussels", inputType: "text", key: "city", value: city },
            address: {
                label: t("Address"),
                placeholder: "address...",
                inputType: "text",
                key: "address",
                value: address,
            },
            zipCode: {
                label: t("Postal Zip Code"),
                placeholder: t("zip code"),
                inputType: "text",
                key: "zipCode",
                value: zipCode,
            },
        },
    };

    useEffect(() => {
        if (
            canContinue &&
            (name.length === 0 ||
                vatNumber.length === 0 ||
                clientType == null ||
                !isValidEmail(email) ||
                website.length === 0 ||
                country.length === 0 ||
                city.length === 0 ||
                address.length === 0 ||
                zipCode.length === 0 ||
                emailExist)
        ) {
            toggle();
        } else if (
            !canContinue &&
            name.length > 0 &&
            vatNumber.length > 0 &&
            clientType != null &&
            isValidEmail(email) &&
            website.length > 0 &&
            country.length > 0 &&
            city.length > 0 &&
            address.length > 0 &&
            zipCode.length > 0 &&
            !emailExist
        ) {
            toggle();
        }
    }, [name, vatNumber, clientType, email, website, country, city, address, zipCode, id, canContinue, toggle, emailExist]);

    const handleChange = (key, value) => {
        updateNewCompanyData(key, value);
    };

    const handleSelect = (key, value) => {
        if (clientType === value) {
            updateNewCompanyData(key, "");
            return;
        }
        updateNewCompanyData(key, value);
    };

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const checkEmailExist = async (email) => {
        await axios
            .post("checkEmail", { userId, email })
            .then((res) => {
                if (res.status !== 200) setEmailExist(true);
                else setEmailExist(false);
            })
            .catch(() => setEmailExist(true));
    };

    return (
        <div className="page-container page2 scroll-bar scroll-bar--overflow padding-s--right" style={{ overflowX: "hidden" }}>
            <p className="margin-xl--top w600">
                {t("step")} 2 <span className="red">|</span> {t("Register the Company")}
            </p>
            <div className="flex--grow grid gap-xxxxl margin-xl--top unselectable">
                <div className="flex flex--column  gap-xl">
                    {Object.values(INPUT_FIELDS.left).map(({ label, placeholder, inputType, choices = [], key, value }, index) => {
                        return (
                            <Fragment key={index}>
                                {inputType !== "choice" && inputType !== "email" && (
                                    <InputField
                                        type={inputType}
                                        fieldType="underline"
                                        label={label}
                                        placeholder={placeholder}
                                        onChange={({ target: { value: newValue } }) => {
                                            handleChange(key, newValue);
                                        }}
                                        value={value}
                                    />
                                )}
                                {inputType === "email" && (
                                    <div>
                                        <InputField
                                            type={inputType}
                                            fieldType="underline"
                                            label={label}
                                            placeholder={placeholder}
                                            onChange={({ target: { value: newValue } }) => {
                                                handleChange(key, newValue);
                                            }}
                                            value={value}
                                            onBlur={({ target: { value } }) => checkEmailExist(value)}
                                        />
                                        {emailExist && <WarningBox text={t("email-exists")} size="0.3rem" fontSize="0.6rem" lineHeight="0.3rem" />}
                                    </div>
                                )}
                                {inputType === "choice" && (
                                    <div>
                                        <p className="size300 w600 margin-s--bottom">{label}</p>
                                        <div className="flex gap-s">
                                            {choices.map((choice, index) => {
                                                const selected = value === index;
                                                return (
                                                    <div
                                                        key={index}
                                                        onClick={() => {
                                                            handleSelect(key, index);
                                                        }}
                                                        className={`w500 size200 pointer ${selected && "red"}`}>
                                                        {choice}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}
                            </Fragment>
                        );
                    })}
                </div>
                <div className="flex flex--column gap-xl">
                    <InputField
                        className=""
                        fieldType="underline"
                        type={INPUT_FIELDS.right.website.inputType}
                        label={INPUT_FIELDS.right.website.label}
                        placeholder={INPUT_FIELDS.right.website.placeholder}
                        value={[INPUT_FIELDS.right.website.value]}
                        onChange={({ target: { value: newValue } }) => {
                            handleChange(INPUT_FIELDS.right.website.key, newValue);
                        }}
                    />
                    <div className="flex gap-l">
                        <InputField
                            className="flex--grow"
                            fieldType="underline"
                            type={INPUT_FIELDS.right.country.inputType}
                            label={INPUT_FIELDS.right.country.label}
                            placeholder={INPUT_FIELDS.right.country.placeholder}
                            value={[INPUT_FIELDS.right.country.value]}
                            onChange={({ target: { value: newValue } }) => {
                                handleChange(INPUT_FIELDS.right.country.key, newValue);
                            }}
                        />
                        <InputField
                            className="flex--grow"
                            fieldType="underline"
                            type={INPUT_FIELDS.right.city.inputType}
                            label={INPUT_FIELDS.right.city.label}
                            placeholder={INPUT_FIELDS.right.city.placeholder}
                            value={[INPUT_FIELDS.right.city.value]}
                            onChange={({ target: { value: newValue } }) => {
                                handleChange(INPUT_FIELDS.right.city.key, newValue);
                            }}
                        />
                    </div>
                    <InputField
                        className=""
                        fieldType="underline"
                        type={INPUT_FIELDS.right.address.inputType}
                        label={INPUT_FIELDS.right.address.label}
                        placeholder={INPUT_FIELDS.right.address.placeholder}
                        value={[INPUT_FIELDS.right.address.value]}
                        onChange={({ target: { value: newValue } }) => {
                            handleChange(INPUT_FIELDS.right.address.key, newValue);
                        }}
                    />
                    <div className="flex">
                        <InputField
                            fieldType="underline"
                            min={1000}
                            max={9999}
                            type={INPUT_FIELDS.right.zipCode.inputType}
                            label={INPUT_FIELDS.right.zipCode.label}
                            placeholder={INPUT_FIELDS.right.zipCode.placeholder}
                            value={[INPUT_FIELDS.right.zipCode.value]}
                            onChange={({ target: { value: newValue } }) => {
                                handleChange(INPUT_FIELDS.right.zipCode.key, newValue);
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Page2;
