import { useContext, useEffect, useState } from "react";
// import { format } from "date-fns";

import Checkbox from "components/checkbox/checkbox.component";

import { TrashContext, COLUMN_NAMES } from "src/contexts/trash.context";

import "./table.style.scss";
import useTranslate from "src/hooks/use-translate";

const Table = () => {
    const { deletedItems, searchText, selectedItems, isSelectedAll, handleClick, toggleSelectAll } = useContext(TrashContext);

    const t = useTranslate("Trash");

    const [shownRows, setShownRows] = useState([]);

    useEffect(() => {
        const filteredRows = deletedItems.filter((item) => {
            return searchText.length > 0 ? item.name.toLowerCase().includes(searchText.toLowerCase()) : item;
        });
        setShownRows(filteredRows);
    }, [searchText, deletedItems]);

    return (
        <div className="trash-table-container">
            <table className="trash-table">
                <thead>
                    <tr className="table-row">
                        <th className="table-checkbox">
                            <Checkbox id={"selectAll"} selected={isSelectedAll} handleClick={toggleSelectAll} />
                        </th>
                        {Object.values(COLUMN_NAMES).map((columnName) => {
                            return <th key={columnName}>{t(columnName)}</th>;
                        })}
                    </tr>
                </thead>
                <tbody>
                    {shownRows.map((row, index) => {
                        const isSelected = selectedItems.includes(row);
                        return (
                            <tr key={index} className="table-row">
                                <td className="table-checkbox">
                                    <Checkbox id={row.id} selected={isSelected} handleClick={() => handleClick(row)} />
                                </td>
                                {Object.keys(row).map((item) => {
                                    if (item === "id") return null;
                                    return <td key={item}>{row[item]}</td>;
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default Table;
