import Calendar from "components/calendar/calendar.component";
import Selector from "components/selector/selector.component";
import { Fragment, useContext, useEffect, useState } from "react";
import Table from "routes/client-view/invoices/components/table/table.component";
import { CalendarContext } from "src/contexts/calendar.context";
import { InvoiceContext } from "src/contexts/invoice.context";
import useTranslate from "src/hooks/use-translate";
import TabToggle from "./components/toggle/toggle.tab.component";
import * as XLSX from "xlsx";
import { PDFDownloadLink, Page, Text, View, Document, Font, StyleSheet } from "@react-pdf/renderer";

import "./safe-tab.style.scss";
import "src/routes/client-view/invoices/invoices.style.scss";

import { SAFE_LOCKING_PERIODS } from "utils/lists.utils";
import { ClientsContext } from "src/contexts/clients.context";
import { formatDate } from "utils/date.utils";
import { MONTHS } from "utils/calendar.utils";
import axios from "axios";
import LanguageProvider from "src/contexts/language.context";
import { parseAsFloat } from "utils/invoice.utils";

const Inter = require("assets/fonts/Inter-Regular.ttf");
const InterSemiBold = require("assets/fonts/Inter-SemiBold.ttf");

const INTERVALS = {
    day: "Daily",
    month: "Monthly",
    year: "Yearly",
};

const VIEW_TYPES = {
    calendar: "Calendar",
    periodic: "Periodic",
};
function downloadTableAsExcel(tableData) {
    const sheet = XLSX.utils.json_to_sheet(tableData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, sheet, "Sheet1");
    XLSX.writeFile(workbook, "MySafeInvoices.xlsx");
}

Font.register({
    family: "Inter",
    fontStyle: "normal",
    fontWeight: "normal",
    fonts: [{ src: Inter }, { src: InterSemiBold, fontWeight: "bold" }],
});

const styles = StyleSheet.create({
    page: {
        // backgroundColor: "#FFFFFF",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        height: "100%",
        width: "100%",
        // margin: "10vw 10vw 0 10vw",
        paddingHorizontal: "7vw",
        paddingTop: "8vw",
        // paddingBottom: "20vw",
    },
    header: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        height: 55,
        width: "100%",
        // border: "0.6px solid black",
    },
    image: {
        height: 50,
    },
    headerInfoPart: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "flex-end",
        height: 60,
        width: 240,
    },
    headerInfoRow: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        width: 240,
        fontSize: "11px",
        // marginBottom: "3vh",
        // borderBottom: "0.6px solid black",
        height: 20,
    },
    headerText: {
        // marginRight: "3vw",
        height: 20,
        width: 80,
        textAlign: "left",
        // fontWeight: "bold",
        fontFamily: "Inter",
        fontSize: "10px",
    },
    table: {
        // border: "0.6px solid black",
        height: 450,
        width: "100%",
        // paddingHorizontal: "0.5vw",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        // alignItems: "flex-start",
    },
    rowHeader: {
        display: "flex",
        flexDirection: "row",
        fontSize: "11px",
        // marginBottom: 20,
        borderBottom: "0.6px solid black",
        height: 35,
    },
    row: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        // marginVertical: "1vh",
        // paddingVertical: "2vw",
        height: 35,
        width: "100%",
        borderBottom: "0.6px solid black",
    },
    rowData: {
        flex: 1,
        // marginRight: "3vw",
        alignSelf: "center",
        height: 15,
        width: "100%",
        textAlign: "left",
        // fontWeight: "bold",
        fontFamily: "Inter",
        fontSize: "11px",
        // paddingTop: "1vh",
    },
    revenue: {
        color: "#73AF37",
    },
    expense: {
        color: "#C20911",
    },
    finalBalance: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        width: 260,
        marginVertical: "2vh",
        paddingBottom: 15,
        borderBottom: "0.6px solid black",
        height: 20,
    },
    dateHeader: {
        backgroundColor: "#F9DDDE",
        width: "100%",
    },
    balanceInfo: {
        marginRight: "3vw",
        height: 15,
        width: 130,
        textAlign: "left",
        // fontWeight: "bold",
        fontFamily: "Inter",
        fontSize: "11px",
    },
    footer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center",
        width: "100%",
        height: 30,
        borderTop: "0.6px solid black",
        alignSelf: "flex-end",
    },
    footerText: {
        fontSize: "9px",
    },
});

const MyDocument = ({ data, date, month, year, type, companyName, startingBalance, finalBalance }) => {
    // Invoice document to download as PDF
    const t = useTranslate("MySafe");
    let dateType = "";
    let shownDate = date;
    switch (type) {
        case "day":
            dateType = t("Daily");
            break;
        case "month":
            dateType = t("Monthly");
            shownDate = month;
            break;
        case "year":
            dateType = t("Yearly");
            shownDate = year;
            break;
        default:
            dateType = t("Daily");
            break;
    }

    const dataForAllPages = [];
    for (let i = 10; i < data.length + 10; i += 10) {
        if (i <= data.length) dataForAllPages.push(data.slice(i - 10, i));
        else dataForAllPages.push(data.slice(i - 10));
    }

    return (
        <Document>
            {dataForAllPages.map((pageInvoices, index) => (
                <Page size="A4" key={index} wrap>
                    <View style={styles.page} wrap>
                        <View style={styles.header} fixed>
                            {/* <Image src={CDImage} style={styles.image} /> */}
                            <div></div>
                            <View style={styles.headerInfoPart}>
                                <View style={styles.headerInfoRow}>
                                    <Text style={[styles.headerText, { width: 160 }]}>{t("SAFE")}</Text>
                                    <Text style={[styles.headerText, { textAlign: "right", fontWeight: "bold" }]}>{dateType.toUpperCase()}</Text>
                                </View>
                                <View style={styles.headerInfoRow}>
                                    <Text style={[styles.headerText, { width: 160 }]}>{type === "day" ? t("SAFE TIMELINE") : t("PRINT DATE")}</Text>
                                    <Text style={[styles.headerText, { textAlign: "right", fontWeight: "bold" }]}>{shownDate}</Text>
                                </View>
                                <View style={styles.headerInfoRow}>
                                    <Text style={[styles.headerText, { width: 160 }]}>{t("COMPANY")}</Text>
                                    <Text style={[styles.headerText, { textAlign: "right", fontWeight: "bold" }]}>{companyName}</Text>
                                </View>
                            </View>
                        </View>

                        <View style={[styles.dateHeader, { marginTop: 45 }]} fixed>
                            <Text style={[styles.balanceInfo, { textAlign: "left" }]}>{date}</Text>
                        </View>

                        <View style={[styles.finalBalance, { marginTop: 30 }]} fixed>
                            <Text style={styles.balanceInfo}>{t("STARTING BALANCE")}</Text>
                            <Text style={[styles.balanceInfo, { textAlign: "right" }]}>€{startingBalance}</Text>
                        </View>

                        <View style={styles.table} fixed>
                            <View style={styles.rowHeader}>
                                <Text style={[styles.rowData, { flex: 1.5 }]}>{t("DATE")}</Text>
                                <Text style={[styles.rowData, { flex: 2.5 }]}>{t("EXPLANATION")}</Text>
                                <Text style={[styles.rowData]}>{t("REVENUE")}</Text>
                                <Text style={[styles.rowData]}>{t("EXPENSE")}</Text>
                            </View>
                            {pageInvoices.map((row, index) => (
                                <View key={row.date + index} style={styles.row}>
                                    <Text style={[styles.rowData, { flex: 1.5 }]}>{formatDate(row.date).slice(0, formatDate(row.date).lastIndexOf(" "))}</Text>
                                    <Text style={[styles.rowData, { flex: 2.5 }]}>{row.explanation}</Text>
                                    <Text style={[styles.rowData, styles.revenue]}>+{row.revenue ? new Intl.NumberFormat().format(row.revenue) : 0}</Text>
                                    <Text style={[styles.rowData, styles.expense]}>-{row.expense ? new Intl.NumberFormat().format(row.expense) : 0}</Text>
                                </View>
                            ))}
                        </View>

                        {index === dataForAllPages.length - 1 && (
                            <View style={[styles.finalBalance, { marginVertical: 30 }]} fixed>
                                <Text style={styles.balanceInfo}>{t("FINAL BALANCE")}</Text>
                                <Text style={[styles.balanceInfo, { textAlign: "right" }]}>€{finalBalance}</Text>
                            </View>
                        )}

                        <View style={styles.footer} fixed>
                            <Text render={({ pageNumber, totalPages }) => `${t("Page")} ${pageNumber} of ${totalPages}`} style={styles.footerText} />
                        </View>
                    </View>
                </Page>
            ))}
            {dataForAllPages.length === 0 && (
                <Page size="A4" wrap>
                    <View style={styles.page} wrap>
                        <View style={styles.header} fixed>
                            {/* <Image src={CDImage} style={styles.image} /> */}
                            <div></div>
                            <View style={styles.headerInfoPart}>
                                <View style={styles.headerInfoRow}>
                                    <Text style={[styles.headerText, { width: 120 }]}>{t("SAFE")}</Text>
                                    <Text style={[styles.headerText, { textAlign: "right", fontWeight: "bold" }]}>{dateType.toUpperCase()}</Text>
                                </View>
                                <View style={styles.headerInfoRow}>
                                    <Text style={[styles.headerText, { width: 120 }]}>{type === "day" ? t("SAFE TIMELINE") : t("PRINT DATE")}</Text>
                                    <Text style={[styles.headerText, { textAlign: "right", fontWeight: "bold" }]}>{shownDate}</Text>
                                </View>
                                <View style={styles.headerInfoRow}>
                                    <Text style={[styles.headerText, { width: 120 }]}>{t("COMPANY")}</Text>
                                    <Text style={[styles.headerText, { textAlign: "right", fontWeight: "bold" }]}>{companyName}</Text>
                                </View>
                            </View>
                        </View>

                        <View style={[styles.dateHeader, { marginTop: 45 }]} fixed>
                            <Text style={[styles.balanceInfo, { textAlign: "left" }]}>{date}</Text>
                        </View>

                        <View style={[styles.finalBalance, { marginTop: 30 }]} fixed>
                            <Text style={styles.balanceInfo}>{t("STARTING BALANCE")}</Text>
                            <Text style={[styles.balanceInfo, { textAlign: "right" }]}>€{startingBalance}</Text>
                        </View>

                        <View style={styles.table} fixed>
                            <View style={styles.rowHeader}>
                                <Text style={[styles.rowData, { flex: 1.5 }]}>{t("DATE")}</Text>
                                <Text style={[styles.rowData, { flex: 2.5 }]}>{t("EXPLANATION")}</Text>
                                <Text style={[styles.rowData]}>{t("REVENUE")}</Text>
                                <Text style={[styles.rowData]}>{t("EXPENSE")}</Text>
                            </View>
                        </View>

                        <View style={[styles.finalBalance, { marginVertical: 30 }]} fixed>
                            <Text style={styles.balanceInfo}>{t("FINAL BALANCE")}</Text>
                            <Text style={[styles.balanceInfo, { textAlign: "right" }]}>€{finalBalance}</Text>
                        </View>

                        <View style={styles.footer} fixed>
                            <Text render={({ pageNumber, totalPages }) => `${t("Page")} ${pageNumber} of ${totalPages}`} style={styles.footerText} />
                        </View>
                    </View>
                </Page>
            )}
        </Document>
    );
};

const SafeTab = () => {
    const { calendarViewType, setCalendarViewType, selectedDay, shownMonth, shownYear } = useContext(CalendarContext);
    const {
        shownInvoices,
        calculatedStartingBalance,
        calculatedBalanceChange,
        calculatedFinalBalance,
        startingBalance,
        balanceChangeTotal,
        finalBalanceTotal,
        setStartingBalance,
        calculateBalanceChange,
    } = useContext(InvoiceContext);
    const {
        openedClient: { name, surname, safeLockingPeriod, startingBalance: startingBalanceUser, userId, firmId },
        updateClient,
    } = useContext(ClientsContext);
    const t = useTranslate("MySafe");

    const [viewType, setViewType] = useState(VIEW_TYPES.calendar);
    const [editable, setEditable] = useState(false);
    const [startingBalanceInput, setStartingBalanceInput] = useState(new Intl.NumberFormat().format(parseAsFloat(startingBalanceUser)));

    // const pdfDownloadRef = useRef();

    const handleIntervalChange = (name) => {
        setCalendarViewType(name);
    };

    const handleViewTypeChange = (type) => {
        setViewType(type);
    };

    const handleToggle = (value) => {
        setEditable(value);
    };

    const handleDownloadExcel = () => {
        // sort the invoices by date
        const sortedInvoices = [...shownInvoices];
        sortedInvoices.sort((a, b) => {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);
            return dateA - dateB;
        });
        // update the date of the invoices
        const organizedInvoices = sortedInvoices.map((invoice) => ({
            ...invoice,
            date: formatDate(invoice.date).slice(0, formatDate(invoice.date).lastIndexOf(" ")),
        }));
        downloadTableAsExcel(organizedInvoices);
    };

    const handleUpdateStartingBalance = (e) => {
        if (startingBalanceUser === startingBalanceInput) return;
        const isCompany = firmId !== -1;
        updateClient(userId, firmId, "startingBalance", startingBalance);
        setStartingBalanceInput(new Intl.NumberFormat().format(parseAsFloat(startingBalanceInput)));
        axios.post("setStartingBalance", { clientId: isCompany ? firmId : userId, startingBalance: parseAsFloat(startingBalanceInput), isCompany });
    };

    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        const sanitizedValue = inputValue.replace(/[^0-9.]/g, "");
        setStartingBalanceInput(sanitizedValue);
    };

    useEffect(() => {
        if (isNaN(parseAsFloat(startingBalanceInput))) return;
        setStartingBalance(parseAsFloat(startingBalanceInput));
    }, [startingBalanceInput, setStartingBalance]);

    return (
        <div className="safe-tab-container">
            <div className="top-left">
                <div className="flex flex--space-between-content border smooth-corners padding-xs--block padding-m--inline size400">
                    <p className="w600">{t("Client Safe Plan")}</p>
                    <p>{t(SAFE_LOCKING_PERIODS[safeLockingPeriod])}</p>
                </div>
            </div>
            <div className="top-right flex flex--center-items gap-m">
                <button
                    onClick={() => handleViewTypeChange(VIEW_TYPES.calendar)}
                    className={`border smooth-corners padding-xs--block padding-m--inline size400 w600 ${
                        viewType === VIEW_TYPES.calendar && "selected_view_type"
                    }`}>
                    {t("Calendar")}
                </button>
                <button
                    onClick={() => handleViewTypeChange(VIEW_TYPES.periodic)}
                    className={`border smooth-corners padding-xs--block padding-m--inline size400 w600 ${
                        viewType === VIEW_TYPES.periodic && "selected_view_type"
                    }`}>
                    {t("Periodic")}
                </button>
                {viewType === VIEW_TYPES.calendar && (
                    <div className="flex flex--grow flex--end-content">
                        <TabToggle value={editable} onClick={handleToggle} />
                    </div>
                )}
            </div>
            {viewType === VIEW_TYPES.calendar && (
                <Fragment>
                    <div className="bottom-left padding-m--right">
                        <Calendar />
                        <div className="data-wrapper with-line margin-m--bottom">
                            <div className="sub-text">
                                <span className="sub-title">{t("from reference")}:</span>
                                <span className="info">NaN</span>
                            </div>
                        </div>
                        <div className="print-wrapper">
                            <div className="sub-text margin-xs--bottom">
                                <div className="sub-title">{t("Select Format")}</div>
                            </div>
                            <div className="button-wrapper">
                                <div className="btn flex flex--center flex1 size350">
                                    <PDFDownloadLink
                                        style={{
                                            color: "#000000",
                                            width: "100%",
                                            height: "100%",
                                            paddingTop: "0.5rem",
                                            textAlign: "center",
                                        }}
                                        fileName={`Invoices_${firmId === -1 ? name + " " + surname : name}_${
                                            calendarViewType === "day"
                                                ? formatDate(selectedDay).slice(0, formatDate(selectedDay).lastIndexOf(" "))
                                                : calendarViewType === "month"
                                                ? t(MONTHS[shownMonth]).toUpperCase()
                                                : calendarViewType === "year"
                                                ? shownYear
                                                : ""
                                        }.pdf`}
                                        document={
                                            <LanguageProvider>
                                                <MyDocument
                                                    data={[...shownInvoices].sort((a, b) => {
                                                        const dateA = new Date(a.date);
                                                        const dateB = new Date(b.date);
                                                        return dateA - dateB;
                                                    })}
                                                    date={formatDate(selectedDay).slice(0, formatDate(selectedDay).lastIndexOf(" "))}
                                                    month={t(MONTHS[shownMonth]).toUpperCase()}
                                                    year={shownYear}
                                                    type={calendarViewType}
                                                    companyName={firmId === -1 ? name + " " + surname : name}
                                                    startingBalance={calculatedStartingBalance}
                                                    finalBalance={calculatedFinalBalance}
                                                />
                                            </LanguageProvider>
                                        }>
                                        {({ blob, url, loading, error }) => "PDF"}
                                    </PDFDownloadLink>
                                </div>
                                <button className="flex1 size350" onClick={handleDownloadExcel}>
                                    {t("Excel")}
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="bottom-right">
                        <div className="header">
                            {/* <div className="line" /> */}
                            {/* <div className="text unselectable">
                                <span className="bold">10 {t("days")} </span>
                                {t("left for your safe to be locked")}
                            </div> */}
                            <div>
                                <div className="margin-xs--bottom sub-text">
                                    <span className="bold">{t("first balance")}: </span>
                                    <span className="info">
                                        €
                                        <input
                                            className="balance-input"
                                            value={startingBalanceInput}
                                            onChange={handleInputChange}
                                            onBlur={handleUpdateStartingBalance}
                                            type="text"
                                        />
                                    </span>
                                </div>
                                <div className="margin-s--top sub-text">
                                    <span className="bold">{`${t("balance change")} (${t("total")})`}: </span>
                                    <span className={`info ${parseInt(balanceChangeTotal) >= 0 ? "green" : "red"}`}>€{balanceChangeTotal}</span>
                                </div>
                                <div className="margin-s--top sub-text">
                                    <span className="bold">{`${t("final balance")} (${t("total")})`}: </span>
                                    <span className="info">€{finalBalanceTotal}</span>
                                </div>
                            </div>

                            <div>
                                <div className="margin-s--top sub-text">
                                    <span className="bold">
                                        {`${t("starting balance")} (${
                                            calendarViewType === "day" ? t("daily") : calendarViewType === "month" ? t("monthly") : t("yearly")
                                        })`}{" "}
                                    </span>
                                    <span className="info">€{calculatedStartingBalance}</span>
                                </div>
                                <div className="margin-s--top sub-text">
                                    <span className="bold">
                                        {`${t("balance change")} (${
                                            calendarViewType === "day" ? t("daily") : calendarViewType === "month" ? t("monthly") : t("yearly")
                                        })`}
                                        :{" "}
                                    </span>
                                    <span className={`info ${parseInt(calculatedBalanceChange) >= 0 ? "green" : "red"}`}>€{calculatedBalanceChange}</span>
                                </div>
                                <div className="margin-s--top sub-text">
                                    <span className="bold">
                                        {`${t("final balance")} (${
                                            calendarViewType === "day" ? t("daily") : calendarViewType === "month" ? t("monthly") : t("yearly")
                                        })`}
                                        :{" "}
                                    </span>
                                    <span className="info">€{calculatedFinalBalance}</span>
                                </div>
                            </div>

                            <div className="button-wrapper">
                                {Object.keys(INTERVALS).map((_interval) => {
                                    return (
                                        <button
                                            key={_interval}
                                            onClick={() => handleIntervalChange(_interval)}
                                            className={`button unselectable ${_interval === calendarViewType && "selected"}`}>
                                            {INTERVALS[_interval]}
                                        </button>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="table-wrapper">
                            <Table editable={editable} startingBalance={startingBalance} />
                        </div>
                    </div>
                </Fragment>
            )}
            {viewType === VIEW_TYPES.periodic && (
                <div style={{ width: "100%", gridColumn: "1/-1" }} className="flex flex--space-between-content gap-xxxl">
                    <div className="flex--grow w600">
                        <p className="with-line margin-m--bottom">{t("Select Period")}</p>
                        <Selector data={[1, 2]} />
                    </div>
                    <div className="flex--grow w600">
                        <p className="with-line margin-m--bottom">{t("Select Year")}</p>
                        <Selector data={[1, 2]} />
                    </div>
                    <div className="flex--grow w600">
                        <p className="with-line margin-m--bottom">{t("Select Month")}</p>
                        <Selector data={[1, 2]} />
                    </div>
                    <div className="flex--grow w600">
                        <p className="with-line margin-m--bottom">{t("Select Week")}</p>
                        <Selector data={[1, 2]} />
                    </div>
                    <div className="flex--grow w600">
                        <p className="with-line margin-m--bottom">{t("Select Format")}</p>
                        <div className="flex gap-m">
                            <button className="flex--grow border smooth-corners padding-xs--block">PDF</button>
                            <button className="flex--grow border smooth-corners padding-xs--block">Excel</button>
                        </div>
                    </div>
                </div>
            )}
            {safeLockingPeriod === 4 && (
                <div className="inactive-container" style={{ height: "92%", pointerEvents: "none", transform: "translate(-1.3%, -3.5%)" }}>
                    <div className="inactive">
                        <span className="inactive text">{t("safe inactivated")}</span>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SafeTab;
