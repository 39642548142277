import { useContext } from "react";
import { setDefaultOptions, format, isSameDay } from "date-fns";
import { BsFillCaretDownFill, BsFillCaretUpFill } from "react-icons/bs";

import DayView from "./views/day-view";
import MonthView from "./views/month-view";
import YearView from "./views/year-view";

import { getMonthName } from "../../utils/calendar.utils";

import { CalendarContext } from "src/contexts/calendar.context";

import "./calendar.style.scss";
import useTranslate from "src/hooks/use-translate";

const CALENDAR_VIEW_TYPES = {
	year: "year",
	month: "month",
	day: "day",
};
setDefaultOptions({ weekStartsOn: 1 });

const Calendar = () => {
	const {
		selectedDay,
		shownMonth,
		shownYear,
		shownYearsInterval,
		calendarViewType,
		Prev,
		Next,
		goToToday,
		showSelectedDay,
		changeView,
	} = useContext(CalendarContext);

	const t = useTranslate("MySafe");

	const handleNext = () => {
		Next();
	};

	const handlePrev = () => {
		Prev();
	};

	const handleTodayButton = () => {
		goToToday();
	};

	const handleShowSelectedDateButton = () => {
		showSelectedDay();
	};

	const handleViewChange = () => {
		changeView();
	};

	return (
		<div className="calendar-container">
			<div className="header">
				<div
					className={`header-title unselectable ${
						calendarViewType === CALENDAR_VIEW_TYPES.year && "disabled"
					}`}
					onClick={handleViewChange}
				>
					{calendarViewType === CALENDAR_VIEW_TYPES.day &&
						`${getMonthName(shownMonth)} ${shownYear}`}
					{calendarViewType === CALENDAR_VIEW_TYPES.month && `${shownYear}`}
					{calendarViewType === CALENDAR_VIEW_TYPES.year && `${shownYearsInterval.join(" - ")}`}
				</div>
				<div className="right-side">
					<BsFillCaretUpFill onClick={handlePrev} className="change-month-button" />
					<BsFillCaretDownFill onClick={handleNext} className="change-month-button" />
				</div>
			</div>
			{calendarViewType === CALENDAR_VIEW_TYPES.day && <DayView />}
			{calendarViewType === CALENDAR_VIEW_TYPES.month && <MonthView />}
			{calendarViewType === CALENDAR_VIEW_TYPES.year && <YearView />}

			<div className="footer">
				<div className="box unselectable" onClick={handleShowSelectedDateButton}>
					{format(selectedDay, "eee',' dd MMMM yyyy")}
				</div>
				<div
					className={`box unselectable ${isSameDay(selectedDay, new Date()) && "disabled"}`}
					onClick={handleTodayButton}
				>
					{t("today")}
				</div>
			</div>
		</div>
	);
};

export default Calendar;
