import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { AiOutlineSortAscending, AiOutlineSortDescending } from "react-icons/ai";

import Row from "./row/row.component";

import "./accountant-table.style.scss";
import useTranslate from "src/hooks/use-translate";
import { ClientsContext } from "src/contexts/clients.context";

const COLUMNS = {
    name: "Client Company",
    idNo: "ID Number",
    filler: "",
};

const SORT_DIRECTIONS = {
    ascending: "ascending",
    descending: "descending",
    none: "none",
};

const Table = ({ searchText = ["", false], data: _data, extraData, initialIndex, onRowClick, onDelete = null, isIdEditable = false }) => {
    const { setOpenedClientById } = useContext(ClientsContext);
    const [data, setData] = useState(_data);
    const [sort, setSort] = useState(["", SORT_DIRECTIONS.none]); // [sortKey, direction]
    const [expandedIndex, setExpandedIndex] = useState(initialIndex);

    const t = useTranslate("Clients");

    const filterTable = useCallback(
        (text) => {
            if (text === "") {
                setData(_data);
                return;
            }

            setData(() => {
                const newValue = [..._data];
                return newValue.filter((client) => {
                    const isPersonalAccount = client.firmId === -1;
                    if (isPersonalAccount) return client.name.toLowerCase().includes(text.toLowerCase());
                    return client.name.toLowerCase().includes(text.toLowerCase());
                });
            });
        },
        [_data]
    );

    useEffect(() => {
        setData(_data);
    }, [_data]);

    useEffect(() => {
        if (searchText[1]) {
            filterTable(searchText[0]);
        }
    }, [searchText, filterTable]);

    const changeSort = useCallback(
        (sortKey) => {
            if (sort[0] !== sortKey) {
                setSort([sortKey, SORT_DIRECTIONS.ascending]);
                return SORT_DIRECTIONS.ascending;
            }
            if (sort[1] === SORT_DIRECTIONS.ascending) {
                setSort([sortKey, SORT_DIRECTIONS.descending]);
                return SORT_DIRECTIONS.descending;
            }
            if (sort[1] === SORT_DIRECTIONS.descending) {
                setSort(["", SORT_DIRECTIONS.none]);
                return SORT_DIRECTIONS.none;
            }
        },
        [sort]
    );

    const handleSort = useCallback(
        (sortKey) => {
            const direction = changeSort(sortKey);
            setData((prevData) => {
                if (direction === SORT_DIRECTIONS.none) return _data;

                const tempData = [...prevData];
                tempData.sort((row1, row2) => {
                    const a = row1[sortKey].toLowerCase();
                    const b = row2[sortKey].toLowerCase();
                    if (a > b) return direction === SORT_DIRECTIONS.ascending ? 1 : -1;
                    if (a < b) return direction === SORT_DIRECTIONS.ascending ? -1 : 1;
                    return 0;
                });
                return tempData;
            });
        },
        [_data, changeSort]
    );

    const getCompanyName = (row) => {
        if (row.firmId === -1) {
            return row.name + " " + row.surname;
        }
        return row.name;
    };

    const handleRowClick = (row, index) => {
        if (onRowClick) {
            onRowClick(getCompanyName(row));
            return;
        }
        handleExpand(index);
    };

    const handleExpand = (index) => {
        setExpandedIndex((prevIndex) => {
            return index === prevIndex ? null : index;
        });
    };

    return (
        <div className="accountant-table-container">
            {data.length === 0 && <div className="size500 w500 margin-s--block empty-table">{t("there are no clients")}</div>}
            <table>
                {data.length > 0 && (
                    <Fragment>
                        <thead className={`${onDelete && "with-delete"}`}>
                            <tr className="size400 w500">
                                {Object.keys(COLUMNS).map((key) => {
                                    const column = COLUMNS[key];
                                    return (
                                        <th key={column}>
                                            {t(column)}

                                            {column && (
                                                <span
                                                    onClick={() => {
                                                        handleSort(key);
                                                    }}
                                                    className="sort-button-wrapper">
                                                    {sort[0] !== key && <AiOutlineSortDescending className="sort-button none" />}
                                                    {sort[0] === key && sort[1] === SORT_DIRECTIONS.none && (
                                                        <AiOutlineSortDescending className="sort-button none" />
                                                    )}
                                                    {sort[0] === key && sort[1] === SORT_DIRECTIONS.ascending && (
                                                        <AiOutlineSortDescending className="sort-button" />
                                                    )}
                                                    {sort[0] === key && sort[1] === SORT_DIRECTIONS.descending && (
                                                        <AiOutlineSortAscending className="sort-button" />
                                                    )}
                                                </span>
                                            )}
                                        </th>
                                    );
                                })}
                                {onDelete && <th></th>}
                            </tr>
                        </thead>

                        <tbody className={`scroll-bar scroll-bar--overflow ${onDelete && "with-delete"}`}>
                            {data.map((row, index) => {
                                return (
                                    <Row
                                        key={(row.userId ?? row.firmId) + row.name}
                                        row={row}
                                        index={index}
                                        extraData={extraData ?? []}
                                        onClick={() => {
                                            handleRowClick(row, index);
                                            setOpenedClientById(row?.userId ?? -1, row.firmId);
                                        }}
                                        isExpanded={index === expandedIndex}
                                        onDelete={
                                            onDelete
                                                ? () => {
                                                      onDelete(index);
                                                  }
                                                : null
                                        }
                                        isIdEditable={isIdEditable}
                                    />
                                );
                            })}
                        </tbody>
                    </Fragment>
                )}
            </table>
        </div>
    );
};
export default Table;
