import "./side-selector.style.scss";
import { useEffect, useRef, useState } from "react";

const SideSelector = ({ data, onSelected, selected }) => {
    const selectedRef = useRef();
    const [leftOffset, setLeftOffset] = useState(0);

    const handleOnChange = (value, e) => {
        onSelected(value);
        setLeftOffset(e.target.offsetLeft);
    };

    const handleHover = (event) => {
        setLeftOffset(event.target.offsetLeft);
    };

    const handleMouseLeave = (e) => {
        if (selected === e.target.value) return;
        setLeftOffset(document.getElementsByClassName("selector-item selected")[0].offsetLeft);
    };

    useEffect(() => {
        if (selectedRef) setLeftOffset(selectedRef.current.offsetLeft);
    }, [selectedRef]);

    return (
        <div className="horizontal-selector">
            <ul className="selector-list">
                {data.map((item) => (
                    <li
                        key={item.name}
                        ref={item.name === selected ? selectedRef : null}
                        value={item.name}
                        className={`selector-item ${selected === item.name ? "selected" : ""}`}
                        onMouseEnter={handleHover}
                        onMouseLeave={handleMouseLeave}
                        onClick={(e) => handleOnChange(item.name, e)}>
                        {item.label}
                    </li>
                ))}
            </ul>
            <div
                className="background-color-element"
                style={{
                    transform: `translateX(${leftOffset - 1.5}px)`,
                    width: `${100 / data.length}%`,
                }}
            />
        </div>
    );
};

export default SideSelector;
