import { Fragment, useContext, useEffect, useMemo, useState } from "react";
import { IoClose } from "react-icons/io5";

import useToggle from "src/hooks/use-toggle";

import Page1 from "./pages/page-1";
import Page2 from "./pages/page-2";
import Page2_1 from "./pages/page-2_1";
import Page3 from "./pages/page-3";
import Page3_1 from "./pages/page-3_1";
import Page4 from "./pages/page-4";
import Page4_1 from "./pages/page-4_1";
import Page5 from "./pages/page-5";
import Page5_1 from "./pages/page-5_1";
import PagePersonal2 from "./pages/personal-page-2";
import PagePersonal2_1 from "./pages/personal-page-2_1";
import PagePersonal3 from "./pages/personal-page-3";
import PagePersonal3_1 from "./pages/personal-page-3_1";

import { NewClientContext } from "src/contexts/new-client.context";

import "./new-client-modal.style.scss";
import useTranslate from "src/hooks/use-translate";

const NewClientModal = ({ dismiss = () => {} }) => {
    const {
        newCompanyData: { companyType },
        admins,
        employees,
        save,
        clear,
    } = useContext(NewClientContext);

    const [canContinue, toggleCanContinue] = useToggle(false);
    const [step, setStep] = useState(0);

    const t = useTranslate("Clients");

    const nextPageButtonLabel = useMemo(
        () => ((companyType === 0 && step === 4) || (companyType === 1 && step === 6 + admins.length + employees.length) ? t("finish") : t("continue")),
        [step, companyType, admins.length, employees.length, t]
    );

    const handleBack = () => {
        setStep((oldValue) => oldValue - 1);
    };

    const handleContinue = () => {
        if (nextPageButtonLabel === t("finish")) {
            save();
            dismiss();
            return;
        }
        if (step === 0 && canContinue) toggleCanContinue();
        if (step === 2 && canContinue) toggleCanContinue();
        setStep((oldValue) => oldValue + 1);
    };

    useEffect(() => {
        return clear;
    }, [clear]);

    return (
        <div className="new-client-modal-container absolute fill">
            <div onClick={dismiss} className="absolute fill" />
            <div className="modal flex flex--column smooth-corners padding-xxl--inline padding-xxl--block">
                <div className="modal-header flex flex--space-between-content flex--center-items padding-s--bottom">
                    <p className="size500 w600">{t("add new client")}</p>
                    <IoClose onClick={dismiss} size={"1.6rem"} className="pointer" />
                </div>
                <div className="flex flex--grow scroll-bar scroll-bar--overflow">
                    {step === 0 && <Page1 canContinue={canContinue} toggle={toggleCanContinue} />}
                    {companyType === 1 && (
                        // Pages for company
                        <Fragment>
                            {step === 1 && <Page2 canContinue={canContinue} toggle={toggleCanContinue} />}
                            {step === 2 && <Page2_1 canContinue={canContinue} toggle={toggleCanContinue} />}
                            {step === 3 && <Page3 canContinue={canContinue} toggle={toggleCanContinue} />}
                            {admins.map((admin, index) => {
                                return (
                                    <Fragment key={index}>
                                        {step === 4 + index && <Page3_1 canContinue={canContinue} toggle={toggleCanContinue} index={index} />}
                                    </Fragment>
                                );
                            })}
                            {step === 4 + admins.length && <Page4 canContinue={canContinue} toggle={toggleCanContinue} />}
                            {employees.map((admin, index) => {
                                return (
                                    <Fragment key={index}>
                                        {step === 5 + admins.length + index && <Page4_1 canContinue={canContinue} toggle={toggleCanContinue} index={index} />}
                                    </Fragment>
                                );
                            })}
                            {step === 5 + admins.length + employees.length && <Page5 canContinue={canContinue} toggle={toggleCanContinue} />}
                            {step === 6 + admins.length + employees.length && <Page5_1 canContinue={canContinue} toggle={toggleCanContinue} />}
                        </Fragment>
                    )}
                    {companyType === 0 && (
                        // Pages for personal accounts
                        <Fragment>
                            {step === 1 && <PagePersonal2 canContinue={canContinue} toggle={toggleCanContinue} />}
                            {step === 2 && <PagePersonal2_1 canContinue={canContinue} toggle={toggleCanContinue} />}
                            {step === 3 && <PagePersonal3 canContinue={canContinue} toggle={toggleCanContinue} />}
                            {step === 4 && <PagePersonal3_1 canContinue={canContinue} toggle={toggleCanContinue} />}
                        </Fragment>
                    )}
                </div>
                <div className="flex margin-xxl--top">
                    {step > 0 && (
                        <button onClick={handleBack} style={{ borderRadius: "1rem" }} className="size400 border padding-xxl--inline padding-xs--block">
                            {t("back")}
                        </button>
                    )}
                    <button
                        disabled={!canContinue}
                        onClick={handleContinue}
                        style={{ marginLeft: "auto", borderRadius: "1rem" }}
                        className="size400 border padding-xxl--inline padding-xs--block">
                        {nextPageButtonLabel}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default NewClientModal;
