import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "./App";

import AuthProvider from "./contexts/auth.context";
import LanguageProvider from "./contexts/language.context";
import FolderProvider from "./contexts/folder.context";
import InvoiceProvider from "./contexts/invoice.context";
import ChatProvider from "./contexts/chat.context";
import CalendarProvider from "./contexts/calendar.context";
import TrashProvider from "./contexts/trash.context";
// import ClientsProvider from "./contexts/clients.context";
// import NewClientProvider from "./contexts/new-client.context";
import TicketsProvider from "./contexts/tickets.context";
import S3Provider from "./contexts/s3.context";

import reportWebVitals from "./reportWebVitals";

import "./utils/connection.utils";

import "./index.scss";
import ClientsProvider from "./contexts/clients.context";
import LoadingProvider from "./contexts/loading.context";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <LoadingProvider>
                <LanguageProvider>
                    <AuthProvider>
                        <S3Provider>
                            <ClientsProvider>
                                <FolderProvider>
                                    <TrashProvider>
                                        <CalendarProvider>
                                            <InvoiceProvider>
                                                <ChatProvider>
                                                    <TicketsProvider>
                                                        <App />
                                                    </TicketsProvider>
                                                </ChatProvider>
                                            </InvoiceProvider>
                                        </CalendarProvider>
                                    </TrashProvider>
                                </FolderProvider>
                            </ClientsProvider>
                        </S3Provider>
                    </AuthProvider>
                </LanguageProvider>
            </LoadingProvider>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
