import { Fragment } from "react";
import { BiLockAlt, BiLockOpenAlt } from "react-icons/bi";

import "./lock-progress.style.scss";

const LockProgress = ({ lockedBoxes = [], allBoxes = 0 }) => {
	const isFilled = lockedBoxes.length === allBoxes;

	return (
		<div className="flex flex--center-items gap-s lock-progress-container">
			{isFilled ? (
				<Fragment>
					<BiLockAlt className="lock-icon locked" />
					<div className={`lock-box flex flex--center size300 locked filled`}>
						{allBoxes + " / " + allBoxes}
					</div>
				</Fragment>
			) : (
				<Fragment>
					<BiLockOpenAlt className="lock-icon" />

					{[...Array(allBoxes).keys()].map((_, index) => {
						return (
							<div
								className={`lock-box flex flex--center size300 ${
									lockedBoxes.includes(index + 1) && "locked"
								}`}
								key={index}
							>
								{index + 1}
							</div>
						);
					})}
				</Fragment>
			)}
		</div>
	);
};

export default LockProgress;
