import "./warning-box.style.scss";

import React from "react";
import { BsCheck2Circle, BsExclamationCircle } from "react-icons/bs";

const WarningBox = ({ text = "", size = "1.1rem", fontSize = null, lineHeight = null, isCheckmark = false, ...props }) => {
    return (
        <div
            {...props}
            className={`warning-box-container 
			${isCheckmark ? "done" : "error"} flex flex--center-items gap-m padding-m--block padding-xl--inline border border--thick smooth-corners`}>
            {isCheckmark ? <BsCheck2Circle size={`${parseFloat(size) * 2.1}rem`} /> : <BsExclamationCircle size={`${parseFloat(size) * 2.2}rem`} />}
            {text && text.length > 0 && <p style={{ fontSize: fontSize ?? size, lineHeight: lineHeight ?? "1.6rem" }}>{text}</p>}
        </div>
    );
};

export default WarningBox;
