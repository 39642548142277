import InputField from "components/input-field/input-field.component";
import { useContext, useState } from "react";

import { ClientsContext } from "src/contexts/clients.context";

import useTranslate from "src/hooks/use-translate";
import { getItemWhere } from "utils/array.utils";

import { CLIENT_TYPES, COLORS } from "utils/lists.utils";

import "./info-sub-category.style.scss";

const FIELDS = {
    name: { label: "Name", inputLine: 1 },
    vatNumber: { label: "VAT Number", inputLine: 1 },
    email: { label: "Email", inputLine: 1 },
    address: { label: "Location", inputLine: 2 },
};

const InfoSubTab = ({ id }) => {
    const { clients, updateCompanyInfo } = useContext(ClientsContext);
    const t = useTranslate("Clients");

    const [currentClient, setCurrentClient] = useState(getItemWhere(clients, "firmId", id));

    const { color, clientType, specialities, website } = currentClient;

    const handleChange = (key, value) => {
        setCurrentClient((oldValue) => {
            const newValue = { ...oldValue, [key]: value };
            return newValue;
        });
    };
    const handleSave = () => {
        updateCompanyInfo(id, currentClient, true);
    };
    const handleCancel = () => {
        setCurrentClient(getItemWhere(clients, "firmId", id));
    };

    return (
        <div className="info-sub-category-container padding-m--left">
            <p className="with-line w600">{t("Company Information")}</p>
            <div className="grid">
                <div className="left-side ">
                    {Object.keys(FIELDS).map((fieldKey, index) => {
                        const { label } = FIELDS[fieldKey];
                        let value = currentClient[fieldKey];
                        return (
                            <InputField
                                key={index}
                                className="flex--grow size400 padding-xs--bottom"
                                label={t(label)}
                                fieldType="underline"
                                value={value}
                                onChange={({ target: { value } }) => {
                                    handleChange(fieldKey, value);
                                }}
                            />
                        );
                    })}
                </div>
                <div className="right-side">
                    <div className="w600 padding-s--bottom">{t("Client Type")}</div>
                    <div className="flex gap-s">
                        {CLIENT_TYPES.map((type, index) => {
                            return (
                                <div
                                    key={index}
                                    onClick={() => handleChange("clientType", index)}
                                    className={`pointer ${index === clientType && "selected-client"}`}>
                                    {t(type)}
                                </div>
                            );
                        })}
                    </div>
                    <div className="flex flex--space-between-items gap-l margin-m--top container-m">
                        <div className="image" style={{ backgroundColor: color }} />
                        <div className="flex flex--column flex--space-between-content" style={{ width: "60%" }}>
                            <InputField
                                className="flex--grow size400 padding-xs--bottom"
                                label={t("Specialities")}
                                fieldType="underline"
                                value={specialities}
                                onChange={({ target: { value } }) => {
                                    handleChange("specialities", value);
                                }}
                            />
                            <InputField
                                className="flex--grow size400 padding-xs--bottom"
                                label={t("Website")}
                                fieldType="underline"
                                value={website}
                                onChange={({ target: { value } }) => {
                                    handleChange("website", value);
                                }}
                            />
                        </div>
                    </div>
                    <div className="flex flex--column gap-s">
                        <div className="w600 size400 margin-m--top">{t("Company Color")}</div>
                        <div className="size300">{t("choose-color")}</div>
                        <div className="flex gap-xs" style={{ maxWidth: "60%" }}>
                            {COLORS.map((currentColor, index) => {
                                return (
                                    <div
                                        key={index}
                                        onClick={() => handleChange("color", COLORS[index])}
                                        className={`color-picker pointer ${currentColor === color && "selected-color"}`}
                                        style={{
                                            backgroundColor: currentColor,
                                            color: currentColor === "#000000" ? "white" : "black",
                                        }}
                                    />
                                );
                            })}
                        </div>
                    </div>
                    <div className="flex gap-s flex--end-content margin-m--top">
                        <button onClick={handleSave} className="size400 w500">
                            {t("Save")}
                        </button>
                        <button onClick={handleCancel} className="size400 w500 cancel">
                            {t("Cancel")}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InfoSubTab;
