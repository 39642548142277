import WarningBox from "components/warning-box/warning-box.component";
import { useEffect, useState } from "react";
import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as LeftArrow } from "assets/left-arrow.svg";

import { AuthContext } from "../../contexts/auth.context";

import useTranslate from "../../hooks/use-translate";

import "../login/login.style.scss";
import Modal from "components/modal/modal.component";
import { wait } from "utils/function.utils";
import axios from "axios";

const ForgotPassword = () => {
    const navigate = useNavigate();
    const { resetPassword } = useContext(AuthContext);
    const t = useTranslate("ForgotPassword");

    const [upperText, setUpperText] = useState("");
    const [lowerText, setLowerText] = useState("");
    const [image, setImage] = useState(null);
    const [isSent, setIsSent] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSent(true);
        const email = event.target.email.value;
        await resetPassword({ email });
        await wait(2000);
        navigate("/verification", { state: { email } });
    };

    useEffect(() => {
        const fetchAdImage = async () => {
            const [link, imageName] = await axios
                .get("getAdvertisement")
                .then((res) => {
                    if (res.status !== 200) return;
                    const { upperText, lowerText, imageUrl, imageName } = res.data;
                    setUpperText(upperText);
                    setLowerText(lowerText);
                    return [imageUrl, imageName];
                })
                .catch(console.error);
            if (!link) return;
            const file = await fetch(link)
                .then((response) => {
                    if (response.status !== 200) return null;
                    return response.blob();
                })
                .then((blob) => {
                    if (!blob) throw new Error("Blob is null");
                    const file = new File([blob], imageName, { type: blob.type });
                    return file;
                })
                .catch((error) => {
                    console.error(error);
                    return null;
                });
            if (!file) return;

            file.url = window.URL.createObjectURL(file);
            setImage(file);
        };
        fetchAdImage();
    }, [setUpperText, setLowerText]);

    return (
        <div className="login-container">
            <div className="login-left-side">
                {image && (
                    <div className="ads">
                        <span>{upperText}</span>
                        <div className="ad-img-container">
                            <img className="ad-img" src={image.url} alt="advertisement" />
                        </div>
                        <span>{lowerText}</span>
                    </div>
                )}
            </div>
            <form className="login-right-side" onSubmit={handleSubmit}>
                <div className="login-form-container">
                    <Link to={"/"} style={{ width: "min-content" }}>
                        <LeftArrow style={{ fontSize: "2rem" }} />
                    </Link>
                    <span className="title margin-m--top">{t("reset your password").toUpperCase()}</span>
                    <input className="text-box" type="text" placeholder={t("email")} name="email" />
                    <div className="flex gap-xl">
                        <div className="flex--grow flex">
                            <button
                                className="btn btn--red size500 w500 padding-s--block padding-xxl--inline margin-auto--left"
                                style={{ width: "max-content", height: "min-content" }}
                                type="submit">
                                {t("send").toUpperCase()}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
            {isSent && (
                <Modal modal={<WarningBox text={t("Verification code sent to the given email address.")} isCheckmark />} closeIn3 dismiss={[setIsSent]} />
            )}
        </div>
    );
};

export default ForgotPassword;
