import { forwardRef, useCallback, useContext, useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { format } from "date-fns";
import fr from "date-fns/locale/fr";

import { LanguageContext } from "src/contexts/language.context";
import { InvoiceContext } from "src/contexts/invoice.context";

import "react-datepicker/dist/react-datepicker.css";
import "./table.style.scss";
import useTranslate from "src/hooks/use-translate";
import { parseAsFloat } from "utils/invoice.utils";
import YesNoBox from "components/yes-no-box/YesNoBox.component";
import Modal from "components/modal/modal.component";
import axios from "axios";

registerLocale("fr", fr);

const COLUMNS = {
    date: {
        columnName: "date",
        inputType: "date",
        placeholder: "Nan/Nan/Nan",
    },
    explanation: {
        columnName: "explanation",
        inputType: "text",
        placeholder: "N/A",
    },
    revenue: {
        columnName: "revenue",
        inputType: "text",
        placeholder: "0",
        isFloat: true,
    },
    expense: {
        columnName: "expense",
        inputType: "text",
        placeholder: "0",
        isFloat: true,
    },
    balance: {
        columnName: "balance",
        inputType: "text",
        placeholder: "0",
        isFloat: true,
    },
    "": {
        columnName: "",
        inputType: "hidden",
        placeholder: "",
    },
};

const NewRow = ({ newRow, removeRow, handleChange, language }) => {
    const balance = (newRow.revenue ?? 0) - (newRow.expense ?? 0);

    const CustomDatePicker = forwardRef(({ value, onClick, placeholder }, ref) => {
        return (
            <button className="date-picker" onClick={onClick}>
                {value === "" ? placeholder : value}
            </button>
        );
    });

    const formatAmounts = (e) => {
        const inputs = document.getElementsByClassName("new-row-input");
        for (let i = 0; i < inputs.length; i++) {
            const input = inputs[i];
            const isFloat = input.dataset.isfloat === "true";
            const value = input.value;
            if (isFloat) {
                const formattedParsedValue = new Intl.NumberFormat().format(parseAsFloat(value));
                input.value = formattedParsedValue;
            }
        }
    };

    return (
        <tr className="table-row new-row">
            {Object.keys(newRow).map((columnName) => {
                const isDisabled = (columnName === "revenue" && newRow.expense > 0) || (columnName === "expense" && newRow.revenue > 0);
                return (
                    <td key={columnName}>
                        {columnName === "date" ? (
                            <DatePicker
                                dateFormat="dd/MM/yyyy"
                                selected={new Date(newRow[columnName])}
                                onChange={handleChange}
                                locale={language}
                                customInput={<CustomDatePicker />}
                                placeholderText={COLUMNS.date.placeholder}
                            />
                        ) : (
                            <input
                                className="new-row-input"
                                disabled={isDisabled}
                                name={columnName}
                                type={COLUMNS[columnName].inputType}
                                value={newRow[columnName] ?? ""}
                                onChange={handleChange}
                                placeholder={COLUMNS[columnName].placeholder}
                                data-isfloat={COLUMNS[columnName].isFloat ? "true" : "false"}
                                onBlur={formatAmounts}
                            />
                        )}
                    </td>
                );
            })}
            <td className={`${balance > 0 ? "positive" : balance < 0 ? "negative" : ""}`}>{new Intl.NumberFormat().format(balance) ?? ""}</td>
            <td onClick={removeRow} className="remove-button">
                X
            </td>
        </tr>
    );
};

const Table = ({ editable = true }) => {
    const { language } = useContext(LanguageContext);
    const { shownInvoices, newRows, filteredNewRows, removedInvoices, addNewRow, removeNewRow, removeRow, changeOnNewRow, save } = useContext(InvoiceContext);

    const [showYesNoOnSave, setShowYesNoOnSave] = useState(false);
    const [approvedSaveInvoice, setApprovedSaveInvoice] = useState(false);
    const [showYesNo, setShowYesNo] = useState(false);
    const [approvedRemoveInvoice, setApprovedRemoveInvoice] = useState(false);
    const [index, setIndex] = useState(null);

    const t = useTranslate("MySafe");

    const handleNewButton = () => {
        addNewRow();
    };

    const handleRemoveNewRow = (index) => {
        removeNewRow(index);
    };

    const handleRemoveRow = (index) => {
        setIndex(index);
        setShowYesNo(true);
    };

    const handleSave = () => {
        if (filteredNewRows.length === 0 && removedInvoices.length === 0) return;
        setShowYesNoOnSave(true);
    };

    const handleChange = (event, index) => {
        changeOnNewRow(event, index);
    };

    useEffect(() => {
        if (approvedRemoveInvoice && index !== null) {
            removeRow(index); // remove the invoice from the table
            setApprovedRemoveInvoice(false);
            setIndex(null);
        }
        if (approvedSaveInvoice) {
            save();
            setApprovedSaveInvoice(false);
        }
    }, [approvedRemoveInvoice, index, removeRow, approvedSaveInvoice, save]);

    return (
        <div className="invoice-table-container">
            <table className="invoice-table">
                <thead>
                    <tr className="table-row">
                        {Object.values(COLUMNS).map(({ columnName }) => {
                            return <th key={columnName}>{t(columnName)}</th>;
                        })}
                    </tr>
                </thead>
                <tbody>
                    {newRows.map((row, index) => (
                        <NewRow
                            key={index}
                            newRow={row}
                            removeRow={() => handleRemoveNewRow(index)}
                            handleChange={(event) => handleChange(event, index)}
                            language={language}
                        />
                    ))}
                    {shownInvoices.map((row, index) => {
                        return (
                            <tr key={index} className={`table-row ${index === shownInvoices.length - 1 && "last-child"}`}>
                                {Object.keys(row).map((columnName) => {
                                    return (
                                        <td key={columnName} className={columnName}>
                                            {columnName === "date"
                                                ? format(new Date(row[columnName]), "dd/MM/yyyy")
                                                : columnName === "explanation"
                                                ? row[columnName]
                                                : new Intl.NumberFormat().format(row[columnName]) ?? COLUMNS[columnName].placeholder}
                                        </td>
                                    );
                                })}
                                <td
                                    className={`${
                                        (row.revenue ?? 0) - (row.expense ?? 0) > 0 ? "positive" : (row.revenue ?? 0) - (row.expense ?? 0) < 0 ? "negative" : ""
                                    }`}>
                                    {new Intl.NumberFormat().format((row.revenue ?? 0) - (row.expense ?? 0))}
                                </td>
                                {editable && (
                                    <td
                                        onClick={() => {
                                            handleRemoveRow(index);
                                        }}
                                        className="remove-button">
                                        X
                                    </td>
                                )}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            {editable && (
                <div onClick={handleNewButton} className="new-file-button">
                    <div className="text">+ {t("new")}</div>
                </div>
            )}
            {editable && (
                <div className="save-button-container">
                    <button
                        disabled={filteredNewRows.length === 0 && removedInvoices.length === 0}
                        className={`save-button ${filteredNewRows.length === 0 && removedInvoices.length === 0 && "disabled"}`}
                        onClick={handleSave}>
                        {t("save")}
                    </button>
                </div>
            )}
            {showYesNo && (
                <Modal
                    modal={
                        <YesNoBox
                            setVisible={setShowYesNo}
                            setApproved={setApprovedRemoveInvoice}
                            text={t("remove-invoice-warning")}
                            rightOption={t("remove-invoice")}
                            rightOptionRed
                        />
                    }
                    touchable
                    dismiss={[setShowYesNo]}
                />
            )}
            {showYesNoOnSave && (
                <Modal
                    modal={
                        <YesNoBox
                            setVisible={setShowYesNoOnSave}
                            setApproved={setApprovedSaveInvoice}
                            text={t("save-invoice-warning")}
                            rightOption={t("save-invoice")}
                            rightOptionRed
                        />
                    }
                    touchable
                    dismiss={[setShowYesNoOnSave]}
                />
            )}
        </div>
    );
};

export default Table;
