import useTranslate from "src/hooks/use-translate";
import "./selector.style.scss";

const Selector = ({ data, disabled = false, selected = undefined, onSelected = () => {} }) => {
    const t = useTranslate("Clients");
    const handleOnChange = (event) => {
        const { value } = event.target;
        onSelected(value);
    };

    return (
        <div className={`custom-select ${disabled && "disabled"}`}>
            <select onChange={handleOnChange} disabled={disabled} value={selected}>
                {data.map((item) => {
                    return (
                        <option key={item} value={item}>
                            {t(item)}
                        </option>
                    );
                })}
            </select>
            <span className="focus"></span>
        </div>
    );
};

export default Selector;
