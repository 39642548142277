import { useContext } from "react";

import { GoSearch } from "react-icons/go";

import { TrashContext } from "src/contexts/trash.context";
import useTranslate from "src/hooks/use-translate";
import Table from "./components/table/table.component";

import "./trash.style.scss";

const Trash = () => {
	const { selectedItems, deletedItems, restoreItem, deleteItem, emptyTrash, search } =
		useContext(TrashContext);

	const t = useTranslate("Trash");

	const handleSearch = (event) => {
		event.preventDefault();
		const searchText = event.target.searchText.value;
		search(searchText);
	};

	return (
		<div className="trash-container">
			<div className="header">
				<h1 className="bold">{t("trash")}</h1>
				<form onSubmit={handleSearch} className="header-search-form">
					<input id="searchText" className="header-search" type="text" placeholder={t("search")} />
					<button type="submit">
						<GoSearch />
					</button>
				</form>
				<div className="buttons-container">
					<button
						className={`${selectedItems.length === 0 && "disabled"}`}
						disabled={selectedItems.length === 0}
						onClick={restoreItem}
					>
						{t("restore")}
					</button>
					<button
						className={`${selectedItems.length === 0 && "disabled"}`}
						disabled={selectedItems.length === 0}
						onClick={deleteItem}
					>
						{t("delete forever")}
					</button>
					<button
						className={`${deletedItems.length === 0 && "disabled"}`}
						disabled={deletedItems.length === 0}
						onClick={emptyTrash}
					>
						{t("empty trash")}
					</button>
				</div>
			</div>
			<div className="warning-container">
				<div className="line" />
				<span className="warning-message">
					{t("documents that have been in trash more than 30 days will be automatically")}{" "}
					<span className="bold">{t("deleted")}</span>
				</span>
				<div className="line" />
			</div>
			<Table />
		</div>
	);
};

export default Trash;
