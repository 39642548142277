import InputField from "components/input-field/input-field.component";
import { useContext, useEffect } from "react";

import { NewClientContext } from "src/contexts/new-client.context";

import useTranslate from "src/hooks/use-translate";

import { COLORS, SUBSCRIPTIONS } from "utils/lists.utils";

const Page2_1 = ({ canContinue, toggle }) => {
    const {
        newCompanyData: { specialities, color, subscription },
        updateNewCompanyData,
    } = useContext(NewClientContext);

    const t = useTranslate("NewClientModal");

    useEffect(() => {
        if (canContinue && specialities.length === 0) toggle();
        else if (!canContinue && specialities.length > 0) toggle();
    }, [toggle, canContinue, specialities]);

    const handleChange = (key, value) => {
        updateNewCompanyData(key, value);
    };

    return (
        <div className="page-container page2-1 padding-xxl--right">
            <p className="margin-xl--top w600">
                {t("step")} 2.1 <span className="red">|</span> {t("Register the Company")}
            </p>
            <div className="grid gap-xxxxl flex--grow margin-xl--top">
                <div id="left" className="flex flex--column gap-xxxl">
                    <InputField
                        fieldType="underline"
                        label={t("Specialities")}
                        placeholder={t("what does this company do?")}
                        value={specialities}
                        onChange={({ target: { value } }) => {
                            handleChange("specialities", value);
                        }}
                    />
                    <div>
                        <p className="w600 size300 margin-s--bottom">{t("Subscription")}</p>
                        <p className="size200">{t("Subscription will be determined based on the size and revenue of the company")}</p>
                        <div className="flex flex--column gap-s margin-l--top">
                            {SUBSCRIPTIONS.map((subName, index) => {
                                return (
                                    <button
                                        key={index}
                                        onClick={() => {
                                            handleChange("subscription", index);
                                        }}
                                        className={`w500 btn smooth-corners padding-l--inline padding-s--block ${subscription === index && "btn--selected"}`}>
                                        {t(subName)}
                                    </button>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div id="right">
                    <div className="flex flex--column gap-s">
                        <div className="image" style={{ backgroundColor: COLORS[color] }} />
                        <p className="w600 size300 margin-m--top">{t("Company Color")}</p>
                        <p className="size200">{t("Please choose a representative color for your company based on your logo/brand colors")}</p>
                        <div className="flex gap-xs" style={{ maxWidth: "60%", flexWrap: "wrap" }}>
                            {COLORS.map((currentColor, index) => {
                                return (
                                    <div
                                        key={index}
                                        onClick={() => handleChange("color", index)}
                                        className={`color-picker pointer ${index === color && "selected-color"}`}
                                        style={{
                                            backgroundColor: currentColor,
                                            color: currentColor === "#000000" ? "white" : "black",
                                        }}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Page2_1;
