import { useState } from "react";
import "./toggle.style.scss";

const Toggle = ({ value = false, onClick = (_) => {} }) => {
	const [isChecked, setIsChecked] = useState(value);

	const handleClick = () => {
		setIsChecked(!isChecked);
		onClick(!isChecked);
	};

	return (
		<div onClick={handleClick} className="toggle-container">
			<div className={`toggle ${isChecked && "checked"}`} />
		</div>
	);
};

export default Toggle;
