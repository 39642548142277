import { useState, useEffect, useContext, Fragment, useMemo } from "react";

import Folder from "components/folder/folder.component";
import Table from "components/table/table.component";
import SidebarFolder from "components/sidebar/sidebar-folder/sidebar-folder.component";
import Selector from "components/selector/selector.component";

import { FolderContext } from "src/contexts/folder.context";

import useTranslate from "src/hooks/use-translate";

import { AuthContext } from "src/contexts/auth.context";
import { ARCHIVE_PERIODS, YEARS } from "utils/lists.utils";

import { ReactComponent as Unlocked } from "assets/buttons/unlocked.svg";
import { ReactComponent as Locked } from "assets/buttons/locked.svg";

import "./archive.style.scss";

import YesNoBox from "components/yes-no-box/YesNoBox.component";
import Modal from "components/modal/modal.component";
import WarningBox from "components/warning-box/warning-box.component";
import { isObjectEmpty } from "utils/array.utils";

const Archive = () => {
    const { currentFolderLocked, currentLocation, files, focusedFiles, yearSelection, lockFolder, goBack, addFolder, updateFilesFromServer, setYear } =
        useContext(FolderContext);
    const {
        user: { archivePeriod },
    } = useContext(AuthContext);

    const archivePeriodName = useMemo(() => ARCHIVE_PERIODS[archivePeriod], [archivePeriod]);
    // console.warn({ files, focusedFiles, currentLocation }); // by ege

    const [breadcrumbs, setBreadcrumbs] = useState("");
    const [warning, setWarning] = useState(false);
    const [approved, setApproved] = useState(false);
    const [lockSuccesful, setLockSuccesful] = useState(false);
    const [showLockedWarning, setShowLockedWarning] = useState(false);
    const [showError, setShowError] = useState(false);

    const t = useTranslate("Archive");

    useEffect(() => {
        if (currentLocation.length !== 0) {
            let str = t(currentLocation[0]);
            if (currentLocation.length > 1) str += " > " + currentLocation.slice(1).join(" > ");
            setBreadcrumbs(str.toUpperCase());
        }
    }, [currentLocation, t]);

    const handleGoBack = () => {
        goBack();
    };

    const handleCreateFolder = () => {
        if (Object.keys(files).length >= 6) return;
        addFolder("New Folder", true);
    };

    const handleSelect = (value) => {
        setYear(value);
    };

    /**
     * Show warning before locking (if there is no lock)
     * lock operation/request is in useEffect hook after warning is shown
     */
    const showWarnningBeforeLocking = () => {
        if (!currentFolderLocked) setWarning(true);
    };

    function sortFoldersByName(foldersObj) {
        const keys = Object.keys(foldersObj);
        keys.sort((folder1, folder2) => {
            if (folder1.startsWith("PURCHASE")) {
                return -1;
            } else if (folder1.startsWith("SALES")) {
                if (folder2.startsWith("PURCHASE")) {
                    return 1;
                } else {
                    return -1;
                }
            } else if (folder1.startsWith("VAT")) {
                if (folder2.startsWith("PURCHASE") || folder2.startsWith("SALES")) {
                    return 1;
                } else {
                    return -1;
                }
            } else if (folder1.startsWith("FINANCE")) {
                if (folder2.startsWith("PURCHASE") || folder2.startsWith("SALES") || folder2.startsWith("VAT")) {
                    return 1;
                } else {
                    return -1;
                }
            } else if (folder1.startsWith("TAX")) {
                if (folder2.startsWith("PURCHASE") || folder2.startsWith("SALES") || folder2.startsWith("VAT") || folder2.startsWith("FINANCE")) {
                    return 1;
                } else {
                    return -1;
                }
            } else return 1;
        });
        const sortedObj = {};
        for (let key of keys) {
            sortedObj[key] = foldersObj[key];
        }
        return sortedObj;
    }

    useEffect(() => {
        // Lock request after the approval of shown warning
        (async () => {
            if (approved && !focusedFiles.isLocked && !currentFolderLocked) {
                // Lock the folder
                const error = await lockFolder();
                if (!error && approved) {
                    setApproved(false);
                    updateFilesFromServer();
                    setLockSuccesful(true);
                } else setShowError(true);
            }
        })();
    }, [approved, focusedFiles, lockFolder, updateFilesFromServer, currentFolderLocked]);

    return (
        <div className="home-container">
            {/* Left Panel */}
            <div className="left-panel" style={{ paddingLeft: 0 }}>
                {/* Archive folders and year selection are shown here */}
                <div className="header-container">
                    <h1 className="bold with-line">{t("archive")}</h1>
                </div>
                <div className="sidebar-folders-container padding-xl--inline">
                    <Selector data={YEARS} onSelected={handleSelect} className="selector" />
                </div>
                <div className="red text-center size400 w600 padding-m--inline padding-s--block border border--thick smooth-corners margin-s--top margin-l--bottom">
                    {archivePeriodName} {t("archive-period")}
                </div>
                <div className="sidebar-folders-container scroll-bar scroll-bar--overflow">
                    {/* Root folders are shown here */}
                    {Object.keys(sortFoldersByName(files)).map((folderName, index) => {
                        const childrenFolders = isObjectEmpty(files[folderName]?.folders) ? files[folderName]?.files : files[folderName]?.folders; // .files or .folders
                        const isEmpty = isObjectEmpty(childrenFolders);
                        return (
                            <div key={index} className="size300">
                                <SidebarFolder key={folderName} folderName={folderName} label={t(folderName)} isEmpty={isEmpty} />
                            </div>
                        );
                    })}
                </div>
                <div className="create-folder-button" onClick={handleCreateFolder}>
                    + {t("create folder")}
                </div>
            </div>

            {/* Right Panel */}
            <div className="right-panel scroll-bar--overflow flex flex--column">
                {currentLocation.length > 0 && (
                    <Fragment>
                        {breadcrumbs !== "" && (
                            // The current location and the lock is shown here
                            <div className="header-group">
                                <div className="home-header">
                                    <ion-icon onClick={handleGoBack} name="arrow-back-outline" />
                                    <div className="breadcrumbs">{breadcrumbs}</div>
                                </div>
                                {focusedFiles.isLocked ? (
                                    <Locked className="lock-btn pointer margin-s--inline" onClick={() => setShowLockedWarning(true)} />
                                ) : (
                                    <Unlocked className="lock-btn pointer margin-s--inline" onClick={showWarnningBeforeLocking} />
                                )}
                            </div>
                        )}
                        <div className="folders-container flex flex--start-items flex--wrap gap-l scroll-bar scroll-bar--overflow padding-m--right margin-m--right">
                            {!currentFolderLocked && <Folder addButton size="small" />}
                            {focusedFiles.folders.map(({ fileName, isEmpty, folderId, isLocked, isCustom }, index) => {
                                // All current folders are shown here
                                return (
                                    <Folder
                                        key={folderId + yearSelection}
                                        fileName={fileName}
                                        isCustomFolder={isCustom}
                                        isEmpty={isEmpty}
                                        folderId={folderId}
                                        size="small"
                                        locked={isLocked}
                                    />
                                );
                            })}
                        </div>
                        <Table />
                    </Fragment>
                )}
            </div>

            {/* Approvals and Warnings */}
            {warning && (
                <Modal modal={<YesNoBox setVisible={setWarning} setApproved={setApproved} text={t("lock-warning")} />} touchable dismiss={[setWarning]} />
            )}
            {lockSuccesful && <Modal modal={<WarningBox text={t("lock-successful")} isCheckmark />} closeIn3 dismiss={[setLockSuccesful, setApproved]} />}
            {showLockedWarning && <Modal modal={<WarningBox text={t("locked-already")} />} closeIn3 dismiss={[setShowLockedWarning]} />}
            {showError && <Modal modal={<WarningBox text={t("lock-error")} />} closeIn3 dismiss={[setShowError]} />}
        </div>
    );
};
export default Archive;
