import { useCallback, useState } from "react";

const useToggle = (initialState = false) => {
	// hook just like useState but it toggles the state (true or false)
	const [state, setState] = useState(initialState);

	// const toggle = () => setState(!state);
	const toggle = useCallback(() => setState((state) => !state), []);

	return [state, toggle];
};

export default useToggle;
