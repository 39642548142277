import { useContext, useEffect, useMemo } from "react";
import { NewClientContext } from "src/contexts/new-client.context";
import useTranslate from "src/hooks/use-translate";

const PagePersonal3_1 = ({ canContinue, toggle }) => {
    const {
        newCompanyData: { liveChat, mobile },
        updateNewCompanyData,
    } = useContext(NewClientContext);

    const t = useTranslate("NewClientModal");

    const FIELDS = useMemo(
        () => [
            {
                title: t("Live Chat"),
                info: t("Direct contact between the Client and Accountant through the live chat."),
                choices: [t("Inactivated"), t("Activated")],
                key: "liveChat",
                value: liveChat,
            },
            {
                title: t("Mobile App"),
                info: t("Mobile App"),
                choices: [t("Inactivated"), t("Activated")],
                key: "mobile",
                value: mobile,
            },
        ],
        [liveChat, mobile, t]
    );

    useEffect(() => {
        if (!canContinue && liveChat != null && mobile != null) toggle();
        if (canContinue && (liveChat == null || mobile == null)) toggle();
    }, [toggle, canContinue, liveChat, mobile]);

    const handleChange = (key, value) => {
        updateNewCompanyData(key, value);
    };

    return (
        <div className="page-container page5_1">
            <p className="size400 w600 margin-xl--top">
                {t("step")} 3.1 <span className="red">|</span> {t("Select Accounting Plans")}
            </p>
            <div className="flex flex--column gap-xxxl margin-m--top">
                {FIELDS.map(({ title, info, choices, key, value }, index) => {
                    return (
                        <div key={index}>
                            <p className="size300 w600 margin-s--bottom">{title}</p>
                            <p className="size200 margin-xl--bottom">{info}</p>
                            <div className="flex gap-m">
                                {choices.map((choice, index) => {
                                    return (
                                        <button
                                            key={choice}
                                            className={`btn smooth-corners padding-xxl--inline padding-s--block ${value === index && "btn--selected"}`}
                                            onClick={() => {
                                                handleChange(key, index);
                                            }}>
                                            {choice}
                                        </button>
                                    );
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default PagePersonal3_1;
