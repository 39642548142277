import { useContext, useEffect, useRef } from "react";

import FileContainer from "components/file-container/file-container.component";

import { FolderContext } from "src/contexts/folder.context";

import "./file-selector.style.scss";

const FileSelector = ({ index, setIndex, length, ref }) => {
    const {
        openedFile,
        openFile,
        focusedFiles: { files },
    } = useContext(FolderContext);
    const selectedRef = useRef(null);
    const containerRef = useRef(null);

    const handleOpenNext = () => {
        if (index === length) return;
        setIndex((index) => index + 1);
        openFile(files[index].fileName);
    };

    const handleOpenPrev = () => {
        if (index === 1) return;
        setIndex((index) => {
            openFile(files[index - 2].fileName);
            return index - 1;
        });
    };

    useEffect(() => {
        if (selectedRef.current && containerRef.current) {
            containerRef.current.scrollLeft = selectedRef.current.offsetLeft - 150;
        }
    });

    return (
        <div className="file-selector-container">
            <button onClick={handleOpenPrev}>
                <ion-icon id="scroll-button" name="chevron-back-outline" />
            </button>
            <div className="files-container" ref={containerRef}>
                {files.map(({ fileName }) => {
                    const isSelected = openedFile.name === fileName;
                    return <FileContainer key={fileName} fileName={fileName} selected={isSelected} refrnc={isSelected ? selectedRef : null} />;
                })}
            </div>
            <button onClick={handleOpenNext}>
                <ion-icon id="scroll-button" name="chevron-forward-outline" />
            </button>
        </div>
    );
};

export default FileSelector;
