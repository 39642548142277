import "./checkbox.style.scss";

const Checkbox = ({ label, id = 0, selected, handleClick = () => {} }) => {
    return (
        <div className="checkbox-container flex flex--center-items gap-s">
            {label}
            <input id={id} name="checkbox" onChange={handleClick} checked={selected} className="checkbox" type="checkbox" />
        </div>
    );
};

export default Checkbox;
