const dictionary = {
    Login: {
        en: {
            "welcome to metibox": "Welcome to metibox",
            email: "email",
            password: "password",
            login: "login",
            "remember me": "Remember me",
            "forgot password?": "Forgot Password?",
            "invalid email address or password": "Invalid email address or password",
            "email is wrong": "Email is wrong",
            "password is wrong": "Password is wrong",
            "passwords dont match": "Passwords do not match",
            "password length min 6": "Password must be at least 6 characters",
        },
        fr: {
            "welcome to metibox": "bienvenue sur Meti-box",
            email: "Adresse email",
            password: "Mot de passe",
            login: "Connexion",
            "remember me": "Souviens-toi de moi",
            "forgot password?": "Mot de passe oublié?",
            "invalid email address or password": "Adresse email ou mot de passe invalide ",
            "email is wrong": "L'adresse email est incorrecte",
            "password is wrong": "Le mot de passe est incorrect",
            "passwords dont match": "Les mots de passe ne correspondent pas",
            "password length min 6": "Le mot de passe doit comporter au moins 6 caractères",
        },
    },
    Verification: {
        en: {
            "Verify Your Code": "Verify Your Code",
            confirm: "CONFIRM",
        },
        fr: {
            "Verify Your Code": "Vérifiez votre code",
            confirm: "CONFIRMER",
        },
    },
    ForgotPassword: {
        en: {
            "reset your password": "reset your password",
            "username or email": "Username or Email",
            confirm: "CONFIRM",
            "Invalid email address": "invalid email address",
            "Invalid verification code": "Invalid verification code",
            send: "Send",
            "Verification code sent to the given email address.": "Verification code sent to the given email address.",
            email: "Email",
        },
        fr: {
            "reset your password": "réinitialiser votre mot de passe",
            "username or email": "Nom d’utilisateur ou email",
            confirm: "CONFIRMER",
            "invalid email address": "adresse email non valide",
            "Invalid verification code": "code de vérification invalide",
            send: "Envoyer",
            "Verification code sent to the given email address.": "Code de vérification envoyé à l'adresse e-mail indiquée.",
            email: "Email",
        },
    },
    Sidebar: {
        en: {
            Home: "Home",
            Chat: "Chat",
            Invoices: "Invoices",
            Trash: "Trash",
            Archive: "Archive",
            Notifications: "Notifications",
            Clients: "Clients",
            Tickets: "Tickets",
            Account: "Account",
            Profile: "Profile",
        },
        fr: {
            Home: "Page d’accueil",
            Chat: "Chat",
            Invoices: "Factures",
            Trash: "Corbeille",
            Archive: "Archive",
            Notifications: "Notifications",
            Clients: "Clients",
            Tickets: "Tickets",
            Account: "Compte",
        },
    },
    Topbar: {
        en: {
            Profile: "Profile",
        },
        fr: {
            Profile: "Profil",
        },
    },
    Archive: {
        en: {
            archive: "Archive",
            "archive-period": "Archive Period",
            PURCHASE: "PURCHASE",
            SALES: "SALES",
            BANKS: "BANKS",
            TAX: "TAX",
            FINANCE: "FINANCE",
            VAT: "VAT",
            "my docs": "My Docs",
            reference: "Reference",
            name: "Name",
            created: "Created",
            new: "New",
            count: "Count",
            "create folder": "Create Folder",
            page: "Page",
            "view file": "View File",
            "download file": "Download File",
            "move file": "Move",
            "delete file": "Delete",
            "view original file": "View Original File",
            "download original file": "Download Original File",
            "An error has occurred. Please try again!": "An error has occurred. Please try again!",
            "lock-warning":
                "You are about to lock this folder. After the locking operation if you wish to add new documents you need to open a ticket to your accountant to unlock this folder. Do you want to continue?",
            "lock-successful": "Successfully locked!",
            "unlock-successful": "Successfully unlocked!",
            "locked-already":
                "Since this folder is  already locked, you cannot make any modifications in this folder. If you wish unlock this folder, you need to open a ticket to your accountant.",
            "lock-error": "Error on locking folder",
            rename: "Rename",
            delete: "Delete",
            "error-deletebox": "You cannot delete Box folders",
            "error-renamebox": "You cannot rename Box folders",
            "error-deletefolder": "Error on deleting folder",
            "error-deletefile": "Error on deleting file",
            "Client type will be determined based on the size and revenue of the company.":
                "Client type will be determined based on the size and revenue of the company.",
            "The period which your accounts of ACHANTS, VENTES, and TVA will be examined by the client’s accountant.":
                "The period which your accounts of ACHANTS, VENTES, and TVA will be examined by the client’s accountant.",
            "The period which Client’s safe to be locked. The Client will get notifications about their upcoming safe locking deadlines.":
                "The period which Client’s safe to be locked. The Client will get notifications about their upcoming safe locking deadlines.",
            "Direct contact between the Client and Accountant through the live chat.":
                "Direct contact between the Client and Accountant through the live chat.",
            "Upload your documents just by scanning the pages using the METI Box mobile application. ":
                "Upload your documents just by scanning the pages using the METI Box mobile application. ",
            Subscription: "Subscription",
            "Archive Period": "Archive Period",
            "Safe Locking Period": "Safe Locking Period",
            "Live Chat": "Live Chat",
            "Mobile App": "Mobile App",
            plans: "Plans",
            "change-archive-period": "You are about to change Client’s Archive Period. Do you want to continue?",
            "change-subscription": "You are about to change Client’s Subscription Plan. Do you want to continue?",
            "change-safelocking-period": "You are about to change Client’s Safe Locking Period. Do you want to continue?",
            "change-chatbox": "You are about to change Client’s ChatBox Plan. Do you want to continue?",
            "change-mobileplan": "You are about to change Client’s Mobile App Plan. Do you want to continue?",
            Drag: "Drag",
            Click: "Click",
            "to move": "to move",
            "to open menu": "to open menu",
            download: "Download",
            "download-ref": "Download (Reference)",
            "delete-file": "Delete File",
            "delete-file-warning": "You are about to delete this file. Do you want to continue?",
            "New Folder": "New Folder",
            "My Docs": "My Docs",
        },
        fr: {
            archive: "Archive",
            "archive-period": "Période D'archivage",
            PURCHASE: "ACHATS",
            SALES: "VENTES",
            BANKS: "BANQUES",
            TAX: "FISCALE",
            FINANCE: "BANQUES",
            VAT: "TVA",
            "my docs": "Mes Documents",
            reference: "Référence",
            name: "Nom",
            created: "Créé",
            new: "Nouveau",
            count: "Compteur",
            "create folder": "Créer un dossier",
            page: "Page",
            "view file": "Afficher le fichier",
            "download file": "Télécharger le Fichier",
            "move file": "Déplacer le fichier",
            "delete file": "Supprimer le fichier",
            "view original file": "Afficher le Fichier D'origine",
            "download original file": "Télécharger le Fichier D'origine",
            "lock-warning":
                "Vous êtes sur le point de verrouiller ce dossier. Après l'opération de verrouillage si vous souhaitez ajouter de nouveaux documents vous devez ouvrir un ticket auprès de votre comptable pour qu'il déverrouille le dossier. Voulez-vous continuer?",
            "lock-successful": "Verrouillé avec succès !",
            "unlock-successful": "Déverrouillé avec succès !",
            "locked-already":
                "Étant donné que ce dossier est déjà verrouillé, vous ne pouvez pas apporter de modifications dans ce dossier. Si vous souhaitez déverrouiller ce dossier, vous devez ouvrir un ticket auprès de votre comptable.",
            "lock-error": "Erreur lors du verrouillage du dossier",
            rename: "Renommer",
            delete: "Supprimer",
            "error-deletebox": "Vous ne pouvez pas supprimer les dossiers Box",
            "error-renamebox": "Vous ne pouvez pas renommer les dossiers Box",
            "error-deletefolder": "Erreur lors de la suppression du fichier",
            "error-deletefile": "Erreur lors de la suppression du dossier",
            "Client type will be determined based on the size and revenue of the company.":
                "Le type de client sera déterminé en fonction de la taille et du chiffre d'affaires de l'entreprise.",
            "The period which your accounts of ACHANTS, VENTES, and TVA will be examined by the client’s accountant.":
                "La période pendant laquelle les comptes de ACHATS, VENTES et TVA seront examinés par le comptable du client.",
            "The period which Client’s safe to be locked. The Client will get notifications about their upcoming safe locking deadlines.":
                "La période pendant laquelle la caisse du client doit être verrouillé. Le client recevra des notifications sur les prochaines dates limites de verrouillage de sa caisse.",
            "Direct contact between the Client and Accountant through the live chat.": "Contact direct entre le client et le comptable via le chat en direct.",
            "Upload your documents just by scanning the pages using the METI Box mobile application. ":
                "Téléchargez vos documents en scannant simplement les pages à l'aide de l'application mobile METI Box.",
            Subscription: "Abonnement",
            "Archive Period": "Période D'archivage",
            "Safe Locking Period": "Période De verrouillage sécurisé",
            "Live Chat": "Chat en direct",
            "Mobile App": "Application mobile",
            plans: "Plans",
            "change-archive-period": "Vous êtes sur le point de modifier la période d'archivage du client. Voulez-vous continuer?",
            "change-subscription": "Vous êtes sur le point de modifier le plan d'abonnement du client. Voulez-vous continuer?",
            "change-safelocking-period": "Vous êtes sur le point de modifier la période de verrouillage de la caisse. Voulez-vous continuer?",
            "change-chatbox": "Vous êtes sur le point de modifier le plan ChatBox du client. Voulez-vous continuer?",
            "change-mobileplan": "Vous êtes sur le point de modifier le plan d'application mobile du client. Voulez-vous continuer?",
            Drag: "Faites glisser",
            Click: "Cliquez",
            "to move": "pour bouger",
            "to open menu": "pour ouvrir le menu",
            download: "Télécharger",
            "download-ref": "Télécharger (Référence)",
            "delete-file": "Supprimer le fichier",
            "delete-file-warning": "Vous êtes sur le point de supprimer ce fichier. Voulez-vous continuer?",
            "New Folder": "Nouveau Dossier",
            "My Docs": "Mes Docs",
        },
    },
    MySafe: {
        en: {
            "my safe": "My Safe",
            today: "Today",
            daily: "Daily",
            monthly: "Monthly",
            yearly: "Yearly",
            "from reference": "From Reference",
            "starting balance": "Starting Balance",
            "first balance": "First Balance",
            "balance change": "Balance Change",
            "current balance": "Current Balance",
            "final balance": "Final Balance",
            "print documents": "Print Documents",
            explanation: "Explanation",
            revenue: "Revenue",
            expense: "Expense",
            new: "New",
            save: "Save",
            date: "Date",
            balance: "Balance",
            day: "Day",
            month: "Month",
            year: "Year",
            Explanation: "EXPLANATION",
            Revenue: "REVENUE",
            Expense: "EXPENSE",
            Date: "DATE",
            "safe inactivated": "Safe is inactivated.",
            "contact accountant": "Please contact with your accountant to activate this feature.",
            "Client Safe Plan": "Client Safe Plan",
            Calendar: "Calendar",
            Periodic: "Periodic",
            "Select Format": "Select Format",
            "Select Period": "Select Period",
            "Select Week": "Select Week",
            "Select Month": "Select Month",
            "Select Year": "Select Year",
            "STARTING BALANCE": "STARTING BALANCE",
            DATE: "DATE",
            EXPLANATION: "EXPLANATION",
            REVENUE: "REVENUE",
            EXPENSE: "EXPENSE",
            "FINAL BALANCE": "FINAL BALANCE",
            SAFE: "SAFE",
            "SAFE TIMELINE": "SAFE TIMELINE",
            "PRINT DATE": "PRINT DATE",
            COMPANY: "COMPANY",
            Page: "Page",
            "negative-balance": "The final balance cannot be negative.",
            total: "Total",
            "remove-invoice-warning": "You are about to remove this invoice. Do you want to continue?",
            "save-invoice": "Save Invoice",
            "remove-invoice": "Remove Invoice",
            "save-invoice-warning": "You are about to save this invoice. Do you want to continue?",
        },
        fr: {
            "my safe": "Ma caisse",
            today: "Aujourd’hui",
            daily: "Quotidien",
            monthly: "Mensuel",
            yearly: "Annuel",
            "from reference": " À partir de la référence",
            "starting balance": "Solde de départ",
            "first balance": "Solde initial",
            "balance change": "Changement de solde",
            "current balance": "Solde actuel",
            "final balance": "Solde final",
            "print documents": "Imprimer les documents",
            explanation: "Description",
            revenue: "Revenu",
            expense: "Dépense",
            new: "Nouveau",
            save: "Sauvegarder",
            date: "Date",
            balance: "Solde",
            day: "Jour",
            month: "Mois",
            year: "Année",
            Explanation: "DESCRIPTION",
            Revenue: "REVENUE",
            Expense: "DÉPENSE",
            Date: "DATE",
            "safe inactivated": "Caisse inactive.",
            "contact accountant": "Veuillez contacter votre comptable pour activer cette fonctionnalité.",
            January: "Janvier",
            February: "Février",
            March: "Mars",
            April: "Avril",
            May: "Mai",
            June: "Juin",
            July: "Juillet",
            August: "Août",
            September: "Septembre",
            October: "Octobre",
            November: "Novembre",
            December: "Décembre",
            "Client Safe Plan": "Plan de la caisse client",
            Calendar: "Calendrier",
            Periodic: "Périodique",
            "Select Format": "Sélectionnez le format",
            "Select Period": "Sélectionnez la période",
            "Select Week": "Sélectionnez la semaine",
            "Select Month": "Sélectionnez le mois",
            "Select Year": "Sélectionnez l'année",
            "STARTING BALANCE": "SOLDE DE DÉPART",
            DATE: "DATE",
            EXPLANATION: "DESCRIPTION",
            REVENUE: "REVENUE",
            EXPENSE: "DÉPENSE",
            "FINAL BALANCE": "SOLDE FINAL",
            SAFE: "CAISSE",
            "SAFE TIMELINE": "CALENDRIER DE LA CAISSE",
            "PRINT DATE": "DATE D'IMPRESSION",
            COMPANY: "ENTREPRISE",
            Page: "Page",
            "negative-balance": "Le solde final ne peut pas être négatif.",
            total: "Total",
            "remove-invoice-warning": "Vous êtes sur le point de supprimer cette facture. Voulez-vous continuer?",
            "save-invoice": "Enregistrer la facture",
            "remove-invoice": "Supprimer la facture",
            "save-invoice-warning": "Vous êtes sur le point d'enregistrer cette facture. Voulez-vous continuer?",
        },
    },
    Trash: {
        en: {
            trash: "Trash",
            name: "Name",
            location: " Location",
            "date deleted": "Date Deleted",
            "item type": "Item Type",
            restore: "Restore",
            "delete forever": "Delete Forever",
            "empty trash": "Empty Trash",
            search: "Search",
            "empty trash now": "Empty Trash now",
            "documents that have been in trash more than 30 days will be automatically":
                "Documents that have been in Trash more than 30 days will be automatically",
            deleted: "deleted",
            "text Document": "Text Document",
            "file folder": "File Folder",
        },
        fr: {
            trash: "Corbeille",
            name: "Nom",
            location: "Emplacement",
            "date deleted": "Date de suppression",
            "item type": "Type d’article",
            restore: "Restaurer",
            "delete forever": "Supprimer définitivement",
            "empty trash": "Vider la corbeille",
            search: "Rechercher",
            "empty trash now": "Vider la corbeille maintenant",
            "documents that have been in trash more than 30 days will be automatically":
                "Les documents qui sont dans la corbeille depuis plus de 30 jours seront supprimés automatiquement",
            deleted: "supprimés",
            "text Document": "Document texte",
            "file folder": "Dossier de fichiers",
        },
    },
    Account: {
        en: {
            account: "Account",
            personal: "Personal",
            "my account": "My Account",
            settings: "Settings",
            profile: "Profile",
            "change job title": "Change Job Title",
            "current job title": "Current Job Title",
            "change your job title based on the updates in your career path in your company.":
                "Change your job title based on the updates in your career path in your company.",
            "change contact information": "Change Contact Information",
            "change contact information based on the recent changes in your phone number or email address.":
                "Change contact information based on the recent changes in your phone number or email address.",
            "log out": "Log out",
            company: "Company",
            information: "Information",
            people: "People",
            plans: "Plans",
            "account basics": "Account Basics",
            name: "Name",
            username: " Username",
            "access type": "Access Type",
            Member: "Member",
            Administrator: "Administrator",
            "work information": "Work Information",
            "job title": "Job Title",
            contact: "Contact",
            email: "Email",
            "email address": "Email Address",
            phone: "Phone",
            password: "Password",
            "change password": "Change Password",
            "use a password at least 8 characters long which includes letters, numbers, and symbols.":
                " Use a password at least 8 characters long which includes letters, numbers, and symbols",
            "current password": "Current Password",
            "new password": "New Password",
            "confirm new password": "Confirm New Password",
            save: "Save",
            language: "Language",
            notifications: "Notifications",
            "mobile push notifications": " Mobile push notifications",
            "receive push notifications on messages and reminders via your mobile app":
                "Receive push notifications on messages and reminders via your mobile app",
            "desktop push notifications": "Desktop push notifications",
            "receive push notifications on messages and reminders immediately via your desktop app":
                "Receive push notifications on messages and reminders immediately via your desktop app",
            "email push notifications": " Email push notifications",
            "receive email updates on messages and reminders": "Receive email updates on messages and reminders",
            "change the language used in the user interface.": "Change the language used in the user interface.",
            preferences: " Preferences",
            appearance: " Appearance",
            "customize how METI Box looks on your device": " Customize how METI Box looks on your device",
            light: "Light",
            dark: "Dark",
            specialties: " Specialties",
            location: " Location",
            "client since": " Client Since",
            "add new member": "Add New Member",
            remove: "Remove",
            "archive period": " Archive Period",
            "The period which your accounts of PURCHASES, SALES, and VAT will be examined by your accountants.":
                "The period which your accounts of PURCHASES, SALES, and VAT will be examined by your accountants.",
            "Please contact with your accountant to change your Archive Period.": "Please contact with your accountant to change your Archive Period.",
            "box archive": "Box Archive",
            "safe locking period": " Safe Locking Period",
            "The period which your safe to be locked. You will get notifications about your upcoming safe locking deadlines.":
                "The period which your safe to be locked. You will get notifications about your upcoming safe locking deadlines.",
            "weeks safe": "Weeks Safe",
            "month safe": "Month Safe",
            "inactivated safe": "Inactivated Safe",
            website: "Website",
            emplacement: "Emplacement",
            "Company Information": "Company Information",
            specialities: "Specialities",
            "My Profile": "My Profile",
            "Your passwords do not match.": "Your passwords do not match.",
            error: "An error occurred.",
            People: "People",
            Clients: "Clients",
            "delete-undone":
                "Deleting Client’s account will remove all of their records and information from C&D Compta’s METI Box database. Please inform your client that this cannot be undone.",
            "download-all-before-deleting": "Download all records and information related to the Client before deleting their account permanently.",
            download: "Download",
            continue: "Continue",
            advertisement: "Advertisement",
            "upper advertisement": "Upper Advertisement",
            "lower advertisement": "Lower Advertisement",
            "remove-image": "Remove image from Meti Box",
            "delete-user-warning": "You are about to remove this user. This operation cannot be undone. Do you want to continue?",
            "remove-person": "Remove Person",
            logout: "Logout",
            "logout-warning": "You are about to logout. Do you want to continue?",
        },
        fr: {
            account: "Compte",
            personal: "Personnel",
            "my account": "Mon compte",
            settings: "Paramètres",
            profile: "Profil",
            "My Profile": "Mon profil",
            "change job title": "Changer le titre du poste",
            "current job title": "Titre du poste actuel",
            "change your job title based on the updates in your career path in your company.":
                "Changez votre titre de poste en fonction des mises à jour de votre parcours professionnel dans votre entreprise.",
            "change contact information based on the recent changes in your phone number or email address.":
                "Modifiez les informations de contact en fonction des récentes modifications de votre numéro de téléphone ou de votre adresse e-mail.",
            "log out": "Se déconnecter",
            company: "Entreprise",
            information: "Information",
            people: "Personnes",
            plans: "Plans",
            "account basics": "Compte basic",
            name: "Nom",
            username: " Nom d’utilisateur",
            "access type": "Type d’accès",
            Member: "Membre",
            Administrator: "Administrateur",
            "work information": "Renseignements sur le travail",
            "job title": "Titre du poste",
            contact: "Contact",
            email: "Email",
            "email address": "Adresse e-mail",
            phone: "Téléphone",
            password: "Mot de passe",
            "change password": "Changer le mot de passe",
            "use a password at least 8 characters long which includes letters, numbers, and symbols.":
                " Utilisez un mot de passe d’au moins 8 caractères comprenant des lettres, des chiffres et des symboles",
            "current password": "Mot de passe actuel",
            "new password": "Nouveau mot de passe",
            "confirm new password": "Confirmer le nouveau mot de passe",
            save: "Sauvegarder",
            language: "Langue",
            notifications: "Notifications",
            "mobile push notifications": "Notifications sur le mobile",
            "receive push notifications on messages and reminders via your mobile app":
                "Recevez des notifications comme les messages et les rappels via votre application mobile",
            "desktop push notifications": "Notifications sur ordinateur",
            "receive push notifications on messages and reminders immediately via your desktop app":
                "Recevez des notifications comme les messages et les rappels sur votre ordinateur",
            "email push notifications": "Notifications par e-mail",
            "receive email updates on messages and reminders": "Recevoir des mises à jour par email sur les messages et les rappels",
            "change the language used in the user interface.": "Changez la langue utilisée dans l’interface utilisateur.",
            preferences: "Préférences",
            appearance: "Apparence",
            "customize how METI Box looks on your device": "Personnaliser l’apparence de METI Box sur votre appareil",
            light: "Clair",
            dark: "Foncé",
            specialties: "Spécialités",
            location: "Emplacement",
            "client since": "Client depuis",
            "add new member": "Ajouter un nouveau membre",
            remove: "Supprimer",
            "archive period": "Période d’archivage",
            "The period which your accounts of PURCHASES, SALES, and VAT will be examined by your accountants.":
                "La période pendant laquelle vos dossiers ACHATS, VENTES et TVA seront examinés par vos comptables.",
            "Please contact with your accountant to change your Archive Period.": "Veuillez contacter votre comptable pour modifier votre période d’archivage.",
            "box archive": "Archive box",
            "safe locking period": " Période de verrouillage de la caisse",
            "The period which your safe to be locked. You will get notifications about your upcoming safe locking deadlines.":
                "La période pendant laquelle votre caisse doit être verrouillée. Vous recevrez des notifications sur vos prochaines échéances de verrouillage sécurisé.",
            "weeks safe": "Caisse hebdomadaire",
            "month safe": "Caisse mensuelle",
            "inactivated safe": "Caisse inactive",
            website: "Site web",
            emplacement: "Emplacement",
            "Company Information": "Information sur l'entreprise",
            specialities: "Spécialités",
            "Your passwords do not match.": "Vos mots de passe ne correspondent pas.",
            error: "Une erreur s'est produite.",
            People: "Personnes",
            Clients: "Clients",
            "delete-undone":
                "La suppression du compte du client supprimera toutes les données et les informations de la base de données METI-Box C&D Compta. Veuillez informer votre client que cela ne peut pas être annulé.",
            "download-all-before-deleting": "Téléchargez tous les enregistrements et informations liés au client avant de supprimer définitivement son compte.",
            download: "Télécharger",
            continue: "Continuer",
            advertisement: "Publicité",
            "upper advertisement": "Publicité du haut",
            "lower advertisement": "Publicité du bas",
            "remove-image": "Supprimer l’image de Meti Box",
            "delete-user-warning": "Vous êtes sur le point de supprimer cet utilisateur. Cette opération ne peut pas être annulée. Voulez-vous continuer?",
            "remove-person": "Supprimer la personne",
            logout: "Se déconnecter",
            "logout-warning": "Vous êtes sur le point de vous déconnecter. Voulez-vous continuer?",
        },
    },
    Clients: {
        en: {
            myclients: "My Clients",
            "add new client": "Add New Client",
            Administrator: "Administrator",
            Member: "Member",
            back: "Back",
            finish: "Finish",
            continue: "Continue",
            all: "All",
            filter: "Filter",
            "there are no clients": "There are no clients",
            "Client Company": "Client Company",
            "ID Number": "ID Number",
            notifications: "Notifications",
            "Good Morning": "Good Morning",
            "company information": "Company Information",
            "Company Information": "Company Information",
            Plans: "Plans",
            People: "People",
            "Delete Client": "Delete Client",
            people: "People",
            notes: "Notes",
            archive: "Archive",
            safe: "Safe",
            "clear all": "Clear All",
            "Client Type": "Client Type",
            Subscription: "Subscription",
            "Archive Period": "Archive Period",
            "Safe Locking Period": "Safe Locking Period",
            "Live Chat": "Live Chat",
            "Mobile App": "Mobile App",
            Gold: "Gold",
            Silver: "Silver",
            Bronze: "Bronze",
            "2 Weeks": "2 Weeks",
            "1 Month": "1 Month",
            "3 Months": "3 Months",
            "6 Months": "6 Months",
            Activated: "Activated",
            Inactivated: "Inactivated",
            "my clients": "My Clients",
            "all clients": "All Clients",
            "add new people": "Add New People",
            "edit id": "Edit ID",
            "duplicate id": "The ID number already exists.",
            "remove-notif-warning": "You are about to remove this notification. Do you want to continue?",
            Name: "Name",
            "Job Title": "Job Title",
            Username: "Username",
            Specialities: "Specialities",
            "VAT Number": "VAT Number",
            Website: "Website",
            Email: "Email",
            "Client Since": "Client Since",
            Location: "Location",
            Phone: "Phone",
            "Accounting Plan": "Accounting Plan",
            Save: "Save",
            Cancel: "Cancel",
            "Representative Color": "Representative Color",
            "choose-color": "Please choose a representative color for your company based on your logo/brand colors.",
            "Client Information": "Client Information",
        },
        fr: {
            myclients: "Mes Clients",
            "add new client": "Nouveau client",
            Administrator: "Administrateur",
            Member: "Membre",
            back: "Retour",
            finish: "Terminer",
            continue: "Continuer",
            all: "Tous",
            filter: "Filtre",
            "there are no clients": "Il n’y a pas de client",
            "Client Company": "Société cliente",
            "ID Number": "Numéro d’identification",
            notifications: "Notifications",
            "Good Morning": "Bonjour",
            "company information": "Information sur la société",
            "Company Information": "Information sur l'entreprise",
            Plans: "Plans",
            People: "Personnes",
            "Delete Client": "Supprimer le client",
            people: "Personnes",
            notes: "Notes",
            archive: "Archives",
            safe: "Caisse",
            "clear all": "Tout effacer",
            "Client Type": "Type de client",
            Subscription: "Abonnement",
            "Archive Period": "Période d’archivage",
            "Safe Locking Period": "Période de verrouillage de la caisse",
            "Live Chat": "Chat en direct",
            "Mobile App": "App mobile",
            Gold: "Or",
            Silver: "Argent",
            Bronze: "Bronze",
            "2 Weeks": "2 semaines",
            "1 Month": "1 mois",
            "3 Months": "3 mois",
            "6 Months": "6 mois",
            Activated: "Activé",
            Inactivated: "Désactivé",
            "my clients": "Mes Clients",
            "all clients": "Tous les clients",
            "add new people": "Ajouter de nouvelles personnes",
            "edit id": "Modifier l’ID",
            "duplicate id": "Le numéro d’identification existe déjà.",
            "remove-notif-warning": "Vous êtes sur le point de supprimer cette notification. Voulez-vous continuer?",
            Name: "Nom",
            "Job Title": "Titre du poste",
            Username: "Nom d’utilisateur",
            Specialities: "Spécialités",
            "VAT Number": "Numéro de TVA",
            Website: "Site web",
            Email: "Email",
            "Client Since": "Client depuis",
            Location: "Emplacement",
            Phone: "Téléphone",
            "Accounting Plan": "Plan comptable",
            Save: "Sauvegarder",
            Cancel: "Annuler",
            "Representative Color": "Couleur représentative",
            "choose-color": "Veuillez choisir une couleur représentative pour votre entreprise en fonction des couleurs de votre logo / marque.",
            "Client Information": "Information sur le client",
        },
    },
    Tickets: {
        en: {
            Tickets: "Tickets",
            "There are no awaiting tickets": "There are no awaiting tickets",
            OPEN: "OPEN",
            CLOSED: "CLOSED",
            Delete: "Delete",
            "Create Ticket": "Create Ticket",
            Subject: "Subject",
            "Ticket ID": "Ticket ID",
            Status: "Status",
            "Created Date": "Created Date",
            "Last Activity": "Last Activity",
            "Requested By": "Requested By",
            "assigned to": "Assigned to",
            "New Ticket": "New Ticket",
            "You already selected a single client": "You already selected a single client",
            "Select the person in the company": "Select the person in the company",
            "Type the topic you want support from your untant...": "Type the topic you want support from your accountant...",
            Client: "Client",
            "Type the name of the company/client": "Type the name of the company/client",
            "download-all": "Download All",
        },
        fr: {
            Tickets: "Tickets",
            "There are no awaiting tickets": "Il n’y a pas de tickets en attente",
            OPEN: "OUVERT",
            CLOSED: "FERMÉ",
            Delete: "Supprimer",
            "Create Ticket": "Créer un ticket",
            Subject: "Objet",
            "Ticket ID": "ID du ticket",
            Status: "Statut",
            "Created Date": "Date de création",
            "Last Activity": "Dernière activité",
            "Requested By": "Demandé par",
            "assigned to": "Destiné à",
            "New Ticket": "Nouveau ticket",
            "You already selected a single client": "Vous avez déjà sélectionné un seul client",
            "Select the person in the company": "Sélectionnez la personne dans l’entreprise",
            "Type the topic you want support from your accountant...": "Tapez le sujet pour lequel vous souhaitez obtenir de l’aide de votre comptable...",
            Client: "Client",
            "Type the name of the company/client": "Tapez le nom de la société/client",
            "download-all": "Tout télécharger",
        },
    },
    NewClientModal: {
        en: {
            "Choose an account type": "Choose an account type",
            Personal: "Personal",
            Company: "Company",
            website: "Website",
            name: "Name",
            surname: "Surname",
            username: "Username",
            email: "Email",
            phone: "Phone",
            "job title": "Job Title",
            "vat number": "VAT Number",
            "register the client": "Register the client",
            step: "Step",
            gold: "Gold",
            silver: "Silver",
            bronze: "Bronze",
            digits: "digits",
            Country: "Country",
            "country of this person": "Country of this person",
            City: "City",
            "city of this person": "City of this person",
            Address: "Address",
            "address of this person": "Address of this person",
            "zip code": "Zip Code",
            "Postal Zip Code": "Postal Zip Code",
            "Please choose a representative color for yourself": "Please choose a representative color for yourself",
            "Please choose a representative color for your company based on your logo/brand colors":
                "Please choose a representative color for your company based on your logo/brand colors",
            "Representative Color": "Representative Color",
            Specialities: "Specialities",
            "what does this company do?": "What does this company do?",
            "Register the Company": "Register the Company",
            "Archive Period": "Archive Period",
            "The period which Client’s accounts of ACHANTS, VENTES, and TVA will be examined by the accountant.":
                "The period which Client’s accounts of ACHANTS, VENTES, and TVA will be examined by the accountant.",
            "Safe Locking Period": "Safe Locking Period",
            "The period which Client’s safe to be locked. Client will get notifications about their upcoming safe locking deadlines.":
                "The period which Client’s safe to be locked. Client will get notifications about their upcoming safe locking deadlines.",
            Weeks: "Weeks",
            Month: "Month",
            Months: "Months",
            Activated: "Activated",
            Inactivated: "Inactivated",
            "Live Chat": "Live Chat",
            "Direct contact between the Client and Accountant through the live chat.":
                "Direct contact between the Client and Accountant through the live chat.",
            "Mobile App": "Mobile App",
            "Select Accounting Plans": "Select Accounting Plans",
            "client type": "Client Type",
            "Type the email of the accountant you want to assign this ticket to...": "Type the email of the accountant you want to assign this ticket to...",
            "Type the topic you want support from your accountant...": "Type the topic you want support from your accountant...",
            "Type the name of the company/client": "Type the name of the company/client",
            "You already selected a single client": "You already selected a single client",
            "Select the person in the company": "Select the person in the company",
            "max3-admin":
                "The maximum number of 3 administrator can be set for a company. Please select the amount of administrators that will be created for the company.",
            "determine-admins": "Determine the Administrator/s",
            "email-exists": "The email you entered already exists.",
            "Choose the access type of the account...": "Choose the access type of the account...",
            Administrator: "Administrator",
            Member: "Member",
            "Select Accounting Plans for the Company": "Select Accounting Plans for the Company",
        },
        fr: {
            "Choose an account type": "Choisissez un type de compte",
            Personal: "Personne Physique",
            Company: "Entreprise",
            website: "Site web",
            name: "Nom",
            surname: "Prénom",
            username: "Nom d’utilisateur",
            email: "Email",
            phone: "Téléphone (utilisez le code du pays)",
            "job title": "Titre du poste",
            "vat number": "Numéro de TVA",
            step: "Étape",
            gold: "Or",
            silver: "Argent",
            bronze: "Bronze",
            digits: "nombres",
            Country: "Pays",
            "country of this person": "Pays de cette personne",
            City: "Ville",
            "city of this person": "Ville de cette personne",
            Address: "Adresse",
            "address of this person": "Adresse de cette personne",
            "zip code": "Code postal",
            "Postal Zip Code": "Code postal",
            "Please choose a representative color for yourself": "Veuillez choisir une couleur représentative pour vous",
            "Please choose a representative color for your company based on your logo/brand colors":
                "Veuillez choisir une couleur représentative pour votre entreprise en fonction de vos couleurs de logo / marque",
            Specialities: "Spécialités",
            "what does this company do?": "Quelle est l'activité de l'entreprise?",
            "Register the Company": "Enregistrer l’entreprise",
            "Representative Color": "Couleur représentative",
            "Archive Period": "Période d’archivage",
            "The period which Client’s accounts of ACHANTS, VENTES, and TVA will be examined by the accountant.":
                "La période pendant laquelle les dossiers ACHATS, VENTES et TVA seront examinés par le comptable.",
            "Safe Locking Period": "Période de verrouillage de la caisse",
            "The period which Client’s safe to be locked. Client will get notifications about their upcoming safe locking deadlines.":
                "La période pendant laquelle la caisse du client doit être verrouillée. Le client recevra des notifications sur ses prochaines échéances de verrouillage sécurisé.",
            Weeks: "Semaines",
            Month: "Mois",
            Months: "Mois",
            Activated: "Activé",
            Inactivated: "Inactif",
            "Live Chat": "Chat en direct",
            "Direct contact between the Client and Accountant through the live chat.": "Contact direct entre le client et le comptable via le chat en direct.",
            "Mobile App": "Application mobile",
            "Select Accounting Plans": "Sélectionnez les plans comptables",
            "client type": "Type de client",
            "Type the email of the accountant you want to assign this ticket to...": "Tapez l’email du comptable auquel vous souhaitez attribuer ce ticket...",
            "Type the topic you want support from your accountant...": "Tapez le sujet dont vous souhaitez obtenir le soutien de votre comptable...",
            "Type the name of the company/client": "Tapez le nom de la société / du client",
            "You already selected a single client": "Vous avez déjà sélectionné un seul client",
            "Select the person in the company": "Sélectionnez la personne dans l’entreprise",
            "max3-admin":
                "Le nombre maximum de 3 administrateurs peut être défini pour une entreprise. Veuillez sélectionner le nombre d’administrateurs qui seront créés pour l’entreprise.",
            "determine-admins": "Déterminer l’administrateur / les administrateurs",
            "email-exists": "L’email que vous avez entré existe déjà.",
            "Choose the access type of the account...": "Choisissez le type d’accès du compte...",
            Administrator: "Administrateur",
            Member: "Membre",
            "Select Accounting Plans for the Company": "Sélectionnez les plans des comptables pour l’entreprise",
        },
    },
    Chat: {
        en: {
            "Chat Box": "Chat Box",
        },
        fr: {
            "Chat Box": "Chat Box",
        },
    },
    YesNoBox: {
        en: {
            Cancel: "Cancel",
        },
        fr: {
            Cancel: "Annuler",
        },
    },
};

export default dictionary;
