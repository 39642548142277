import { useMemo, useState } from "react";
import { IoIosSettings } from "react-icons/io";

import InfoTab from "./tabs/information/information-tab.component";
import PeopleTab from "./tabs/people/people-tab.component";
import NotesTab from "./tabs/notes/notes-tab.component";
import ArchiveTab from "./tabs/archive/archive-tab.component";
import SafeTab from "./tabs/safe/safe-tab.component";
import SettingsTab from "./tabs/settings/settings-tab.component";

import "./row-data.style.scss";
import useTranslate from "src/hooks/use-translate";

const RowData = ({ data, index, id }) => {
    const TABS = useMemo(
        () => ({
            info: {
                name: "company information",
                component: <InfoTab id={id} isCompany={data?.firmId !== -1 ?? true} />,
            },
            people: {
                name: "people",
                component: <PeopleTab id={id} />,
            },
            notes: {
                name: "notes",
                component: <NotesTab data={data} index={index} id={data.userId ?? data.firmId} isCompany={data?.userId != undefined ? false : true} />,
            },
            archive: { name: "archive", component: <ArchiveTab index={index} id={data.userId ?? data.firmId} /> },
            safe: { name: "safe", component: <SafeTab index={index} id={data.userId ?? data.firmId} /> },
            settings: {
                name: <IoIosSettings />,
                component: <SettingsTab isPersonalAccount={data.companyType === 0} id={data.userId ?? data.firmId} />,
            },
        }),
        [data, index, id]
    );

    const [selectedTab, setSelectedTab] = useState("info");
    const t = useTranslate("Clients");
    const handleSelect = (tab) => {
        setSelectedTab(tab);
    };

    return (
        <div className="row-data-container">
            <div className="tab-bar unselectable">
                {Object.keys(TABS).map((tab, index) => {
                    return (
                        <div
                            key={index}
                            onClick={() => {
                                handleSelect(tab);
                            }}
                            className={`size400 w500 tab-button ${tab === selectedTab && "selected"}`}>
                            {t(TABS[tab].name)}
                        </div>
                    );
                })}
            </div>
            <div className="tab-container scroll-bar scroll-bar--overflow">{TABS[selectedTab]?.component}</div>
        </div>
    );
};

export default RowData;
