import { useContext } from "react";
import { FiLock } from "react-icons/fi";
import { CalendarContext } from "src/contexts/calendar.context";

import "../calendar.style.scss";
import { InvoiceContext } from "src/contexts/invoice.context";

const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

const MonthView = () => {
    const { shownMonth, shownYear, changeMonth } = useContext(CalendarContext);
    const { calculatedBalanceChange } = useContext(InvoiceContext);

    const handleClick = (month, year) => {
        changeMonth(month, year);
    };

    return (
        <div className="month-view-wrapper">
            {months.map((month, index) => {
                // const date = new Date();
                // const thisYear = date.getFullYear();
                // const locked = thisYear > shownYear ? true : index < shownMonth && thisYear === shownYear;
                const locked = false;
                return (
                    <div
                        key={index}
                        onClick={() => handleClick(index, shownYear)}
                        className={`month 
						${shownMonth === index && "selected "}
						${shownMonth === index && parseInt(calculatedBalanceChange) > 0 && "green"}
						${shownMonth === index && parseInt(calculatedBalanceChange) < 0 && "red"}
						${locked && "locked"}
						`}
                        // ${shownMonth == index && shownYear == selectedDay.getFullYear() && "selected"} // can be used for current month style later
                    >
                        {month}
                        {locked && <FiLock className="lock-icon" />}
                    </div>
                );
            })}
        </div>
    );
};

export default MonthView;
