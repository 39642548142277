import { Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import DraggableList from "react-draggable-list";
import { useMenuState } from "@szhsin/react-menu";

import ContextMenu from "components/context-menu/context-menu.component";
import { ReactComponent as Handle } from "assets/drag-handle.svg";

import { FolderContext, getFileLinkFromServer } from "src/contexts/folder.context";

import "./table.style.scss";
import useTranslate from "src/hooks/use-translate";
import Modal from "components/modal/modal.component";
import WarningBox from "components/warning-box/warning-box.component";
import { AuthContext } from "src/contexts/auth.context";
import axios from "axios";
import { ClientsContext } from "src/contexts/clients.context";
import { isObjectEmpty } from "utils/array.utils";
import { AiOutlineSortAscending, AiOutlineSortDescending } from "react-icons/ai";
import YesNoBox from "components/yes-no-box/YesNoBox.component";

const COLUMNS = [
    { field: "ref", name: "reference", flex: 3 },
    { field: "fileName", name: "name", flex: 10 },
    { field: "createdAt", name: "created", flex: 6 },
];

const SORT_DIRECTIONS = {
    ascending: "ascending",
    descending: "descending",
    none: "none",
};

// Sub Component of Item
const HoverMenu = ({ className }) => {
    const t = useTranslate("Archive");
    return (
        <div className={`${className} flex flex--column flex--center-content size300 padding-s--inline smooth-corners`}>
            <p className="line">
                <span className="first-word">{t("Drag")}</span> {t("to move")}
            </p>
            <p className="line">
                <span className="first-word">{t("Click")}</span> {t("to open menu")}
            </p>
        </div>
    );
};

// Sub Component of Table
const Item = ({ item, itemSelected, dragHandleProps }, context) => {
    const { ref, fileName, createdAt, fileId } = item;

    const { currentFolderLocked, currentLocation, yearSelection, openFile, deleteFile, setFileToBeMoved, getUpdatedFile, updateRef } =
        useContext(FolderContext);
    const { user } = useContext(AuthContext);
    const { openedClient } = useContext(ClientsContext);

    const [menuProps, toggleMenu] = useMenuState();
    const { onMouseDown, onTouchStart } = dragHandleProps;
    const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });

    const [showLockedWarning, setShowLockedWarning] = useState(false);
    const [refInput, setRefInput] = useState(ref ?? "");
    const [fileNameInput, setFileNameInput] = useState(fileName ?? "");

    const [showYesNo, setShowYesNo] = useState(false);
    const [approvedDeleteFile, setApprovedDeleteFile] = useState(false);

    const timeoutRef = useRef();

    const t = useTranslate("Archive");

    useEffect(() => {
        if (!ref) return;
        setRefInput(ref);
    }, [ref]);

    const handleOpenFile = useCallback(() => {
        openFile(fileName);
    }, [fileName, openFile]);

    const handleOpenOriginalFile = useCallback(() => {
        openFile(fileName, true);
    }, [fileName, openFile]);

    const handleDownloadFile = useCallback(async () => {
        const path = yearSelection + "/" + currentLocation.join("/") + "/" + fileName;
        let link;
        if (!isObjectEmpty(openedClient)) link = await getFileLinkFromServer(openedClient.userId, openedClient.firmId, path);
        else link = await getFileLinkFromServer(user.userId, user.firmId, path);
        const name = ref === "NA" ? fileName : ref + " - " + fileName;
        const file = await fetch(link)
            .then((response) => response.blob())
            .then((blob) => {
                const file = new File([blob], name, { type: blob.type });
                return file;
            })
            .catch((error) => {
                console.error(error);
            });
        const fileURL = window.URL.createObjectURL(await getUpdatedFile(file, ref));
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = name;
        alink.click();
    }, [fileName, ref, getUpdatedFile, currentLocation, yearSelection, user.userId, user.firmId, openedClient]);

    const handleDownloadOriginalFile = useCallback(async () => {
        const path = yearSelection + "/" + currentLocation.join("/") + "/" + fileName;
        let fileURL;
        if (!isObjectEmpty(openedClient)) fileURL = await getFileLinkFromServer(openedClient.userId, openedClient.firmId, path);
        else fileURL = await getFileLinkFromServer(user.userId, user.firmId, path);

        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = fileName;
        alink.click();
    }, [fileName, currentLocation, yearSelection, user.userId, user.firmId, openedClient]);

    const handleMoveFile = useCallback(() => {
        setFileToBeMoved(fileName);
    }, [fileName, setFileToBeMoved]);

    const handleDeleteFile = useCallback(
        (e, fromMenu = false) => {
            if (!fromMenu) e.stopPropagation();
            if (currentFolderLocked) {
                setShowLockedWarning(true);
                return;
            }
            setShowYesNo(true);
        },
        [currentFolderLocked, setShowYesNo]
    );

    const handleContextMenu = useCallback(
        (e) => {
            e.preventDefault();
            setAnchorPoint({ x: e.clientX, y: e.clientY });
            toggleMenu(true);
        },
        [toggleMenu]
    );

    const updateRefValue = useCallback(() => {
        if (ref === refInput) return;
        if (!fileId) return;
        axios.post("updateRef", {
            userId: user.userId,
            firmId: user.firmId,
            ref: refInput,
            docId: fileId,
        });
        updateRef(refInput, fileId, undefined, user.accountant === -1);
    }, [refInput, user, fileId, ref, updateRef]);

    const updateFileNameValue = useCallback(() => {
        if (fileName === fileNameInput) return;
        if (!fileId) return;
        axios.post("updateFileName", {
            userId: user.userId,
            firmId: user.firmId,
            docId: fileId,
            newName: fileNameInput,
            oldKey: isObjectEmpty(openedClient)
                ? (user.firmId === -1 ? "0" + user.userId : "1" + user.firmId) + "/" + yearSelection + "/" + currentLocation.join("/") + "/" + fileName
                : (openedClient.firmId === -1 ? "0" + openedClient.userId : "1" + openedClient.firmId) +
                  "/" +
                  yearSelection +
                  "/" +
                  currentLocation.join("/") +
                  "/" +
                  fileName,
            newKey: isObjectEmpty(openedClient)
                ? (user.firmId === -1 ? "0" + user.userId : "1" + user.firmId) + "/" + yearSelection + "/" + currentLocation.join("/") + "/" + fileNameInput
                : (openedClient.firmId === -1 ? "0" + openedClient.userId : "1" + openedClient.firmId) +
                  "/" +
                  yearSelection +
                  "/" +
                  currentLocation.join("/") +
                  "/" +
                  fileNameInput,
        });
        // updateFileName(refInput, fileId, undefined, user.accountant === -1);
    }, [user, fileId, fileName, fileNameInput, currentLocation, yearSelection, openedClient]);

    const contextMenuList = useMemo(
        () => [
            {
                label: t("view file"),
                onClick: handleOpenFile,
            },
            {
                label: t("view original file"),
                onClick: handleOpenOriginalFile,
            },
            {
                label: t("download file"),
                onClick: handleDownloadFile,
            },
            {
                label: t("download original file"),
                onClick: handleDownloadOriginalFile,
            },
            {
                label: t("move file"),
                onClick: handleMoveFile,
            },
            {
                label: t("delete file"),
                onClick: (e) => handleDeleteFile(e, true),
            },
        ],
        [t, handleOpenFile, handleOpenOriginalFile, handleDownloadFile, handleDownloadOriginalFile, handleMoveFile, handleDeleteFile]
    );

    useEffect(() => {
        if (approvedDeleteFile) {
            deleteFile(fileName); // delete the file from the table
            setApprovedDeleteFile(false);
        }
    }, [approvedDeleteFile, deleteFile, fileName]);

    return (
        <Fragment>
            <div className={`body-item grid pointer padding-m--block ${itemSelected && "selected-row"}`} onClick={handleOpenFile}>
                <span
                    className="drag-handler pointer text-center"
                    onTouchStart={(e) => {
                        e.preventDefault();
                        onTouchStart(e);
                    }}
                    onMouseDown={(e) => {
                        e.stopPropagation();
                        if (e.button !== 0) return; // if not left click
                        timeoutRef.current = setTimeout(() => {
                            onMouseDown(e);
                        }, 300);
                    }}
                    onMouseUp={(e) => {
                        e.stopPropagation();
                        clearTimeout(timeoutRef.current);
                    }}
                    onMouseLeave={(e) => {
                        e.stopPropagation();
                    }}
                    onClick={(e) => {
                        e.stopPropagation();
                        if (itemSelected) return;
                        handleContextMenu(e);
                    }}
                    onContextMenu={handleContextMenu}>
                    <Handle className="handle" style={{ height: "1.2rem" }} />
                    <HoverMenu className="hover-menu" />
                </span>
                {user.role === 0 ? (
                    <input
                        className="ref-input"
                        value={refInput}
                        onChange={(e) => setRefInput(e.target.value)}
                        onBlur={updateRefValue}
                        onClick={(e) => e.stopPropagation()}
                    />
                ) : (
                    <span>{ref ?? ""}</span>
                )}
                <input
                    className="filename-input"
                    value={fileNameInput}
                    onChange={(e) => setFileNameInput(e.target.value)}
                    onBlur={updateFileNameValue}
                    onClick={(e) => e.stopPropagation()}
                />
                {/* <span className="wrap" onClick={handleOpenFile}>
                    {fileName}
                </span> */}
                <span>{createdAt}</span>
                <span onClick={handleDeleteFile}>X</span>
            </div>
            <ContextMenu list={contextMenuList} menuProps={menuProps} toggleMenu={toggleMenu} anchorPoint={anchorPoint} />
            {showLockedWarning && <Modal modal={<WarningBox text={t("locked-already")} />} closeIn3 dismiss={[setShowLockedWarning]} />}
            {showYesNo && (
                <Modal
                    modal={
                        <YesNoBox
                            setVisible={setShowYesNo}
                            setApproved={setApprovedDeleteFile}
                            text={t("delete-file-warning")}
                            rightOption={t("delete-file")}
                            rightOptionRed
                        />
                    }
                    touchable
                    dismiss={[setShowYesNo]}
                    fullscreen
                />
            )}
        </Fragment>
    );
};

// Main Component
const Table = () => {
    const { focusedFiles, currentFolderLocked, addFile, sortFiles } = useContext(FolderContext);
    const {
        user: { accountant },
    } = useContext(AuthContext);
    const [rows, setRows] = useState([]);
    const [showLockedWarning, setShowLockedWarning] = useState(false);
    const [sort, setSort] = useState(["", SORT_DIRECTIONS.none]); // [sortKey, direction]

    const t = useTranslate("Archive");

    const inputRef = useRef();
    const containerRef = useRef();

    useEffect(() => {
        setRows(() => focusedFiles.files);
    }, [focusedFiles]);

    const handleListChange = (newList) => {
        sortFiles(newList, accountant === -1);
    };

    const handleClick = () => {
        if (currentFolderLocked) {
            setShowLockedWarning(true);
            return;
        }
        if (!inputRef) return;
        inputRef.current.click();
    };

    const handleNewFile = (event) => {
        if (event.target.files === "") return;
        for (const file of event.target.files) {
            addFile(file);
        }
    };

    const changeSort = useCallback(
        (sortKey) => {
            if (sort[0] !== sortKey) {
                setSort([sortKey, SORT_DIRECTIONS.ascending]);
                return SORT_DIRECTIONS.ascending;
            }
            if (sort[1] === SORT_DIRECTIONS.ascending) {
                setSort([sortKey, SORT_DIRECTIONS.descending]);
                return SORT_DIRECTIONS.descending;
            }
            if (sort[1] === SORT_DIRECTIONS.descending) {
                setSort(["", SORT_DIRECTIONS.none]);
                return SORT_DIRECTIONS.none;
            }
        },
        [sort]
    );

    const handleSort = useCallback(
        (sortKey) => {
            const direction = changeSort(sortKey);
            setRows((prevData) => {
                if (direction === SORT_DIRECTIONS.none) return focusedFiles.files;

                const tempData = [...prevData];
                tempData.sort((row1, row2) => {
                    const a =
                        sortKey === "createdAt"
                            ? new Date(row1[sortKey])
                            : sortKey === "ref" && !isNaN(row1[sortKey])
                            ? parseInt(row1[sortKey])
                            : row1[sortKey].toLowerCase();
                    const b =
                        sortKey === "createdAt"
                            ? new Date(row2[sortKey])
                            : sortKey === "ref" && !isNaN(row2[sortKey])
                            ? parseInt(row2[sortKey])
                            : row2[sortKey].toLowerCase();
                    if (a > b) return direction === SORT_DIRECTIONS.ascending ? 1 : -1;
                    if (a < b) return direction === SORT_DIRECTIONS.ascending ? -1 : 1;
                    return 0;
                });
                return tempData;
            });
        },
        [changeSort, focusedFiles.files]
    );

    return (
        <div className="table-container flex flex--column">
            <input type="file" style={{ display: "none" }} ref={inputRef} onChange={handleNewFile} accept=".pdf" multiple />
            {rows.length > 0 && (
                <Fragment>
                    <div className="header grid flex--center-items padding-s--block">
                        <span></span>
                        {COLUMNS.map(({ name, field }, index) => (
                            <span
                                key={index}
                                className="w600 flex flex--center-items pointer"
                                onClick={() => {
                                    handleSort(field);
                                }}>
                                {t(name)}
                                {name && (
                                    <span className="flex flex--center-items margin-xs--left">
                                        {sort[0] !== field && <AiOutlineSortDescending className="sort-button none" />}
                                        {sort[0] === field && sort[1] === SORT_DIRECTIONS.none && <AiOutlineSortDescending className="sort-button none" />}
                                        {sort[0] === field && sort[1] === SORT_DIRECTIONS.ascending && <AiOutlineSortDescending className="sort-button" />}
                                        {sort[0] === field && sort[1] === SORT_DIRECTIONS.descending && <AiOutlineSortAscending className="sort-button" />}
                                    </span>
                                )}
                            </span>
                        ))}
                        <span></span>
                    </div>

                    <div ref={containerRef} className="body scroll-bar">
                        <DraggableList
                            itemKey={(item) => item.fileName}
                            template={Item}
                            list={rows}
                            onMoveEnd={handleListChange}
                            padding={0}
                            constrainDrag={true}
                            container={() => containerRef.current}
                            unsetZIndex={true}
                        />
                    </div>
                </Fragment>
            )}
            <div className="footer grid unselectable padding-s--block">
                <span></span>
                <span>
                    <span className="pointer btn--hover-dark smooth-corners padding-xs" onClick={handleClick}>
                        + {t("new")}
                    </span>
                </span>
                <span>
                    {t("count")}: {rows.length}
                </span>
                <span></span>
            </div>
            {showLockedWarning && <Modal modal={<WarningBox text={t("locked-already")} />} closeIn3 dismiss={[setShowLockedWarning]} />}
        </div>
    );
};

export default Table;
