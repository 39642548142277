import { useContext, useState, useEffect, useCallback } from "react";

import { LanguageContext } from "../contexts/language.context";

import dictionary from "../utils/dictionary.util";

const useTranslate = (page) => {
	// hook for language usage (returns a function 't'. wrap all text with 't' and it translates accordingly)
	const { language } = useContext(LanguageContext);
	const [dictPage, setDictPage] = useState(page in dictionary ? dictionary[page][language] : null);

	useEffect(() => {
		setDictPage(page in dictionary ? dictionary[page][language] : null);
	}, [language, page]);

	const t = useCallback(
		(rawText) => {
			if (dictPage) {
				const translatedText = dictPage[rawText];
				if (translatedText) return translatedText;
			}
			return rawText;
		},
		[dictPage]
	);

	return t;
};

export default useTranslate;
