import Image from "components/image/image.component";
import Selector from "components/selector/selector.component";
import { Fragment, useContext, useState } from "react";
import { BsPersonFill } from "react-icons/bs";
import { ClientsContext } from "src/contexts/clients.context";

import useTranslate from "src/hooks/use-translate";
import { getIndexWhere, getItemWhere } from "utils/array.utils";
import { POSITIONS } from "utils/lists.utils";

import "./people-sub-category.style.scss";
import NewProfileModal from "routes/client-view/account/inner-routes/company/people/components/new-profile-modal/new-profile-modal.component";
import axios from "axios";
import { getNow } from "utils/date.utils";
import ReactDOM from "react-dom";
import AuthProvider from "src/contexts/auth.context";
import { BrowserRouter } from "react-router-dom";
import LanguageProvider from "src/contexts/language.context";

const sortEmployeeList = (list = []) => {
    const admins = list.filter((person) => person.position === 0);
    const members = list.filter((person) => person.position === 1);
    return [...admins, ...members];
};

const PeopleSubTab = ({ id }) => {
    const { clients, updateEmployees, removeEmployee, setClients } = useContext(ClientsContext);
    const t = useTranslate("Clients");

    let client = getItemWhere(clients, "firmId", id);
    if (!client) client = getItemWhere(clients, "userId", id);
    const isPersonalAccount = client.firmId === -1;

    let data, color;
    if (isPersonalAccount) {
        data = getItemWhere(clients, "userId", id);
        ({ color } = data);
    } else {
        data = getItemWhere(clients, "firmId", id).employees;
        ({ color } = getItemWhere(clients, "firmId", id));
        data = sortEmployeeList(data);
    }

    const [people, setPeople] = useState(isPersonalAccount ? [data] : sortEmployeeList(data));

    const handleRemove = (idx) => {
        if (idx === 0 && people[1].position !== 0) {
            alert("There should be at least one admin left!");
            return;
        }

        const newList = [...people];
        const employeeId = newList[idx].userId;
        newList.splice(idx, 1);
        removeEmployee(id, newList, employeeId);
        setPeople((oldList) => oldList.filter((employee) => employee.userId !== employeeId));
    };

    const handleChangeType = (idx, newPosition) => {
        if (newPosition === 1 && people[1].position !== 0) {
            alert("There should be at least one admin left!");
            return;
        }

        const newList = [...people];
        newList[idx].position = newPosition;
        updateEmployees(id, newList, newList[idx].userId, newPosition);
    };

    const handleOpenModal = () => {
        // setIsNewMemberModalVisible(true);
        // add NewProfileModal to body of html to make it full screen
        const modalContainer = document.createElement("div");

        // Render the NewProfileModal component into the container
        ReactDOM.render(
            <BrowserRouter>
                <AuthProvider>
                    <LanguageProvider>
                        <NewProfileModal dismiss={() => handleCloseModal(modalContainer)} onSave={handleAddNewMember} firmId={id} />
                    </LanguageProvider>
                </AuthProvider>
            </BrowserRouter>,
            modalContainer
        );

        // Append the container to the body
        document.body.appendChild(modalContainer);
    };

    const handleCloseModal = (modalContainer) => {
        ReactDOM.unmountComponentAtNode(modalContainer);
        document.body.removeChild(modalContainer);
    };

    const handleAddNewMember = async (newProfile) => {
        const userId = await axios
            .post("/newMember", { ...newProfile, createdAt: getNow() })
            .then((res) => (res.status !== 200 ? null : res.data.userId))
            .catch((err) => {
                console.error(err);
                return null;
            });
        if (!userId) return;
        const newEmployee = { ...newProfile, userId };
        const clientIndex = getIndexWhere(clients, !isPersonalAccount ? "firmId" : "userId", id);
        setClients((oldClients) => {
            const newClients = [...oldClients];
            const oldEmployeeList = [...newClients[clientIndex].employees];
            newClients[clientIndex].employees = [...oldEmployeeList, newEmployee];
            return newClients;
        });
        if (newEmployee.position === 1) setPeople((oldPeople) => [...oldPeople, newEmployee]);
        else setPeople((oldPeople) => [newEmployee, ...oldPeople]);
    };

    return (
        <div className="people-sub-category-container padding-m--left overflow-visible">
            <div className="flex flex--space-between-content">
                <p className="w600 size400 with-line flex1 padding-l--right">{t("people")}</p>
                <button className="pointer btn semi-round-corners padding-xs padding-m--inline" onClick={handleOpenModal}>
                    +{t("add new people")}
                </button>
            </div>
            {people.map((person, index) => {
                const { name, surname, jobTitle, position } = person;

                return (
                    <div key={index} className="person padding-s--bottom padding-m--top">
                        <div
                            className="img"
                            style={{
                                borderColor: color,
                                borderWidth: "0.25rem",
                            }}>
                            <Image name={name + " " + surname} color={color} />
                        </div>
                        <p className="w600 size400">{name + " " + surname}</p>
                        <div className="flex gap-s">
                            <BsPersonFill />
                            <span>{t(jobTitle)}</span>
                        </div>
                        {isPersonalAccount && <div className="border text-center smooth-corners padding-xs--block">{t("Administrator")}</div>}
                        {!isPersonalAccount && (
                            <Fragment>
                                <Selector
                                    onSelected={(value) => {
                                        handleChangeType(index, POSITIONS.indexOf(value));
                                    }}
                                    data={POSITIONS}
                                    selected={POSITIONS[position]}
                                />
                                <button onClick={() => handleRemove(index)} className="size40 w400">
                                    {t("Remove")}
                                </button>
                            </Fragment>
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default PeopleSubTab;
