import WarningBox from "components/warning-box/warning-box.component";
import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import VerificationInput from "react-verification-input";

import { ReactComponent as LeftArrow } from "assets/left-arrow.svg";

import useTranslate from "../../../hooks/use-translate";

import "../../login/login.style.scss";
import axios from "axios";

const Verification = () => {
    const t = useTranslate("Verification");
    const navigate = useNavigate();
    const {
        state: { email },
    } = useLocation();
    const [error, setError] = useState(false);
    const [verificationCode, setVerificationCode] = useState("");

    const handleSubmit = async (event) => {
        event.preventDefault();
        const err = await axios
            .post("verifyCode", { code: verificationCode, email })
            .then((res) => {
                if (res.status !== 200) return true;
                return null;
            })
            .catch((err) => true);
        if (err) {
            setError(true);
            setVerificationCode("");
        } else navigate("/new-password", { state: { email } });
    };

    useEffect(() => {
        if (verificationCode.length) {
            setError(false);
        }
    }, [verificationCode]);

    return (
        <div className="login-container">
            <div className="login-left-side">{/* TODO: logo */}</div>
            {/* <div className="login-right-side"> */}
            <form className="login-right-side" onSubmit={handleSubmit}>
                <div className="login-form-container">
                    <Link to={"/"} style={{ width: "min-content" }}>
                        <LeftArrow style={{ fontSize: "2rem" }} />
                    </Link>
                    <span className="title margin-m--top">{t("Verify Your Code").toUpperCase()}</span>
                    <div style={{ display: "flex" }}>
                        <VerificationInput
                            value={verificationCode}
                            onChange={setVerificationCode}
                            length={4}
                            validChars={"0-9"}
                            placeholder="x"
                            classNames={{
                                container: "verification-container",
                                character: `verification-character ${error && "error"}`,
                                characterInactive: "verification-character--inactive",
                                characterSelected: "verification-character--selected",
                            }}
                        />
                    </div>
                    <div className="flex flex--start-items">
                        {error && <WarningBox text={"Incorrect verification code"} />}
                        <button className="btn btn--red size500 w500 padding-s--block padding-xxl--inline margin-auto--left" type="submit">
                            {t("confirm").toUpperCase()}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default Verification;
