import { useContext, useEffect, useMemo, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { MdOutlineAttachment } from "react-icons/md";
import { TbSend } from "react-icons/tb";

import { ChatContext } from "src/contexts/chat.context";

import "./message-input.style.scss";

const MessageInput = () => {
    const { sendMessage, sendFile, selectedChat } = useContext(ChatContext);
    const [message, setMessage] = useState("");
    const [file, setFile] = useState(null);
    const { chatId, chatUserId, groupChat = null } = useMemo(() => selectedChat, [selectedChat]);

    useEffect(() => {
        setMessage("");
    }, [selectedChat]);

    const handleChange = (event) => {
        const text = event.target.value;
        setMessage(text);
    };

    const openFileInput = () => {
        document.getElementById("msg-file").click();
    };

    const handleSend = () => {
        if (file) {
            sendFile(file, chatUserId, chatId, groupChat);
            setFile(null);
            setMessage("");
        } else if (message !== "") {
            sendMessage(message, chatUserId, chatId, groupChat);
            setMessage("");
        }
    };

    const handleSelectFile = (event) => {
        if (!event.target?.files || event.target.files.length === 0) {
            setFile(null);
            return;
        }
        const file = event.target.files[0];
        setMessage(file.name);
        setFile(file);
    };

    const cancelFile = () => {
        setFile(null);
        setMessage("");
    };

    return (
        <div className="input-container">
            <input type="file" id="msg-file" style={{ display: "none" }} onChange={handleSelectFile} />
            <div className="attachment-button pointer" onClick={openFileInput}>
                <MdOutlineAttachment />
            </div>
            {file && (
                <div className="cancel-btn pointer absolute" onClick={cancelFile}>
                    X
                </div>
            )}
            <TextareaAutosize className={`text-area ${file && "disabled"}`} maxRows={5} value={message} onChange={handleChange} disabled={file} />
            <div onClick={handleSend} className="send-button">
                <TbSend />
            </div>
        </div>
    );
};

export default MessageInput;
