import { COLORS } from "utils/lists.utils";
import "./image.style.scss";

// const IMAGE_COLORS = ["redish", "yellowish", "bluish"];

const Image = ({ name = "", color = COLORS[0], round = false, size = "2rem" }) => {
	let initials = name.split(" ");

	if (
		(initials.length === 1 && initials[0].length > 0) ||
		(initials.length > 1 && initials[0].length > 0 && initials[initials.length - 1].length === 0)
	) {
		initials = initials[0][0].toUpperCase();
	} else if (
		initials.length > 1 &&
		initials[initials.length - 1].length > 0 &&
		initials[0].length > 0
	) {
		initials = initials[0][0].toUpperCase() + initials[initials.length - 1][0].toUpperCase();
	} else if (
		initials.length > 1 &&
		initials[initials.length - 1].length > 0 &&
		initials[0].length === 0
	) {
		initials = initials[initials.length - 1][0].toUpperCase();
	}

	// let textColor = "black";
	// if (color === COLORS[0] || color === COLORS[1]) {
	// 	textColor = "white";
	// }

	// const colorIndex = name[0] < "I" ? 0 : name[0] < "R" ? 1 : 2;
	// const imageBackground = IMAGE_COLORS[colorIndex];

	return (
		<div
			className={`round-image unselectable`}
			style={
				round
					? {
							width: size,
							height: size,
							border: `${parseInt(size) * 0.1}rem solid ${color ?? COLORS[0]}`,
							fontSize: `${parseInt(size) * 0.4}rem`,
							fontWeight: 600,
					  }
					: {}
			}
		>
			{initials}
		</div>
	);
};

export default Image;
