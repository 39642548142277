// import FileSelector from "components/file-selector/file-selector.component";
import Image from "components/image/image.component";
import { useMemo } from "react";
import { useContext } from "react";
import { AuthContext } from "src/contexts/auth.context";
import useTranslate from "src/hooks/use-translate";
import { POSITIONS } from "utils/lists.utils";

import "./my-account.style.scss";

const FIELDS = {
    accountBasics: {
        label: "account basics",
        fields: {
            name: { label: "name", getValue: (user) => user.name + " " + user.surname },
            username: { label: "username" },
            position: { label: "access type", getValue: (user) => POSITIONS[user.position] },
        },
    },
    workInfo: {
        label: "work information",
        fields: {
            companyName: { label: "company" },
            jobTitle: { label: "job title" },
        },
    },
    contact: {
        label: "contact",
        fields: { email: { label: "email" }, phone: { label: "phone" } },
    },
};

const MyAccount = () => {
    const { user } = useContext(AuthContext);
    const { name, surname, companyName, jobTitle, color } = user;

    const t = useTranslate("Account");

    const fullName = useMemo(() => name + " " + surname, [name, surname]);

    return (
        <div className="my-profile-container">
            <span className="with-line size600 w600">{t("My Profile")}</span>
            <div className="data-wrapper">
                <div className="header">
                    {`${fullName} | `}
                    <span>{`${companyName !== "" ? companyName : t("personal")} | ${jobTitle ?? ""}`}</span>
                </div>
                <Image name={fullName} round color={color} size={"6rem"} />
                {/* <button onClick={handleClick}>Change Photo</button> */}
                <div className="fields">
                    {Object.values(FIELDS).map((object, index) => {
                        const { label, fields } = object;
                        return (
                            <div key={index} className="padding-xxxl--bottom">
                                <p className="size300 w600 with-line margin-xl--bottom">{t(label)}</p>
                                {Object.keys(fields).map((field, index) => {
                                    const value = fields[field]?.getValue ? fields[field]?.getValue(user) : user[field];
                                    return (
                                        <div key={field} className="grid">
                                            <p className="title margin-m--bottom">{t(fields[field].label)}</p>
                                            <p className="value">{t(value)}</p>
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default MyAccount;
