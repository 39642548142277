import Image from "components/image/image.component";
import useTranslate from "src/hooks/use-translate";

import "./person.style.scss";

const Person = ({ name = "", jobTitle = "", id = -1, position = "", isAdmin = false, color = "", onRemove = () => {}, onClick = () => {} }) => {
    const t = useTranslate("Account");

    const handleRemove = (e) => {
        e.stopPropagation();
        onRemove(id);
    };

    return (
        <div onClick={onClick} className="person-container">
            <Image name={name} color={color} round size="2rem" />
            <div className="name-wrapper size400 unselectable">
                <p className="size400 w600">{name}</p>
            </div>
            <p className="size400 unselectable">{jobTitle}</p>
            <div className="size300 unselectable user-type padding-s--block">{t(position)}</div>
            {isAdmin && (
                <button onClick={handleRemove} className="size300 btn btn--red-text padding-s--block padding-xl--inline">
                    {t("remove")}
                </button>
            )}
        </div>
    );
};

export default Person;
