import { useContext } from "react";

import { LanguageContext } from "../../../contexts/language.context";

import "./language-selector.scss";
import SideSelector from "components/side-selector/SideSelector.component";

const LANGUAGES = {
    en: "English",
    fr: "French",
};

const languageData = [
    { label: "FRE", name: "French" },
    { label: "ENG", name: "English" },
];

const LanguageSelector = () => {
    const { changeLanguage, language } = useContext(LanguageContext);

    const handleLanguageChange = (value) => {
        changeLanguage(value);
    };

    return (
        <div className="language-select">
            {/* <Selector data={["English", "French"]} onSelected={handleLanguageChange} selected={LANGUAGES[language]} /> */}
            <SideSelector data={languageData} onSelected={handleLanguageChange} selected={LANGUAGES[language]} />
        </div>
    );
};

export default LanguageSelector;
