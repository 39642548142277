import useTranslate from "src/hooks/use-translate";
import "./pdf-viewer.style.scss";

import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { RiZoomInLine, RiZoomOutLine } from "react-icons/ri";
import {
    // ScrollMode,
    SpecialZoomLevel,
    Viewer,
    Worker,
} from "@react-pdf-viewer/core";
import { Icon, MinimalButton, Position, Tooltip } from "@react-pdf-viewer/core";

// Plugins
import {
    zoomPlugin,
    // RenderCurrentScaleProps
} from "@react-pdf-viewer/zoom";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
// import disableScrollPlugin from "./custom-plugins/disable-scroll.plugin";

import FileSelector from "components/file-selector/file-selector.component";

import { FolderContext } from "src/contexts/folder.context";

// Import the styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import axios from "axios";
import { AuthContext } from "src/contexts/auth.context";

const PdfViewer = ({ viewOriginal = false }) => {
    const {
        openedFile,
        openedFileInfo,
        focusedFiles: { files },
        openFile,
        closeFile,
        updateRef,
        getUpdatedFile,
    } = useContext(FolderContext);
    const { user } = useContext(AuthContext);
    // const { readFromS3 } = useContext(S3Context);

    const [shownFile, setShownFile] = useState(null);
    const [openedFileIndex, setOpenedFileIndex] = useState(0);
    const [refInput, setRefInput] = useState(openedFile.ref ?? "");
    const t = useTranslate("Archive");

    const zoomOutRef = useRef(null);
    const zoomInRef = useRef(null);

    const zoomPluginInstance = zoomPlugin();
    const { CurrentScale, ZoomIn, ZoomOut } = zoomPluginInstance;

    // Plugin to disable the scroll in pdf viewer
    // const disableScrollPluginInstance = disableScrollPlugin();

    const pageNavigationPluginInstance = pageNavigationPlugin();
    const { GoToPreviousPage, GoToNextPage, CurrentPageLabel } = pageNavigationPluginInstance;

    useEffect(() => {
        const initializeFile = async () => {
            if (!openedFile) return null;
            let reader = new FileReader();

            reader.onloadend = (e) => {
                setShownFile(e.target.result);
            };

            // If ref text on top right is not wanted be shown
            if (viewOriginal) {
                reader.readAsDataURL(openedFile);
                setOpenedFileIndex(files.findIndex(({ fileName }) => fileName === openedFile.name) + 1);
                return;
            }
            // Write ref value on top right
            reader.readAsDataURL(await getUpdatedFile(openedFile, openedFile.ref));
            // reader.readAsDataURL(openedFile); // read without ref
            setOpenedFileIndex(files.findIndex(({ fileName }) => fileName === openedFile.name) + 1);
        };
        initializeFile();
    }, [openedFile, files, viewOriginal, getUpdatedFile]);

    const handleOpenNext = useCallback(() => {
        if (openedFileIndex === files.length) return;
        setOpenedFileIndex((index) => index + 1);
        openFile(files[openedFileIndex].fileName);
    }, [openedFileIndex, files, openFile]);

    const handleOpenPrev = useCallback(() => {
        if (openedFileIndex === 1) return;
        setOpenedFileIndex((index) => {
            openFile(files[openedFileIndex - 2].fileName);
            return index - 1;
        });
    }, [openedFileIndex, files, openFile]);

    useEffect(() => {
        // Handle keyboard shortcuts
        function handleKeyDown(event) {
            if (event.key === "ArrowLeft") {
                handleOpenPrev();
            } else if (event.key === "ArrowRight" || event.key === "Tab") {
                handleOpenNext();
            } else if (event.key === "-" || event?.which === "Minus") {
                if (zoomOutRef?.current) zoomOutRef.current.click();
            } else if (event.key === "+" || event?.which === "Plus") {
                if (zoomInRef?.current) zoomInRef.current.click();
            }
        }
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [handleOpenPrev, handleOpenNext]); // Run this effect only once, when the component mounts

    const handleClose = useCallback(() => {
        closeFile();
    }, [closeFile]);

    const handleChangeRef = useCallback(() => {
        if (openedFile?.ref === refInput) return;
        axios.post("updateRef", {
            userId: user.userId,
            firmId: user.firmId,
            ref: refInput,
            docId: openedFileInfo.fileId,
        });
        updateRef(refInput, openedFileInfo.fileId, undefined, user.accountant === -1);
    }, [openedFile, refInput, openedFileInfo, updateRef, user]);

    return (
        <div className="pdf-viewer-container" style={{ zIndex: 10 }}>
            {shownFile && (
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
                    <div className="pdf-viewer-topbar">
                        {/* Top bar */}
                        <div className="left-side">
                            <div className="left-side-box">
                                <div className="title">{t("reference")}</div>
                                {user.role === 0 ? (
                                    <input
                                        className="ref-input"
                                        value={refInput}
                                        onChange={(e) => setRefInput(e.target.value)}
                                        onBlur={handleChangeRef}
                                        style={{ width: "80%" }}
                                    />
                                ) : (
                                    <div className="body">{openedFile.ref ?? ""}</div>
                                )}
                            </div>
                            <div className="left-side-box">
                                <div className="title">{t("name")}</div>
                                <div className="body">{openedFile.name}</div>
                            </div>
                        </div>
                        <div className="center">
                            {t("page")}
                            <CurrentPageLabel>{(props) => <span>{`${props.currentPage + 1} / ${props.numberOfPages}`}</span>}</CurrentPageLabel>
                        </div>
                        <div className="right-side">
                            <ZoomOut>
                                {(props) => (
                                    <button ref={zoomOutRef} onClick={props.onClick}>
                                        <RiZoomOutLine className="zoom-icon" onClick={props.onClick} />
                                    </button>
                                )}
                            </ZoomOut>
                            <CurrentScale>{(props) => <div className="white unselectable">{`${Math.round(props.scale * 100)}%`}</div>}</CurrentScale>
                            <ZoomIn>
                                {(props) => (
                                    <button ref={zoomInRef} onClick={props.onClick}>
                                        <RiZoomInLine className="zoom-icon" />
                                    </button>
                                )}
                            </ZoomIn>
                            <div
                                style={{
                                    fontSize: "2.4vh",
                                    color: "white",
                                }}
                                className="unselectable"
                                onClick={handleClose}>
                                X
                            </div>
                        </div>
                    </div>

                    <div className="pdf-viewer">
                        <div
                            // Previous Page Button
                            style={{
                                left: 0,
                                position: "absolute",
                                top: "50%",
                                transform: "translate(24px, -50%)",
                                zIndex: 1,
                                // flex: 1,
                                // paddingInline: "20px",
                                // display: "flex",
                                // alignItems: "center",
                            }}>
                            <GoToPreviousPage>
                                {(props) => (
                                    <Tooltip
                                        position={Position.BottomCenter}
                                        target={
                                            <MinimalButton onClick={props.onClick}>
                                                <Icon size={28}>
                                                    <path d="M18.4.5,5.825,11.626a.5.5,0,0,0,0,.748L18.4,23.5" />
                                                </Icon>
                                            </MinimalButton>
                                        }
                                        content={() => "Previous page"}
                                        offset={{ left: 0, top: 8 }}
                                    />
                                )}
                            </GoToPreviousPage>
                        </div>

                        <Viewer
                            // ******** This is where file is shown ******
                            // fileUrl={openedFile?.url ?? shownFile}
                            fileUrl={shownFile}
                            plugins={[
                                zoomPluginInstance,
                                // disableScrollPluginInstance,
                                pageNavigationPluginInstance,
                            ]}
                            // scrollMode={ScrollMode.Wrapped}
                            defaultScale={SpecialZoomLevel.PageFit}
                        />

                        <div
                            // Next Page Button
                            style={{
                                position: "absolute",
                                right: 0,
                                top: "50%",
                                transform: "translate(-24px, -50%)",
                                zIndex: 1,
                                // flex: 1,
                                // paddingInline: "20px",
                                // display: "flex",
                                // alignItems: "center",
                            }}>
                            <GoToNextPage>
                                {(props) => (
                                    <Tooltip
                                        position={Position.BottomCenter}
                                        target={
                                            <MinimalButton onClick={props.onClick}>
                                                <Icon size={28}>
                                                    <path d="M5.651,23.5,18.227,12.374a.5.5,0,0,0,0-.748L5.651.5" />
                                                </Icon>
                                            </MinimalButton>
                                        }
                                        content={() => "Next page"}
                                        offset={{ left: 0, top: 8 }}
                                    />
                                )}
                            </GoToNextPage>
                        </div>
                    </div>

                    <div className="pdf-viewer-bottombar">
                        {/* Bottom selector bar */}
                        <div className="file-count-container">{`${openedFileIndex}/${files.length.toString()}`}</div>
                        <FileSelector index={openedFileIndex} setIndex={setOpenedFileIndex} length={files.length} />
                    </div>
                </Worker>
            )}
        </div>
    );
};

export default PdfViewer;
