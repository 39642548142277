import { Link } from "react-router-dom";

import "./sidebar-button.style.scss";

const SidebarButton = ({ title, icon, route, isSelected }) => {
	return (
		<div className="sidebar-button-wrapper">
			<Link to={route} className={`sidebar-button ${isSelected && "selected"}`} title={title}>
				<div className={`sidebar-icon ${isSelected && "selected"}`}>{icon}</div>
			</Link>
		</div>
	);
};

export default SidebarButton;
