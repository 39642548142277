import { useCallback, useContext, useEffect, useState } from "react";
import { BsSearch } from "react-icons/bs";
import { useLocation } from "react-router-dom";

import Table from "components/accountant-table/accountant-table.component";
import FilterModal from "./components/filter-modal/filter-modal.component";

import { ClientsContext } from "src/contexts/clients.context";

import "./clients.style.scss";
import NewClientModal from "components/new-client-modal/new-client-modal.component";
import { useMemo } from "react";
import { ACTIVATION_TYPES, ARCHIVE_PERIODS, CLIENT_TYPES, SAFE_LOCKING_PERIODS, SUBSCRIPTIONS } from "utils/lists.utils";
import useTranslate from "src/hooks/use-translate";
import { isObjectEmpty } from "utils/array.utils";
import { AuthContext } from "src/contexts/auth.context";

const FILTER_CATEGORIES = {
    clientType: {
        key: "clientType",
        label: "Client Type",
        choices: [...CLIENT_TYPES, "PP"],
    },
    subscription: {
        key: "subscription",
        label: "Subscription",
        choices: SUBSCRIPTIONS,
    },
    archivePeriod: {
        key: "archivePeriod",
        label: "Archive Period",
        choices: ARCHIVE_PERIODS,
    },
    safeLockingPeriod: {
        key: "safeLockingPeriod",
        label: "Safe Locking Period",
        choices: SAFE_LOCKING_PERIODS,
    },
    liveChat: { key: "liveChat", label: "Live Chat", choices: ACTIVATION_TYPES },
    mobile: { key: "mobile", label: "Mobile App", choices: ACTIVATION_TYPES },
};

const filterTemplate = {
    clientType: [], // for Client Type
    subscription: [], // for Subscription
    archivePeriod: [], // for Archive Period
    safeLockingPeriod: [], // for Safe Locking PeriodSafe Locking Period
    liveChat: [], // for Live Chat
    mobile: [], // for Mobile App
};

const checkIncludes = (array = [], item = "") => {
    return array.includes(item);
};

const fillFilterArrayIfEmpty = (filterArray, fullList) => {
    if (filterArray.length === 0) {
        const filledArray = Array.from(Array(fullList.length).keys()); // [0, 1, 2 ...]
        return filledArray;
    }
    return filterArray;
};

const Clients = () => {
    const { clients, openedClient, setOpenedClientById } = useContext(ClientsContext);
    const {
        user: { userId },
    } = useContext(AuthContext);
    const { state } = useLocation();
    const [stateParams] = useState(sessionStorage.getItem("clickedNotification") ? { ...state } : { initialIndex: -1, id: -1, isCompany: true });
    const [selectedTab, setSelectedTab] = useState(1);
    const [isIdEditable, setIsIdEditable] = useState(false);

    useEffect(() => {
        sessionStorage.removeItem("clickedNotification");
    }, []);

    const t = useTranslate("Clients");

    const [newClientModalVisible, setNewClientModalVisible] = useState(false);
    const [filterModalVisible, setFilterModalVisible] = useState(false);
    const [filters, setFilters] = useState(null);
    const [searchText, setSearchText] = useState(["", false]); // [text, is search button clicked]

    const filteredClients = useMemo(() => {
        if (!filters || Object.keys(filters).length === 0) return clients;

        const organizedFilters = filterTemplate;

        Object.keys(filters).forEach((key, index) => {
            organizedFilters[key] = fillFilterArrayIfEmpty(filters[key], FILTER_CATEGORIES[key].choices);
        });

        const {
            clientType: clientTypeArr,
            subscription: subscriptionArr,
            archivePeriod: archivePeriodArr,
            safeLockingPeriod: safeLockingPeriodArr,
            liveChat: liveChatArr,
            mobile: mobileArr,
        } = organizedFilters;

        const _filteredClients = clients.filter((client) => {
            const isPersonalAccount = client.firmId === -1;
            const { subscription, archivePeriod, safeLockingPeriod, liveChat, mobile } = client;

            if (isPersonalAccount && !clientTypeArr.includes(CLIENT_TYPES.length)) return false; // if it is personal account and "PP" is not chosen
            if (!isPersonalAccount && !checkIncludes(clientTypeArr, client.clientType)) return false;
            if (
                !checkIncludes(subscriptionArr, subscription) ||
                !checkIncludes(archivePeriodArr, archivePeriod) ||
                !checkIncludes(safeLockingPeriodArr, safeLockingPeriod) ||
                !checkIncludes(liveChatArr, liveChat) ||
                !checkIncludes(mobileArr, mobile)
            )
                return false;

            return true;
        });

        return _filteredClients;
    }, [clients, filters]);

    const handleOpenFiltersModal = () => {
        setFilterModalVisible(true);
    };
    const handleCloseFiltersModal = () => {
        setFilterModalVisible(false);
    };

    const handleClearFilters = () => {
        setFilters(null);
    };
    const handleFilterSubmit = (newFilters) => {
        setFilters(newFilters);
    };

    const handleSearch = (event) => {
        event.preventDefault();
        const { value } = event.target;

        setSearchText((oldValue) => {
            const newValue = [value, true];
            return newValue;
        });
    };

    const handleOpenNewClientModal = () => {
        setNewClientModalVisible(true);
    };

    const handleCloseNewClientModal = () => {
        setNewClientModalVisible(false);
    };

    useEffect(() => {
        if (stateParams.initialIndex !== -1 && isObjectEmpty(openedClient))
            setOpenedClientById(stateParams.isCompany ? -1 : stateParams.id, stateParams.isCompany ? stateParams.id : -1);
    }, [openedClient, clients, stateParams, setOpenedClientById]);

    const handleTabChange = useCallback(
        (tabIndex) => {
            if (selectedTab === tabIndex) return;
            setSelectedTab(tabIndex);
        },
        [selectedTab]
    );

    const handleEditId = () => {
        setIsIdEditable((oldValue) => !oldValue);
    };

    return (
        <div className="clients-container route-container">
            <div className="flex flex--center-items gap-xxxl margin-s--bottom padding-s--bottom border--bottom">
                <p className="size600 w600 ">{t("myclients")}</p>
                <form className="flex size400">
                    <input placeholder="Search" onChange={handleSearch} value={searchText[0]} />
                    <BsSearch />
                </form>
                <div name="tab buttons" className="size400 w600 tabs-wrapper">
                    <span onClick={() => handleTabChange(0)} className={`pointer text-center padding-xs--bottom ${selectedTab === 0 && "selected-tab"}`}>
                        {t("my clients")} {`(${clients.reduce((acc, client) => (client?.accountant ?? client.accountantId === userId ? acc + 1 : acc), 0)})`}
                    </span>
                    <span onClick={() => handleTabChange(1)} className={`pointer text-center padding-xs--bottom ${selectedTab === 1 && "selected-tab"}`}>
                        {t("all clients")} {`(${clients.length})`}
                    </span>
                </div>
            </div>

            <div className="relative unselectable margin-xxl--right">
                {/* The clients table */}
                {filteredClients.length !== 0 && (
                    <Table
                        searchText={searchText}
                        data={selectedTab === 0 ? filteredClients.filter((client) => client?.accountant ?? client.accountantId === userId) : filteredClients}
                        extraData={[1]}
                        initialIndex={stateParams.initialIndex}
                        isIdEditable={isIdEditable}
                    />
                )}

                {/* Header Row in table or the buttons */}
                <div className="absolute flex gap-s margin-xxxl--right" style={{ top: "0.6rem", right: 0, maxWidth: "35%", flexWrap: "wrap" }}>
                    <button onClick={handleOpenNewClientModal} className={`size400 w500 border smooth-corners padding-l--inline`}>
                        + {t("add new client")}
                    </button>
                    <button
                        onClick={handleEditId}
                        className={`size400 w500 border smooth-corners padding-l--inline ${isIdEditable ? "white" : ""}`}
                        style={isIdEditable ? { backgroundColor: "#556B2F" } : {}}>
                        {t("edit id")}
                    </button>
                    <button
                        onClick={handleClearFilters}
                        className={`size400 w500 border smooth-corners padding-l--inline ${!filterModalVisible && !filters && "selected"}`}>
                        {t("all")}
                    </button>
                    <button
                        onClick={handleOpenFiltersModal}
                        className={`size400 w500 border smooth-corners padding-l--inline ${(filterModalVisible || filters) && "selected"}`}>
                        {t("filter")}
                    </button>
                </div>
            </div>

            {/* Modals */}
            {filterModalVisible && (
                <FilterModal
                    categories={Object.values(FILTER_CATEGORIES)}
                    filters={filters}
                    onClear={handleClearFilters}
                    onFilter={handleFilterSubmit}
                    dismiss={handleCloseFiltersModal}
                />
            )}
            {newClientModalVisible && <NewClientModal dismiss={handleCloseNewClientModal} />}
        </div>
    );
};
export default Clients;
