import { useContext, useMemo } from "react";
import { IoArrowBack, IoClose } from "react-icons/io5";

import Folder from "./components/folder/folder.component";

import { FolderContext } from "src/contexts/folder.context";

import "./move-file-modal.style.scss";
import useTranslate from "src/hooks/use-translate";

const MoveFileModal = ({ dismiss = () => {} }) => {
    const { focusedFilesMoveFile, currentLocationMoveFile, goBackMoveFile, moveFile } = useContext(FolderContext);

    const t = useTranslate("Archive");

    const breadCrumbs = useMemo(() => {
        if (currentLocationMoveFile.length <= 2) {
            return currentLocationMoveFile.join(" > ").toUpperCase();
        }
        return `... > ${currentLocationMoveFile.slice(-2).join(" > ").toUpperCase()}`;
    }, [currentLocationMoveFile]);

    const handleMoveButton = () => {
        moveFile();
    };

    function sortFoldersByName(foldersArr) {
        const sortedFolders = [...foldersArr];
        sortedFolders.sort(({ fileName: folder1 }, { fileName: folder2 }) => {
            if (folder1.startsWith("PURCHASE")) {
                return -1;
            } else if (folder1.startsWith("SALES")) {
                if (folder2.startsWith("PURCHASE")) {
                    return 1;
                } else {
                    return -1;
                }
            } else if (folder1.startsWith("VAT")) {
                if (folder2.startsWith("PURCHASE") || folder2.startsWith("SALES")) {
                    return 1;
                } else {
                    return -1;
                }
            } else if (folder1.startsWith("FINANCE")) {
                if (folder2.startsWith("PURCHASE") || folder2.startsWith("SALES") || folder2.startsWith("VAT")) {
                    return 1;
                } else {
                    return -1;
                }
            } else if (folder1.startsWith("TAX")) {
                if (folder2.startsWith("PURCHASE") || folder2.startsWith("SALES") || folder2.startsWith("VAT") || folder2.startsWith("FINANCE")) {
                    return 1;
                } else {
                    return -1;
                }
            } else return 1;
        });
        return sortedFolders;
    }

    console.log({ focusedFilesMoveFile });

    return (
        <div className="move-file-modal-container absolute fill">
            <div onClick={dismiss} className="absolute fill" />
            <div className="modal flex flex--column smooth-corners padding-xxl--inline padding-xxl--block">
                <div className="modal-header size500 w600 flex flex--center-items gap-m padding-s--bottom">
                    {currentLocationMoveFile.length > 0 && (
                        <div style={{ height: "1.6rem" }}>
                            <IoArrowBack onClick={goBackMoveFile} size={"1.6rem"} className="pointer" />
                        </div>
                    )}

                    <div title={currentLocationMoveFile.join(" > ").toUpperCase()}>{breadCrumbs}</div>
                    <IoClose onClick={dismiss} size={"1.6rem"} className="pointer" style={{ marginLeft: "auto" }} />
                </div>
                <div className="folders-container flex gap-l margin-xxl--top scroll-bar" style={{ height: "min-content" }}>
                    {sortFoldersByName(focusedFilesMoveFile.folders).map(({ fileName, isEmpty }, index) => {
                        return <Folder key={index} fileName={t(fileName)} isEmpty={isEmpty} />;
                    })}
                </div>
                <button
                    onClick={handleMoveButton}
                    disabled={currentLocationMoveFile.length === 0}
                    className="size400 w600 padding-l--inline padding-xs--block border border--thick smooth-corners"
                    style={{ marginTop: "auto", marginLeft: "auto" }}>{`Move`}</button>
            </div>
        </div>
    );
};

export default MoveFileModal;
