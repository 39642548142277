import { Fragment, useCallback, useContext, useState } from "react";
import { BsCheckCircle, BsSafe, BsXLg } from "react-icons/bs";
import { ReactComponent as Ticket } from "assets/sidebar-icons/ticket.svg";
import RowData from "./components/row-data.component";
import { ClientsContext } from "src/contexts/clients.context";

import "./row.style.scss";
import { getIndexWhere } from "utils/array.utils";
import axios from "axios";
import useTranslate from "src/hooks/use-translate";

const Row = ({ row, index, extraData = [], isExpanded = false, onClick, onDelete, isIdEditable }) => {
    const { clients, setClients } = useContext(ClientsContext);
    const t = useTranslate("Clients");

    let surname = "",
        id = 0,
        isCompany = false;
    const { color, name, uploads = 0, tickets = 0, invoices = 0, idNo } = row;
    const [idInput, setIdInput] = useState(idNo ?? "");

    if (row?.firmId === -1) {
        // If personal client
        ({ userId: id, surname } = row);
    } else {
        // If company
        ({ firmId: id } = row);
        isCompany = true;
    }

    const handleChangeId = useCallback(async () => {
        if (idInput === "") return;
        if (idInput.length > 15) {
            alert("Id Number can't be longer than 15 characters");
            return;
        }
        const clientIndex = getIndexWhere(clients, isCompany ? "firmId" : "userId", id);
        const error = await axios
            .post("/updateClientInfo", { clientId: id, key: "idNo", value: idInput, isCompany })
            .then((res) => {
                if (res.status !== 200) return true;
                return null;
            })
            .catch((err) => {
                if (err.response.status === 403) alert(t("duplicate id"));
                console.error(err);
                return true;
            });
        if (error) return;
        setClients((oldClients) => {
            const newClients = [...oldClients];
            newClients[clientIndex].idNo = idInput;
            return newClients;
        });
    }, [idInput, id, isCompany, clients, setClients, t]);

    if (clients.length <= index) return null;
    return (
        <Fragment>
            <tr className={`size400 w400 row-title unselectable ${isExpanded && "expanded-title"}`}>
                <td onClick={onClick}>
                    <span style={{ backgroundColor: color }} className="img-box" />{" "}
                    <span className="w600">{surname.length > 0 ? name + " " + surname : name} </span>
                </td>
                <td onClick={onClick}>
                    {isIdEditable ? (
                        <input
                            className="id-input"
                            value={idInput}
                            onChange={(e) => setIdInput(e.target.value)}
                            onBlur={handleChangeId}
                            style={{ width: "80%" }}
                        />
                    ) : (
                        <span className="w600">{idNo}</span>
                    )}
                </td>
                <td onClick={onClick} className="fillers">
                    <span className="size300 w600 filler-item">
                        <BsCheckCircle className="icon" />
                        <span>{uploads > 99 ? "99+" : uploads}</span>
                    </span>
                    <span className="size300 w600 filler-item">
                        <Ticket className="icon" />
                        <span>{tickets > 99 ? "99+" : tickets}</span>
                    </span>
                    <span className="size300 w600 filler-item">
                        <BsSafe className="icon" />
                        <span>{invoices > 99 ? "99+" : invoices}</span>
                    </span>
                </td>
                {onDelete && (
                    <td>
                        <BsXLg onClick={onDelete} size={18} style={{ zIndex: 10 }} />
                    </td>
                )}
            </tr>
            {extraData.length > 0 && (
                <tr className={`row-data ${isExpanded && "expanded"}`}>
                    <td>
                        <RowData index={index} data={row} id={id} />
                    </td>
                    {/* <td style={{ backgroundColor: "pink" }}></td> */}
                </tr>
            )}
        </Fragment>
    );
};

export default Row;
