import Checkbox from "components/checkbox/checkbox.component";
import { useEffect } from "react";
import { useCallback, useState } from "react";
import { useMemo } from "react";

import "./ticket-table.style.scss";
import { formatDate } from "utils/date.utils";

const TicketTable = ({ rows = [], columns = [], checkbox = false, clickable = false, onChecked = () => {}, onClick = () => {} }) => {
    const [selectedItems, setSelectedItems] = useState([]);
    const [isSelectedAll, setIsSelectedAll] = useState(false);

    const WIDTH_MULTIPLIER = useMemo(() => {
        const sum = columns.reduce((sum, column) => {
            return sum + (column?.span ?? 0);
        }, 0);
        return 100 / sum;
    }, [columns]);

    const handleSelectAll = useCallback(() => {
        setSelectedItems(() => {
            if (isSelectedAll) {
                return [];
            }
            return rows.map((row) => row.ticketId.toString());
        });

        setIsSelectedAll((initialValue) => !initialValue);
    }, [isSelectedAll, rows]);

    const handleSelect = useCallback(
        ({ target: { id } }) => {
            const isSelected = selectedItems.indexOf(id) !== -1;

            if (isSelected) {
                setSelectedItems((oldList) => {
                    const tempList = [...oldList];
                    tempList.splice(oldList.indexOf(id), 1);
                    return tempList;
                });
                return;
            }

            setSelectedItems((oldList) => {
                const tempList = [...oldList];
                tempList.push(id);
                return tempList;
            });
        },
        [selectedItems]
    );

    useEffect(() => {
        // every time a row is selected, check if all row are selected
        if (isSelectedAll && selectedItems.length < rows.length) setIsSelectedAll(false);
        else if (!isSelectedAll && selectedItems.length === rows.length) setIsSelectedAll(true);
    }, [selectedItems, isSelectedAll, rows]);

    useEffect(() => {
        // every time selectedItems change call onChecked method
        onChecked(selectedItems);
    }, [selectedItems, onChecked]);

    useEffect(() => {
        // every time rows change, deselect all rows
        setSelectedItems([]);
    }, [rows]);

    return (
        <div className="ticket-table-container scroll-bar scroll-bar--overflow margin-xxl--right padding-m--right">
            <table>
                <thead>
                    <tr>
                        {checkbox && (
                            <th className="ticket-checkbox">
                                <Checkbox name={"Select All"} selected={isSelectedAll} handleClick={handleSelectAll} />
                            </th>
                        )}
                        {columns.map(({ headerName, span }, index) => {
                            return (
                                <th
                                    key={headerName}
                                    className="text-left"
                                    style={{
                                        width: `${WIDTH_MULTIPLIER * span}%`,
                                    }}>
                                    {headerName}
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    {rows.map((row, index) => {
                        return (
                            <tr className={`border--bottom ${clickable && "pointer"}`} key={index}>
                                {checkbox && (
                                    <td className="ticket-checkbox">
                                        <Checkbox
                                            id={row.ticketId}
                                            name={`Select Row ${index}`}
                                            selected={selectedItems.indexOf(row.ticketId.toString()) !== -1}
                                            handleClick={handleSelect}
                                        />
                                    </td>
                                )}
                                {columns.map(({ key, valueGetter }) => {
                                    if (!row[key])
                                        return (
                                            <td
                                                key={key}
                                                onClick={() => {
                                                    onClick(row.ticketId);
                                                }}></td>
                                        ); // if there is no item in row with given key
                                    if (key === "lastActivity" || key === "createdAt") return <td key={key}>{formatDate(row[key])}</td>;
                                    if (valueGetter)
                                        return (
                                            <td
                                                key={key}
                                                onClick={() => {
                                                    onClick(row.ticketId);
                                                }}>
                                                {valueGetter(row[key])}
                                            </td>
                                        ); // if there is a function to be applied to value
                                    return (
                                        <td
                                            key={key}
                                            onClick={() => {
                                                onClick(row.ticketId);
                                            }}>
                                            {row[key]}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default TicketTable;
