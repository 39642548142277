import { Fragment, useState } from "react";
import useTranslate from "src/hooks/use-translate";

import InfoSubTabCompany from "./sub-tabs/info/info-sub-category-company.component";
import InfoSubTabPerson from "./sub-tabs/info/info-sub-category-person.component";
import PlansSubTab from "./sub-tabs/plans/plans-sub-category.component";
import PeopleSubTab from "./sub-tabs/people/people-sub-category.component";
import DeleteSubTap from "./sub-tabs/delete/delete-sub-tab.component";

import "./settings-tab.style.scss";
import { useMemo } from "react";

const SettingsTab = ({ isPersonalAccount, id }) => {
    const t = useTranslate("Clients");
    const [selectedTab, setSelectedTab] = useState(0);

    const handleChangeTab = (idx) => {
        setSelectedTab(idx);
    };

    const FIELD_LIST = useMemo(
        () =>
            isPersonalAccount
                ? [
                      { label: "Company Information", href: "/account/company/information" },
                      { label: "Plans", href: "/account/company/plans" },
                  ]
                : [
                      { label: "Company Information", href: "/account/company/information" },
                      { label: "Plans", href: "/account/company/plans" },
                      { label: "People", href: "/account/company/people" },
                  ],
        [isPersonalAccount]
    );

    return (
        <div className="settings-tab-container unscrollable">
            <div className="left">
                <div className="buttons-container">
                    {FIELD_LIST.map((field, index) => {
                        const { label } = field;
                        return (
                            <button key={index} onClick={() => handleChangeTab(index)} className={`${index === selectedTab && "selected"} size400 w500`}>
                                {t(label)}
                            </button>
                        );
                    })}
                    <div className="with-line margin-m--top"></div>
                    <button onClick={() => handleChangeTab(3)} className={`${selectedTab === 3 && "selected"} size400 w500 delete`}>
                        {t("Delete Client")}
                    </button>
                </div>
            </div>
            <div className="right padding-m--right scroll-bar scroll-bar--overflow">
                {selectedTab === 0 && (
                    <Fragment>
                        {isPersonalAccount && <InfoSubTabPerson id={id} />}
                        {!isPersonalAccount && <InfoSubTabCompany id={id} />}
                    </Fragment>
                )}
                {selectedTab === 1 && <PlansSubTab id={id} />}
                {!isPersonalAccount && selectedTab === 2 && <PeopleSubTab id={id} />}
                {selectedTab === 3 && <DeleteSubTap id={id} />}
            </div>
        </div>
    );
};

export default SettingsTab;
