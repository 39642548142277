import { useContext } from "react";
import { AuthContext } from "src/contexts/auth.context";
import useTranslate from "src/hooks/use-translate";
import { COLORS } from "utils/lists.utils";
import "./company-information.style.scss";

const FIELDS = {
    companyName: { text: "name" },
    specialities: { text: "specialities" },
    website: { text: "website" },
    address: { text: "location" },
    createdAt: { text: "client since" },
};

const CompanyInformation = () => {
    const { user } = useContext(AuthContext);

    const t = useTranslate("Account");

    return (
        <div className="company-information-container">
            <span className="with-line size600 w600">{t("Company Information")}</span>
            <div className="data-wrapper">
                <p className="size500 w600 with-line margin-m--block">{(user?.companyName !== "" ? user.companyName : user?.name).toUpperCase()}</p>
                <div className="image margin-l--top" style={{ backgroundColor: user.color ?? COLORS[0] }} />
                <div className="fields size400">
                    {Object.keys(FIELDS).map((field, idx) => {
                        var value = user[field];
                        // if (field === "name") {
                        // 	value = COMPANY.name + " " + COMPANY.type;
                        // }
                        return (
                            <div key={idx} className="grid margin-m--block">
                                <p className="title">{t(FIELDS[field].text)}</p>
                                <p className="value">{value}</p>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default CompanyInformation;
