import { useContext } from "react";

import { ReactComponent as FolderEmpty } from "assets/folder/folder_empty.svg";
import { ReactComponent as FolderFilled } from "assets/folder/folder_filled.svg";

import { FolderContext } from "src/contexts/folder.context";

import "./sidebar-folder.style.scss";

const SidebarFolder = ({ folderName, label, isEmpty }) => {
    const { getInside, currentLocation } = useContext(FolderContext);

    const handleClick = () => {
        getInside(folderName, true);
    };

    return (
        <div
            className={`sidebar-folder-instance-container ${currentLocation.length > 0 && currentLocation[0] === folderName && "choosen-folder"}`}
            onClick={handleClick}>
            {isEmpty ? <FolderEmpty className="folder-icon" /> : <FolderFilled className="folder-icon" />}
            <div className="folder-name">{label}</div>
        </div>
    );
};

export default SidebarFolder;
