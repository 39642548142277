import { useContext, useEffect, useState } from "react";
import "./advertisement.style.scss";
import useTranslate from "src/hooks/use-translate";
import axios from "axios";
import { AuthContext } from "src/contexts/auth.context";

const Advertisement = () => {
    const {
        user: { userId },
    } = useContext(AuthContext);
    const t = useTranslate("Account");
    const [upperText, setUpperText] = useState("");
    const [lowerText, setLowerText] = useState("");
    const [image, setImage] = useState(null);

    useEffect(() => {
        const fetchAdImage = async () => {
            const [link, imageName] = await axios
                .get("getAdvertisement")
                .then((res) => {
                    if (res.status !== 200) return;
                    const { upperText, lowerText, imageUrl, imageName } = res.data;
                    setUpperText(upperText);
                    setLowerText(lowerText);
                    return [imageUrl, imageName];
                })
                .catch(console.error);
            if (!link) return;

            const file = await fetch(link)
                .then((response) => {
                    if (response.status !== 200) return null;
                    return response.blob();
                })
                .then((blob) => {
                    if (!blob) throw new Error("Blob is null");
                    const file = new File([blob], imageName, { type: blob.type });
                    return file;
                })
                .catch((error) => {
                    console.error(error);
                    return null;
                });
            if (!file) return;

            file.url = window.URL.createObjectURL(file);
            setImage(file);
        };
        fetchAdImage();
    }, []);

    const handleUploadImage = async (event) => {
        if (event.target.files.length === 0) return;
        const file = new File([event.target.files[0]], event.target.files[0].name, { type: event.target.files[0].type });
        const url = await axios
            .get("getSecurePutImage", { params: { imageName: file.name, userId } })
            .then((res) => {
                if (res.status !== 200) return null;
                return res.data;
            })
            .catch(() => null);
        if (!url) return;
        console.log({ file });
        let error = await fetch(url, {
            method: "PUT",
            headers: {
                "Content-type": "multipart/form-data",
            },
            body: file,
        })
            .then((res) => res.text())
            .then((data) => {
                if (data === "") {
                    console.log("File uploaded successfully.");
                    return null;
                } else return true;
            })
            .catch((err) => {
                console.error({ errorOnUploadPhotoPut: err });
            });
        if (error) return;
        file.url = window.URL.createObjectURL(event.target.files[0]);
        setImage(file);
    };

    const handleRemoveImage = async () => {
        const err = await axios
            .post("deleteAdvertiseImage", { userId, imageName: image.name })
            .then((res) => {
                if (res.status !== 200) return true;
                return null;
            })
            .catch(() => true);
        if (err) return;
        setImage(null);
    };

    // console.log({ image, upperText, lowerText });
    const saveAds = async (e) => {
        console.log({ image, upperText, lowerText });
        e.preventDefault();
        if (!image.name || !upperText || !lowerText) return;
        const err = await axios
            .post("saveAds", { userId, imageName: image.name, upperText, lowerText })
            .then((res) => {
                if (res.status !== 200) return true;
                return null;
            })
            .catch(() => true);
        if (err) return;
        alert("All saved!");
    };

    return (
        <div className="advertise">
            <div className="column-ads">
                <div>
                    <label htmlFor="uppertext" className="label-advertise">
                        {t("upper advertisement")}
                    </label>
                    <input id="uppertext" className="text" value={upperText} onChange={(e) => setUpperText(e.target.value != undefined && e.target.value)} />
                </div>
                <input className="button" type="file" onChange={handleUploadImage} />
                <div>
                    <label htmlFor="lowertext" className="label-advertise">
                        {t("lower advertisement")}
                    </label>
                    <input id="lowertext" className="text" value={lowerText} onChange={(e) => setLowerText(e.target.value)} />
                </div>
                <button className="save-ads-btn btn btn--red-border btn--hover" onClick={saveAds}>
                    {t("save")}
                </button>
            </div>
            {image && (
                <div>
                    <div className="ad-image-container">
                        <img className="ad-image" src={image.url} alt="advertisement" />
                    </div>
                    <div className="ad-image-remove btn btn--red-border btn--hover" onClick={handleRemoveImage}>
                        {t("remove-image")}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Advertisement;
