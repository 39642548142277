import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useState } from "react";
import useTranslate from "src/hooks/use-translate";
import "./rich-text-editor.style.scss";
import { MdOutlineAttachment, MdOutlineCancel } from "react-icons/md";

const RichTextEditor = ({ dismiss = () => {}, save = () => {}, files = [], setFiles = () => {} }) => {
    const [text, setText] = useState("");

    const t = useTranslate("RichTextEditor");

    const config = {
        plugins: ClassicEditor.builtinPlugins,
        toolbar: ["heading", "|", "bold", "italic", "|", "outdent", "indent", "|", "bulletedList", "numberedList", "|", "undo", "redo"],
    };

    const handleSubmit = () => {
        save(text);
    };

    const openFileSelector = () => {
        document.getElementById("ticket-file").click();
    };

    const handleSelectFile = (e) => {
        if (!e.target?.files[0] || e.target?.files.length === 0) return;
        // if any of the files are larger than 10MB, alert the user
        for (let i = 0; i < e.target.files.length; i++) {
            if (e.target.files[i].size > 15500000) {
                alert("File size must be less than 15MB");
                return;
            }
        }
        const newFiles = Array.from(e.target.files);
        setFiles((oldFiles) => [...oldFiles, ...newFiles]);
    };

    const cancelFile = (name) => {
        setFiles((oldFiles) => {
            const newFiles = oldFiles.filter((file) => file.name !== name);
            return newFiles;
        });
    };

    return (
        <div className="rich-text-editor-container">
            <input type="file" id="ticket-file" className="inputfile display-none" onChange={handleSelectFile} multiple />
            <div className="editor border--left border--thicker border--red">
                <CKEditor
                    editor={ClassicEditor}
                    data={text}
                    config={config}
                    onChange={(event, editor) => {
                        const data = editor.getData();
                        setText(data);
                    }}
                />
            </div>

            <div className="ticket-bottom flex flex--end-content gap-m margin-m--top relative">
                {/* Bottom Part */}
                <div className="attachment-button pointer" onClick={openFileSelector}>
                    <MdOutlineAttachment />
                </div>
                <div className="editor-files flex1 gap-xl scroll-bar">
                    {files?.length > 0 && (
                        <>
                            {Array.from(files).map((file, index) => (
                                <div key={index} className="file-container flex gap-xs">
                                    <div className="file-name">{file.name}</div>
                                    <MdOutlineCancel className="cancel-btn pointer" onClick={() => cancelFile(file.name)} />
                                </div>
                            ))}
                        </>
                    )}
                </div>
                <button onClick={dismiss} className="w500 btn padding-s--block padding-xxxxxxl--inline">
                    {t("Cancel")}
                </button>
                <button
                    onClick={handleSubmit}
                    className="w500 smooth-corners padding-s--block padding-xxxxxxl--inline"
                    style={{ backgroundColor: "#DA676C", color: "white" }}>
                    {t("Submit")}
                </button>
            </div>
        </div>
    );
};

export default RichTextEditor;
