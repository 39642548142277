import { useContext, useEffect } from "react";
import { NewClientContext } from "src/contexts/new-client.context";

const Page4 = ({ canContinue, toggle }) => {
    const { employees, updateNumberOfEmployees } = useContext(NewClientContext);

    // useEffect(() => {
    // if (!canContinue && employees.length > 0) toggle();
    // if (canContinue && employees.length === 0) toggle();
    // }, [toggle, canContinue, employees]);

    useEffect(() => {
        if (!canContinue) toggle();
    }, [canContinue, toggle]);

    const handleClick = (numberOfEmployees) => {
        updateNumberOfEmployees(numberOfEmployees);
    };

    return (
        <div className="page-container page4">
            <p className="size400 w600 margin-xl--top">
                Step 4 <span className="red">|</span> Add Member/s
            </p>
            <p className="size300 w400 margin-m--top">
                “Member” users can be added to the system by the accountant during the initial account setup, or company administrator can add their selected
                personnel to the system later.
                <br />
                <br />
                The maximum number of 5 member can be added for a company. If you want to do this operation right now, please select the amount of “Member”
                users that will be added to the company.
            </p>
            <div className="flex gap-m margin-xl--top">
                <button
                    onClick={() => {
                        handleClick(0);
                    }}
                    className={`btn smooth-corners padding-m--block padding-l--inline ${employees.length === 0 && "btn--selected"}`}>
                    Skip
                </button>

                {[1, 2, 3, 4, 5].map((number) => {
                    return (
                        <button
                            key={number}
                            onClick={() => {
                                handleClick(number);
                            }}
                            className={`btn smooth-corners padding-m--block padding-l--inline ${employees.length === number && "btn--selected"}`}>
                            {number}
                        </button>
                    );
                })}
            </div>
        </div>
    );
};

export default Page4;
