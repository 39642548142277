import { useContext } from "react";
import { ClientsContext } from "src/contexts/clients.context";
import { getItemWhere } from "utils/array.utils";
import { ACTIVATION_TYPES, ARCHIVE_PERIODS, SAFE_LOCKING_PERIODS, SUBSCRIPTIONS } from "utils/lists.utils";

import "./information-tab.style.scss";
import useTranslate from "src/hooks/use-translate";

const FIELDS = {
    name: { label: "Name" },
    jobTitle: { label: "Job Title" },
    username: { label: "Username" },
    specialities: { label: "Specialities" },
    vatNumber: { label: "VAT Number" },
    website: { label: "Website" },
    email: { label: "Email" },
    createdAt: { label: "Client Since" },
    address: { label: "Location" },
    phone: { label: "Phone" },
    location: { label: "Location" },
};

const PLANS = {
    subscription: { label: "Subscription", list: SUBSCRIPTIONS },
    archivePeriod: { label: "Archive Period", list: ARCHIVE_PERIODS },
    safeLockingPeriod: { label: "Safe Locking Period", list: SAFE_LOCKING_PERIODS },
    liveChat: { label: "Live Chat", list: ACTIVATION_TYPES },
    mobile: { label: "Mobile App", list: ACTIVATION_TYPES },
};

const InfoTab = ({ id, isCompany }) => {
    const { clients } = useContext(ClientsContext);
    const t = useTranslate("Clients");
    let color,
        name,
        data = null;
    if (!isCompany) {
        data = getItemWhere(clients, "userId", id);
        ({ name = "", color = "#000000" } = data);
    } else {
        data = getItemWhere(clients, "firmId", id);
        ({ name = "", color = "#000000" } = data);
    }

    return (
        <div className="info-tab-container margin-xl--top">
            <div className="flex gap-xxl company">
                <div
                    style={!isCompany ? { borderColor: color } : { backgroundColor: color }}
                    className={`size800 w600 flex flex--center ${!isCompany ? "img-circle" : "img-box"}`}>
                    {!isCompany && name[0].toUpperCase() + data?.surname[0].toUpperCase()}
                </div>
                <div className="fields flex--grow">
                    {data &&
                        Object.keys(FIELDS).map((field, idx) => {
                            var value = data[field];
                            if (!value) return null;

                            if (field === "name") {
                                value = name + (data?.firmId === -1 ? ` ${data?.surname}` : "");
                            }
                            return (
                                <div key={idx} className="grid">
                                    <p className="size400 w600">{t(FIELDS[field].label)}</p>
                                    <p className="size400 w400">{value}</p>
                                </div>
                            );
                        })}
                </div>
            </div>
            <div className="flex flex--column flex--grow flex--end-content">
                <div className="size400 w600 with-line margin-xxl--top margin-m--bottom">{t("Accounting Plan")}</div>
                <div className="flex flex--space-between-content">
                    {data &&
                        Object.keys(PLANS).map((key, idx) => {
                            const { label, list } = PLANS[key];
                            const value = list[data[key]];
                            return (
                                <div key={idx} className="flex gap-m">
                                    <div className="size400 w600">{t(label)}</div>
                                    <div className="size400 w400">{t(value)}</div>
                                </div>
                            );
                        })}
                </div>
            </div>
        </div>
    );
};

export default InfoTab;
