import { useContext, useEffect, useState } from "react";

import Selector from "components/selector/selector.component";
import Table from "components/table/table.component";
import Folder from "components/folder/folder.component";
import SidebarFolder from "components/sidebar/sidebar-folder/sidebar-folder.component";
import LockProgress from "./components/lock-progress/lock-progress.component";

import { FolderContext } from "src/contexts/folder.context";

import { ReactComponent as Unlocked } from "assets/buttons/unlocked.svg";
import { ReactComponent as Locked } from "assets/buttons/locked.svg";

import "./archive-tab.style.scss";
import Modal from "components/modal/modal.component";
import WarningBox from "components/warning-box/warning-box.component";
import useTranslate from "src/hooks/use-translate";
import { YEARS } from "utils/lists.utils";
import { isObjectEmpty } from "utils/array.utils";

/**
 *
 * Archive Tab to be shown to accountants when they see their listed clients' archive
 *
 */
const ArchiveTab = ({ id }) => {
    const { focusedFiles, currentLocation, clientFiles, yearSelection, setYear, goBack, lockFolderAcc, unlockFolderAcc } = useContext(FolderContext);
    // const { year } = useContext(ClientsContext);
    // const [selectedValue, setSelectedValue] = useState(2023);  // if yoıu wanna set selected year by default with selected property in <Selector>
    const [breadcrumbs, setBreadcrumbs] = useState("");

    const [lockSuccesful, setLockSuccesful] = useState(false);
    const [unlockSuccesful, setUnlockSuccesful] = useState(false);
    const [showError, setShowError] = useState(false);

    const t = useTranslate("Archive");

    useEffect(() => {
        if (currentLocation.length !== 0) {
            let str = t(currentLocation[0]);
            if (currentLocation.length > 1) str += " > " + currentLocation.slice(1).join(" > ");
            setBreadcrumbs(str.toUpperCase());
        } else {
            setBreadcrumbs("");
        }
    }, [currentLocation, t]);

    const handleGoBack = () => {
        goBack();
    };

    const lock = () => {
        const lockOperation = async () => {
            const error = await lockFolderAcc(id);
            if (error) setShowError(true);
            else setLockSuccesful(true);
        };
        lockOperation();
    };

    const unlock = () => {
        const unlockOperation = async () => {
            const error = await unlockFolderAcc(id);
            if (error) setShowError(true);
            else setUnlockSuccesful(true);
        };
        unlockOperation();
    };

    const handleSelect = (value) => {
        setYear(value);
        setBreadcrumbs("");
    };

    function sortFoldersByName(foldersObj) {
        const keys = Object.keys(foldersObj);
        keys.sort((folder1, folder2) => {
            if (folder1.startsWith("PURCHASE")) {
                return -1;
            } else if (folder1.startsWith("SALES")) {
                if (folder2.startsWith("PURCHASE")) {
                    return 1;
                } else {
                    return -1;
                }
            } else if (folder1.startsWith("VAT")) {
                if (folder2.startsWith("PURCHASE") || folder2.startsWith("SALES")) {
                    return 1;
                } else {
                    return -1;
                }
            } else if (folder1.startsWith("FINANCE")) {
                if (folder2.startsWith("PURCHASE") || folder2.startsWith("SALES") || folder2.startsWith("VAT")) {
                    return 1;
                } else {
                    return -1;
                }
            } else if (folder1.startsWith("TAX")) {
                if (folder2.startsWith("PURCHASE") || folder2.startsWith("SALES") || folder2.startsWith("VAT") || folder2.startsWith("FINANCE")) {
                    return 1;
                } else {
                    return -1;
                }
            } else return 1;
        });
        const sortedObj = {};
        for (let key of keys) {
            sortedObj[key] = foldersObj[key];
        }
        return sortedObj;
    }

    return (
        <div className="archive-tab-container scroll-bar scroll-bar--overflow">
            {/* selector and right side line + breadcrumbs */}
            <div className="flex gap-xxxl">
                <div className="flex flex--end-items gap-xxl padding-m--bottom sticky selector-wrapper">
                    <Selector data={YEARS} onSelected={handleSelect} />
                </div>

                <div className="home-header flex">
                    {breadcrumbs.length > 0 && (
                        // Header panel that shows the current location
                        <div className="name-bar flex flex1 flex--space-between-content">
                            <div className="flex flex--center-items gap-l size400 w600 ">
                                <ion-icon onClick={handleGoBack} name="arrow-back-outline" />
                                <div className="breadcrumbs">{breadcrumbs}</div>
                            </div>
                            <div className="lock-buttons">
                                {/* Lock and unlock buttons for accountant */}
                                {focusedFiles.isLocked ? (
                                    <Locked className="lock-btn pointer" onClick={unlock} />
                                ) : (
                                    <Unlocked className="lock-btn pointer" onClick={lock} />
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {/* folders and lock progress */}
            <div className={`flex gap-xxxl`}>
                <div>
                    {clientFiles &&
                        !isObjectEmpty(clientFiles) &&
                        Object.keys(sortFoldersByName(clientFiles[id][yearSelection].folders)).map((folderName, index) => {
                            const childrenFolders = clientFiles[id][yearSelection].folders[folderName]?.folders;
                            const isEmpty = Object.keys(childrenFolders ?? {})?.length === 0;
                            return (
                                <div key={folderName + index} className="fixed-height size400">
                                    <SidebarFolder folderName={folderName} isEmpty={isEmpty} label={t(folderName)} />
                                </div>
                            );
                        })}
                </div>

                {breadcrumbs.length === 0 ? (
                    // If we are in root directory, show the "lock progress" in the boxes
                    clientFiles &&
                    !isObjectEmpty(clientFiles) && (
                        <div className="flex flex--column flex--grow">
                            {Object.keys(sortFoldersByName(clientFiles[id][yearSelection].folders)).map((folderName, index) => {
                                if (clientFiles[id][yearSelection].folders[folderName].isCustom) return null;
                                const progress = clientFiles[id][yearSelection].folders[folderName]?.progress;
                                return (
                                    progress && (
                                        <div key={folderName + index} className="fixed-height size400">
                                            <LockProgress lockedBoxes={progress[0]} allBoxes={progress[1]} />
                                        </div>
                                    )
                                );
                            })}
                        </div>
                    )
                ) : (
                    // Show normal file/folder system
                    <div className="flex flex--grow flex--column">
                        <div className="folders-container flex flex--wrap gap-s">
                            <Folder addButton size="small" />
                            {focusedFiles.folders.map(({ fileName, isEmpty, isLocked, folderId, isCustom }, index) => {
                                return (
                                    <Folder
                                        key={folderId}
                                        folderId={folderId}
                                        fileName={fileName}
                                        isEmpty={isEmpty}
                                        locked={isLocked}
                                        size="small"
                                        isCustomFolder={isCustom}
                                    />
                                );
                            })}
                        </div>
                        <Table />
                    </div>
                )}
            </div>

            {lockSuccesful && <Modal modal={<WarningBox text={t("lock-successful")} isCheckmark />} closeIn3 dismiss={[setLockSuccesful]} />}
            {unlockSuccesful && <Modal modal={<WarningBox text={t("unlock-successful")} isCheckmark />} closeIn3 dismiss={[setUnlockSuccesful]} />}
            {showError && <Modal modal={<WarningBox text={t("lock-error")} />} closeIn3 dismiss={[setShowError]} />}
        </div>
    );
};

export default ArchiveTab;
