import Image from "components/image/image.component";
import InputField from "components/input-field/input-field.component";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { useMemo } from "react";
import { IoClose } from "react-icons/io5";
import { AuthContext } from "src/contexts/auth.context";

import { POSITIONS } from "utils/lists.utils";

import "./new-profile-modal.style.scss";
import useTranslate from "src/hooks/use-translate";

const Page1 = ({ position, update }) => {
    const t = useTranslate("NewClientModal");
    const handleSelect = (idx) => {
        update("position", idx);
    };

    return (
        <div className="page-container page1">
            <p className="margin-xl--top w600">
                {t("step")} 1 <span className="red">|</span> {t("Choose the access type of the account...")}
            </p>
            <div className="flex flex--grow flex--center gap-l">
                <button
                    onClick={() => {
                        handleSelect(0);
                    }}
                    className={`size400 w600 smooth-corners padding-m--block btn 
					${position === 0 && "btn--selected"}
					`}>
                    {t("Administrator")}
                </button>
                <button
                    onClick={() => {
                        handleSelect(1);
                    }}
                    className={`size400 w600 smooth-corners padding-m--block btn 
					${position === 1 && "btn--selected"}
					`}>
                    {t("Member")}
                </button>
            </div>
        </div>
    );
};
const Page2 = ({ newProfileInfo, update, color = "" }) => {
    const { name, surname, username, jobTitle, email, phone, position } = newProfileInfo;
    const profileType = POSITIONS[position];
    const fullName = useMemo(() => name + " " + surname, [name, surname]);

    const t = useTranslate("NewClientModal");

    const handleChange = (key, value) => {
        update(key, value);
    };

    return (
        <div className="page-container page3-1">
            <p className="margin-xl--top w600">
                {t("step")} 2 <span className="red">|</span> {profileType}
            </p>
            <div className="grid  gap-l margin-xl--top">
                <div id="left" className="flex flex--column flex--space-between-content">
                    <Image name={fullName} color={color} round size="6rem" />
                    <div className="flex flex--column gap-xl">
                        <InputField
                            fieldType="underline"
                            label="Name"
                            placeholder="Jane"
                            onChange={({ target: { value } }) => handleChange("name", value)}
                            value={name}
                        />
                        <InputField
                            fieldType="underline"
                            label="Surname"
                            placeholder="Doe"
                            onChange={({ target: { value } }) => handleChange("surname", value)}
                            value={surname}
                        />
                    </div>
                </div>
                <div id="right" className="flex flex--column gap-xl">
                    <InputField
                        fieldType="underline"
                        label="Username"
                        placeholder="janeDoe001"
                        onChange={({ target: { value } }) => handleChange("username", value)}
                        value={username}
                    />
                    <InputField
                        fieldType="underline"
                        label="Job Title"
                        placeholder="Chief Financial Officer"
                        onChange={({ target: { value } }) => handleChange("jobTitle", value)}
                        value={jobTitle}
                    />
                    <InputField
                        fieldType="underline"
                        label="Email"
                        type="email"
                        placeholder="jane.doe@my-company.com"
                        onChange={({ target: { value } }) => handleChange("email", value)}
                        value={email}
                    />
                    <InputField
                        fieldType="underline"
                        label="Phone"
                        type="tel"
                        placeholder="+0123456789"
                        onChange={({ target: { value } }) => handleChange("phone", value)}
                        value={phone}
                    />
                </div>
            </div>
        </div>
    );
};

const initialProfle = {
    name: "",
    surname: "",
    username: "",
    jobTitle: "",
    email: "",
    phone: "",
    position: null,
    // role: null,
    // firmId: null,
};

const NewProfileModal = ({ color = "", dismiss = () => {}, onSave = () => {}, firmId = null }) => {
    const {
        user: { role, userId, accountant },
    } = useContext(AuthContext);
    const [page, setPage] = useState(0);
    const [newProfileInfo, setNewProfileInfo] = useState({ ...initialProfle, role: role, firmId, userId, accountant });
    const [canContinue, setCanContinue] = useState(false);

    const t = useTranslate("Clients");

    const profileTypeIndex = useMemo(() => newProfileInfo.position, [newProfileInfo]);

    useEffect(() => {
        if (page === 0 && profileTypeIndex != null) setCanContinue(true);
        else if (
            page === 1 &&
            (Object.values(newProfileInfo).findIndex((value) => value == null || value === "") !== -1 || !isValidEmail(newProfileInfo.email))
        )
            setCanContinue(false);
        else if (page === 1 && Object.values(newProfileInfo).findIndex((value) => value == null || value === "") === -1) setCanContinue(true);
    }, [profileTypeIndex, page, newProfileInfo]);

    const nextPageButtonLabel = useMemo(() => (page === 0 ? "continue" : "finish"), [page]);

    const updateProfileInfo = (key, value) => {
        setNewProfileInfo((oldValue) => {
            const newValue = { ...oldValue, [key]: value };
            return newValue;
        });
    };

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleContinue = () => {
        if (page === 1) {
            submit();
            return;
        }
        setPage((oldValue) => oldValue + 1);
    };

    const handleBack = () => {
        if (page === 0) return;
        setPage((oldValue) => oldValue - 1);
    };

    const submit = () => {
        onSave(newProfileInfo);
        dismiss();
    };

    return (
        <div className="new-profile-modal-container absolute fill">
            <div onClick={dismiss} className="absolute fill" />
            <div className="modal flex flex--column smooth-corners padding-xxl--inline padding-xxl--block">
                <div className="modal-header flex flex--space-between-content flex--center-items padding-s--bottom">
                    <p className="size500 w600">{t("add new client")}</p>
                    <IoClose onClick={dismiss} size={"1.6rem"} className="pointer" />
                </div>
                <div className="flex flex--grow scroll-bar scroll-bar--overflow">
                    {page === 0 && <Page1 position={newProfileInfo?.position} update={updateProfileInfo} />}
                    {page === 1 && <Page2 newProfileInfo={newProfileInfo} update={updateProfileInfo} color={color} />}
                </div>
                <div className="flex margin-xxl--top">
                    {page > 0 && (
                        <button onClick={handleBack} style={{ borderRadius: "1rem" }} className="size400 border padding-xxl--inline padding-xs--block">
                            {t("Back")}
                        </button>
                    )}
                    <button
                        disabled={!canContinue}
                        onClick={handleContinue}
                        style={{ marginLeft: "auto", borderRadius: "1rem" }}
                        className="size400 border padding-xxl--inline padding-xs--block">
                        {t(nextPageButtonLabel)}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default NewProfileModal;
