import { startOfMonth, startOfWeek, endOfMonth, endOfWeek, startOfDay, addDays } from "date-fns";

export const MONTHS = {
	0: "January",
	1: "February",
	2: "March",
	3: "April",
	4: "May",
	5: "June",
	6: "July",
	7: "August",
	8: "September",
	9: "October",
	10: "November",
	11: "December",
};

export function getMonthName(index) {
	return MONTHS[index];
}

export function takeWeek(start = new Date()) {
	let date = startOfWeek(startOfDay(start));

	return function () {
		const week = [...Array(7)].map((_, i) => addDays(date, i));
		date = addDays(week[6], 1);
		return week;
	};
}

export default function takeMonth(start = new Date()) {
	let month = [];
	let date = start;

	function lastDayOfRange(range) {
		return range[range.length - 1][6];
	}

	return function () {
		const weekGen = takeWeek(startOfMonth(date));
		const endDate = startOfDay(endOfWeek(endOfMonth(date)));
		month.push(weekGen());

		while (lastDayOfRange(month) < endDate) {
			month.push(weekGen());
		}

		const range = month;
		month = [];
		date = addDays(lastDayOfRange(range), 1);

		return range;
	};
}

export function getYearInterval(year) {
	const start = year - (year % 10);
	return [start, start + 9];
}
