import { useContext } from "react";

import { FolderContext } from "src/contexts/folder.context";

import "./file-container.style.scss";

const FileContainer = ({ fileName, selected = false, refrnc }) => {
    const { openFile } = useContext(FolderContext);

    const handleClick = () => {
        openFile(fileName);
    };

    return (
        <div className="file-container-wrapper" ref={refrnc}>
            <div onClick={handleClick} className={`file-container ${selected && "selected"}`}>
                {fileName}
            </div>
        </div>
    );
};

export default FileContainer;
