import { createContext, useMemo, useCallback } from "react";
import { S3 } from "aws-sdk";
import JSZip from "jszip";
import axios from "axios";

const s3 = new S3({
    region: process.env.REACT_APP_AWS_REGION,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    signatureVersion: "v4",
});

// Non-member functions

export async function secureLinkPut(imgName) {
    const params = {
        Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
        Key: imgName,
        Expires: 60,
    };
    const secureLink = await s3.getSignedUrlPromise("putObject", params);
    return secureLink;
}

export async function secureLinkRead(imgName) {
    const params = {
        Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
        Key: imgName,
        Expires: 10,
    };

    const secureLink = await s3.getSignedUrlPromise("getObject", params);

    return secureLink;
}

// S3 Context

export const S3Context = createContext({
    uploadMsgFileToS3: () => {},
    uploadTicketFilesToS3: () => {},
    readFromS3: () => {},
    downloadFolderAsZip: () => {},
    downloadFolderAsZipWithRef: () => {},
    downloadMsgFile: () => {},
    downloadTicketFile: () => {},
    downloadAllTicketFilesAsZip: () => {},
});

const S3Provider = ({ children }) => {
    const uploadMsgFileToS3 = useCallback((chatId, file) => {
        const params = {
            Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
            Key: `msg-files/${chatId}/${file.name}`,
            Body: file,
            ACL: "private",
        };

        s3.upload(params, (err, data) => {
            if (err) console.log(err);
        });
    }, []);

    const uploadTicketFilesToS3 = useCallback((ticketId, files) => {
        // upload multiple ticket files to S3
        for (const file of files) {
            const params = {
                Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
                Key: `ticket-files/${ticketId}/${file.name}`,
                Body: file,
                ACL: "private",
            };
            s3.upload(params, (err, data) => {
                if (err) console.log(err);
                else console.log({ data });
            });
        }
    }, []);

    const readFromS3 = useCallback(async (fileKey) => {
        const params = {
            Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
            Key: fileKey,
        };

        const response = await s3.getObject(params).promise();
        const blob = new Blob([response.Body]);
        return blob;
    }, []);

    const downloadMsgFile = useCallback(
        async (fileName, chatId) => {
            const fileKey = `msg-files/${chatId}/${fileName}`;
            const blob = await readFromS3(fileKey);
            const downloadLink = document.createElement("a");
            const url = window.URL.createObjectURL(blob);
            downloadLink.href = url;
            downloadLink.download = fileName;
            downloadLink.click();
        },
        [readFromS3]
    );

    const downloadTicketFile = useCallback(
        async (fileName, ticketId) => {
            const fileKey = `ticket-files/${ticketId}/${fileName}`;
            const blob = await readFromS3(fileKey);
            const downloadLink = document.createElement("a");
            const url = window.URL.createObjectURL(blob);
            downloadLink.href = url;
            downloadLink.download = fileName;
            downloadLink.click();
        },
        [readFromS3]
    );

    const downloadAllTicketFilesAsZip = useCallback(async (requestedBy, ticketId) => {
        const key = `ticket-files/${ticketId}`;
        // List objects in the folder
        const listObjectsResult = await s3
            .listObjectsV2({
                Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
                Prefix: key,
            })
            .promise();

        // Create a new zip file
        const zip = new JSZip();

        // Add each object in the folder to the zip file
        for (const object of listObjectsResult.Contents) {
            const objectKey = object.Key;

            // Ignore the folder itself
            if (objectKey === key) {
                continue;
            }

            // Get the object data from S3
            const getObjectResult = await s3
                .getObject({
                    Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
                    Key: objectKey,
                })
                .promise();

            // Add the object to the zip file
            zip.file(objectKey.replace(key, ""), getObjectResult.Body);
        }
        // Generate the zip file
        const zipBlob = await zip.generateAsync({ type: "blob" });

        // Save the zip file to the user's device
        const downloadLink = document.createElement("a");
        downloadLink.download = `${requestedBy}_ticket.zip`;
        downloadLink.href = URL.createObjectURL(zipBlob);
        downloadLink.click();
    }, []);

    const downloadFolderAsZip = useCallback(async (folderPath, folderName) => {
        // List objects in the folder
        const listObjectsResult = await s3
            .listObjectsV2({
                Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
                Prefix: folderPath,
            })
            .promise();

        // Create a new zip file
        const zip = new JSZip();

        // Add each object in the folder to the zip file
        for (const object of listObjectsResult.Contents) {
            const objectKey = object.Key;

            // Ignore the folder itself
            if (objectKey === folderPath) {
                continue;
            }

            // Get the object data from S3
            const getObjectResult = await s3
                .getObject({
                    Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
                    Key: objectKey,
                })
                .promise();

            // Add the object to the zip file
            zip.file(objectKey.replace(folderPath, ""), getObjectResult.Body);
        }
        // Generate the zip file
        const zipBlob = await zip.generateAsync({ type: "blob" });

        // Save the zip file to the user's device
        const downloadLink = document.createElement("a");
        downloadLink.download = `${folderName}.zip`;
        downloadLink.href = URL.createObjectURL(zipBlob);
        downloadLink.click();
    }, []);

    const downloadFolderAsZipWithRef = useCallback(async (folderPath, folderName, clientUserId, clientFirmId, yearSelection, currentLocation) => {
        const listObjectsResult = await s3
            .listObjectsV2({
                Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
                Prefix: folderPath,
            })
            .promise();

        // Create a new zip file
        const zip = new JSZip();

        const clientuserIdfirmId = [clientUserId, clientFirmId];
        const clientuserIdfirmIdJoined = clientuserIdfirmId.join(",");
        // fetch the file system of the current folderPath for ref values
        const resp = await axios.get(`/getClientFiles?clients=${clientuserIdfirmIdJoined}`);
        const clientId = clientFirmId === -1 ? clientUserId : clientFirmId;
        const clientFiles = resp.data[clientId][yearSelection].folders;
        let tempCurrentLocation = clientFiles;
        currentLocation.slice(0, -1).forEach((folderName) => {
            tempCurrentLocation = tempCurrentLocation[folderName].folders;
        });
        const currentFolderLocation = tempCurrentLocation[currentLocation[currentLocation.length - 1]].folders[folderName]; // files: [], folders: {} of the current folderName..

        // Add each object in the folder to the zip file
        for (const object of listObjectsResult.Contents) {
            const objectKey = object.Key;
            // Ignore the folder itself
            if (objectKey === folderPath) {
                continue;
            }

            // go the the current file in the file system to get the ref value
            const locationOfCurrFile = objectKey.slice(objectKey.indexOf(folderName) + folderName.length + 1, objectKey.lastIndexOf("/"));
            const locationOfCurrFileArr = locationOfCurrFile !== "" ? locationOfCurrFile.split("/") : []; // in format of ["grandparentfolder", "parentfolder", "folder"...]
            let tempCurrentLocation = currentFolderLocation;
            locationOfCurrFileArr.forEach((folderName) => {
                tempCurrentLocation = tempCurrentLocation.folders[folderName];
            });
            const currentLocationFiles = tempCurrentLocation.files;

            // get the ref value of the file
            const currFileName = objectKey.slice(objectKey.lastIndexOf("/") + 1);
            const file = currentLocationFiles.find((file) => file.fileName === currFileName);
            if (!file) {
                console.error({ currFileName });
                continue;
            }
            const refValue = file.ref;
            console.log({ refValue, currFileName, currentLocationFiles });

            // Get the object data from S3
            const getObjectResult = await s3
                .getObject({
                    Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
                    Key: objectKey,
                })
                .promise();

            // add ref value of the at the end of the file name before the extension
            const oldFileNameFull = objectKey.replace(folderPath, "").slice(1);
            const lastIdxOfSlash = oldFileNameFull.lastIndexOf("/");
            const parentLocation = oldFileNameFull.slice(0, lastIdxOfSlash === -1 ? 0 : lastIdxOfSlash);
            const oldFileName = oldFileNameFull.slice(oldFileNameFull.lastIndexOf("/") + 1).split(".")[0];
            const extension = oldFileNameFull.split(".")[1] ?? "pdf";
            const fileName = `${parentLocation}/${refValue}-${oldFileName}.${extension}`;

            // Add the object to the zip file
            zip.file(fileName, getObjectResult.Body);
        }
        // Generate the zip file
        const zipBlob = await zip.generateAsync({ type: "blob" });

        // Save the zip file to the user's device
        const downloadLink = document.createElement("a");
        downloadLink.download = `${folderName}_ref.zip`;
        downloadLink.href = URL.createObjectURL(zipBlob);
        downloadLink.click();
    }, []);

    const value = useMemo(
        () => ({
            uploadMsgFileToS3,
            uploadTicketFilesToS3,
            readFromS3,
            downloadMsgFile,
            downloadTicketFile,
            downloadAllTicketFilesAsZip,
            downloadFolderAsZip,
            downloadFolderAsZipWithRef,
        }),
        [
            uploadMsgFileToS3,
            uploadTicketFilesToS3,
            readFromS3,
            downloadMsgFile,
            downloadTicketFile,
            downloadAllTicketFilesAsZip,
            downloadFolderAsZip,
            downloadFolderAsZipWithRef,
        ]
    );
    return <S3Context.Provider value={value}>{children}</S3Context.Provider>;
};

export default S3Provider;
