import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Table from "components/accountant-table/accountant-table.component";

import { AuthContext } from "src/contexts/auth.context";
import { ClientsContext } from "src/contexts/clients.context";

import "./notifications.style.scss";
import useTranslate from "src/hooks/use-translate";
import axios from "axios";
import Modal from "components/modal/modal.component";
import YesNoBox from "components/yes-no-box/YesNoBox.component";

const Notifications = () => {
    const {
        user: { name, userId },
    } = useContext(AuthContext);
    const { clients } = useContext(ClientsContext);
    const navigate = useNavigate();

    const t = useTranslate("Clients");

    const [data, setData] = useState([]);
    const [showYesNo, setShowYesNo] = useState(false);
    const [approvedRemoveNotif, setApprovedRemoveNotif] = useState(false);
    const [index, setIndex] = useState(null);

    useEffect(() => {
        if (clients) {
            setData(() =>
                clients.filter((client) => {
                    if (client.uploads > 0) return true;
                    else if (client.tickets > 0) return true;
                    else if (client.invoices > 0) return true;
                    return false;
                })
            );
        }
    }, [clients]);

    const handleClick = (companyName) => {
        const index = clients.findIndex((client) => {
            if (client.firmId === -1) {
                return companyName === client.name + " " + client.surname;
            }
            return companyName === client.name ?? "";
        });
        sessionStorage.setItem("clickedNotification", "true");
        navigate("/clients", {
            replace: true,
            state: { initialIndex: index, id: clients[index].userId ?? clients[index].firmId, isCompany: clients[index].firmId !== -1 },
        });
    };

    const handleDelete = async (index) => {
        setIndex(index);
        setShowYesNo(true);
    };

    const deleteNotification = useCallback(
        async (index) => {
            const error = await axios
                .post("removeNotification", { userId: data[index].userId ?? 0, firmId: data[index].firmId, accountantId: userId })
                .then((res) => {
                    if (res.status !== 200) return true;
                    return null;
                })
                .catch(() => {
                    return true;
                });
            if (error) return;
            setData((prevData) => {
                const tempData = [...prevData];
                tempData.splice(index, 1);
                return tempData;
            });
        },
        [userId, data]
    );

    const handleDenyRemoveNotif = () => {
        setIndex(null);
    };

    useEffect(() => {
        if (approvedRemoveNotif && index !== null) {
            deleteNotification(index);
            setApprovedRemoveNotif(false);
            setIndex(null);
        }
    }, [approvedRemoveNotif, index, setApprovedRemoveNotif, deleteNotification]);

    return (
        <div className="notifications-container route-container margin-xxxl--top">
            <p className="size700 w600 margin-l--bottom">
                {t("Good Morning")} {name},
            </p>
            <p className="size600 w500 margin-s--bottom with-line">{t("notifications")}</p>
            <Table data={data} onRowClick={handleClick} onDelete={handleDelete} />
            {showYesNo && (
                <Modal
                    modal={
                        <YesNoBox
                            setVisible={setShowYesNo}
                            setApproved={setApprovedRemoveNotif}
                            setDenied={handleDenyRemoveNotif}
                            text={t("remove-notif-warning")}
                            rightOption={t("Remove Notification")}
                            rightOptionRed
                        />
                    }
                    touchable
                    dismiss={[setShowYesNo]}
                />
            )}
        </div>
    );
};
export default Notifications;
