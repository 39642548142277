import { useContext, useEffect, useState } from "react";

import Selector from "components/selector/selector.component";

import { ClientsContext } from "src/contexts/clients.context";

import { ACTIVATION_TYPES, ARCHIVE_PERIODS, SAFE_LOCKING_PERIODS, SUBSCRIPTIONS } from "utils/lists.utils";

import "./plans-sub-category.style.scss";
import { getItemWhere } from "utils/array.utils";
import useTranslate from "src/hooks/use-translate";
import Modal from "components/modal/modal.component";
import YesNoBox from "components/yes-no-box/YesNoBox.component";

const PLANS = [
    {
        key: "subscription",
        label: "Subscription",
        detail: "Client type will be determined based on the size and revenue of the company.",
        choices: SUBSCRIPTIONS,
    },
    {
        key: "archivePeriod",
        label: "Archive Period",
        detail: "The period which your accounts of ACHANTS, VENTES, and TVA will be examined by the client’s accountant.",
        choices: ARCHIVE_PERIODS,
    },
    {
        key: "safeLockingPeriod",
        label: "Safe Locking Period",
        detail: "The period which Client’s safe to be locked. The Client will get notifications about their upcoming safe locking deadlines.",
        choices: SAFE_LOCKING_PERIODS,
    },
    {
        key: "liveChat",
        label: "Live Chat",
        detail: "Direct contact between the Client and Accountant through the live chat.",
        choices: ACTIVATION_TYPES,
    },
    {
        key: "mobile",
        label: "Mobile App",
        detail: "Upload your documents just by scanning the pages using the METI Box mobile application. ",
        choices: ACTIVATION_TYPES,
    },
];

const WARNING_LIST = ["change-archive-period", "change-subscription", "change-safelocking-period", "change-chatbox", "change-mobileplan"];

const PlansSubTab = ({ id }) => {
    const { clients, updateCompanyInfoPartially } = useContext(ClientsContext);
    const [showWarning, setShowWarning] = useState(false);
    const [approved, setApproved] = useState(false);
    const [keyValue, setKeyValue] = useState({ key: "", value: "", index: 0 });
    const t = useTranslate("Archive");

    let client = getItemWhere(clients, "firmId", id);
    if (!client) client = getItemWhere(clients, "userId", id);
    const { firmId } = client;
    const isPersonalAccount = firmId === -1;

    let data;
    if (isPersonalAccount) data = getItemWhere(clients, "userId", id);
    else data = getItemWhere(clients, "firmId", id);

    const handleChange = (key, value, oldValue, index) => {
        if (value === oldValue) return;
        setShowWarning(true);
        setKeyValue({ key, value, index });
    };

    useEffect(() => {
        if (approved) {
            updateCompanyInfoPartially(id, keyValue.key, keyValue.value);
            setApproved(false);
        }
    }, [approved, id, keyValue, updateCompanyInfoPartially]);

    return (
        <div className="plans-sub-category-container padding-m--left">
            <p className="with-line w600 size400">{t("plans")}</p>
            {PLANS.map((plan, index) => {
                const { key, label, detail, choices } = plan;
                return (
                    <div key={index} className="grid padding-m--block">
                        <div>
                            <p className="w600 size400 padding-s--bottom">{t(label)}</p>
                            <p className="w400 size300">{t(detail)}</p>
                        </div>
                        <Selector
                            onSelected={(value) => handleChange(key, choices.indexOf(value), data[key], index)}
                            selected={choices[data[key]]}
                            data={choices}
                        />
                    </div>
                );
            })}
            {showWarning && (
                <Modal
                    modal={<YesNoBox setVisible={setShowWarning} setApproved={setApproved} text={t(WARNING_LIST[keyValue.index])} />}
                    touchable
                    dismiss={[setShowWarning]}
                />
            )}
        </div>
    );
};

export default PlansSubTab;
