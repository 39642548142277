import { useContext, useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import "./notes-tab.style.scss";
import axios from "axios";
import { AuthContext } from "src/contexts/auth.context";

const NotesTab = ({ id, isCompany }) => {
    const {
        user: { userId },
    } = useContext(AuthContext);
    const [text, setText] = useState("");

    useEffect(() => {
        (async () => {
            await axios.get(`getNotes?accountantId=${userId}&clientId=${id}&isCompany=${isCompany}`).then((res) => {
                if (res.status === 200 && res.data.note != undefined) setText(res.data.note);
            });
        })();
    }, [id, isCompany, userId]);

    const config = {
        plugins: ClassicEditor.builtinPlugins,
        // toolbar: ClassicEditor.defaultConfig.toolbar.items,
        toolbar: ["heading", "|", "bold", "italic", "|", "outdent", "indent", "|", "bulletedList", "numberedList", "|", "undo", "redo"],
    };

    const handleEditNotes = async () => {
        console.log({ text });
        await axios.post("saveNotes", { note: text, accountantId: userId, isCompany, clientId: id });
    };

    return (
        <div className="notes-container">
            <CKEditor
                editor={ClassicEditor}
                data={text}
                config={config}
                onChange={(event, editor) => {
                    const data = editor.getData();
                    if (data.length > 1999) {
                        setText(data.slice(0, 2000));
                        return;
                    }
                    setText(data);
                }}
                onBlur={handleEditNotes}
            />
        </div>
    );
};

export default NotesTab;
