import { createContext, useCallback, useMemo, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export const AuthContext = createContext({
    user: null,
    isSignedIn: null,
    login: () => {},
    logout: () => {},
    updateUserFromServer: () => {},
    resetPassword: () => {},
    newPassword: () => {},
    updateUser: () => {},
});

axios.defaults.headers.common["Authorization"] = `${localStorage.getItem("sesToken")}` ?? `${sessionStorage.getItem("sesToken")}` ?? null;

const AuthProvider = ({ children }) => {
    const navigate = useNavigate();

    const [isSignedIn, setIsSignedIn] = useState(localStorage.getItem("isSignedIn") ?? sessionStorage.getItem("isSignedIn") === "1" ?? false);
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("user") ?? sessionStorage.getItem("user")) ?? null);
    const [rememberMe, setRememberMe] = useState(false);

    const login = useCallback(
        async ({ email, password, rememberMe }) => {
            // log the user in
            if (isSignedIn) return;
            var errorStatus = null;
            await axios
                .post("/login", {
                    // send login request
                    email: email,
                    password: password,
                })
                .then((res) => {
                    const { sesToken, userInfo } = res.data;
                    setUser(userInfo); // set the user data to data that is returned from server
                    setIsSignedIn(true); // set isSignedIn to true
                    axios.defaults.headers.common["Authorization"] = sesToken; // set default access token to session token that is returned
                    localStorage.setItem("sesToken", sesToken); // save the session token to localStorage to keep session alive on refresh
                    sessionStorage.setItem("user", JSON.stringify(userInfo)); // save the user values to sessionStorage to keep session alive on refresh
                    sessionStorage.setItem("isSignedIn", "1"); // save the signed in information to sessionStorage
                    if (rememberMe) {
                        setRememberMe(true);
                        localStorage.setItem("user", JSON.stringify(userInfo));
                        localStorage.setItem("isSignedIn", "1");
                    } else {
                        localStorage.removeItem("user");
                        localStorage.removeItem("isSignedIn");
                    }
                })
                .catch((err) => {
                    errorStatus = err.response.status;
                });

            return errorStatus;
        },
        [isSignedIn]
    );

    const logout = useCallback(() => {
        // log the user out, set all values to null and navigate back to login page
        sessionStorage.removeItem("isSignedIn");
        sessionStorage.removeItem("user");
        localStorage.removeItem("user");
        localStorage.removeItem("isSignedIn");
        localStorage.removeItem("sesToken");
        setIsSignedIn(false);
        setUser(null);
        navigate("/", { replace: true });
        // navigate("/");
    }, [navigate]);

    const resetPassword = useCallback(async ({ email }) => {
        await axios.post("resetPassword", { email }).then(console.log).catch(console.error);
    }, []);

    const newPassword = useCallback(async ({ email, password }) => {
        await axios.post("setNewPassword", { email, password }).then(console.log).catch(console.error);
    }, []);

    const updateUserFromServer = useCallback(
        async (newUser) => {
            await axios
                .get(`/getUserInfo?userId=${user.userId}`)
                .then((res) => {
                    setUser(res.data);
                    sessionStorage.setItem("user", JSON.stringify(res.data));
                    if (rememberMe) localStorage.setItem("user", JSON.stringify(res.data));
                })
                .catch(console.error);
        },
        [user, rememberMe]
    );

    const updateUser = useCallback(
        (key_value) => {
            setUser((prevUser) => ({ ...prevUser, ...key_value }));
            sessionStorage.setItem("user", JSON.stringify({ ...user, ...key_value }));
            if (rememberMe) localStorage.setItem("user", JSON.stringify({ ...user, ...key_value }));
        },
        [rememberMe, user]
    );

    const value = useMemo(
        () => ({ user, isSignedIn, login, logout, updateUserFromServer, resetPassword, newPassword, updateUser }),
        [user, isSignedIn, login, logout, updateUserFromServer, resetPassword, newPassword, updateUser]
    );

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
