import { Fragment, useState, useContext, useMemo } from "react";
import { format } from "date-fns";
import { BsArrowLeft } from "react-icons/bs";
import parse from "html-react-parser";

import Image from "components/image/image.component";

import { TicketsContext } from "src/contexts/tickets.context";

import useTranslate from "src/hooks/use-translate";

import "./ticket-details.style.scss";
import RichTextEditor from "../richt-text-editor/rich-text-editor.component";
import { AuthContext } from "src/contexts/auth.context";
import { S3Context } from "src/contexts/s3.context";
import { formatDate } from "utils/date.utils";
const downloadIcon = require("src/assets/download.jpg");

const Field = ({ label, data }) => {
    return (
        <p className="size400 border--bottom padding-xxxl--right padding-s--bottom">
            <span className="w500 padding-l--right">{label}</span> {data}
        </p>
    );
};

const TicketDetails = ({ goBack, ticketId }) => {
    const { downloadTicketFile, downloadAllTicketFilesAsZip } = useContext(S3Context);
    const { user } = useContext(AuthContext);
    const { closeTicket, getTicketById, respond } = useContext(TicketsContext);

    const { userId, name, surname } = useMemo(() => user, [user]);
    const t = useTranslate("Tickets");

    const [isTextEditorVisible, setIsTextEditorVisible] = useState(false);
    const [ticket, setTicket] = useState(getTicketById(ticketId));
    const [files, setFiles] = useState([]);

    const { requestedBy, subject, status, createdAt, lastActivity, assignedTo, messages } = useMemo(() => ticket, [ticket]);

    const requestedByName = useMemo(() => {
        if (requestedBy === userId) return `${name} ${surname}`;

        return `${requestedBy.name} ${requestedBy.surname}`;
    }, [requestedBy, userId, name, surname]);

    const assignedToName = useMemo(() => {
        if (assignedTo === userId) return `${name} ${surname}`;

        return `${assignedTo.name} ${assignedTo.surname}`;
    }, [assignedTo, userId, name, surname]);

    const handleWriteReplyButton = () => {
        setIsTextEditorVisible(true);
    };

    const handleCloseButton = () => {
        closeTicket(ticketId, userId);
        setTicket((oldValue) => {
            return { ...oldValue, status: 0 };
        });
    };

    const handleSendRespond = (text) => {
        // the reason to use ?? is left side is for user who created ticket and replies now, right side is the replying user who the ticket is sent to at the beginning
        respond(
            ticketId,
            text,
            assignedTo.userId ?? requestedBy.userId,
            user.userId,
            files.map((file) => file.name)
        );
        setIsTextEditorVisible(false);
    };

    return (
        <div className="ticket-details-container flex flex--column">
            <div name="header" className="flex flex--center-items padding-xs--bottom border--bottom">
                <BsArrowLeft onClick={goBack} size={"2.4rem"} className="pointer" />
                <p className="size500 w600 padding-l--left">{subject}</p>
                <p className="size500 padding-m--inline">{`[${ticketId}]`}</p>
                <span className="border round-corners  padding-l--inline padding-xs--block">{status ? "OPEN" : "CLOSED"}</span>
                <div className="flex flex--grow flex--end-content gap-m padding-l--right">
                    <button
                        onClick={handleWriteReplyButton}
                        disabled={status === 0}
                        className={`btn btn--hover size400 w600 padding-l--inline padding-xs--block ${status === 0 && "btn--disabled"}`}>
                        {t("Write a Reply")}
                    </button>
                    <button
                        onClick={handleCloseButton}
                        disabled={status === 0}
                        className={`btn btn--hover btn--red-text size400 w600 padding-l--inline padding-xs--block ${status === 0 && "btn--disabled"}`}>
                        {t("Close Ticket")}
                    </button>
                </div>
            </div>
            <div name="sub-header" className="padding-xxxl--right padding-l--top margin-xxxl--top">
                <div className="flex flex--start-items gap-xxxl">
                    <div name="left">
                        <Field label={t("Requested by")} data={requestedByName} />
                        <div className="margin-xxl--top">
                            <Field label={t("Assigned to")} data={assignedToName} />
                        </div>
                    </div>
                    <div className="flex--grow" />
                    <Field label={t("Last Activity")} data={formatDate(lastActivity)} />
                    <Field label={t("Created Date")} data={formatDate(createdAt)} />
                </div>
            </div>
            <div name="details" className="flex--grow padding-xxxl--right margin-xxl--top scroll-bar scroll-bar--overflow">
                {messages &&
                    messages.map(({ sourceId, date, messages, name, surname, companyName, color, files = null }, index) => {
                        return (
                            <Fragment key={index}>
                                <div className="ticket-grid">
                                    <Image name={`${name} ${surname}`} color={color} size={"3rem"} round />
                                    <div name="header" className="flex flex--center-items border--bottom gap-xl">
                                        <p className="size400 w600">
                                            {`${name} ${surname}`} {companyName !== "" && <span> | {companyName}</span>}
                                        </p>
                                        <span className="flex--grow" />
                                        <p>{formatDate(date)}</p>
                                    </div>
                                    <div></div>
                                    <div name="message" className="padding-l--top padding-xxxl--bottom">
                                        {parse(messages)}
                                    </div>
                                </div>

                                {/* files to download */}
                                {files && (
                                    <div className=" gap-s ticket-files">
                                        {files.map((fileName, index) => (
                                            <div className="flex flex--center-items ticket-file" key={fileName + index}>
                                                <img
                                                    className="download-img pointer"
                                                    src={downloadIcon}
                                                    alt="a"
                                                    onClick={() => downloadTicketFile(fileName, ticketId)}
                                                />
                                                <p className="ticket-filename">{fileName}</p>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </Fragment>
                        );
                    })}

                {/* download all files as a zip */}
                {files && (
                    <div className="flex flex--center-items download-all" key={"download-all"}>
                        <img
                            className="download-img pointer"
                            src={downloadIcon}
                            alt="a"
                            onClick={() => downloadAllTicketFilesAsZip(requestedByName, ticketId)}
                        />
                        <p className="ticket-filename">{t("download-all")}</p>
                    </div>
                )}
                {isTextEditorVisible && (
                    <RichTextEditor
                        dismiss={() => {
                            setIsTextEditorVisible(false);
                        }}
                        save={handleSendRespond}
                        files={files}
                        setFiles={setFiles}
                    />
                )}
            </div>
        </div>
    );
};

export default TicketDetails;
