import { useContext } from "react";
// import { FiLock } from "react-icons/fi";

import { CalendarContext } from "src/contexts/calendar.context";

import "../calendar.style.scss";
import { InvoiceContext } from "src/contexts/invoice.context";

const YearView = () => {
    const { shownYear, shownYearsInterval, changeYear } = useContext(CalendarContext);
    const { calculatedBalanceChange } = useContext(InvoiceContext);

    const handleClick = (year) => {
        changeYear(year);
    };

    return (
        <div className="year-view-wrapper">
            {Array.from(Array(12)).map((_, index) => {
                const year = shownYearsInterval[0] + index;
                const locked = shownYear > year;
                // const locked = lockedYears.indexOf(year) != -1;
                return (
                    <div
                        key={index}
                        onClick={() => {
                            handleClick(year);
                        }}
                        className={`year 
						${year === shownYear && "selected"}
                        ${year === shownYear && parseInt(calculatedBalanceChange) > 0 && "green"}
                        ${year === shownYear && parseInt(calculatedBalanceChange) < 0 && "red"}
                        ${locked && "locked"}
						`}
                        // ${year > shownYearsInterval[1] && "disabled"}
                    >
                        {year}
                        {/* {locked && <FiLock className="lock-icon" />} */}
                    </div>
                );
            })}
        </div>
    );
};

export default YearView;
