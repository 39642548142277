//table of contents
/*
	clients

*/

// clients

export const COLORS = [
    "#000000",
    "#91490C",
    "#FF3B30",
    "#FF9500",
    "#FFCC00",
    "#34C759",
    "#5AC8FA",
    "#007AFF",
    "#5856D6",
    "#AF52DE",
    "#FF84D6",
    "#C7C7CC",
    "#FFFFFF",
];

export const COMPANY_TYPES = ["Personal", "Company"];

export const SUBSCRIPTIONS = ["Gold", "Silver", "Bronze"];

export const ARCHIVE_PERIODS = ["1 Box", "2 Box", "4 Box", "12 Box"];

export const SAFE_LOCKING_PERIODS = ["2 Weeks", "1 Month", "3 Months", "6 Months", "Inactivated"];

export const ACTIVATION_TYPES = ["Inactivated", "Activated"];

export const CLIENT_TYPES = ["SRL", "ASBL", "SA", "SDE", "SCS", "SNC"]; // "PP"

export const POSITIONS = ["Administrator", "Member"];

export const USER_TYPES = ["Accountant", "Client"];

export const YEARS = ["2023", "2022", "2021", "2020"]; // first item(year) is default for for system

/////////////////////////////////////////////////
