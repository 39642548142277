import axios from "axios";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { AuthContext } from "./auth.context";
import { FolderContext } from "./folder.context";

const ITEM_TYPES = {
    text: "Text Document",
    pdf: "PDF Document",
    folder: "File Folder",
};

export const COLUMN_NAMES = {
    name: "name",
    location: "location",
    date: "date deleted",
    type: "item type",
};

export const TrashContext = createContext({
    selectedItems: [],
    deletedItems: [],
    searchText: "",
    isSelectedAll: false,
    search: () => {},
    restoreItem: () => {},
    deleteItem: () => {},
    emptyTrash: () => {},
    handleClick: () => {},
    toggleSelectAll: () => {},
});

const TrashProvider = ({ children }) => {
    const { user } = useContext(AuthContext);
    const { files, clientFiles, updateFilesFromServer } = useContext(FolderContext);

    const [selectedItems, setSelectedItems] = useState([]); // list of selected rows
    const [deletedItems, setDeletedItems] = useState([]); // list of items in trash
    const [searchText, setSearchText] = useState("");
    const [isSelectedAll, setIsSelectedAll] = useState(false); // boolean that holds the value if all rows are selected

    useEffect(() => {
        // update 'isSelectedAll' value every time when an item is selected, deselected or deleted
        if (isSelectedAll && selectedItems.length < deletedItems.length) {
            setIsSelectedAll(false);
        }
    }, [selectedItems, isSelectedAll, deletedItems]);

    useEffect(() => {
        if (!user) return;
        const fetchTrash = async () => {
            await axios.get(`getTrash?userId=${user.userId}&firmId=${user.firmId}`).then(({ data: { deletedDocs, deletedFolders } }) => {
                const tempDeleteDocs = deletedDocs.map((doc) => {
                    return {
                        id: doc.docId,
                        name: doc.fileName,
                        location: doc.path,
                        date: doc.deletedAt,
                        type: ITEM_TYPES.pdf,
                    };
                });
                const tempDeleteFolders = deletedFolders.map((folder) => {
                    return {
                        id: folder.folderId,
                        name: folder.folderName,
                        location: folder.path,
                        date: folder.deletedAt,
                        type: ITEM_TYPES.folder,
                    };
                });
                setDeletedItems([...tempDeleteFolders, ...tempDeleteDocs]);
            });
        };
        fetchTrash();
    }, [user, files, clientFiles]);

    const search = useCallback((text) => {
        // set the searchText to the value 'text'
        setSearchText(text);
    }, []);

    const restoreItem = useCallback(async () => {
        // restore selected items (move out of the trash)
        console.log({ selectedItems, deletedItems });
        const docs = selectedItems.filter((item) => item.type !== "File Folder");
        const docListArr = docs.map((item) => item.id);
        const docList = "(" + docListArr.join(",") + ")";

        const folders = selectedItems.filter((item) => item.type === "File Folder");
        const folderListArr = folders.map((item) => item.id);
        const folderList = "(" + folderListArr.join(",") + ")";

        await axios.post(`restoreTrash`, { docList, folderList, userId: user.userId, firmId: user.firmId }).then((res) => {
            if (res.status !== 200) return console.error("Error restoring trash");
        });
        setDeletedItems((oldList) => {
            return oldList.filter((item) => !selectedItems.includes(item));
        });
        setSelectedItems([]);
        setIsSelectedAll(false);
        updateFilesFromServer();
    }, [selectedItems, user, deletedItems, updateFilesFromServer]);

    const deleteItem = useCallback(async () => {
        // delete the rows in 'selectedItems'
        const folderList = selectedItems
            .filter((item) => item.type === "File Folder")
            .map((item) => ({ folderId: item.id, folderName: item.name, location: item.location }));
        const docList = selectedItems
            .filter((item) => item.type !== "File Folder")
            .map((item) => ({ docId: item.id, fileName: item.name, path: item.location }));

        const err = await axios
            .post("deleteTrashItemsForever", { docList, folderList, clientId: user.userId, isCompany: user.firmId !== -1 })
            .then((res) => {
                if (res.status !== 200) return true;
                return null;
            })
            .catch((err) => {
                console.error(err);
                return true;
            });
        if (err) return;

        setDeletedItems((oldList) => oldList.filter((item) => !selectedItems.some((selectedItem) => selectedItem.id === item.id)));
        setSelectedItems([]);
        setIsSelectedAll(false);
    }, [user, selectedItems]);

    const emptyTrash = useCallback(async () => {
        // delete all rows
        const folderList = deletedItems
            .filter((item) => item.type === "File Folder")
            .map((item) => ({ folderId: item.id, folderName: item.name, location: item.location }));
        const docList = deletedItems
            .filter((item) => item.type !== "File Folder")
            .map((item) => ({ docId: item.id, fileName: item.name, path: item.location }));

        const err = await axios
            .post("deleteTrashItemsForever", { docList, folderList, clientId: user.userId, isCompany: user.firmId !== -1 })
            .then((res) => {
                if (res.status !== 200) return true;
                return null;
            })
            .catch((err) => {
                console.error(err);
                return true;
            });
        if (err) return;
        setDeletedItems([]);
        setSelectedItems([]);
        setIsSelectedAll(false);
    }, [deletedItems, user]);

    const handleClick = useCallback(
        (item) => {
            // select or deselect the row that is clicked
            const isSelected = selectedItems.includes(item);

            if (isSelected) {
                // if already selected, unselect it
                setSelectedItems((oldList) => {
                    const tempList = [...oldList];
                    return tempList.filter((itm) => itm.id !== item.id);
                });
                return;
            }
            // select it
            setSelectedItems((oldList) => [...oldList, item]);
        },
        [selectedItems]
    );

    const toggleSelectAll = useCallback(() => {
        // select or deselect all rows when select all button clicked
        setSelectedItems(() => {
            if (isSelectedAll) {
                return [];
            }
            return deletedItems;
        });

        setIsSelectedAll((initialValue) => !initialValue);
    }, [isSelectedAll, deletedItems]);

    const value = useMemo(
        () => ({
            selectedItems,
            deletedItems,
            searchText,
            isSelectedAll,
            search,
            restoreItem,
            deleteItem,
            emptyTrash,
            handleClick,
            toggleSelectAll,
        }),
        [selectedItems, deletedItems, searchText, isSelectedAll, search, restoreItem, deleteItem, emptyTrash, handleClick, toggleSelectAll]
    );
    return <TrashContext.Provider value={value}>{children}</TrashContext.Provider>;
};

export default TrashProvider;
