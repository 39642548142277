import { Fragment, useContext, useMemo, useState, useEffect, useCallback } from "react";
import axios from "axios";

import Person from "./components/person/person.component";
import Company from "./components/company/company.component";
import NewProfileModal from "./components/new-profile-modal/new-profile-modal.component";

import { AuthContext } from "src/contexts/auth.context";

import { POSITIONS } from "utils/lists.utils";

import "./people.style.scss";
import useTranslate from "src/hooks/use-translate";
import { getNow } from "utils/date.utils";
import Modal from "components/modal/modal.component";
import YesNoBox from "components/yes-no-box/YesNoBox.component";

const sortEmployeeList = (list) => {
    const admins = list.filter((person) => person.position === 0);
    const members = list.filter((person) => person.position === 1);

    return [...admins, ...members];
};

const People = () => {
    const {
        user: { position, userId, firmId, color, accountant },
    } = useContext(AuthContext);

    const t = useTranslate("Account");

    const [peopleList, setPeopleList] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [isNewMemberModalVisible, setIsNewMemberModalVisible] = useState(false);
    const [showRemovePersonWarning, setShowRemovePersonWarning] = useState(false);
    const [approvedRemove, setApprovedRemove] = useState(false);
    const [userToBeDeletedId, setDeleteUserId] = useState(null);

    const fetchMemberList = useCallback(() => {
        axios
            .get(`/getMembers?userId=${userId}&firmId=${firmId}&position=${position}`)
            .then(({ data }) => {
                setPeopleList(sortEmployeeList(data));
            })
            .catch(console.log);
    }, [userId, firmId, position]);

    useEffect(() => {
        if (!userId) return;
        fetchMemberList();
    }, [firmId, userId, position, fetchMemberList]);

    const isAdmin = useMemo(() => position === 0, [position]);

    const accountants = useMemo(() => peopleList, [peopleList]);

    const handleOpenModal = () => {
        setIsNewMemberModalVisible(true);
    };

    const handleCloseModal = () => {
        setIsNewMemberModalVisible(false);
    };

    const handleRemove = (deletedUserId) => {
        setShowRemovePersonWarning(true);
        setDeleteUserId(deletedUserId);
    };

    const handleAddNewMember = async (newProfile) => {
        const err = await axios
            .post("/newMember", { ...newProfile, createdAt: getNow() })
            .then((res) => (res.status !== 200 ? true : null))
            .catch((err) => {
                console.error(err);
                return true;
            });
        if (err) return;
        setPeopleList((oldValue) => {
            newProfile.clients = [];
            const newValue = [...oldValue];
            newValue.push(newProfile);
            return sortEmployeeList(newValue);
        });
    };

    const handleClickOnRow = (index) => {
        if (selectedIndex === index) {
            setSelectedIndex(-1);
            return;
        }
        setSelectedIndex(index);
    };

    useEffect(() => {
        if (approvedRemove === true && userToBeDeletedId !== null) {
            axios.post("/deleteAccount", { userId, firmId, userToDelete: userToBeDeletedId }).then(console.log).catch(console.error);

            setPeopleList((oldValue) => {
                const newValue = oldValue.filter(({ userId: _userId }) => _userId !== userToBeDeletedId);
                return newValue;
            });
            setDeleteUserId(null);
        }
    }, [approvedRemove, userId, firmId, userToBeDeletedId]);

    return (
        <div className="people-container">
            <span className="size600 w600 with-line">{t("People")}</span>
            <div className="data-wrapper scroll-bar scroll-bar--overflow">
                <div className="flex flex--column margin-m--bottom">
                    {accountant === -1
                        ? peopleList.map((accountant, index) => {
                              const fullName = accountant.name + " " + accountant.surname;
                              return (
                                  <Fragment key={index}>
                                      <div
                                          name="accordion-row"
                                          className={`person-wrapper padding-s--block padding-m--inline border--bottom ${
                                              index === selectedIndex && "expanded-title"
                                          }`}>
                                          <Person
                                              onClick={() => handleClickOnRow(index)}
                                              index={index}
                                              id={accountant.userId}
                                              name={fullName}
                                              jobTitle={accountant.jobTitle}
                                              position={POSITIONS[accountant.position]}
                                              color={color}
                                              isAdmin={isAdmin}
                                              onRemove={handleRemove}
                                          />
                                      </div>

                                      <div
                                          name="accordion-data"
                                          className={`accordion-data padding-m--inline ${index === selectedIndex && "expanded padding-m--top"}`}
                                          style={{ overflow: "hidden" }}>
                                          {/* Expanded Part */}
                                          <p className="w600 padding-xs--bottom border--bottom border--thick border--red margin-m--bottom">
                                              {t("Clients")} {`(${accountant.clients.length})`}
                                          </p>
                                          <div
                                              className="flex flex--column gap-l scroll-bar scroll-bar--overflow padding-m--right"
                                              style={{ maxHeight: "40vh" }}>
                                              {accountant.clients.map((client, index) => {
                                                  return (
                                                      <Company
                                                          key={index}
                                                          client={client}
                                                          accountantList={accountants}
                                                          selectedAccountant={fullName}
                                                          onSelect={fetchMemberList}
                                                          isAdmin={isAdmin}
                                                      />
                                                  );
                                              })}
                                          </div>
                                      </div>
                                  </Fragment>
                              );
                          })
                        : peopleList.map((accountant, index) => {
                              const fullName = accountant.name + " " + accountant.surname;
                              return (
                                  <Fragment key={index}>
                                      <div
                                          name="accordion-row"
                                          className={`person-wrapper padding-s--block padding-m--inline border--bottom ${
                                              index === selectedIndex && "expanded-title"
                                          }`}>
                                          <Person
                                              onClick={() => handleClickOnRow(index)}
                                              index={index}
                                              id={accountant.userId}
                                              name={fullName}
                                              jobTitle={accountant.jobTitle}
                                              position={POSITIONS[accountant.position]}
                                              color={color}
                                              isAdmin={isAdmin}
                                              onRemove={handleRemove}
                                          />
                                      </div>
                                  </Fragment>
                              );
                          })}
                </div>

                {isAdmin && (
                    <button onClick={handleOpenModal} className="size400 w600 text-left">
                        + {t("add new member")}
                    </button>
                )}
            </div>
            {isNewMemberModalVisible && <NewProfileModal dismiss={handleCloseModal} color={color} onSave={handleAddNewMember} firmId={firmId} />}
            {showRemovePersonWarning && (
                <Modal
                    modal={
                        <YesNoBox
                            setVisible={setShowRemovePersonWarning}
                            setApproved={setApprovedRemove}
                            text={t("delete-user-warning")}
                            rightOption={t("remove-person")}
                            rightOptionRed
                        />
                    }
                    touchable
                    dismiss={[setShowRemovePersonWarning]}
                />
            )}
        </div>
    );
};

export default People;
