import Selector from "components/selector/selector.component";
import { useState } from "react";

import "./company.style.scss";
import axios from "axios";

const Company = ({ client, accountantList, selectedAccountant, onSelect, isAdmin = false }) => {
    const [newSelectedAccountant, setNewSelectedAccountant] = useState("");

    const organizedAccountantList = accountantList.map(({ name, surname }) => {
        return name + " " + surname;
    });

    const { color } = client;
    const companyName = client.surname ? client.name + " " + client.surname : client.name;

    const assignNewAccountant = async (newAccountant) => {
        const accountant = accountantList.find(({ name, surname }) => {
            return name + " " + surname === newAccountant;
        });
        setNewSelectedAccountant(accountant.name + " " + accountant.surname);
        let res;
        if (client.firmId === -1) res = await axios.post("assignClient", { userId: client.userId, accountantId: accountant.userId });
        else res = await axios.post("assignFirm", { firmId: client.firmId, accountantId: accountant.userId });
        if (res.status !== 200) return;
        setNewSelectedAccountant("");
        onSelect();
    };

    return (
        <div className="company-container flex flex--center-items gap-m">
            <div className="smooth-corners" style={{ aspectRatio: 1, height: "1.8rem", backgroundColor: color ?? "#000000" }} />
            <p className="w600" style={{ width: "25%" }}>
                {companyName}
            </p>
            <p>{client.vatNumber ?? ""}</p>
            {isAdmin && (
                <div style={{ width: "30ch", marginLeft: "auto" }}>
                    <Selector
                        data={organizedAccountantList}
                        selected={newSelectedAccountant === "" ? selectedAccountant : newSelectedAccountant}
                        onSelected={assignNewAccountant}
                    />
                </div>
            )}
        </div>
    );
};

export default Company;
