import "./input-field.style.scss";

const FIELD_TYPES = {
	box: "box",
	underline: "underline",
	invisible: "invisible",
};

const InputField = ({
	label,
	placeholder,
	onChange,
	value,
	type = "text",
	fieldType = FIELD_TYPES.box,
	className,
	...props
}) => {
	return (
		<div className={`flex flex--column ${className}`}>
			{label && <p className="size300 w600">{label}</p>}
			<input
				{...props}
				className={`custom-input padding-xs--block
			${fieldType === FIELD_TYPES.box && "box"}
			${fieldType === FIELD_TYPES.underline && "underline"}
			${fieldType === FIELD_TYPES.invisible && "invisible"}
			${className}
			`}
				type={type}
				placeholder={placeholder}
				value={value}
				onChange={onChange}
			/>
		</div>
	);
};

export default InputField;
