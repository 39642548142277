import { useContext, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { ReactComponent as LeftArrow } from "assets/left-arrow.svg";

import { AuthContext } from "../../../contexts/auth.context";

import useTranslate from "../../../hooks/use-translate";

import "../../login/login.style.scss";
import WarningBox from "components/warning-box/warning-box.component";

const Verification = () => {
    const { newPassword } = useContext(AuthContext);
    const t = useTranslate("Login");
    const navigate = useNavigate();
    const {
        state: { email },
    } = useLocation();

    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [error, setError] = useState(false);
    const [shortPasswordError, setShortPasswordError] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const email = event.target.email.value;
        await newPassword({ email, password });
        navigate("/");
    };

    const handleNewPassword = async (event) => {
        event.preventDefault();
        if (password !== password2) {
            setShortPasswordError(false);
            setError(true);
            return;
        } else if (password.length < 6) {
            setError(false);
            setShortPasswordError(true);
            return;
        }
        await newPassword({ email, password });
        navigate("/");
    };

    return (
        <div className="login-container">
            <div className="login-left-side"></div>
            <form className="login-right-side" onSubmit={handleSubmit}>
                <div className="login-form-container">
                    <Link to={"/"} style={{ width: "min-content" }}>
                        <LeftArrow style={{ fontSize: "2rem" }} />
                    </Link>
                    <span className="title margin-m--top">{t("new password").toUpperCase()}</span>
                    <input
                        className="text-box"
                        type="text"
                        placeholder={t("New Password")}
                        name="password1"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <input
                        className="text-box"
                        type="text"
                        placeholder={t("New Password Again")}
                        name="password2"
                        value={password2}
                        onChange={(e) => setPassword2(e.target.value)}
                    />

                    <div className="flex flex--start-items">
                        {/* {error && <WarningBox text={ERROR_CODES[error]} />} */}
                        <button
                            className="btn btn--red size500 w500 padding-s--block padding-xxl--inline margin-auto--left"
                            type="submit"
                            onClick={handleNewPassword}>
                            {t("confirm").toUpperCase()}
                        </button>
                    </div>
                    {error && <WarningBox text={t("passwords dont match")} style={{ marginTop: "2vh" }} size="0.6rem" fontSize={"1rem"} />}
                    {shortPasswordError && <WarningBox text={t("password length min 6")} style={{ marginTop: "2vh" }} size="0.6rem" fontSize={"1rem"} />}
                </div>
            </form>
        </div>
    );
};

export default Verification;
